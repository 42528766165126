img
  image-orientation: none !important

.jobvibe-future .fix-modal
  width: auto
  @media (max-width: 479px)
    width: 100%
  @media (min-width: 768px)
    width: 672px
  .modal-content
    margin-top: 50px
    max-width: 692px
    transform: translateX(-50%)
    position: relative
    left: 50%
    #related-posts-link
      background: #FE873C
      color: white
      padding: 0 55px
      line-height: 18px
      font-family: Roboto
      font-size: 15px
      height: 0
      transition: height .5s .5s
      position: relative
      &:after
        transition: height .3s , width .3s
        position: absolute
        bottom: 0
        height: 0
        width: 0
        left: 98px
        content: ''
        transform: translateY(50%) rotate(45deg)
        background: #FE873C
      span
        transition: opacity .5s
        opacity: 0
        margin-top: 17px
        #anchor
          display: inline-block
          margin-left: 20px
          font-weight: bold
          position: relative
          cursor: pointer
          color: white
          &:hover
            color: white
          &:after
            position: absolute
            content: ''
            background: linear-gradient(to bottom right, #FE873C 49.5%, #ffffff 50.5%)
            height: 7px
            width: 7px
            top: 50%
            transform: translateY(-50%) rotate(-45deg)
            right: -20px

      &[visible='true']
        transition: height .3s
        height: 54px
        @media(max-width: 535px)
          height: 74px
        &:after
          height: 8px
          width: 8px
          transition: height .3s, width .3s
        span
          transition: opacity .5s .3s
          opacity: 1
    .modal-inner-wrap
      width: 100%
      padding: 25px 56px 30px
      .close-cross
        top: 15px
        right: 15px
        position: absolute
        width: 30px
        height: 30px
        background: #11A9FF
        border-radius: 100%
        cursor: pointer
        &:before, &:after
          display: block
          content: ''
          height: 2px
          width: 16px
          position: absolute
          left: 50%
          top: 50%
          background: white
        &:before
          transform: translate(-50%, -50%) rotate(-45deg)
        &:after
          transform: translate(-50%, -50%) rotate(45deg)
        @media (min-width: 768px)
          top: -15px
          right: -15px

      h1
        font-family: Roboto
        font-size: 20px
        font-weight: 500
        color: #11A9FF
        margin-bottom: 16px
      h2
        font-size: 16px
        font-family: Roboto
        font-weight: 500
        color: #323232
        margin: 16px 0 8px
        span
          color: #E43F3F
          margin-left: 5px
          font-size: 12px
      .text__control
        max-height: 112px
        font-size: 13px
        .text__highlighter
          max-height: 112px
      .js-textarea-comment
        margin-bottom: 24px
        min-height: 56px
        height: 71px
        resize: none
        width: 100%
        padding: 0
        border: none
      .subject-box
        padding: 6px 37px 6px 11px
        background: #FE873C
        color: white
        position: relative
        border-radius: 3px
        font-size: 12px
        font-weight: bold
        display: inline-block
        margin: 5px 10px 9px 0px
        .subject-cross
          cursor: pointer
          position: absolute
          display: inline-block
          height: 10px
          width: 10px
          right: 8px
          top: 50%
          transform: translateY(-50%)
          &:before, &:after
            border-radius: 2px
            width: 10px
            height: 2px
            background: white
            content: ''
            position: absolute
            top: 50%
            left: 50%
          &:before
            transform: translate(-50%, -50%) rotate(-45deg)
          &:after
            transform: translate(-50%, -50%) rotate(45deg)
      textarea
        height: 71px
        margin-top: 2px
      input, textarea
        width: 100%
        margin-bottom: 9px
        padding: 9px 13px
        font-size: 13px
        line-height: 16px
        border-radius: 3px
        border: 1px solid #D9D9D9
        &::placeholder
          color: #8B8A96
        &[type='button']
          background: #11A9FF
          border-radius: 3px
          width: 140px
          text-align: center
          color: white
          font-size: 16px
          font-weight: bold
          padding: 13px 10px
          outline: none
          border: none
      select
        width: 162px
        padding: 8px 10px
        margin-bottom: 7px
        background: none
        font-size: 13px
        line-height: 16px
        border-radius: 3px
        border: 1px solid #D9D9D9
        color: #8B8A96
      #images-wrap
        text-align: left
        line-height: 0
        margin-bottom: 36px
        .preview
          image-orientation: none
          display: inline-block
          height: 80px
          width: 80px
          background-size: cover
          background-position: center
          margin: 5px 15px 5px 5px
          border-radius: 3px
          position: relative
          .preview-cross
            position: absolute
            top: -10px
            right: -10px
            width: 20px
            height: 20px
            background: #11A9FF
            border-radius: 50%
            cursor: pointer
            z-index: 2
            &:before, &:after
              width: 10px
              height: 2px
              position: absolute
              top: 50%
              left: 50%
              background: white
              border-radius: 2px
              content: ''
            &:before
              transform: translate(-50%, -50%) rotateZ(45deg)
            &:after
              transform: translate(-50%, -50%) rotateZ(-45deg)
          .video-react
            height: 100%
            .video-react-control-bar
              display: none
            .video-react-big-play-button
              width: 30px
              height: 16px
              border-radius: 3px
              bottom: 10px
              top: unset
              &:before
                font-size: 16px
                line-height: 13px

      #dropzone-wrap
        position: relative
        margin-bottom: 24px
        margin-top: 19px
        cursor: pointer
        h3
          pointer-events: none
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          text-align: center
          font-size: 14px
          font-family: Roboto
          white-space: nowrap
          color: #69BADE
          z-index: 1
          svg
            color: #DADADA
            margin-right: 10px
            position: relative
            top: 2px
          span
            text-decoration: underline
            cursor: pointer
      .collapse
        border-bottom: 1px solid #dfdfdf
        padding-bottom: 30px
        margin-bottom: 30px
        .collapse-content-wrap
          width: 100%
          margin: 0 auto 30px
          #croppie
            clear: both
            .cr-boundary, .cr-resizer
              max-width: 100%
              .cr-viewport
                max-width: 100%
          .btn-success
            float: left
            margin-left: 40px
          .btn-danger
            float: right
            margin-right: 40px
      span.error-message
        font-family: Roboto
        opacity: 0
        transition: opacity .3s
        color: #cd3c40
        font-weight: bold
        padding-left: 20px
        &.shown
          opacity: 1