.btn-group
  .btn-default
    background-color: white
    border-color: $mid-grey
    color: $mid-grey

    &:hover
      background-color: transparent

    &.active
      box-shadow: none
      border-bottom: 3px solid $amber
      color: $dark-grey
      font-weight: bold

    &.attention-flash
      -webkit-animation-duration: 0.5s
      animation-duration: 0.5s
      -webkit-animation-fill-mode: both
      animation-fill-mode: both
      -webkit-animation-name: loaded-flash
      animation-name: loaded-flash

    @-webkit-keyframes loaded-flash
      70%
        border-bottom-color: #FBAA00

    @keyframes loaded-flash
      70%
        border-bottom-color: #FBAA00
