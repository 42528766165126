.team-dropdown-toggle
  font-size: 13px
  display: flex
  align-items: center
  position: relative
  top: 2px
  margin-right: 24px
  cursor: pointer
  max-width: 220px
  justify-content: flex-end
  .current-name
    margin-left: 3px
    margin-right: 9px
    color: #11A9FF
    font-weight: bold
  svg
    transition: .6s
    flex-shrink: 0
    &.opened
      transform: rotateZ(180deg)
  .team-dropdown-list
    width: 230px
    position: absolute
    top: calc(100% + 3px)
    padding: 15px 17px 15px
    border: 1px solid #E6E6E6
    box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
    border-radius: 3px
    right: 0
    z-index: 10
    background: white
    cursor: initial
    font-weight: initial
    transition: .3s
    opacity: 0
    color: initial
    &.mounted
      opacity: 1
    input
      background: #FFFFFF
      border: 1px solid #11A9FF
      box-sizing: border-box
      border-radius: 4px
      font-size: 13px
      width: 100%
      padding: 10px 13px
      height: 32px
      outline: none
      margin-bottom: 10px
      &::placeholder
        color: #C4C4C4
        font-style: italic
    .team
      cursor: pointer
      display: flex
      align-items: center
      &:not(:last-child)
        margin-bottom: 10px
      .space-logo, img
        display: inline-block
        font-weight: 900
        width: 26px
        height: 26px
        border: 1px solid white
        border-radius: 100%
        margin: 0
        line-height: 26px
        font-size: 13px
        text-align: center
        flex-shrink: 0
      .team-name
        font-size: 13px
        position: relative
        left: 11px
        top: 1px
        flex-grow: 1
        .ending
          color: #8a8a8a