.leave-modal
  .close-cross
    top: 0
    right: 0
    position: absolute
    width: 30px
    height: 30px
    background: #11A9FF
    border-radius: 100%
    cursor: pointer
    transform: translate(50%, -50%)
    &:before, &:after
      display: block
      content: ''
      height: 2px
      width: 16px
      position: absolute
      left: 50%
      top: 50%
      background: white
    &:before
      transform: translate(-50%, -50%) rotate(-45deg)
    &:after
      transform: translate(-50%, -50%) rotate(45deg)
  .modal-content
    position: relative
    h1
      color: #11A9FF
      text-align: center
      font-size: 24px
      padding-top: 24px
      margin-bottom: 0
    h2
      font-size: 14px
      text-align: center
      margin-top: 16px
      line-height: 24px
      padding: 0 20px
    h3
      line-height: 18px
      font-size: 13px
      text-align: center
      color: #979797
      margin-top: 10px
    .buttons-wrap
      width: 100%
      margin-top: 19px
      padding-right: 15px
      display: flex
      justify-content: center
      padding-bottom: 24px
      & > div
        width: 130px
        cursor: pointer
        border-radius: 4px
        user-select: none
        text-align: center
        &.accept-button
          padding: 10px
          font-size: 16px
          background: #11A9FF
          color: white
          font-weight: bold
          margin-right: 20px
        &.cancel-button
          font-size: 15px
          padding: 10px
          color: #11A9FF