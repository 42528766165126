.product-overview
  .overview-detail
    min-height: 100vh
    +phones-and-tablets
      min-height: 0

  .jv-logo
    img
      max-height: rhythm(1.5)

.overview-vibe
  overflow-x: hidden

+phones-and-tablets
  .product-overview .overview-screenshot
    min-height: 0

.overview-wrapper
  margin: rhythm(5) auto 0 auto
  text-align: center
  color: white
  max-width: 470px
  +phones-and-tablets
    margin-top: rhythm(1)

  img
    max-height: 75%
    max-width: 90%
    +desktop-only
      position: absolute
      left: 115%
      padding-right: 20%
      top: rhythm(5)
    +phones-and-tablets
      margin-top: rhythm(1)
      margin-bottom: rhythm(1)
      max-height: rhythm(8)

.product-overview-bg
  position: fixed
  top: 0
  left: 0
  bottom: 0
  width: 50%
  // background-image: url(image_path('how-jobvibe-works-bg.png'))
  background-repeat: repeat
  +phones-and-tablets
    width: 100%

  &.product-overview-bg-vibe
    background-color: $blue
  &.product-overview-bg-fix
    background-color: $red
  &.product-overview-bg-buzz
    background-color: $amber

.product-overview-complete-flash
  background: $blue
  color: white
  transform: translate(0, -100%)
  height: 0
  overflow: hidden
  padding: 0
  margin: 0

  &.is-visible
    height: auto
    padding: rhythm(1)
    transition-property: all
    transition-duration: .5s
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
    transform: translate(0, 0%)

  .store-links img
    max-height: rhythm(1.5)
    margin-top: rhythm(0.25)
    margin-bottom: rhythm(0.25)
  img.img-responsive
    width: 100px