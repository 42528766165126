// chart.js

.doughnut-value
  font-size: 37px
  font-weight: bold
  width: 100%
  height: 0

  .number
    position: absolute
    top: 40px
    text-align: center
    position: relative

.vibe-awesome
  color: $deep-blue !important

.vibe-good
  color: $deep-blue !important

.vibe-ok
  color: $yellow !important

.vibe-bad
  color: $red !important

.vibe-terrible
  color: $red !important
