.onboarding-modal
  max-width: 860px
  width: 630px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  height: unquote("calc(100vh - 20px)")

.onboarding-modal.summary
  width: 860px

.existed-meeting-modal
  max-width: 450px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  max-height: 240px

  .modal-content
    overflow: hidden

.schedule-modal
  @media(min-width: 670px)
    width: 670px
  @media(max-width: 670px)
    max-width: 670px
    width: auto
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    height: unquote("calc(100vh - 20px)")
  .modal-content
    z-index: 1
    img.close-button
      display: block !important
  .Select-control
    height: 42px
    background: #fff
    border: 1px solid #EDEDED
    box-sizing: border-box
    border-radius: 4px
    padding-left: 14px
  .Select-menu-outer
    .Select-menu
      .Select-option.has_settings
        &:not(.is-focused)
          background-color: rgba(  237, 237, 237, 0.5)
  .Select-placeholder, .Select-value
    padding-top: 4px
    margin-left: 5px

  .Select-value-label
    padding-left: 5px

  .Select-input
    padding-left: 0
    padding-top: 4px

  .Select-menu-outer
    background-color: #fff

  .modal-content
    width: 100%
.tour-modal
  width: 766px

  .modal-content
    border-radius: 6px

body.grow.index.jobvibe-future.modal-open
  div[role="dialog"]:last-of-type
    div.modal-backdrop.fade.in
      z-index: 1050
