@import "../settings"

.fix-form
  border: 3px solid $orange
  img#upload_preview
    max-width: 100%
    max-height: 350px

.fix-status
  border-top: 4px solid $bright-green
  background-color: $pale-green
  padding: 8px

span.success
  color: $bright-green
  font-weight: bold


.jobvibe-future .post-show
  margin: 0px -17px
  @media (max-width: $screen-xs-max)
    margin-left: -14px
    margin-right: -14px
    margin-top: -12px
    .back-btn
      margin-left: 14px
      margin-top: 10px
  .__react_component_tooltip.place-top:after
    margin-left: -12px
  .post-show-inner-wrap
    display: flex
    padding: 0 10px
    @media (max-width: 480px)
      display: block
    .post-menu
      padding-left: 31px
      width: 297px
      margin-top: -3px
      @media (max-width: 480px)
        padding-left: 3px
      h3
        font-size: 14px
        color: #11A9FF
        margin-bottom: 8px
        cursor: pointer
        transition: color .5s
        &:hover
          color: #00D5E2
    //------If you need to overwrite some styles for specific type of post, add additional class to component with ".post-wrapper" class
    //------to make your styles more important
    .post-wrapper
      padding: 29px 29px 0
      border-radius: 4px
      position: relative
      border: 1px solid #F3F3F3
      background-color: #fff
      border-bottom-width: 2px
      margin-bottom: 23px
      width: 100%
      .post-header
        margin-bottom: 18px
        display: flex
        position: relative
        align-items: center
        .author-avatar
          padding-right: 12px
          img
            width: 36px
            height: 36px
            border-radius: 100%
            @media(max-width: 767px)
              width: 50px
              height: 50px
        .author-info
          display: flex
          flex-direction: column
          justify-content: center
          white-space: nowrap
          .author-name
            font-size: 14px
            font-weight: bold
            line-height: 22px
          .author-role-position
            font-size: 11px
            color: #BBBBBB
            font-weight: normal
            line-height: normal
            display: block
          & + div, + img.heart
            margin-left: auto
        a
          color: #323232
          text-decoration: none
        .heart
          width: 20px
          height: 20px
          margin-left: 20px
          stroke: #D5D5D5
          fill: #ffffff
          overflow: visible
          transition: stroke .3s, fill .3s
          cursor: pointer
          &.marked
            fill: #F5351B
            stroke: #F5351B
        .pinned-post
          height: 22px
          width: 22px
          overflow: visible
          cursor: pointer
        .poll-alert-icon
          display: inline-block
          margin: 0 auto
      .post-body
        h2.editing-label
          font-size: 15px
          line-height: 18px
          font-weight: 500
          margin-bottom: 12px
        .help-block.text-danger
          color: #CD3C40 !important
          font-size: 16px !important
          font-weight: bold !important
        #images-wrap
          text-align: left
          line-height: 0
          margin-bottom: 35px
          .preview
            image-orientation: none
            max-height: 80px
            max-width: 80px
            display: inline-block
            background-size: cover
            background-position: center
            margin: 5px 15px 5px 5px
            border-radius: 3px
            position: relative
            img.preview
              max-width: 100%
              height: auto
              vertical-align: top
            &:before
              content: 'x'
              position: absolute
              top: -10px
              right: -10px
              width: 20px
              height: 20px
              background: #11A9FF
              border-radius: 50%
              cursor: pointer
              color: #fff
              line-height: 20px
              text-align: center
              font-weight: bold
              font-size: 10px
              z-index: 1
            > div
              height: 100%
              .video-react-control-bar
                display: none
              .video-react-big-play-button
                width: 2em
          .preview-video
            display: block

            > div.video-react
              min-height: 80px
        #dropzone-wrap
          position: relative
          margin-bottom: 24px
          margin-top: 10px
          cursor: pointer
          h3
            pointer-events: none
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            text-align: center
            font-size: 14px
            font-family: Roboto
            white-space: nowrap
            color: #69BADE
            z-index: 1
            line-height: 20px
            @media(max-width: $screen-sm-max)
              white-space: initial
            svg
              color: #DADADA
              margin-right: 10px
              position: relative
              top: 2px
            span
              text-decoration: underline
              cursor: pointer
        .collapse.show
          margin-bottom: 15px
          .collapse-content-wrap
            text-align: center

        .post-show-media
          margin: 0 -29px 28px
          .img-responsive
            margin: auto
          .slide
            height: auto
            bottom: 0
          .border
            border-radius: 0 !important
            border: 1px solid #fff !important
            .cover
              border-radius: 0 !important
          img
            height: 360px
            background-size: contain
            background-position: center
            background-repeat: no-repeat
          div.img-div
            height: 360px
            background-size: contain
            background-position: center
            background-repeat: no-repeat
          .carousel-indicators
            position: absolute
            bottom: 10px
            li
              background-color: rgb(255,255,255)
              background-color: rgba(255,255,255, 0.6)
              &.active
                background-color: rgb(255,255,255)
                background-color: rgba(255,255,255, 1)
          .carousel-indicators
            bottom: -25px
          .carousel-inner
            .item
              height: 360px
          .carousel-control
            z-index: 5
            color: #DADADA
            line-height: 30px
            opacity: 1
            width: 30px
            div
              position: absolute
              width: 13px
              height: 13px
              top: 50%
              transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg)
              border-bottom: 2px solid white
              border-right: 2px solid white
              &.control-right
                right: 11px
              &.control-left
                transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg)
                left: 11px
          .video-react
            padding: 0 !important
            min-height: 50px
            height: 100%
        .post-title
          font-size: 18px
          margin-bottom: 10px
          img
            width: 18px
            height: 18px
            margin-top: -3px
            margin-right: 12px
            &.editing
              margin-top: 12px
              display: none
          &.resolved .type
            color: #4da60b
            .title
              color: #4da60b
          .type
            color: #B7B7B7
            font-weight: bold
            &.black
              color: #323232
            .title
              color: #323232
        .post-marked-done-ago
          line-height: 13px
          font-size: 13px
          margin-bottom: 20px
          color: #4DA60B
        .shoutout-receiver
          text-align: center
          .buzz-shoutout-title
            font-weight: 500
            line-height: normal
            font-size: 18px
            color: #323232
            margin: 5px 0 20px
          a
            color: #323232
            text-decoration: none
        .post-content
          div.company-values
            z-index: 2
          .mentions-input-wrap
            padding: 6px 12px
            border: 1px solid #D9D9D9
            margin-bottom: 15px
            min-height: 100px
            textarea
              min-height: unset
              border: none !important
          .post-content-text
            margin-bottom: 14px
            font-size: 14px
            line-height: 21px
            overflow-wrap: anywhere
            a
              cursor: pointer
              &:hover
                text-decoration: none
          textarea
            border-radius: 0
            box-shadow: none
            transition: none
            border: 1px solid #D9D9D9
            &:focus
              border: 1px solid #C7C7C7
              outline: none
          #save-edit-btn
            margin-bottom: 20px
          .file-link
            text-decoration: none
            .file-tile
              background: #F8F8F8
              //background: linear-gradient(to left, #8BDBFF 0%, rgba(139, 219, 255, 0.5)  100%)
              display: flex
              height: 40px
              padding: 0 13px
              border-radius: 6px
              margin-bottom: 10px
              position: relative
              cursor: pointer
              svg
                align-self: center
                margin-right: 12px
              .file-name
                align-self: center
                color: #11A9FF
          .post-tile-space-info
            width: 70%
            text-align: right
            margin-top: 3px
            font-size: 13px
            color: #B7B7B7
            padding: 0 1px
            a
              color: #58575F
              text-decoration: none
              padding-left: 12px
            .space-logo, .circle-image
              display: inline-block
              font-weight: bold
              width: 18px
              height: 18px
              border: 1px solid white
              border-radius: 100%
              margin: 0
              line-height: 180%
              font-size: 9px
              font-family: "Roboto", sans-serif
              text-align: center
              text-shadow: none
              position: relative
              bottom: 2px
            .circle-image
              border-color: #F3F3F3
          .post-details-wrap
            display: flex
            .post-details
              width: 30%
              .time-ago, .views-counter
                font-style: normal
                font-weight: normal
                line-height: normal
                font-size: 12px
                color: #D5D5D5
                margin-bottom: 30px
                display: inline-block
                text-align: left
              .time-ago
                padding-right: 20px
          div.poll-info
            position: relative
            height: 45px
            .time-ago
              position: absolute
              margin-top: 5px
            .vote-btn
              width: 100%
              @media(max-width: 306px)
                width: 90%
              position: absolute
              float: right
              text-align: center
              a
                font-weight: 500
                cursor: pointer
                position: relative
                font-size: 13px
                line-height: 15px
            .vibe-anon
              span
                font-size: 11px
                line-height: 13px
                padding-left: 8px
          .post-solution
            .post-solution-content-title p
              font-weight: bold
              line-height: 18px
              font-size: 14px
              color: #323232
              margin-bottom: 11px
              margin-top: 22px
            .post-solution-content-text
              margin-bottom: 10px
              font-size: 14px
              line-height: 20px
              textarea
                border-radius: 0
                box-shadow: none
                transition: none
                &:focus
                  border: 1px solid #C7C7C7
                  outline: none
          .categories-wrapper
            overflow-x: hidden
            text-overflow: ellipsis
            margin-top: 22px
            margin-bottom: 27px
            font-size: 14px
            line-height: 22px
            span
              color: #323232
              font-weight: bold
          .tags-wrapper
            margin-bottom: 24px
            text-align: left
            margin-top: -5px
            .tags-title
              font-weight: bold
              line-height: 18px
              font-size: 14px
              color: #323232
              margin-bottom: 11px
              margin-top: 22px
            a, span
              max-width: 100%
              overflow-x: hidden
              text-overflow: ellipsis
              text-decoration: none
              font-size: 14px
              line-height: 21px
              background: #11A9FF
              color: white
              border-radius: 20px
              padding: 6px 13px
              font-weight: bold
              margin-right: 7px
              white-space: nowrap
              display: inline-block
              margin-top: 5px
          .app-alert-header
            font-size: 18px
            line-height: 25px
            font-weight: bold
            padding-right: 30px
            margin-bottom: 24px
          .feedback-wrapper
            .feedback-info
              font-size: 14px
              line-height: 21px
              font-weight: bold
              padding-bottom: 29px
            .app-alert-feedback
              font-size: 14px
              line-height: 21px
              text-align: left
              padding-bottom: 20px
              .feedback-paragraph
                display: inline-block
                padding-left: 40px
                padding-top: 2px
              .feedback-counter
                display: inline-block
                position: absolute
                background: #11A9FF
                color: #ffffff
                border-radius: 50%
                width: 24px
                height: 24px
                text-align: center
                font-weight: bold
                padding-top: 2px
                margin-right: 16px
        .subject-wrapper
          margin-bottom: 15px
          span
            padding: 8px 30px 8px 18px
            color: white
            background-color: #11A9FF
            position: relative
            border-radius: 18px
            font-size: 13px
            line-height: 15px
            font-weight: 500
            display: inline-block
            margin: 10px 10px 5px 0px
            .subject-cross
              cursor: pointer
              position: absolute
              display: inline-block
              height: 10px
              width: 10px
              right: 8px
              top: 50%
              transform: translateY(-50%)
              &:before, &:after
                border-radius: 2px
                width: 10px
                height: 2px
                background: #8FCCE8
                content: ''
                position: absolute
                top: 50%
                left: 50%
              &:before
                transform: translate(-50%, -50%) rotate(-45deg)
              &:after
                transform: translate(-50%, -50%) rotate(45deg)
        .comment-error
          font-size: 12px
          margin-top: 7px
          color: #FE483C
          margin-left: 61px
        .post-tile-comment-input
          position: relative
          display: flex
          .media-control-panel
            margin-left: 5px
            img
              cursor: pointer
          .post-tile-icon-wrapper
            font-family: Roboto, sans-serif
            font-size: 13px
            display: inline-flex
            color: #323232
            cursor: pointer
            line-height: 40px
            font-weight: bold
            margin-left: -2px
            margin-right: 13px
            .post-tile-icon
              width: 30px
              height: 30px
              background-color: #E6E6E6
              -webkit-border-radius: 50%
              -moz-border-radius: 50%
              border-radius: 50%
              color: #fff
              display: inline-flex
              margin-top: 5px
              margin-right: 10px
              font-size: 15px
              justify-content: center
              img
                align-self: center
                padding-bottom: 0
            &.post-tile-liked
              font-weight: bold
              color: #11A9FF
              .post-tile-icon
                background-color: #11A9FF
          .mentions-input-wrap
            border-radius: 4px
            border: 1px solid #E7E7E7
            flex-grow: 1
            width: 80%
            min-height: 40px
            padding: 10px 34px 10px 14px
            font-size: 14px
            line-height: 16px
            background: #FFFFFF
            textarea
              word-wrap: break-word
              border: none
              min-height: unset
              &::placeholder
                color: #B2B1C0
                letter-spacing: .6px
                font-size: 12px
                line-height: 21px
            &.error
              border: 1px solid #F5351B
              background: #FFF7F5
        .comment-dropzone-wrap
          position: absolute
          top: 7px
          right: 16px
          cursor: pointer
          >div
            height: auto !important
            border: none !important
            border-radius: 0 !important
          h3
            position: absolute
            top: 50%
            left: 50%
            -ms-transform: translate(-50%, -50%)
            transform: translate(-50%, -50%)
            text-align: center
            font-size: 14px
            font-family: Roboto, sans-serif
            white-space: initial
            color: #69BADE
            z-index: 1
            margin-bottom: 4px
            line-height: 20px
        .comment-images-wrap
          text-align: left
          line-height: 0
          margin: 20px 0 20px 60px
          .preview
            border-radius: 3px !important
            display: inline-block
            width: 80px
            background-size: cover
            background-position: center
            margin: 5px 15px 5px 5px
            position: relative
            &:before
              content: 'x'
              position: absolute
              top: -10px
              right: -10px
              width: 20px
              height: 20px
              background: #11A9FF
              border-radius: 50%
              cursor: pointer
              color: #fff
              line-height: 20px
              text-align: center
              font-weight: bold
              font-size: 10px
              z-index: 1
            .video-react-big-play-button
              font-size: 1.5em
            .preview-img
              max-width: 100%
              height: auto
              vertical-align: top
          > div
            height: 100%
            .video-react-control-bar
              display: none
            .video-react-big-play-button
              width: 2em
      .post-owners
        height: 30px
        cursor: pointer
        margin-bottom: 30px
        display: flex
        justify-content: flex-start
        margin-left: -2px
        a
          text-decoration: none
          &:hover
            color: #323232
        .media-left
          padding-right: 45px
        a.post-owner-link
          font-family: Roboto, sans-serif
          font-style: normal
          font-weight: normal
          line-height: 13px
          font-size: 13px
          color: #323232
          text-decoration: none
        .media-left.media-middle
          position: relative
          z-index: 0
          img
            width: 34px
            height: 34px
            border: 2px solid #fff
            position: absolute
            top: 0
            left: 0
            &.post-owner-multiple-icon
              &:nth-child(2)
                left: 18px
                opacity: 0.95
                z-index: -1
              &:nth-child(3)
                left: 36px
                opacity: 0.9
                z-index: -2
              &:nth-child(4)
                left: 54px
                opacity: 0.85
                z-index: -3
              &:nth-child(5)
                left: 72px
                opacity: 0.8
                z-index: -4
              &:nth-child(6)
                left: 90px
                opacity: 0.75
                z-index: -5
              &:nth-child(7)
                left: 108px
                opacity: 0.7
                z-index: -6
        p
          font-family: Roboto, sans-serif
          font-weight: normal
          line-height: 34px
          font-size: 13px
          color: #323232
          text-decoration: none
      .post-comments-section
        margin: 25px -29px 0
        border-top: 1px solid #F3F3F3
        border-bottom-right-radius: 4px
        border-bottom-left-radius: 4px
        .comment-section
          margin: 0
          padding: 0
        .post-comments
          padding: 0 2px
          .user-comment
            display: table
            width: 100%
            &:first-of-type
              padding-top: 25px
            &:last-of-type
              padding-bottom: 30px
          .post-comments-unfold
            font-size: 12px
            line-height: 16px
            color: #11A9FF
            cursor: pointer
            padding: 0 32px 30px
          .post-comment
            display: table-cell
            font-size: 13px
            color: #323232
            position: relative
            padding: 0 32px 16px
            >a
              font-weight: 500
              text-decoration: none
              white-space: initial
              margin-right: 7px
              color: #323232
              @media (min-width: 768px)
                white-space: nowrap
            span
              width: 100%
              text-align: justify
              line-height: 18px
              word-wrap: break-word
              word-break: break-word
              margin-left: 0
              a
                color: #1297E3
                text-decoration: none
                word-wrap: break-word
              &.comment-content-text
                margin-right: 7px
              &.time-ago
                margin-top: 2px
                margin-left: 0
                font-size: 12px
                color: #D5D5D5
            .post-marked-done-content
              color: #4DA60B
              margin-top: 9px
              margin-bottom: 16px
              .post-icon-done
                width: 14px
                height: 14px
                margin-right: 5px
                margin-left: 3px
                margin-top: -2px
                align-self: center
              a
                color: #4DA60B!important
            .comment-dropdown
              cursor: pointer
              position: absolute
              top: 0
              right: 20px
              display: none
              user-select: none
              .options-wrap
                position: absolute
                display: none
                z-index: 2
                background-color: #fff
                border: 1px solid rgba(0,0,0,.15)
                box-shadow: 0 6px 12px rgba(0,0,0,.175)
                min-width: 80px
                font-weight: 700
                line-height: 18px
                font-size: 14px
                cursor: pointer
                border-radius: 4px
                padding: 10px
                right: -26px
                top: 30px
                &:after
                  content: ""
                  position: absolute
                  width: 15px
                  height: 15px
                  background: linear-gradient(to left top, rgba(255,255,255,0) 49.5%, rgba(255,255,255,1) 50.5%)
                  transform: rotate(45deg) skew(15deg, 15deg)
                  top: -8px
                  right: 29px
                  border-top: 1px solid #dedede
                  border-left: 1px solid #dedede
                .edit
                  background-color: #11A9FF
                  color: #fff
                  text-align: center
                  padding: 7px 30px
                  margin-bottom: 5px
                  border-radius: 2px
                  z-index: 2
                  position: relative
                .delete
                  background-color: #fff
                  color: #e43f3f
                  border: 1px solid #e43f3f
                  padding: 6px 30px
                  border-radius: 2px
                  margin-bottom: 10px
              &[custom-open='true']
                display: block
                .options-wrap
                  display: block
                  z-index: 3
            &:hover .comment-dropdown
              display: block
            &.editing
              a
                margin-top: 7px
              .mentions-input-wrap
                width: 85%
                padding: 6px
                background: white
                border-radius: 4px
                border: 1px solid #E7E7E7
                textarea
                  min-height: unset

                &.error
                  border: 1px solid #F5351B
                  background: #FFF7F5
              i
                padding-left: 3px
                margin-top: 7px
                color: #939da3
              div.comment-error
                margin-bottom: 15px
                padding-left: 75px
                padding-right: 50px

              div.comment-error-edit
                font-size: 12px
                margin-top: 7px
                color: #FE483C
            .comment-show-media
              margin: 10px 0 0
              width: 160px
              @media (min-width: 460px)
                width: 260px
              .img-responsive
                margin: auto
              .slide
                height: auto
                bottom: 0
              .border
                border-radius: 0 !important
                border: 1px solid #fff !important
                .cover
                  border-radius: 0 !important
              div.img-div
                height: 160px
                @media (max-width: 460px)
                  width: 160px
                background-size: contain
                background-position: top left
                background-repeat: no-repeat
              .carousel-indicators
                position: absolute
                bottom: 10px
                li
                  background-color: rgb(255,255,255)
                  background-color: rgba(255,255,255, 0.6)
                  &.active
                    background-color: rgb(255,255,255)
                    background-color: rgba(255,255,255, 1)
              .carousel-indicators
                bottom: -25px
              .carousel-inner
                .item
                  height: 160px
                  width: 260px
                  @media (max-width: 460px)
                    width: 160px
                  div.img-div
                    background-position: center
              .carousel-control
                z-index: 5
                color: #DADADA
                line-height: 30px
                opacity: 1
                width: 30px
                div
                  position: absolute
                  width: 13px
                  height: 13px
                  top: 50%
                  transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg)
                  border-bottom: 2px solid white
                  border-right: 2px solid white
                  &.control-right
                    right: 11px
                  &.control-left
                    transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg)
                    left: 11px
              .video-react
                padding: 0 !important
                min-height: 50px
                height: 100%

            &.editing
              display: flex
        #comment-dropzone-wrap
          position: relative
          margin-bottom: 32px
          margin-top: 19px
          cursor: pointer
          h3
            position: absolute
            top: 50%
            left: 50%
            -ms-transform: translate(-50%, -50%)
            transform: translate(-50%, -50%)
            text-align: center
            font-size: 14px
            font-family: Roboto, sans-serif
            white-space: initial
            color: #69BADE
            z-index: 1
            margin-bottom: 4px
            line-height: 20px


        .post-comment-input
          position: relative
          display: flex
          margin: 0 -3px 0 -1px
          .media-control-panel
            margin-left: 5px
            display: flex
            align-items: center
            img
              cursor: pointer
          .mentions-input-wrap
            border-radius: 4px
            border: 1px solid #E7E7E7
            flex-grow: 1
            width: 100%
            min-height: 40px
            padding: 10px 14px
            font-size: 12px
            background: #FFFFFF
            textarea
              word-wrap: break-word
              border: none
              min-height: unset
              &::placeholder
                color: #B2B1C0
                letter-spacing: .6px
                line-height: 21px
            &.error
              border: 1px solid #F5351B
              background: #FFF7F5
        .post-icon-wrapper
          font-family: Roboto, sans-serif
          font-size: 13px
          margin-right: 10px
          display: inline-flex
          color: #323232
          cursor: pointer
          line-height: 30px
          margin-top: 5px
          span
            position: relative
            top: 1px
            left: 1px
            width: 8px
          @media (min-width: 992px)
            margin-right: 18px
          .post-icon
            width: 30px
            height: 30px
            background-color: #E6E6E6
            -webkit-border-radius: 50%
            -moz-border-radius: 50%
            border-radius: 50%
            color: #fff
            display: inline-flex
            text-align: center
            margin-right: 10px
            font-size: 15px
            justify-content: center
            img
              align-self: center
              margin-top: -1px
          &.post-liked
            font-weight: bold
            color: #11A9FF
            .post-icon
              background-color: #11A9FF
        .post-marked-done
          padding: 0 32px 19px
          color: #4da60b
          font-weight: 700
          font-size: 14px
          font-family: Roboto,sans-serif
          display: flex
          img
            width: 30px
            height: 30px
            margin-right: 13px
            border-radius: 100%
          span
            margin-bottom: 0
            padding-top: 3px
            line-height: 22px
            a
              text-decoration: none
              color: #4da60b
        .comment-images-wrap
            text-align: left
            line-height: 0
            margin-bottom: 35px
            .preview
              border-radius: 3px !important
              display: inline-block
              width: 80px
              background-size: cover
              background-position: center
              margin: 5px 15px 5px 5px
              position: relative
              .preview-img
                max-width: 100%
                vertical-align: top
                height: auto
              &:before
                content: 'x'
                position: absolute
                top: -10px
                right: -10px
                width: 20px
                height: 20px
                background: #11A9FF
                border-radius: 50%
                cursor: pointer
                color: #fff
                line-height: 20px
                text-align: center
                font-weight: bold
                font-size: 10px
                z-index: 1
            > div
              height: 100%
              .video-react-control-bar
                display: none
              .video-react-big-play-button
                width: 2em
      .comment-error
        font-size: 12px
        margin-top: 7px
        color: #FE483C
        margin-left: 57px
        @media(min-width: $screen-sm-max)
          margin-left: 65px

      &.additional-message-wrapper
        .post-icon-wrapper
          font-family: Roboto, sans-serif
          font-size: 13px
          margin-right: 10px
          display: inline-flex
          color: #323232
          cursor: pointer
          line-height: 30px
          margin-top: 3px
          margin-bottom: 31px
          @media (min-width: 992px)
            margin-right: 12px
          @media (min-width: 1100px)
            margin-right: 26px
          .post-icon
            width: 30px
            height: 30px
            background-color: #E6E6E6
            -webkit-border-radius: 50%
            -moz-border-radius: 50%
            border-radius: 50%
            color: #fff
            display: inline-flex
            text-align: center
            margin-right: 10px
            font-size: 15px
            justify-content: center
            img
              align-self: center
              margin-top: -1px
          &.post-liked
            font-weight: bold
            color: #11A9FF
            .post-icon
              background-color: #11A9FF

      .react-tooltip.limit-reached
        text-align: center
        pointer-events: auto !important
        background-color: #8B8A96
        margin-top: -14px !important
        margin-left: -5px !important
        box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
        z-index: 10
        &::after
          border-top: 15px solid #8B8A96 !important
          border-left: 13px solid transparent !important
          border-right: 13px solid transparent !important
          bottom: -10px
          width: 22px
        span
          a
            color: white
            text-decoration: underline
    .post-wrapper
      .post-body .edit-poll
        .mentions-input-wrap
          margin-bottom: 0
          border: none
          min-height: unset
        input
          font-size: 14px
          width: 100%
        input.poll-content
          border: none
        .poll-options
          margin-top: 44px
          input
            height: 36px
            font-size: 13px
            margin-bottom: 6px
            padding-left: 13px
      .poll-options
        font-size: 14px
        line-height: 16px
        .pool-option-box
          display: flex
          margin-bottom: 12px
          .option-content
            position: relative
            min-width: 50%
            max-width: 90%
            color: #323232
            font-size: 14px
            line-height: 19px
            .vote-value
              color: #11A9FF
              position: absolute
              right: -58px
              top: 10px
              font-weight: normal
            &.option-chosen
              font-weight: bold
            span.content-text
              cursor: pointer
          .round
            margin-right: 12px
            z-index: 999
          .round label
            border: 2px solid #D5D5D5
            border-radius: 50%
            cursor: pointer
            height: 18px
            width: 18px
          .round label:after
            border: 2px solid #fff
            border-top: none
            border-right: none
            content: ""
            height: 5px
            left: 2px
            opacity: 0
            position: absolute
            top: 4px
            transform: rotate(-45deg)
            width: 10px
          .round input[type="checkbox"]
            visibility: hidden
            width: 0
          .round input[type="checkbox"]:checked + label
            cursor: default
            background-color: $new-blue
            border-color: $new-blue

          .round input[type="checkbox"]:checked + label:after
            opacity: 1
        .pool-option-box.selected
          &:nth-of-type(1)
            .option-content
              background-color: #BDEBFF
          &:nth-of-type(2)
            .option-content
              background-color: #DAF4FF
          &:nth-of-type(3)
            .option-content
              background-color: #EFFAFF
          &:nth-of-type(4)
            .option-content
              background-color: #EFFAFF
          &.empty
            .option-content
              background-color: white
          .round
            padding-bottom: 15px
      .poll-options-expired
        .pool-option-box
          .option-content
            color: #323232
            font-size: 14px
            line-height: 16px
            .vote-value
              color: #11A9FF
            .vote-progress-bar
              background-color: #F8F8F8
              height: 10px
              margin-bottom: 13px
              margin-top: 5px
              .vote-color-bar
                border-radius: 3px
                height: 10px
                background-color: #BDEBFF
        .pool-option-box
          &:nth-of-type(1)
            .option-content
              .vote-progress-bar
                .vote-color-bar
                  background-color: #11A9FF
      .react-tooltip
        background-color: #8B8A96
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15)
        border-radius: 0
  #jf-back-btn
    margin-left: 10px
