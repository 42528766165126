.need-to-vibe-modal
  display: flex !important
  align-items: center
  .modal-dialog
    width: auto
    max-width: 650px
  .modal-content
    @media(min-width: 480px)
      border-radius: 8px
    .create-vibe-form
      .vibe_new
        .panel
          .panel-body
            padding: 30px 41px 20px
            .vibe-options-wrap
              .vibe-question
                font-size: 18px
                line-height: 21px
                margin-bottom: 46px
              .vibe-sentiments-list
                max-width: 380px
                margin: 0 auto
                .vibe-sentiment
                  padding-bottom: 24px
              .vibe-anon
                padding: 30px 25px 0
                max-width: 100%
            .final-screen-logo
              padding-bottom: 30px
          .default-factors-screen, .why-factors-screen
            .vibe-anon
              padding: 0 41px
              margin-top: 10px
              max-width: 100%

body.blur-background
  div#root
    filter: blur(3px)

  div.modal-backdrop
    opacity: 0.6