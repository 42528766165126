// Added  because .panel was fairly inflexible and bootstrap 4 moves
// to a card based system.
.card
  position: relative
  display: block
  margin-bottom: $card-spacer-y
  background-color: $card-bg
  border-radius: $card-border-radius
  border: $card-border-width solid $card-border-color

.card.joined
  border-bottom: 0
  margin-bottom: 0

.card-block
  @include clearfix
  padding: $card-spacer-x

.linked
  &:hover
    background-color: $light-blue
    cursor: pointer

.card-header
  @include clearfix
  // spacer y used to be here for top and bottom but due
  // to our rhythm, x was used for both
  padding: $card-spacer-x
  margin-bottom: 0 // Removes the default margin-bottom of <hN>
  background-color: $card-cap-bg
  border-bottom: $card-border-width solid $card-border-color

  &:first-child
    border-radius: $card-border-radius-inner $card-border-radius-inner 0 0

  h1, h2, h3, h4
    margin: 0
    padding: 0
    line-height: 1



.card
  > .list-group:first-child
    .list-group-item:first-child
      @include border-top-radius($card-border-radius)

  > .list-group:last-child
    .list-group-item:last-child
      @include border-bottom-radius($card-border-radius)

.card.post-form
  padding: rhythm(0.5)
  .input
    textarea
      padding: 0
  .controls
    .upload
      overflow: hidden
      cursor: pointer
      position: relative
      i
        z-index: 100
        cursor: pointer
      input.file
        z-index: 10
        cursor: pointer
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        padding-top: 100px
        @include opacity(0)
