// Dot-style pagination
$pagination-bg: rgba(255,255,255,0.5)
$pagination-active-bg: white

.pagination-dots
  > li
    > a,
    > span
      padding: 0
      line-height: 0
      width: 10px
      height: 10px
      border-radius: 100px
      margin-left: rhythm(0.15)
      margin-right: rhythm(0.15)
      margin-bottom: rhythm(1) - 10
      border: 0
      background-color: $pagination-bg
    &:first-child
      > a,
      > span
        border-radius: 100px
    &:last-child
      > a,
      > span
        border-radius: 100px
  > .active > a,
  > .active > span
    &,
    &:hover,
    &:focus
      background-color: $pagination-active-bg

