@import "../settings"
a.upload
  position: relative
  &:hover, &:active
    i.icon-backing
      color: $dark-grey

.buzz-form
  border: 3px solid $blue
  img#upload_preview
    max-width: 100%
    max-height: 350px

  option
    color: black


.card.buzz
  .image
    img
      max-width: 100%
      max-height: 400px


.jobvibe-future
  &.buzz
    &.index, &.show
      form.new_buzz
        #uploaded-media
          text-align: left
          line-height: 0
          margin-bottom: 36px
          .preview
            display: inline-block
            height: 80px
            width: 80px
            background-size: cover
            background-position: center
            margin: 5px 15px 5px 5px
            border-radius: 3px
            position: relative
            .preview-cross
              position: absolute
              top: -10px
              right: -10px
              width: 20px
              height: 20px
              background: #0095E6
              border-radius: 50%
              cursor: pointer
              z-index: 2
              &:before, &:after
                width: 10px
                height: 2px
                position: absolute
                top: 50%
                left: 50%
                background: white
                border-radius: 2px
                content: ''
              &:before
                transform: translate(-50%, -50%) rotateZ(45deg)
              &:after
                transform: translate(-50%, -50%) rotateZ(-45deg)
            .video-react
              height: 100%
              .video-react-control-bar
                display: none
              .video-react-big-play-button
                width: 30px
                height: 16px
                border-radius: 3px
                bottom: 10px
                top: unset
                &:before
                  font-size: 16px
                  line-height: 13px
      .buzz
        &.card
          .card-block
            .show-fix-media
              margin-left: -23px
              margin-right: -23px
              margin-bottom: 15px
              position: relative
              div.img-div
                height: 320px
                background-size: cover
                background-position: center
              .item
                video.video-react-video
                  padding: 0 22px
                div.video-react-control-bar
                  padding: 0 22px
                button.video-react-big-play-button
                  left: 40px
              .carousel-indicators
                position: absolute
                z-index: 1
                li
                  position: relative
                  width: 7px
                  height: 7px
                  background-color: rgba(255, 255, 255, 0.6)
                  margin: 0 3px
                  top: -2.5px
                  transition: .5s height, .5s width, .5s top
                  &.active
                    background-color: #FFFFFF
                    width: 11px
                    height: 11px
                    top: 0
              .carousel-control
                width: 10%
                text-shadow: none
                background: none !important
                .fix-carousel
                  top: 50%
                  width: 30px
                  height: 30px
                  margin-top: -10px
                  font-size: 30px
                  position: absolute
                  margin-left: -10px
                  z-index: 5
                  color: #B9B9B9
                  &.fix-carousel-right
                    right: 55%
                  &.fix-carousel-left
                    left: 70%