.grow
  #shoutouts-given-list
    #sgl-header
      font-family: Roboto, sans-serif
      font-style: normal
      font-weight: normal
      line-height: normal
      font-size: 24px
      color: #323232
      margin-bottom: 24px
    #sgl-list
      background: #FFFFFF
      border: 1px solid #E6E6E6
      padding: 0 24px 60px
      font-family: Roboto, sans-serif
      .sgl-list-month
        font-style: normal
        font-weight: 500
        line-height: normal
        font-size: 24px
        color: #11A9FF
        margin-bottom: 10px
        margin-top: 30px
      .sgl-list-item
        border-bottom: 1px solid #E9E9E9
        position: relative
        a
          text-decoration: none
        .sgl-list-name
          font-style: normal
          font-weight: 500
          line-height: 24px
          font-size: 16px
          color: #323232
          margin-bottom: 6px
          margin-top: 20px
        .sgl-list-date
          line-height: 13px
          font-size: 13px
          color: #323232
          margin-bottom: 20px
        .sgl-list-likes
          font-weight: bold
          line-height: 13px
          font-size: 14px
          color: #11A9FF
          position: absolute
          right: 0
          top: 50%
          transform: translateY(-50%)
          width: 75px
          text-align: right
          .like-icon
            width: 30px
            height: 30px
            background-color: #E6E6E6
            border-radius: 50%
            color: #fff
            display: inline-block
            text-align: center
            margin-right: 10px
            font-size: 15px
            line-height: 30px
            vertical-align: middle
            &.liked
              background: #11A9FF
            img
              vertical-align: text-top
          span
            width: 25px
            display: inline-block
    #sgl-actions
      .user-stats
        padding: 0
        span.tile-wrap
          position: relative
      #sgl-shoutout-button
        background: #FE873C
        border-radius: 3px
        border: none
        color: #fff
        font-family: Roboto, sans-serif
        font-style: normal
        font-weight: bold
        line-height: 24px
        font-size: 16px
        text-align: center
        margin-top: 18px
        height: 42px