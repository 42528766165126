@import ./settings

$font-size-base: 14px
$close-font-weight: bold
$close-color: #000
$close-text-shadow: 0 1px 0 #fff

*
  box-sizing: border-box

html
  background: white
  +desktop-only
    background-color: #f3f3f3
  -webkit-font-smoothing: antialiased

body
  +phones-and-tablets
    background: white
  margin: 0
  //border-top: 5px solid $blue
  // line-height: 2rem

  &.success
    border-top: 5px solid $amber

  &.errors
    border-top: 5px solid $red

// Typography

html
  font-family: $default-font-family
  font-size: 10px

body
  font-size: 1.6rem
  line-height: rhythm(1)

.body-content
  +phones-and-tablets
    padding: 0 rhythm(1)

.logo-public img
  margin: rhythm(2) auto rhythm(1)
  display: block

// Other

.public-footer
  text-align: center

.mobile-links
  margin: rhythm(1) 0
  height: rhythm(2)
  img
    margin: 5px
    height: rhythm(1.5)

.huge-icon
  color: #FEAB00
  font-size: 90px
  margin: rhythm(1) 0
  i
    line-height: rhythm(3)

.alert.alert-error
  display: none
.redirect-heelix-modal
  .modal-content
    padding: 60px
    .redirect-modal-subtitle
      font-family: Roboto, Rubik
      font-style: normal
      font-weight: bold
      line-height: 37px
      font-size: 24px
      color: #2A50FC