@import "../settings"

.grow
  &.index
    .page-wrapper
      max-width: none
      padding: 0
      >.nav-page-title.pull-xs-none
        display: none !important

      .grow-feed-header
        flex-direction: column
        &.export
          @media (max-width: 1315px)
            flex-direction: column !important
        @media (min-width: 992px)
          flex-direction: row

      .open-modal
        cursor: pointer
    .full-width
      width: 100%
      .grow-row
        margin-left: 25px
        margin-right: 25px
    .page-container
      max-width: 1200px
      padding: 0 28px
      margin: 0 auto
    #user-profile-wrapper
      background-color: #f3f3f3
      color: #323232
      padding: 32px 0 0
      margin-bottom: 18px
      #img-wrapper
        padding: 0
        align-items: center
        display: flex
        @media (max-width: 1200px)
          justify-content: center
        img#grow-user-profile-img
          @media (min-width: 2300px)
            margin-left: 12px
          width: 100px
          height: 100px
          display: inline-block
      #grow-user-profile-xs-badge
        margin-left: 10px
      #description-wrapper
        @media (min-width: 1200px)
          padding-left: 0
        @media (max-width: 1200px)
          text-align: center
        #name-role-wrapper
          font-size: 14px
          line-height: 20px
          margin-bottom: 22px
          position: relative
          >div
            display: inline-block
          div#edit-profile-link
            @media (min-width: 1200px)
              padding-left: 20px
            top: -95px
            right: 15px
            @media (min-width: 460px)
              top: -120px
              right: 30px
            @media (min-width: 1200px)
              top: 0
            a
              color: #11A9FF
              font-weight: normal
              text-decoration: none
              align-items: center
              display: inline-flex
              font-size: 14px
              line-height: 20px
              svg
                border-radius: 50%
                margin-right: 13px
                border: none
                padding: 0
              span
                display: inline
          h2
            font-weight: bold
            line-height: 34px
            font-size: 24px
            margin-bottom: 0
            margin-top: 20px
            color: #323232
            @media (min-width: 1200px)
              margin-top: 0
          #user-description-with-time-info
            margin-top: 8px
            font-size: 13px
            line-height: 18px
            color: #6A6A6A
            vertical-align: middle
            display: inline-flex
            @media (max-width: 768px)
              display: flex
              flex-direction: column
            a
              color: #6A6A6A
              text-decoration: none
            i
              color: #C4C4C4
              font-size: 17px
              margin-left: 20px
              margin-right: 10px
        #desc-tags-wrapper
          font-size: 14px
          line-height: 20px
          p.description
            margin-bottom: 12px
          p
            padding: 0 15px
            line-height: 20px
            .user-profile-tag
              color: #11A9FF
              margin-right: 10px
            @media (min-width: 1200px)
              padding: 0
        .row.float-right
          @media (min-width: 1200px)
            margin-right: 10px
            float: right
  .user-stats
    padding: 12px
    .tile-wrap
      display: block
      height: 100px
      background: #FFF
      border: 1px solid #E6E6E6
      padding: 24px 20px
      font-size: 15px
      line-height: 18px
      cursor: pointer
      text-decoration: none
      div.info-text
        position: absolute
        top: 50%
        transform: translateY(-50%)
        -webkit-transform: translateY(-50%)
        left: 94px
        right: 25px
        line-height: 18px
        color: #000
        .blue
          color: $new-blue
      div.circle-wrap
        display: block
        position: initial
        background: $new-blue
        height: 52px
        width: 52px
        border-radius: 50%
        &.orange
          background-color: #FE873C
        span
          color: $white
          font-weight: bold
          font-size: 24px
          line-height: 28px
          display: block
          text-align: center
          padding-top: 12px
      .doughnut.chart
        max-width: 52px
        .number
          font-size: 24px
          color: $new-black
          font-weight: bold
          line-height: 28px
          top: 13px
          padding-left: 4px
      .info-icon
        height: 16px
        padding-left: 10px
      @media(max-width: 1064px) and (min-width: 991px)
        height: 120px
        padding-top: 32px
        div.info-text
          left: 88px
  .team-notes
    .tile-wrap
      padding: 20px
      height: 84px
      div.info-text
        left: 80px
      span
        padding-right: 18px
      .eye-icon
        height: 14px


  .team-notes-box
    padding: 6px
    .member-note:last-of-type
      hr
        display: none
    h2.member-notes-header
      font-size: 20px
      font-weight: normal
      font-family: Roboto
      margin-top: 18px
      margin-bottom: 18px
      img
        padding-left: 12px
        padding-top: 6px
    .member-notes-tile
      cursor: default
      padding-top: 23px
      padding-bottom: 35px
      h2
        margin-bottom: 5px
        display: inline-block
        color: #8b8a96
        font-size: 13px
        font-family: Roboto
        font-weight: normal
      h2.bold-title
        margin-bottom: 6px
        margin-top: 5px
        font-weight: bold
        color: #323232
        display: block
      h2.observation-content
        color: #323232
        line-height: 20px
        padding-bottom: 28px
        +desktop-only
          margin-right: 40px
      hr
        padding-bottom: 23px
      .member-note:last-of-type
        padding-bottom: 6px
      h1
        margin-bottom: 10px
        span
          color: #8b8a96
          font-weight: normal
  .grow-header
    font-size: 20px
    line-height: 26px
    color: #323232
    font-weight: normal
    padding-top: 18px
    margin-bottom: 18px
    img
      padding-left: 12px
      padding-top: 5px
  .btn-view-all
    display: inline-block
    border: 1px solid $new-blue
    border-radius: 4px
    background-color: #fff
    color: $new-blue
    font-size: 14px
    line-height: 16px
    padding: 9px 18px 8px 18px
    &.responsive-btn
      @media(max-width: 390px)
        bottom: 40px
    &.view-all-fixes
      @media(min-width: 992px) and (max-width: 1280px)
        bottom: 40px
      @media(max-width: 550px)
        bottom: 50px
  .list-div
    background: #FFF
    border: 1px solid #E6E6E6
    padding:  9px 30px 36px
    ul
      padding: 0
      min-height: 283px
      li
        list-style: none
        padding: 14px 0 15px
        border-bottom: 1px solid #E6E6E6
        .block-link
          display: block
          img
            position: absolute
          .shoutout-title
            display: inline-block
            padding: 3px 0 5px 40px
          .win-title
            display: inline-block
            padding: 0 0 5px 33px
        .list-item-title
          color: #323232
          padding: 0 14px 0 0
          font-size: 16px
          line-height: 24px
          font-weight: 500
          @media(max-width: 450px)
            display: block
        .blue-tag
          font-size: 13px
          line-height: 13px
          color: $new-blue
        .date
          font-size: 13px
          line-height: 13px
          color: #323232
        &:first-of-type
          padding-top: 20px
        &:last-of-type
          border-bottom: none
        .user-link
          padding: 0 14px 0 0
          font-weight: 500
          color: #323232
          span
            font-weight: normal
        &.win
          padding: 18px 0 17px
          i
            color: #d0d4d8
            padding-right: 10px
            display: none
        &.win:hover i
          display: block
    &.fix-contributions
      ul
        padding: 0
        li
          padding:  15px 0
          h2
            font-size: 18px
            line-height: 21px
            margin-bottom: 18px
            margin-top: 15px
            word-break: break-word
          p
            font-size: 13px
            line-height: 13px
            margin-bottom: 20px
            font-family: Roboto
            display: inline-block
            &.fix-content
              font-size: 14px
              line-height: 20px
              margin-bottom: 15px
              display: block
              cursor: pointer
              a
                color: $new-blue
                font-size: 20px
            span
              color: #8b8a96
            a
              color: #323232
            &.tags-wrap
              @media(max-width: $screen-xs-max)
                display: block
                span
                  margin-left: 0
          .tags
            display: inline-block
            color: #8b8a96
            font-size: 13px
            font-family: Roboto
            font-weight: normal
            margin-left: 23px
            a
              color: #323232
              font-family: Roboto
              padding: 0
              &:first-child
                padding-left: 0
              &.tag
                display: inline
                color: $new-blue
                font-size: 13px
                font-weight: normal
                padding-left: 5px
                margin-right: 0
                &:not(:last-child):after
                  content: ','
      .checkout-feed
        width: 304px
        cursor: pointer
        float: right
        h4
          text-decoration: none
          font-weight: bold
          color: #11A9FF
          font-size: 14px
          line-height: 16px
          text-align: right
          padding-top: 10px
        @media(max-width: 550px)
          display: block
          float: none
          margin-top: 20px
          width: unset
          h4
            text-align: left
          span
            margin-right: 15px
    .record-win, .give-shoutout
      background-color: #11A9FF
      color: #FFFFFF
      border: 1px solid #11A9FF
      padding: 9px 17px 8px 17px
      border-radius: 3px
      font-size: 14px
      font-weight: bold
      line-height: 16px
  .empty-win
    background: #F3F3F3
    border: 1px solid #E6E6E6
    text-align: center
    min-height: 378px
    img
      padding: 57px 0 42px
    h2
      color: #575563
      font-weight: normal
      font-size: 20px
      margin-bottom: 4px
    p
      max-width: 302px
      color: #979797
      font-size: 14px
      line-height: 19px
      margin: 0 auto
      padding: 0 10px 31px
    .record-win
      display: block
      max-width: 120px
      margin: 0 auto
      cursor: pointer
      text-align: center
      button
        background-color: #11A9FF
        color: #FFFFFF
        border: 1px solid #11A9FF
        padding: 9px 17px 8px 17px
        border-radius: 3px
        font-size: 14px
        font-weight: bold
        line-height: 16px
  .empty-contribution
    background: #F3F3F3
    border: 1px solid #E6E6E6
    text-align: center
    min-height: 466px
    img
      padding: 91px 0 42px
    h2
      color: #575563
      font-weight: normal
      font-size: 20px
      margin-bottom: 4px
    p
      max-width: 484px
      color: #979797
      font-size: 14px
      line-height: 19px
      margin: 0 auto
      padding: 0 10px 31px
    .give-shoutout-box
      display: block
      max-width: 150px
      margin: 0 auto
      cursor: pointer
      text-align: center
      .give-shoutout
        background-color: #11A9FF
        color: #FFFFFF
        border: 1px solid #11A9FF
        padding: 9px 17px 8px 17px
        border-radius: 3px
        font-size: 14px
        font-weight: bold
        line-height: 16px
    .fix-list
      display: block
      max-width: 245px
      margin: 0 auto
      cursor: pointer
      text-align: center
      h4
        text-decoration: none
        font-weight: bold
        color: $new-blue
        font-size: 14px
        line-height: 13px
        text-align: center
        padding: 12px 0 65px 0
        margin: 0
  .empty-shoutout
    background: #FFF
    border: 1px solid #E6E6E6
    text-align: center
    min-height: 378px
    h4
      margin: 90px 0 30px
      font-size: 16px
      line-height: 24px
      font-weight: 500
      img
        padding-right: 12px
      span
        padding-left: 12px
        font-size: 13px
        line-height: 13px
        font-weight: normal
    h3
      font-size: 18px
      font-weight: bold
      color: $new-orange
      line-height: 18px
      margin-bottom: 18px
    p
      max-width: 531px
      font-size: 14px
      line-height: 21px
      margin: 0 auto
      padding: 0 10px 47px
    .record-shoutout
      display: block
      padding-top: 0
      padding-bottom: 73px
      max-width: 145px
      margin: 0 auto
      cursor: pointer
      text-align: center
      span
        display: block
        float: left
        background: $new-orange
        border-radius: 50%
        color: #FFF
        font-weight: bold
        font-size: 25px
        line-height: 35px
        height: 36px
        width: 36px
      h4
        text-decoration: none
        font-weight: bold
        color: $new-orange
        font-size: 14px
        line-height: 13px
        text-align: right
        padding: 12px 0 0
        margin: 0
  .record-note
    background-color: #11A9FF
    color: #FFFFFF
    border: 1px solid #11A9FF
    padding: 9px 17px 8px 17px
    border-radius: 3px
    font-size: 14px
    font-weight: bold
    line-height: 16px
  .react-tooltip
    margin-left: 2px
    padding: 15px 25px 15px 31px
    background-color: #8B8A96
    color: #FFFFFF
    font-size: 14px
    line-height: 18px
    text-align: center
    opacity: 1
  .__react_component_tooltip.place-top
    margin-top: -24px !important
    box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
    &::after
      border-top: 20px solid #8B8A96 !important
      border-left: 13px solid transparent !important
      border-right: 13px solid transparent !important
      bottom: -20px
      width: 22px
@import "shoutouts_list"
