.jobvibe-future .team-vibe-show
  #timeline-vibe-tab
    padding: 10px 15px
    @media (min-width: 991px)
      padding: 30px 45px
    #chart-categories
      @media (max-width: 1005px)
        width: 100% !important
      #categories-btns
        width: 100%
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: space-around
        padding: 0
        .category-btn
          display: flex
          justify-content: center
          flex-direction: column
          text-align: center
          font-family: Roboto, sans-serif
          font-style: normal
          font-weight: normal
          line-height: 17px
          font-size: 10px
          text-transform: uppercase
          color: #323232
          background: #FFFFFF
          border: 1px solid #E6E6E6
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15)
          border-radius: 4px
          margin-bottom: 12px
          padding: 5px 6px
          vertical-align: middle
          cursor: pointer
          &:not(:first-of-type)
            margin-left: 10px
          &.active, &:hover, &:active, &:focus
            color: #11A9FF
            background-color: #F0FAFF
          @media (min-width: 1200px)
            padding: 11px 11px
            font-size: 12px
          @media (min-width: 1340px)
            margin-bottom: 0
            margin-left: 0
            padding: 11px 22px
    #stats-wrapper
      height: 367px
      @media (max-width: 768px)
        width: 100%
        overflow-x: scroll
        position: relative
    #timeline-chart
      margin-top: 28px
      height: 100%
      @media (max-width: 480px)
        width: 300%
      @media (max-width: 768px)
        width: 200%
        overflow-x: scroll
        white-space: nowrap
      canvas
        margin-top: -40px
    .custom-date
      font-size: 14px
      line-height: 16px
      color: #575563
      text-align: right
      div.datepicker-wrapper
        display: inline-block
      input
        width: 90px
        height: 36px
        border-radius: 4px
        border: 1px solid #E6E6E6
        padding-left: 5px
        font-size: 14px
      #start_date
        margin-left: 15px
      .date-picker
        position: absolute
        right: 50px
        margin-top: -310px
        border: 1px solid #E6E6E6
      .react-datepicker
        font-family: Roboto, sans-serif
        .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected
          background-color: #575563
        .react-datepicker__day--in-selecting-range, .react-datepicker__day--today
          background-color: #706E84
          color: white
      .end-date-calendar
        left: -150px
        .react-datepicker__triangle
          left: 200px
    .date-range-wrapper
      .custom-date
        text-align: left
        @media(min-width: 991px)
          text-align: right
          padding-top: 50px
        span
          display: block
          margin-left: 15px
          margin-top: 10px
          margin-bottom: 10px
          @media (min-width: 991px)
            display: inline-block
            margin-left: 0
            margin-top: 0
            margin-bottom: 0