// Note like button styles are in _btn.sass
.btn-comment
  + .js-like-count
    vertical-align: 0

// Show link inline on vibe show
.like-vibe-show
  .like
    display: inline-block
  .btn-like
    border: 0
    padding: 0
    font-weight: normal
    line-height: inherit
    font-size: inherit
    i
      padding: 0 4px 0 0

.social-action
  color: $mid-grey
  font-weight: bold
  &:hover
    text-decoration: none
    color: $blue
  &.active
    color: $blue

.social-action.fix
  &:hover
    color: $amber
  &.active
    color: $amber
