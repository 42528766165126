.custom-tooltip
  position: absolute
  top: -65px
  width: 325px
  background-color: #8B8A96
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15)
  padding: 10px
  font-size: 13px
  left: 50%
  transform: translateX(-50%)
  border-radius: 8px
  opacity: 0
  transition: .3s opacity
  color: white
  cursor: initial
  &:after
    position: absolute
    left: 50%
    bottom: 0
    transform: translate(-50%, 50%) rotateZ(45deg)
    content: ''
    background-color: #8B8A96
    width: 16px
    height: 16px
  &.mounted
    opacity: .9