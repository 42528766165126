@import ../base/vertical_rhythm

@keyframes saved
  20%
    opacity: 0.8
  100%
    transform: translate(-20px, 0%)
    opacity: 1

.notification-settings-container
  +desktop-only
    .notification-row-container .row:nth-child(n+2)
      margin-top: rhythm(0.5)
    .notification-row-container .row:nth-child(2)
      margin-top: 0!important
    .notification-row-container .row:nth-child(2n+3)
      background: $super-light-grey
      padding-top: rhythm(0.5)
      padding-bottom: rhythm(0.5)
  .form-group, .checkbox
    margin: 0
  .notification-email-frequency select
    z-index: 999
    position: relative
  +phones-and-tablets
    .notification-options
      .row
        margin-top: rhythm(0.5)
      .notification-email-frequency
        margin-top: 0!important
        > div
          margin-left: 15px
          margin-right: 15px
      .col-sm-6, .notification-email-frequency
        text-align: left
        border-top: 1px solid $super-light-grey
        line-height: rhythm(1.5)
        label.text-subheading
          text-transform: none
          color: $text-color
          font-weight: normal
          font-size: 14px
          line-height: rhythm(2)
          height: rhythm(2)
          letter-spacing: 0
          display: block
          padding: 0 !important
          margin: 0 !important
          div
            float: right
        .checkbox
          position: absolute
          top: rhythm(0.25)
          right: rhythm(0.5)

  .checkbox label::after
    // Fix alignment of styled checkboxes
    @media (min-width: $screen-md-min)
      padding-left: 1px

  .checkbox label
    width: 0
    padding: 0

  .checkbox::after
    @extend .text-subheading
    content: "Saved!"
    width: 100%
    font-size: 12px
    text-transform: uppercase
    font-weight: bold
    color: #26C281 !important
    position: absolute
    left: -40px
    top: 7px
    opacity: 0
    @media (max-width: $screen-md-min)
      left: -50px
      top: 5px

  .checkbox.checkbox-done::after
    animation: saved 1s ease-out

  .loading-spinner
    display: none
  .loading-spinner.is-visible
    display: block
    float: none !important
    @media (max-width: $screen-md-min)
      display: inline-block
      float: right !important

.notification
  border-bottom: 1px solid $default-border-color
  white-space: normal !important
  background: #F7F7F7

  .media-left
    img
      width: 28px

  strong.text-muted
    color: #C3C3C3
  a
    padding: rhythm(0.25) !important
    white-space: normal !important
    line-height: 28px !important
    display: block
    &:hover
      background: lighten(#f7f7f7, 1)
  &:last-child
    margin-bottom: 0
    padding-bottom: 0
    border-bottom: 0
  &-count
    background: $red
    color: white
    border-radius: 100px
    width: 23px
    height: 14px
    text-align: center
    position: absolute
    font-size: 11px
    font-weight: bold
    line-height: 16px
    top: 10px
    right: rhythm(0.6)
    +phones-and-tablets
      top: 0
      right: -7px

  &s.open .notification-count
    background: lighten($red, 10)

  &-list
    font-size: 14px
    line-height: 28px
    min-width: 300px !important
    max-height: 60vh
    overflow: hidden
    padding: 0 !important
    border: 1px solid $default-border-color !important
    border-radius: 3px
    &.collapse
      border: none !important
      +phones-and-tablets
        border: 1px solid $default-border-color !important
    +phones-and-tablets
      margin-right: -15px!important
      overflow: auto
      position: relative

    ul
      margin: 0
      padding: 0
      list-style: none
      height: calc(60vh - 56px - 4px)
      margin-bottom: rhythm(2)
      overflow-y: auto
      +phones-and-tablets
        height: auto
        margin-top: rhythm(1)
        margin-bottom: 0

      li
        padding: 0
        margin: 0

  &-wrap
    padding-bottom: rhythm(1)
    &:focus,
    &:hover
      background-color: transparent !important
    +phones-and-tablets
      display: inline-block!important
      position: relative
      padding: 0
      height: rhythm(1)
      border: none!important
      margin: 14px 0 0 !important
      float: none
      a
        position: relative
    .fa-bell-o
      font-size: 20px
      +phones-and-tablets
        font-size: 28px
        line-height: rhythm(1)
        position: relative
        color: $text-color

  &-unread
    background: white

  &-footer
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    border-top: 1px solid $default-border-color
    a
      display: block
      height: rhythm(2)
      line-height: rhythm(2)
    +phones-and-tablets
      bottom: auto
      top: 0
      border-top: none
      border-bottom: 1px solid $default-border-color
