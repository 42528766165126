.label-admin
  background-color: $deep-blue

.label-state
  background-color: $yellow

.label-disabled
  background-color: $red

.label-team
  background-color: $large-label-background-color
  color: $dark-grey

.label
  margin: 0 2px

.label-md
  font-size: 16px
  font-weight: normal
  padding: 7px 15px
  margin: 3px
  display: inline-block
