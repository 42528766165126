$vertical-rhythm: ((name: 'vr-3', lines: 0.5, size: 10), (name: 'vr-2', lines: 0.5, size: 12), (name: 'vr-1', lines: 0.75, size: 14), (name: '0', lines: 1, size: 16), (name: '1', lines: 1, size: 20), (name: '2', lines: 2, size: 25), (name: '3', lines: 2, size: 30) )
$mobile-vertical-rhythm: ((name: 'vr-3', lines: 0.5, size: 8), (name: 'vr-2', lines: 0.5, size: 10), (name: 'vr-1', lines: 0.5, size: 12), (name: '0', lines: 0.75, size: 14), (name: '1', lines: 1, size: 16), (name: '2', lines: 1, size: 20), (name: '3', lines: 2, size: 25) )

@each $vr-size in $vertical-rhythm
  .u-size#{map-get($vr-size, name)},
  .u-size#{map-get($vr-size, name)} p
    font-size: #{map-get($vr-size, size)}px
    line-height: rhythm(map-get($vr-size, lines))
    &:last-of-type
      margin-bottom: 0

.u-line-xs
  line-height: rhythm(0.5)
.u-line-sm
  line-height: rhythm(0.75)
.u-line-md
  line-height: rhythm(1)
.u-line-lg
  line-height: rhythm(1.5)
.u-line-xl
  line-height: rhythm(2)

@media (max-width: $screen-xs-min - 1)
  @each $vr-size in $mobile-vertical-rhythm
    // .u-size#{map-get($vr-size, name)}
    .u-size#{map-get($vr-size, name)} p
      font-size: #{map-get($vr-size, size)}px
      line-height: rhythm(map-get($vr-size, lines))

$rhythm-sizes: ( none: 0, xxxs: 0.125, xxs:0.25, xs:0.5, sm:1, md: 1.5, lg: 2, xl: 4 )
@each $name, $size in $rhythm-sizes
  .u-margin-top-#{$name}
    margin-top: rhythm($size) !important
  .u-margin-bottom-#{$name}
    margin-bottom: rhythm($size) !important
  .u-margin-vertical-#{$name}
    margin-top: rhythm($size) !important
    margin-bottom: rhythm($size) !important

  .u-margin-left-#{$name}
    margin-left: rhythm($size) !important
  .u-margin-right-#{$name}
    margin-right: rhythm($size) !important
  .u-margin-horizontal-#{$name}
    margin-left: rhythm($size) !important
    margin-right: rhythm($size) !important

  .u-padding-top-#{$name}
    padding-top: rhythm($size) !important
  .u-padding-bottom-#{$name}
    padding-bottom: rhythm($size) !important
  .u-padding-vertical-#{$name}
    padding-top: rhythm($size) !important
    padding-bottom: rhythm($size) !important

  .u-padding-left-#{$name}
    padding-left: rhythm($size) !important
  .u-padding-right-#{$name}
    padding-right: rhythm($size) !important
  .u-padding-horizontal-#{$name}
    padding-left: rhythm($size) !important
    padding-right: rhythm($size) !important

  .u-height-#{$name}
    height: rhythm($size)
    line-height: rhythm($size)
