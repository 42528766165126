.custom-scrollbar
  position: absolute
  right: 1px
  width: 10px
  background: #afafaf
  border-radius: 4px
  cursor: grab
  user-select: none
  &.grabbed
    opacity: 1 !important
    cursor: grabbing