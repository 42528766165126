body
  -webkit-font-smoothing: antialiased
  font-family: $roboto-font
  background-color: $background-beige

h1,h2,h3,h4,h5,
.h1,.h2,.h3,.h4,.h5
  font-weight: bold
  margin-bottom: rhythm(0.5)
  margin-top: 0
  line-height: rhythm(1)

// Headings

h2, .h2
  // semibold
  font-weight: 600

h3, .h3
  font-weight: normal

h5, .h5
  text-transform: uppercase
  letter-spacing: 0.05em
  color: $pale-grey

p
  line-height: rhythm(1)
  margin-bottom: rhythm(1)

label
  line-height: rhythm(1)
  margin: 0
  font-size: 14px

.text-heading
  font-size: 18px !important

.text-subheading
  color: $dark-grey
  font-size: 11px
  text-transform: uppercase
  letter-spacing: 0.05em
  font-weight: bold

  .panel &
    color: #C7C7C7

  h2 &
    display: block
    margin: 5px 0

.text-good
  color: $deep-blue

.text-bad
  color: $red

.text-okay
  color: $amber

.text-tiny
  font-size: 66%

.super
  font-size: 14px
  line-height: 10px

.bold
  font-weight: bold
.unbold
  font-weight: normal

.greyed
  color: $mid-grey
  color: rgba(0,0,0,0.4)

.circle-separator
  font-size: 30%
  vertical-align: middle
  margin: 0 10px
  color: #D9D9D9

.disabled
  color: $mid-grey
  font-style: italic

.subdued
  font-size: 80%
  font-style: italic

// Treat a:focus the same as a:link
a:focus
  color: $link-color
  text-decoration: none
  outline: none

a.invisible-link
  color: $text-color

a.no-hover
  &:hover
    text-decoration: none

a.ul-link
  text-decoration: underline

a.primary-link
  font-weight: bold
  text-decoration: underline
  &:hover
    text-decoration: underline
    cursor: pointer

// These should be bound to the vertical rhythm mixins
.font-size-sm
  font-size: 14px

.font-size-md
  font-size: 16px
  line-height: 28px

.form-footer-text-link
  line-height: 43px
  padding-left: 30px
  padding-right: 30px

.line-height-sm
  line-height: 35px

table, .nav-tabs
  a
    color: $table-link-color !important

  a.primary-link
    color: $link-color

.fa-6x
  font-size: 6em

.fa-7x
  font-size: 7em

.fa-8x
  font-size: 8em

.fa-9x
  font-size: 9em

// Targeted underlining
// This is useful for when an icon is used within a
// link, and you don't want the icon to underline on hover.
// Use a span to specific the part of the link to underline.
// Open to better solutions here
a.no-underline
  &:hover
    text-decoration: none

    .underline-target
      text-decoration: underline

hr
  border-color: $hr-border
  margin: rhythm(1) 0

.hr-darker
  border-color: $hr-border-darker

.text-center-sm
  +mobile-only
    text-align: center

.text-center-md
  +phones-and-tablets
    text-align: center

.text-center-lg
  +desktop-only
    text-align: center

// Media element
.media-heading
  margin: 0

input, textarea
  color: $input-color

.placeholder
  color: #aaa
