.jobvibe-future
  h1.fix-list-header
    font-size: 24px
    font-weight: normal
    font-family: Roboto
    margin-bottom: 24px
  .fix-list-tile
    background: #FFFFFF
    padding: 25px
    margin-bottom: 20px
    border: 1px solid #E6E6E6
    cursor: pointer
    h1
      font-family: Roboto
      font-weight: bold
      font-size: 18px
      margin-bottom: 10px
    h2
      display: inline-block
      color: #8b8a96
      font-size: 13px
      font-family: Roboto
      font-weight: normal
      &:last-child
        margin-left: 20px
      span
        color: #323232
        font-family: Roboto
        padding: 0 5px
        &:first-child
          padding-left: 0
        &.tag
          display: inline
          color: #4ab4e2
          font-size: 13px
          font-weight: normal
          padding-left: 5px
          margin-right: 0
          &:not(:last-child):after
            content: ','
      a:hover
        text-decoration: none
    p
      margin-bottom: 0
      font-family: Roboto
      font-size: 14px
      line-height: 20px
      a:hover
        text-decoration: none
  .pagination
    > li
      display: inline-block
      margin-right: 6px
      &.active a:focus
        background: #1297E3
        color: white
      a:focus
        background: white
        color: #1297E3
      &:first-child,&:last-child
        display: none
      &:nth-child(2), &:nth-last-child(2)
        a
          &:hover
            &:after
              background: #0c689c
            &:before
              border-color: #0c689c
          &:after
            content: ''
            width: 16px
            height: 2px
            background: #1297E3
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
          &:before
            content: ''
            width: 10px
            height: 10px
            position: absolute
            transform: translate(0, -50%) rotate(-45deg)
            top: 50%
          span
            color: rgba(0,0,0,0)
      &:nth-last-child(2)
        a:before
          border-bottom: 2px solid #1297E3
          border-right: 2px solid #1297E3
          right: 8px
      &:nth-child(2)
        a:before
          border-top: 2px solid #1297E3
          border-left: 2px solid #1297E3
          left: 8px

    &.prev-hidden
      li:nth-child(2)
        display: none
    &.next-hidden
      li:nth-last-child(2)
        display: none

