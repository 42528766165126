.meter
  vertical-align: - rhythm(0.25) 
  background: rgba(255,255,255,0.8)
  &, &-bar
    border-radius: 2px
    height: rhythm(0.5)
    display: inline-block
  &-bar
    background: $blue
    vertical-align: top
  &-sm
    width: 100px
