.custom-calendar
  .react-datepicker-wrapper
    .react-datepicker__input-container
      input::placeholder
        color: #979797
        opacity: 1
      input:-ms-input-placeholder, input::-ms-input-placeholder
        color: #979797
  .react-datepicker
    border: 1px solid #EFEFEF
    box-sizing: border-box
    box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.25)
    border-radius: 5px
  .react-datepicker__header
    background: #FFFFFF
    border: 1px solid white
    padding-top: 1.5em
  .react-datepicker__triangle
    border-bottom-color: #FFFFFF !important
    &::before
      border-bottom-color: #EFEFEF !important
  .react-datepicker__current-month
    font-weight: 600
    font-size: 18px
    line-height: 21px
    color: #323232
    text-align: left
    font-style: normal
    padding-left: 20px
    margin-bottom: 30px
    margin-top: 5px
  button.react-datepicker__navigation
    width: 24px
    height: 24px
    background: #11A9FF
    border-radius: 50%
    border: unset
    background-repeat: no-repeat
    background-position: center
    margin-top: 0.6em
    &.react-datepicker__navigation--previous
      right: 5.3em !important
      left: unset
      background-image: url(left.svg)
    &.react-datepicker__navigation--next
      background-image: url(right.svg)
      right: 2em
  .react-datepicker__day-names
    font-size: 12px
  .react-datepicker__month
    font-size: 14px
  .react-datepicker__day-name
    color: #C6CACE
    width: 3em
  .react-datepicker__day--selected
    background-color: #11A9FF
  .react-datepicker__day
    width: 2.5em
    &.react-datepicker__day--outside-month
      visibility: hidden
  .react-datepicker__day--today
    &.react-datepicker__day--keyboard-selected
      color: #FFFFFF !important
    &:not(.react-datepicker__day--selected)
      color: #11A9FF
    font-weight: normal
  .react-datepicker-popper
    @media (max-width: 380px)
      left: -30px !important