.btn
  line-height: rhythm(1.5) - 2
  padding-left: 20px
  padding-right: 20px
  padding-top: 0
  padding-bottom: 0
  border-radius: 3px
  &:active
    box-shadow: none
    outline: none
  &:focus
    box-shadow: none
    outline: none

.btn.disabled
  line-height: 40px
  background-color: $light-grey
  border-color: $light-grey
  color: white
  font-weight: 700
  cursor: not-allowed
  font-style: normal
  &:hover
    color: white

.btn-primary
  color: white
  font-weight: bold
  border-radius: $button-border-radius
  border-color: #11A9FF
  &:hover,
  &:active
    background-color: #11A9FF
    border-color: #11A9FF
  // &.disabled

.btn-secondary
  color: $sidebar-background-color
  border-color: $sidebar-background-color !important
  background-color: transparent
  -webkit-font-smoothing: auto
  &:hover,
  &:active,
  &.active
    background-color: $sidebar-background-color
    color: white

  &.disabled
    color: lighten($sidebar-background-color, 20) !important
    border-color: lighten($sidebar-background-color, 20) !important
    background: transparent !important
    font-weight: normal
    letter-spacing: 0

.btn-grey
  color: white
  background-color: $pale-grey
  &:hover,
  &:focus,
  &:active
    color: white
    background-color: $dark-grey

.btn-highlight
  color: white
  font-weight: bold
  background-color: $bright-green
  &:hover
    color: white
    background-color: darken($bright-green, 10)

.btn-white
  color: white
  border-color: white
  background-color: transparent
  font-weight: bold
  &:hover,
  &:focus,
  &:active
    color: white
    border-color: white !important
    background-color: rgba(255,255,255,0.1)

.btn-opaque
  color: rgba(255,255,255,0.5)
  &:hover,
  &:focus,
  &:active
    color: rgba(255,255,255,0.7)
    box-shadow: none
  &.btn.disabled
    background: transparent
    font-weight: normal
    letter-spacing: 0
    color: rgba(255,255,255,0.3)

.btn-like
  border: none
  background-color: transparent
  font-weight: bold
  vertical-align: 0
  padding: 0
  &:hover,
  &:focus,
  &:active
    color: $blue !important

.btn-greyed,
.btn-greyed:focus
  color: lighten($sidebar-background-color, 35)
  border-color: lighten($sidebar-background-color, 45) !important
  background: transparent

.btn-default
  border-color: $deep-blue
  background-color: transparent
  color: $deep-blue
  -webkit-font-smoothing: auto
  &:hover,
  &:active,
  &:hover:active,
  &:active:focus
    background-color: lighten($deep-blue, 45)

.btn-own
  background: $amber
  border: transparent
  color: white
  font-weight: 600
  &:hover,
  &:active,
  &:focus,
  &:hover:active,
  &:active:focus
    background-color: lighten($amber, 5)
    color: white

.btn-sm
  height: rhythm(1) !important
  line-height: rhythm(1) !important
  font-size: 14px

.btn-xsm
  height: $baseline * 2.5 !important
  line-height: $baseline * 2.5 !important
  font-size: 12px
  padding-left: 5px
  padding-right: 5px

.btn-xl
  @extend .height-5x

.btn-square
  width: rhythm(1.5)
  height: rhythm(1.5)
  padding: 0

.btn-square-sm
  width: rhythm(1)
  height: rhythm(1)
  padding: 0

.btn-sm.btn-wide
  padding-left: $baseline*2
  padding-right: $baseline*2

.btn-wide
  padding-left: $baseline * 3
  padding-right: $baseline * 3

.btn-lg.btn-wide
  padding-left: $baseline * 5
  padding-right: $baseline * 5

.btn-md.btn-fixed
  min-width: 130px
  padding-left: $baseline * 3
  padding-right: $baseline * 3
  font-size: 16px

.btn-sm.btn-fixed
  width: 100px

.btn-full
  width: 100%

.input-has-btn
  // Space to the right to fit the submit btn
  padding-right: 100px !important

#jf-back-btn
  cursor: pointer
  text-decoration: none
  margin-bottom: 20px
  display: inline-block
  font-size: 13px
  line-height: 15px
  color: #8B8A96
  img
    margin-right: 13px
    vertical-align: bottom
.jobvibe-future.users.show.grow
  #jf-back-btn
    color: white
