th i
  padding: 0 5px 5px

.table-hover tr:hover td
  color: $table-bg-hover-text-color
  border-color: $table-bg-hover
  a
    color: $blue !important
.table-hover tr:hover + tr td
  border-color: shade($table-bg-hover, 20)

.form-options
  thead td,
  tbody td
    text-align: center
  thead td
    color: $pale-grey
    font-weight: 700
    text-transform: uppercase
    font-size: 12px
    letter-spacing: 0.05em

.table-vr
  th, td
    padding: rhythm(0.25) rhythm(0.5)
    // @extend .u-size0
  tr th:first-child,
  tr td:first-child
    padding-left: 0

.table th,
.table td
  @media (max-width: $screen-md-min)
    padding: rhythm(0.25)!important
