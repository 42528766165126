.row-no-padding
  margin: 0!important
  [class*="col-"]
    padding-left: 0!important
    padding-right: 0!important

.row-flex, .row-flex > div[class*='col-']
  display: flex
  flex: 1 1 auto

.row-flex-wrap
  align-content: flex-start
  flex: 0
  flex-wrap: wrap

.row-flex > div[class*='col-'], .container-flex > div[class*='col-']
  margin: -.2px

.container-flex > div[class*='col-'] div, .row-flex > div[class*='col-'] > div
  width: 100%

.flex-col
  display: flex
  flex: 1 100%
  flex-direction: column

.flex-grow
  display: flex
  flex: 2
