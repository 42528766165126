.popover
  border: 0
  font-family: inherit
  > .arrow
    border-color: transparent !important

[data-toggle="popover"]
  &:focus
    outline: none

[data-toggle="tooltip"]
  cursor: pointer

.tooltip
  font-family: inherit
  font-size: 12px
  pointer-events: none
.tooltip-inner
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20)
