
.create-posts-feed
  padding-bottom: 24px
  position: relative
  .create-forms-wrap
    background: white
    border: 1px solid #E6E6E6
    border-radius: 8px
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px, rgba(0, 0, 0, 0.05) -1px -1px 10px
    .fix-tile-error
      padding-bottom: 20px
    .border-shadow
      border-radius: 8px
      height: 9px
      width: calc(100% - 1px)
      position: absolute
      background: linear-gradient(180deg, #F3F3F3 0%, rgba(239, 239, 239, 0) 100%)
      mix-blend-mode: multiply
    .menu-outer-wrap
      height: 55px
      margin-right: 50px
      overflow: hidden
      .menu
        overflow-x: scroll
        padding-bottom: 20px
        white-space: nowrap
        display: flex
        > div
          //border-bottom: 1px solid #E6E6E6
          padding: 18px 20px 15px
          display: inline-block
          color: #6A6A6A
          transition: color .3s, text-shadow .3s
          text-shadow: none
          font-size: 15px
          white-space: nowrap
          letter-spacing: .1px
          position: relative
          cursor: pointer
          &:last-child
            flex: 1
            padding: 0
          &:hover
            color: #11A9FF
          &:after
            position: absolute
            content: ''
            width: 0
            height: 0
            transition: .3s width
            bottom: -1px
            left: 20px
            background: #11a9ff
          &[active='true']
            color: #11A9FF
            font-weight: bold
            &:after
              width: calc(100% - 40px)
              height: 2px

    .create-poll-tab, .create-questionnaire-tab
      border-radius: 8px
      padding: 24px 24px 0px 24px
      display: flex
      flex-wrap: wrap
      background: #F3F3F3

      .create-poll-feed, .create-questionnaire-feed
        width: 100%
        font-size: 14px
        h2
          font-size: 15px
          line-height: 18px
          font-weight: 500
          margin-bottom: 13px
          .required
            color: #F5351B
          span
            font-weight: normal
        .mentions-input-wrap
          border-radius: 5px
          border: 1px solid #EDEDED
          box-sizing: border-box
          min-height: 73px
          background-color: #FFFFFF
          padding: 12px 16px 10px
          margin-bottom: 12px
          max-width: unset
          @media (min-width: 768px)
            max-width: 440px
          @media (min-width: 1200px)
            max-width: 875px
          textarea
            font-size: 14px
            width: 100%
            &::placeholder, &::-webkit-input-placeholder
              color: #C4C4C4

        input
          font-size: 15px
          width: 100%
        input.poll-content
          border: none
        .poll-options
          .poll-option
            margin-top: 7px
            display: flex
            max-width: 305px
            &[custom-hidden='true']
              display: none
            span
              border: 2px solid #D5D5D5
              border-radius: 50%
              display: inline-block
              width: 18px
              height: 18px
              position: relative
              align-self: center
            input
              display: inline-block
              color: #3f3f3f
              font-size: 15px
              padding-left: 12px
              margin-bottom: 12px
              border: none
              align-self: center
              position: relative
              top: 4px
              height: 42px
              border-radius: 5px
              &::placeholder, &::-webkit-input-placeholder
                color: #C4C4C4
              &:focus
                outline: none
        #images-wrap
          text-align: left
          line-height: 0
          margin-bottom: 36px
          .preview
            image-orientation: none
            display: inline-block
            width: 80px
            background-size: cover
            background-position: center
            margin: 5px 15px 5px 5px
            border-radius: 3px
            position: relative
            text-align: right
            .preview-img
              max-width: 100%
              height: auto
              vertical-align: top
            .preview-cross
              position: absolute
              top: -10px
              right: -10px
              width: 20px
              height: 20px
              background: #11A9FF
              border-radius: 50%
              cursor: pointer
              z-index: 2
              &:before, &:after
                width: 10px
                height: 2px
                position: absolute
                top: 50%
                left: 50%
                background: white
                border-radius: 2px
                content: ''
              &:before
                transform: translate(-50%, -50%) rotateZ(45deg)
              &:after
                transform: translate(-50%, -50%) rotateZ(-45deg)
            .video-react
              height: 100%
              min-height: 50px
              padding: 0 !important
              .video-react-control-bar
                display: none
              .video-react-big-play-button
                width: 30px
                height: 16px
                border-radius: 3px
                bottom: 10px
                top: unset
                &:before
                  font-size: 16px
                  line-height: 13px
        .add-option-wrap
          display: flex
          width: 100%
          margin-top: 12px
          margin-bottom: 20px
          img
            cursor: pointer

      .name-input
        display: inline-block
        color: #3f3f3f
        font-size: 15px
        padding-left: 12px
        margin-bottom: 12px
        border: none
        align-self: center
        position: relative
        top: 4px
        height: 42px
        border-radius: 5px

        &::placeholder, &::-webkit-input-placeholder
          color: #C4C4C4

        &:focus
          outline: none

      .description
        border-radius: 5px
        border: 1px solid #EDEDED
        box-sizing: border-box
        min-height: 73px
        background-color: #FFFFFF
        padding: 12px 16px 10px
        margin-bottom: 12px
        margin-top: 12px
        width: 100%

        textarea
          font-size: 14px
          width: 100%

          &::placeholder, &::-webkit-input-placeholder
            color: #C4C4C4

      .question-type
        color: #6A6A6A

        div
          background-color: #F3F3F3
          border-color: #F3F3F3

        .Select-control
          height: 42px

          .Select-placeholder
            line-height: 40px

          .Select-value
            line-height: 40px !important

            .Select-value-label
              line-height: 40px

          .Select-input
            height: 40px

        .Select-menu-outer
          img
            vertical-align: text-top
            margin-right: 5px

        .Select-value-label
          color: #6A6A6A !important

          img
            vertical-align: text-top
            margin-right: 5px

    .create-post-tab
      padding: 24px 24px 20px 24px
      display: flex
      flex-wrap: wrap
      .create-post-feed
        width: 100%
        font-size: 14px
        .mentions-input-wrap
          background: #FFFFFF
          border: 1px solid #EDEDED
          box-sizing: border-box
          border-radius: 5px
          margin-bottom: 20px
          max-width: unset
          padding: 16px 15px
          min-height: 141px
        .nowrap textarea
          white-space: nowrap
        #images-wrap
          text-align: left
          line-height: 0
          margin-bottom: 36px
          .preview
            image-orientation: none
            display: inline-block
            width: 80px
            background-size: cover
            background-position: center
            margin: 5px 15px 5px 5px
            border-radius: 3px
            position: relative
            text-align: right
            .preview-img
              max-width: 100%
              height: auto
              vertical-align: top
            .preview-cross
              position: absolute
              top: -10px
              right: -10px
              width: 20px
              height: 20px
              background: #11A9FF
              border-radius: 50%
              cursor: pointer
              z-index: 2
              &:before, &:after
                width: 10px
                height: 2px
                position: absolute
                top: 50%
                left: 50%
                background: white
                border-radius: 2px
                content: ''
              &:before
                transform: translate(-50%, -50%) rotateZ(45deg)
              &:after
                transform: translate(-50%, -50%) rotateZ(-45deg)
            .video-react
              height: 100%
              min-height: 50px
              padding: 0 !important
              .video-react-control-bar
                display: none
              .video-react-big-play-button
                width: 30px
                height: 16px
                border-radius: 3px
                bottom: 10px
                top: unset
                &:before
                  font-size: 16px
                  line-height: 13px
    .create-discussion-tab
      padding: 24px 24px 0px 24px
      border-radius: 0 0 8px 8px
      background: #F3F3F3
      display: flex
      flex-wrap: wrap
      .create-discussion-feed
        width: 100%
        font-size: 14px
        h2
          font-size: 15px
          line-height: 18px
          font-weight: 500
          margin-bottom: 13px
          &:not(:first-of-type)
            margin-top: 24px
          .required
            color: #F5351B
          span
            font-weight: normal
        .select-component
          display: block
          width: 100%
          @media(min-width: 600px)
            width: 50%
          .Select-control
            height: 42px
            background-color: #ffffff
            border-radius: 5px
            border: 1px solid #EDEDED
            box-sizing: border-box
            font-size: 14px
            line-height: 16px
            table-layout: fixed
            .Select-multi-value-wrapper
              height: 42px
            .Select-placeholder
              color: #C4C4C4
              padding: 4px 16px
            .Select-value
              color: #979797
              padding: 4px 16px
            .Select-input
              color: #979797
              height: 100%
              padding: 0 16px
              overflow: hidden
              max-width: calc(100% - 20px)
              #type-input
                height: 100%
                padding: 0
                &:focus
                  outline: none
          &.assign
            .Select-placeholder
              color: #323232
        #related-posts-link
          position: absolute
          margin-top: -2px
          @media(max-width: 984px)
            position: relative
            float: right
          width: 50%
          max-width: 360px
          padding-left: 24px
          color: #11A9FF
          font-size: 15px
          line-height: 24px
          text-align: left
          display: none
          &[visible=true]
            display: inline-block
          #anchor
            font-weight: 500
          a
            text-decoration: none
        #subject-input
          width: 100%
          @media(min-width: 600px)
            width: 50%
          display: inline-block
        .discussion-input
          display: inline-block
          height: 42px
          background-color: #ffffff
          color: #323232
          border-radius: 6px
          border: none
          font-size: 14px
          line-height: 16px
          padding: 0 16px
          width: 100%
          &::placeholder
            color: #323232
          &::-moz-placeholder
            line-height: 44px
          &:focus
            outline: none
        .mentions-input-wrap
          padding: 13px
          min-height: 100px
          background-color: #ffffff
          border-radius: 5px
          border: none
          margin-top: 23px
          margin-bottom: 15px
          font-size: 14px
          line-height: 21px
          max-width: unset
          textarea
            background-color: #ffffff
          textarea::placeholder
            color: #C4C4C4
            font-size: 14px
            line-height: 21px
        .subject-wrapper
          span
            padding: 8px 30px 8px 18px
            color: white
            background-color: #11A9FF
            position: relative
            border-radius: 18px
            font-size: 13px
            line-height: 15px
            font-weight: 500
            display: inline-block
            margin: 18px 10px 5px 0px
            .subject-cross
              cursor: pointer
              position: absolute
              display: inline-block
              height: 10px
              width: 10px
              right: 8px
              top: 50%
              transform: translateY(-50%)
              &:before, &:after
                border-radius: 2px
                width: 10px
                height: 2px
                background: #8FCCE8
                content: ''
                position: absolute
                top: 50%
                left: 50%
              &:before
                transform: translate(-50%, -50%) rotate(-45deg)
              &:after
                transform: translate(-50%, -50%) rotate(45deg)
        #images-wrap
          text-align: left
          line-height: 0
          margin-bottom: 36px
          .preview
            image-orientation: none
            display: inline-block
            width: 80px
            background-size: cover
            background-position: center
            margin: 5px 15px 5px 5px
            border-radius: 3px
            position: relative
            text-align: right
            .preview-img
              max-width: 100%
              height: auto
              vertical-align: top
            .preview-cross
              position: absolute
              top: -10px
              right: -10px
              width: 20px
              height: 20px
              background: #11A9FF
              border-radius: 50%
              cursor: pointer
              z-index: 2
              &:before, &:after
                width: 10px
                height: 2px
                position: absolute
                top: 50%
                left: 50%
                background: white
                border-radius: 2px
                content: ''
              &:before
                transform: translate(-50%, -50%) rotateZ(45deg)
              &:after
                transform: translate(-50%, -50%) rotateZ(-45deg)
            .video-react
              height: 100%
              min-height: 50px
              padding: 0 !important
              .video-react-control-bar
                display: none
              .video-react-big-play-button
                width: 30px
                height: 16px
                border-radius: 3px
                bottom: 10px
                top: unset
                &:before
                  font-size: 16px
                  line-height: 13px

        .team-logo
          width: 42px
          height: 42px
          border-radius: 100%
          font-weight: bold
          display: inline-flex
          justify-content: center
          align-items: center
          font-size: 21px

    .dropdown-select-team
      width: 293px
      &.overflow
        overflow: scroll
        overflow-x: hidden
        height: 400px
      input.filter-teams
        font-weight: normal
        width: 100%
        padding: 5px 20px
      input.filter-teams:focus
        border: 1px solid  #D9D9D9
        box-shadow: none
        outline: none
      li.empty a
        color: #9BA2AB
    .create-shoutout-tab
      padding: 24px 24px 0px 24px
      border-radius: 0 0 8px 8px
      background: #F3F3F3
      display: flex
      flex-wrap: wrap
      .create-shoutout-feed
        width: 100%
        font-size: 14px
        h2
          font-size: 15px
          line-height: 18px
          font-weight: 500
          margin-bottom: 13px
          .required
            color: #F5351B
          span
            font-weight: normal
        .shoutouts-left
          display: inline-block
          float: right
          width: 50%
          font-size: 14px
          line-height: 18px
          color: #6A6A6A
          padding: 7px 0 27px 22px
          span
            display: inline-block
            color: #fff
            background: #11A9FF
            height: 25px
            width: 25px
            font-weight: 500
            line-height: 21px
            text-align: center
            border-radius: 50%
            padding-top: 3px
          p
            padding-left: 8px
            display: inline
        .select-component
          display: inline-block
          width: 50%
          padding-right: 10px
          .Select-control
            height: 42px
            background-color: #ffffff
            border-radius: 6px
            border: none
            font-size: 14px
            line-height: 16px
            table-layout: fixed
            .Select-multi-value-wrapper
              height: 42px
            .Select-placeholder
              color: #323232
              padding: 4px 16px
            .Select-value
              color: #979797
              padding: 4px 16px
            .Select-input
              color: #979797
              height: 100%
              padding: 0 16px
              overflow: hidden
              max-width: calc(100% - 20px)
              #company-value-input
                padding: 0
              #shoutout-for-input
                height: 100%
                padding: 0
                &:focus
                  outline: none
          &.is-disabled
            .Select-control
              background-color: #ececec
              cursor: not-allowed
        #company-value-input
          width: 100%
          height: 42px
          background-color: #FFFFFF
          border-radius: 6px
          border: none
          font-size: 14px
          line-height: 16px
          padding: 0
          &::placeholder
            color: #323232
          &:focus
            outline: none
        .mentions-input-wrap
          margin-top: 24px
          margin-bottom: 24px
          font-size: 15px
          max-width: unset
          min-height: 100px
          background-color: white
          border-radius: 5px
          padding: 13px
          textarea::placeholder
            color: #C4C4C4
            font-size: 14px
            line-height: 21px
        #error-message
          display: block
        .company-values-wrapper
          span
            padding: 8px 30px 8px 18px
            color: white
            background: #11A9FF
            position: relative
            border-radius: 18px
            font-size: 13px
            line-height: 15px
            font-weight: bold
            display: inline-block
            margin: 18px 10px 5px 0px
            .cross
              cursor: pointer
              position: absolute
              display: inline-block
              height: 10px
              width: 10px
              right: 8px
              top: 50%
              transform: translateY(-50%)
              &:before, &:after
                border-radius: 2px
                width: 10px
                height: 2px
                background: white
                content: ''
                position: absolute
                top: 50%
                left: 50%
              &:before
                transform: translate(-50%, -50%) rotate(-45deg)
              &:after
                transform: translate(-50%, -50%) rotate(45deg)
    .react-tooltip.limit-reached
      text-align: center
      pointer-events: auto !important
      background-color: #8B8A96
      margin-top: -14px !important
      margin-left: -5px !important
      box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
      z-index: 10
      &::after
        border-top: 15px solid #8B8A96 !important
        border-left: 13px solid transparent !important
        border-right: 13px solid transparent !important
        bottom: -10px
        width: 22px
      span
        a
          color: white
          text-decoration: underline

  .tag-error
    margin-top: 10px
  .file-upload-tile
    background: #F8F8F8
    //background: linear-gradient(to left, #8BDBFF 0%, rgba(139, 219, 255, 0.5)  100%)
    display: flex
    height: 40px
    padding: 0 13px
    border-radius: 6px
    margin-bottom: 10px
    transition: opacity .5s
    opacity: 0
    position: relative
    &.loaded
      opacity: 1
      .file-button
        cursor: pointer
        svg.tick
          opacity: 0
          transition: opacity .2s 3s
        svg.cross
          opacity: 1
          transition: opacity .2s 3.2s
    svg
      align-self: center
      margin-right: 12px
      &.tick
        margin-right: -14px
      &.cross
        opacity: 0
    .file-name
      align-self: center
      color: #11A9FF
      margin-left: 12px
    .file-button
      position: absolute
      left: 0
      display: flex
      top: 50%
      transform: translate(0, -50%)

  .dropzone-wrap
    position: relative
    margin-bottom: 32px
    margin-top: 19px
    cursor: pointer
    &.file-dropzone-wrap
      width: 90%
      height: auto
      @media (min-width: 660px)
        height: 80px
    > div:first-of-type
      @media (max-width: 600px)
        height: 155px !important
    .dropzone-placeholder
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      text-align: center
      h3
        font-size: 14px
        font-family: Roboto
        white-space: nowrap
        color: #11A9FF
        z-index: 1
        margin-bottom: 4px
        line-height: 20px
        @media(max-width: 600px)
          white-space: initial
        svg
          color: #DADADA
          margin-right: 14px
          position: relative
          top: 3px
        span
          text-decoration: underline
          cursor: pointer
          font-weight: bold
      h4
        color: #B3B3B3
        font-size: 12px
        margin: 0
        font-weight: normal

  .collapse
    border-bottom: 1px solid #dfdfdf
    padding-bottom: 30px
    margin-bottom: 30px
    .collapse-content-wrap
      width: 100%
      margin: 0 auto 30px
      #croppie
        clear: both
        .cr-boundary, .cr-resizer
          max-width: 100%
          .cr-viewport
            max-width: 100%
      .btn-success
        float: left
        margin-left: 40px
      .btn-danger
        float: right
        margin-right: 40px


  .controls-panel
    margin-left: auto
    padding-top: 32px
    display: flex
    img
      margin-right: 20px
      height: 30px
      width: 30px
      cursor: pointer
    .icon-circle
      display: inline-flex
      background: #DEDEDE
      height: 30px
      width: 30px
      border-radius: 100%
      justify-content: center
      align-self: center
      svg
        align-self: center
      &[custom-checked='true']
        background: #11A9FF


  .info-panel
    padding-top: 2px
    input[type='button']
      font-size: 15px
      line-height: 18px
      border-color: #11A9FF
      background-color: #11A9FF
      padding: 0 18px 0 18px
      height: 35px

  .posting-to-panel
    font-size: 13px
    padding-top: 35px
    padding-right: 25px
    span
      color: #11A9FF
    img
      padding-left: 7px
      padding-bottom: 2px
