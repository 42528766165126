.nav-tabs-left
  border: 0
  > li
    a
      border: 0
      border-radius: 0
      height: rhythm(2)
      line-height: rhythm(2)
      padding: 0 rhythm(0.5)
    a:hover
      color: $blue
      border: 0
    &.active
      a, a:focus, a:hover
        border: 0
        box-shadow: 3px 0 0 white

@media (max-width: $screen-xs-min)
  .nav-tabs li
    display: block
    float: none
    text-align: center
    font-size: 14px
    border: 0
    a
      border: 0
    &.active a,
    &.active a:focus,
    &.active a:active,
    &.active a:hover
      border: 0
