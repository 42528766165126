.heelix
  &.new-payments-page
    .btn-primary
      background: #11A9FF
      border-color: #11A9FF
      border-radius: 6px

    .no-gutter
      padding-left: 0
      padding-right: 0

    .no-gutter-left
      padding-left: 0

    .no-gutter-right
      padding-right: 0

    .new-payment-wrapper
      background: #FFFFFF
      border: 1px solid #E6E6E6
      box-sizing: border-box
      box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
      border-radius: 8px
      padding: 15px
      @media (min-width: 768px)
        padding: 30px

      h2#new-payment-page-title
        font-size: 30px
        color: #323232
        margin-top: 6px
        margin-bottom: 30px

      #subscription-details-wrapper
        background-color: #fff
        padding: 40px 38px
        box-sizing: border-box
        z-index: 1
        margin-bottom: 15px
        border-bottom: 1px solid #E6E6E6
        @media (min-width: 1360px)
          position: sticky
          float: right
          top: 0
          width: 440px
          box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
          border: 1px solid #E6E6E6
          border-radius: 8px

        h3
          font-size: 18px
          color: #323232
          font-weight: bold
          margin-bottom: 15px

        #subscription-details-amount-wrapper
          font-size: 14px
          display: flex
          align-items: center

          div#subscription-details-amount
            float: left
            font-size: 34px
            vertical-align: middle
            margin-right: 14px
            position: relative

            &:before
              content: '$'
              vertical-align: 28%
              font-size: 18px
              font-weight: bold
              margin-right: 2px

        #subscription-details-description
          line-height: 21px
          font-size: 14px
          color: #323232
          margin-top: 18px


    #faq-section-wrapper
      h3
        font-size: 18px
        margin-bottom: 18px
        color: #323232
        font-weight: bold
        padding-top: 42px
        border-top: 1px solid #DFEBF0

      h4
        font-size: 15px
        margin-bottom: 17px
        color: #323232
        font-weight: bold

      p
        line-height: 21px
        font-size: 14px
        color: #323232
        margin-bottom: 30px

    .cvv-modal
      padding-top: 28px

      .cvv-modal-title
        font-size: 24px
        font-weight: bold
        color: #323232
        text-align: center

      .cards
        margin-top: 41px
        .large-card-container
          text-align: center

        .large-card
          text-align: center
          width: 219px
          display: inline-block
          color: #323232

          .large-card-title
            text-align: left
            font-weight: bold
            font-size: 14px
            line-height: 20px
            margin-top: 8px

          .large-card-body
            text-align: left
            font-size: 13px
            line-height: 18px
            margin-top: 19px
            margin-bottom: 20px
    #stripe-form-wrapper, .credit-card-details-wrapper
      position: relative

      .card-types
        @media (max-width: 991px)
          margin-top: 0 !important
          margin-bottom: 15px !important

      h3#stripe-form-title
        font-size: 18px
        font-weight: bold
        color: #323232
        margin-bottom: 25px

        span
          margin-left: 27px
          font-size: 13px
          color: #69AF48
          font-weight: normal

          .lock-icon
            margin-bottom: 12px
            margin-right: 9px

      .form-group
        label
          font-size: 15px
          color: #323232
          margin-bottom: 12px
          font-weight: normal

          .expiry-date-format
            color: #8B8A96

        input.form-control, select.form-control
          background: #F6F6F6
          border: 1px solid #D5D5D5
          border-radius: 4px
          margin-bottom: 24px

        input#cvv
          background: url($AWS_S3_URL + 'public/images/icons/cvv.svg') no-repeat scroll right 9px top 60%, #F6F6F6

        h3#stripe-address-title
          font-size: 18px
          color: #323232
          font-weight: bold
          margin-bottom: 24px

        input#submit-payment
          margin-top: 25px
          margin-bottom: 60px

        .cvv
          .cvv-label
            float: left

          .cvv-what-is
            text-align: right
            color: #11A9FF
            font-size: 12px
            padding-top: 6px

      .card-details
        label:after
          content: " *"
          color: #F5351B
