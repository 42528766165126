.modal
  transition: top 0.3s ease-in-out !important
.modal-content
  border-radius: 4px
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.05)

.likers-modal, .owners-modal
  ul, li
    list-style: none
    margin-left: 0
    margin-right: 0
    padding-left: 0

.modal-body-no-padding
  padding: 0

.modal-header .close
  margin-top: -1px
  line-height: 0

.modal-header.no-border
  border-bottom: 0
  min-height: rhythm(1)
  padding: rhythm(1)
  padding-bottom: rhythm(0.5)

.modal-body.modal-body-no-padding
  padding: 0

.modal-body p
  font-size: 16px
  line-height: 28px
  margin-bottom: 28px

.intro-modal
  .post-buzz .pillow
    padding: 0
    margin: 0
    border: 0
    box-shadow: none
    text-align: left
  .modal-dialog
    padding-top: 90px
    @media (min-width: 768px)
      width: 700px

.intro-modal-footer
  background: #f5f5f5
  border-radius: 0 0 4px 4px

.modal-backdrop.cover
  background-color: #EFEFEF
  opacity: 1

.modal.cover
  .close
    position: absolute
    right: rhythm(1)
    top: 2vh
    @media (max-width: $screen-md-min)
      right: rhythm(0.5)
  header
    margin-top: rhythm(2)
    @media (max-width: $screen-md-min)
      padding-left: rhythm(0.5)
      padding-right: rhythm(0.5)
  .prev-vertical-center
    margin-left: rhythm(2) * -1
  .next-vertical-center
    margin-right: rhythm(2) * -1
  .modal-dialog
    margin: 0 auto
  .modal-content
    overflow-y: auto
    overflow-x: hidden
    height: 80vh
    margin-top: 1vh
    padding: rhythm(1) rhythm(0.5)
    box-shadow: none
    border-color: $light-grey
    @media (max-width: $screen-md-min)
      height: auto
  &.modal-fit-height
    .modal-content
      height: auto
      padding: 0
      margin: 0
    .modal-body
      padding: rhythm(0.5)

.modal-dialog
  +mobile-only
    width: 100%
    height: 100%
    margin: 0

.modal-content
  border: 0
  +mobile-only
    overflow-y: auto
    width: 100%
    height: 100%
    border-radius: 0

// Fake modal - fullscreen view
.fullscreen-close
  position: absolute
  right: rhythm(1)
  top: rhythm(1)
  font-size: 28px
  z-index: 1000
  a
    color: $pale-grey
    &:hover
      color: $mid-grey

  @media (max-width: $screen-md-min)
    top: rhythm(0.5)
    right: rhythm(0.5)



.resolve-modal
  .modal-header
    padding: 38px 55px 22px
    button
      position: absolute
      width: 30px
      height: 30px
      background: #11A9FF
      border-radius: 50%
      top: 15px
      right: 15px
      opacity: 1
      color: #fff
      text-shadow: none
      font-weight: 400
      margin: 0
      text-align: center
      font-size: 20px
      border: none
      @media (min-width: 768px)
        top: -15px
        right: -15px
    h1
      font-family: Roboto, sans-serif
      font-style: normal
      font-weight: 500
      line-height: normal
      font-size: 20px
      color: #11A9FF
      margin: 0
      padding: 0
  .modal-body
    padding: 0 55px
    margin: 0
    h2
      font-size: 14px
      font-weight: bold
      margin: 0
      span
        font-family: Roboto,sans-serif
        font-style: normal
        font-weight: 400
        line-height: 16px
        font-size: 12px
        color: #e43f3f
    textarea
      line-height: 16px
      font-size: 13px
    input
      background-color: #11A9FF
      margin-top: 24px
      margin-bottom: 28px
      cursor: pointer
      color: white
      &[disabled]
        opacity: .65
        cursor: initial

.win-modal
  width: auto
  @media (max-width: 479px)
    width: 100%
  @media (min-width: 768px)
    width: 692px
  .modal-content
    margin-top: 104px
    max-width: 672px
    transform: translateX(-50%)
    position: relative
    left: 50%
    .modal-inner-wrap
      width: 100%
      padding: 48px 55px 45px
      .close-cross
        position: absolute
        width: 30px
        height: 30px
        top: 15px
        right: 15px
        background: #11A9FF
        border-radius: 100%
        cursor: pointer
        &:before, &:after
          display: block
          content: ''
          height: 2px
          width: 16px
          position: absolute
          left: 50%
          top: 50%
          background: white
        &:before
          transform: translate(-50%, -50%) rotate(-45deg)
        &:after
          transform: translate(-50%, -50%) rotate(45deg)
        @media (min-width: 768px)
          top: -15px
          right: -15px

      h1
        font-family: Roboto, sans-serif
        font-size: 20px
        font-weight: 500
        color: #11A9FF
        margin-bottom: 16px
      h2
        font-size: 16px
        font-family: Roboto, sans-serif
        font-weight: 500
        color: #323232
        margin: 10px 0
        span
          color: #E43F3F
          margin-left: 5px
      .js-textarea-comment
        margin-bottom: 20px
        min-height: 56px
        height: 112px
        resize: none
        width: 100%
        padding: 0
        border: none
      .box
        &.goal
          background: #6264ff
        &.value
          background: #FF0000
        padding: 10px 30px 10px 18px
        color: white
        position: relative
        border-radius: 3px
        font-size: 13px
        font-weight: bold
        display: inline-block
        margin: 5px 10px 5px 0
        .cross
          cursor: pointer
          position: absolute
          display: inline-block
          height: 10px
          width: 10px
          right: 8px
          top: 50%
          transform: translateY(-50%)
          &:before, &:after
            border-radius: 2px
            width: 10px
            height: 2px
            background: white
            content: ''
            position: absolute
            top: 50%
            left: 50%
          &:before
            transform: translate(-50%, -50%) rotate(-45deg)
          &:after
            transform: translate(-50%, -50%) rotate(45deg)
      textarea
        height: 81px
        margin-bottom: 4px
      input, textarea
        width: 100%
        margin-bottom: 10px
        padding: 9px 13px
        font-size: 13px
        line-height: 16px
        border-radius: 3px
        border: 1px solid #D9D9D9
        &::placeholder
          color: #8B8A96
        &[type='button']
          background: #11A9FF
          border-radius: 3px
          width: 171px
          text-align: center
          color: white
          font-size: 16px
          font-weight: bold
          padding: 13px
          outline: none
          border: none
          margin-top: 22px
      span.error-message
        font-family: Roboto, sans-serif
        opacity: 0
        transition: opacity .3s
        color: #cd3c40
        font-weight: bold
        padding-left: 20px
        &.shown
          opacity: 1
    #win-privacy-note
      font-size: 80%
      padding-top: 20px
      line-height: normal
.shoutout-modal
  width: auto
  @media (max-width: 479px)
    width: 100%
  @media (min-width: 768px)
    width: 692px
  .modal-content
    margin-top: 104px
    max-width: 672px
    transform: translateX(-50%)
    position: relative
    left: 50%
    .modal-inner-wrap
      width: 100%
      padding: 48px 55px 45px
      .close-cross
        position: absolute
        width: 30px
        height: 30px
        top: 15px
        right: 15px
        background: #11A9FF
        border-radius: 100%
        cursor: pointer
        &:before, &:after
          display: block
          content: ''
          height: 2px
          width: 16px
          position: absolute
          left: 50%
          top: 50%
          background: white
        &:before
          transform: translate(-50%, -50%) rotate(-45deg)
        &:after
          transform: translate(-50%, -50%) rotate(45deg)
        @media (min-width: 768px)
          top: -15px
          right: -15px

      h1
        font-family: Roboto, sans-serif
        font-size: 20px
        font-weight: 500
        color: #11A9FF
        margin-bottom: 7px
      h2
        font-size: 16px
        font-family: Roboto, sans-serif
        font-weight: 500
        color: #323232
        margin: 10px 0
        span
          color: #E43F3F
          margin-left: 5px
      .js-textarea-comment
        margin-bottom: 20px
        min-height: 56px
        height: 112px
        resize: none
        width: 100%
        padding: 0
        border: none
      .box
        &.value
          background: #FE873C
        padding: 10px 30px 10px 18px
        color: white
        position: relative
        border-radius: 3px
        font-size: 13px
        font-weight: bold
        display: inline-block
        margin: 5px 10px 5px 0
        .cross
          cursor: pointer
          position: absolute
          display: inline-block
          height: 10px
          width: 10px
          right: 8px
          top: 50%
          transform: translateY(-50%)
          &:before, &:after
            border-radius: 2px
            width: 10px
            height: 2px
            background: white
            content: ''
            position: absolute
            top: 50%
            left: 50%
          &:before
            transform: translate(-50%, -50%) rotate(-45deg)
          &:after
            transform: translate(-50%, -50%) rotate(45deg)
      textarea
        height: 82px
        margin-bottom: 4px
      input
        margin-bottom: 10px
      input, textarea
        width: 100%
        padding: 9px 13px
        font-size: 13px
        line-height: 16px
        border-radius: 3px
        border: 1px solid #D9D9D9
        &::placeholder
          color: #8B8A96
        &[type='button']
          background: #FE873C
          border-radius: 3px
          width: 171px
          text-align: center
          color: white
          font-size: 16px
          font-weight: bold
          padding: 13px
          outline: none
          border: none
          margin-top: 22px
          display: block
          &:disabled
            opacity: 0.5
            cursor: not-allowed
      span.error-message
        font-family: Roboto, sans-serif
        opacity: 0
        transition: opacity .3s
        color: #cd3c40
        font-weight: bold
        padding-left: 20px
        &.shown
          opacity: 1
      .select-recipient-box
        display: inline-block
        width: 82%
        +mobile-only
          width: 70%
      .select-component
        font-size: 13px
        line-height: 16px
        &.is-focused .Select-value
          border: 1px solid #FE873C
          border-radius: 3px
        &.is-pseudo-focused
          .Select-control
            border: 1px solid #b3b3b3
            box-shadow: none
            border-radius: 3px
        .Select-placeholder
          margin-left: 4px
        input
          padding-left: 4px
          width: 100%
          border-radius: 3px
          border: none
        .Select-option.is-selected, .Select-option.is-focused
          background-color: #FE873C
          color: white
      .select-component.recipients .Select-arrow-zone
        display: none
      .select-component.teams
        width: 162px
        margin-bottom: 22px
        .Select-arrow-zone
          padding-left: 2px
        &.is-disabled
          .Select-control
            background-color: #ececec
            cursor: not-allowed
      .doughnut.chart
        display: inline-block
        float: right
        margin-top: 12px
        .number
          font-size: 24px
          color: $new-black
          font-weight: bold
          line-height: 28px
          top: 13px
          padding-left: 4px
      p
        font-size: 13px
        margin-bottom: 0
      #select-team-tooltip
        padding: 15px
        background-color: #8B8A96
        color: #FFFFFF
        font-size: 14px
        line-height: 18px
        text-align: center
        opacity: 1
      .__react_component_tooltip.place-top
        margin-left: -204px !important
        box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
        &::after
          border-top: 18px solid #8B8A96 !important
          border-left: 12px solid transparent !important
          border-right: 12px solid transparent !important
          bottom: -15px
          width: 22px
        span
          font-size: 13px
.upgrade-plan-modal
  @media (min-width: 479px)
    margin-top: 104px
  .modal-header
    padding: 38px 55px 22px
    button
      position: absolute
      width: 30px
      height: 30px
      background: #11A9FF
      border-radius: 50%
      top: 15px
      right: 15px
      opacity: 1
      color: #fff
      text-shadow: none
      font-weight: 400
      margin: 0
      text-align: center
      font-size: 20px
      border: none
      @media (min-width: 768px)
        top: -15px
        right: -15px
    h1
      font-family: Roboto, sans-serif
      font-style: normal
      font-weight: 500
      line-height: normal
      font-size: 20px
      color: #11A9FF
      margin: 0
      padding: 0
  .modal-body
    padding: 0 55px
    margin: 0
    h2
      font-size: 14px
      font-weight: bold
      margin: 0
    div
      margin-top: 40px
      a
        margin-bottom: 10px
        line-height: 16px
        background: #11A9FF
        border-radius: 3px
        width: 171px
        text-align: center
        color: white
        font-size: 16px
        font-weight: bold
        padding: 13px
        outline: none
        border: none
        margin-top: 22px

.heelix-modal
  margin-top: 104px
  .modal-header
    padding: 38px 55px 22px
    justify-content: flex-start
    button
      position: absolute
      width: 30px
      height: 30px
      background: #11A9FF
      border-radius: 50%
      top: 15px
      right: 15px
      opacity: 1
      color: #fff
      text-shadow: none
      font-weight: 400
      margin: 0
      text-align: center
      font-size: 20px
      border: none
      @media (min-width: 768px)
        top: -15px
        right: -15px
    h1
      font-family: Roboto, sans-serif
      font-style: normal
      font-weight: 500
      line-height: normal
      font-size: 20px
      color: #11A9FF
      margin: 0
      padding: 0
  .modal-body
    padding: 0 55px
    margin: 0
    h2
      font-size: 14px
      font-weight: bold
      margin: 0
    div
      margin-top: 40px
      a, button
        margin-bottom: 10px
        line-height: 16px
        background: #11A9FF
        border-radius: 3px
        width: 171px
        text-align: center
        color: white
        font-size: 16px
        font-weight: bold
        padding: 13px
        outline: none
        border: none
        margin-top: 22px