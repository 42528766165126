@import "../settings"

@keyframes pulse
  0%
    -moz-box-shadow: 0 0 0 0 rgba(0,147,233, 0.4)
    box-shadow: 0 0 0 0 rgba(0,147,233, 0.4)
  100%
    -moz-box-shadow: 0 0 0 10px rgba(0,147,233, 0)
    box-shadow: 0 0 0 10px rgba(440,147,233, 0)

@keyframes fadeInDown
  0%
    opacity: 0
    transform: translateY(-1.25em)
  100%
    opacity: 1
    transform: translateY(0)

.vibe_new
  &.full-screen
    .panel
      .panel-body
        .vibe-options-wrap
          .vibe-sentiments-list
            max-width: 380px
            margin: 30px auto

          .vibe-anon
            padding: 30px 25px 0

        .final-screen-logo
          padding-bottom: 30px

      .default-factors-screen, .why-factors-screen
        .vibe-anon
          padding: 0 25px
  .panel
    position: relative
    margin-top: 30px

    +mobile-only
      margin-top: 0px

    .panel-header
      line-height: inherit
      font-size: 20px
      height: 100%
      padding: 30px 15px
      line-height: inherit
      span
        height: 100%
        display: inline-block
        vertical-align: middle
      .btn-previous
        position: absolute
        left: 15px
        top: 25px

    // default_factors
    .default-factors-screen, .other-factors-screen, .why-factors-screen
      .panel-body
        padding: 0
        +mobile-only
          height: calc(100% - 130px)
          overflow: auto
          width: 100%

        &.message-body-wrap
          margin: 6px 0 30px
    .other-factors-screen
      .panel-body
        +mobile-only
          height: calc(100% - 50px)

      .panel-footer
        &.secondary
          background-color: $sidebar-background-color

    .panel-body
      padding: 30px 0 0
      // select_sentiment
      .vibe-question
        font-size: 24px
        margin-bottom: 18px
      +mobile-only
        .vibe-question
          line-height: rhythm(1)
          font-size: 24px
        .vibe-user-avatar
          width: 112px
          height: 112px

      .vibe-sentiments-list
        //+make-md-column(12, 0)
        //+make-sm-column(12, 0)
        //+make-xs-column(12, 0)
        +mobile-only
          margin-top: 50px
          margin-bottom: 90px

        .vibe-sentiment
          //+make-md-column(2.4, 0)
          //+make-md-column-offset(0)
          //+make-sm-column(2.4, 0)
          //+make-sm-column-offset(0)
          //+make-xs-column(2.4, 0)
          //+make-xs-column-offset(0)
          font-size: 16px
          width: 20%
          float: left
          padding-bottom: 30px

          .vibe-sentiment-images:hover
            transform: scale(1.52)
            cursor: pointer

          +mobile-only
            font-size: 13px

          .vibe-sentiment-round
            //+make-md-column(12, 0)
            //+make-sm-column(12, 0)
            //+make-xs-column(12, 0)
            min-height: 25px

            .round
              background-color: #DADADA
              width: 13px
              height: 13px
              border-radius: 100%
              border: 1px solid white
              margin-left: auto
              margin-right: auto
              will-change: transform
              transform: scale(1)
              transition-duration: 0.03s

          .vibe-sentiment-name
            //+make-md-column(12, 0)
            //+make-sm-column(12, 0)
            //+make-xs-column(12, 0)
            padding-top: 10px
            text-transform: uppercase
            color: #8A8A8A
            font-weight: bold
            min-height: 45px
            transition-duration: 0.03s
            margin-bottom: 5px

          &.selected, &.selected:hover
            .vibe-sentiment-round .round
              background-color: $blue
              transform: scale(1.7)
              +desktop-only
                animation-duration: 0.5s
                animation-name: pulse

            .vibe-sentiment-name
              color: $blue
              transition-duration: 0.03s

          &:hover
            .vibe-sentiment-round .round
              background-color: $mid-grey
              transform: scale(1.7)
              transition-duration: 0.03s

            .vibe-sentiment-name
              color: $mid-grey
              transition-duration: 0.03s

        .vibe-sentiments-line
          background-color: #E8E8E8
          position: relative
          width: 81%
          height: 2px
          margin-left: 10%
          top: 58px

      // select_good_factors + select_better_factors
      .vibe-factors
        //+make-md-column(12, 0)
        //+make-xs-column(12, 0)
        padding: 0 28px 0 28px
        .control-label.empty-factors
          padding: 0 0 18px
        .control-label
          padding: 30px 0 18px
        #additional-message
          border-radius: 0
          border: 1px solid #E7E7E7
          background-color: #FFFFFF
          box-shadow: none
          padding: 13px 19px
          font-size: 14px
          line-height: 16px
        .vibe-factor
          //+make-md-column(5.8)
          //+make-xs-column(12)
          padding: 15px
          border-radius: 6px
          font-size: 13px
          line-height: 17px
          text-align: center
          height: 55px
          cursor: pointer
          display: flex
          align-items: center
          justify-content: center
          border: solid 1px #EDEDED
          color: #323232
          transition: background-color linear .2s
          margin: 5px 0

          +mobile-only
            font-size: 16px

          &:nth-child(even)
            //+make-md-column-offset(0.4)
            //+make-xs-column-offset(0)

          span.fa-check
            display: none
            font-size: 25px
            color: transparent
            margin-left: auto

          &.selected
            color: white

            span.fa-check
              display: block
              transition: color linear .2s
              color: $white

          &.disabled
            font-style: initial
            border-color: $default-border-color
            color: $pale-grey
            cursor: not-allowed

      // other_factors
      .accordion-factors
        .accordion-factor
          margin-bottom: 5px
          //+make-md-column(12, 0)
          //+make-xs-column(12, 0)

          .accordion-factor-title
            background-color: $blue
            clear: both
            cursor: pointer
            color: white
            +mobile-only
              font-size: 20px
            padding: 14px
            font-weight: bold

          .vibe-factors
            margin-top: 20px
            margin-bottom: 20px
            display: none

            &.open
              animation-name: fadeInDown
              animation-duration: 0.5s
              display: inline-block
              +mobile-only
                display: block
    .vibe-submit
      padding: 24px 0 10px
      .btn-next
        background: #11A9FF
        border-radius: 4px
        border: none
      .large-button
        padding-left: 60px
        padding-right: 60px
    .vibe-anon
      span.seen-by
        padding-right: 5px
        min-width: 30%
        text-align: left
        margin-top: 5px
        font-size: 12px
        line-height: 14px
        color: #ADADAD
      span.anonymous-vibe
        margin: auto
        margin-right: 0
        font-size: 12px
        line-height: 13px
        color: #6A6A6A
        cursor: pointer
      display: flex
      position: initial
      cursor: pointer
      width: 100%
      font-weight: normal
      font-size: 11px
      line-height: 13px
      color: #6A6A6A
      .icon
        margin-right: 8px
        font-size: 150%
        vertical-align: middle

  //INFO: full screen for mobile & fix previous class for vertical align
  +mobile-only
    margin-top: 0px

    .react-vibe-flow > div, .vibe-step, .panel, .vibe_answer, .frame-wrapper, .page-wrapper
      height: 100%
    .page-wrapper
      padding: 0
    .fullscreen-close
      font-size: 16px
    .nav-page-title-xs
      margin-bottom: 0px

    // INFO: center the screen
    .select_sentiment .panel-body, .good_final_screen .panel-body, .better_final_screen .panel-body
      width: 100%
      top: 60%



// good_final_screen + better_final_screen
.panel.good_final_screen, .panel.better_final_screen
  img
    margin-top: 30px
    width: 70px
    height: 70px

  .vibe-sent
    font-size: 42px
    font-weight: bold
    margin-top: 13px

  .sub-title
    font-weight: bold
    font-size: 24px
    margin-top: 18px

  .selected-factors:not(:empty)
    margin: 20px 10px 0 10px

    .selected-factor
      border: solid 1px $blue
      border-radius: 4px
      color: lighten($text-color, 15)
      margin: 0 10px 10px 0
      padding: 4px 13px
      font-size: 17px
      font-weight: 600
      white-space: nowrap
      display: inline-block

  .share
    margin-top: 40px

  .skip-this
    margin-top: 20px
    margin-bottom: 40px
    a
      color: $pale-grey
.page-wrapper
  .default-factors-screen, .other-factors-screen, .why-factors-screen
    .vibe-anon
      span.anonymous-vibe
        text-align: right
        min-width: 70%

// INFO create colors
// select_good_factors
// select_better_factors
// good_final_screen
// better_final_screen
$panel-states: ( select_sentiment: $new-blue, select_good_factors: $new-blue, select_why_good_factors: $new-blue, select_feel_good_factors: $new-blue, select_better_factors: $amber, select_why_better_factors: $amber, select_feel_better_factors: $amber, good_final_screen: $new-blue, better_final_screen: $amber )
@each $state, $color in $panel-states
  .vibe_new
    .panel.#{$state}
      .app-alerts-dropdown
        display: none
      .panel-header
        color: #323232
      .panel-body
        .vibe-factors
          .vibe-factor
            &.selected
              background: #{$color}
              border-color: #{$color}
              font-weight: bold
            +desktop-only
              &:hover:not(.selected):not(.disabled)
                background-color: lighten($color, 35%)
                border-color: lighten($color, 30)

        .vibe-sent
          color: #{$color}
        .share a
          background-color: #{$color}
          border-color: #{$color}
        .selected-factors .selected-factor
          background-color: lighten($color, 45)
          border-color: lighten($color, 30)
        .accordion-factors .accordion-factor
          .accordion-factor-title
            background-color: #{$color}
            &.open
              background-color: darken($color, 10)

      .toggle-all-factors
        margin-left: auto
        margin-right: auto
        a
          color: #{$color}
          cursor: pointer

      .panel-footer
        background-color: $color
        +mobile-only
          bottom: 0px
          position: absolute
          width: 100%

        .btn
          margin-top: 6px
          margin-bottom: 6px
        .btn-save-and-close
          color: $white
          font-weight: bold
          width: 100%
        .btn-previous
          color: $white
          float: left
        .btn-next
          color: #{$color}
          border-color: #{$color}
          float: right
          background-color: $white

          &:hover
            background-color: $white
    .shadowfilter
      margin: 50px auto
      animation: heelixGlow 2s infinite
      @keyframes heelixGlow
        0%
          -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.0))
        50%
          -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.5))
        100%
          -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.0))
