.filters-group
  margin-bottom: 30px
  overflow-y: auto
  p
    font-size: 14px
    line-height: 30px
    color: #6A6A6A
    transition: color .3s, background .3s
    margin-bottom: 2px
    cursor: pointer
    max-width: 170px
    &:hover
      color: #909090
    &[custom-checked='true']
      background: white
      border-radius: 1px
      color: #11A9FF
      padding-left: 6px
      margin-left: 0
      position: relative
      &:before, &:after
        width: 10px
        height: 2px
        background: #D5D5D5
        border-radius: 1px
        position: absolute
        right: 10px
        top: 50%
        content: ''
        transition: transform .3s
      &:before
        transform: translate(0, -50%) rotateZ(45deg)
      &:after
        transform: translate(0, -50%) rotateZ(135deg)
    &.space-members
      display: inline
  div.lead-label
    font-size: 8px
    background-color: #FFFFFF
    padding: 2px 5px
    display: inline
    vertical-align: middle
    margin-left: 7px
    text-transform: uppercase
    color: #11A9FF
    font-weight: 500
    border-radius: 6px
    cursor: default
  a
    text-decoration: none
  .unfold-button
    cursor: pointer
    margin-top: 22px
    position: relative
    background: white
    border-radius: 22px
    font-size: 12px
    color: #6A6A6A
    width: 86px
    height: 26px
    line-height: 26px
    padding-left: 29px
    padding-right: 11px
    &:before, &:after
      width: 10px
      height: 2px
      background: #D5D5D5
      border-radius: 1px
      position: absolute
      left: 10px
      top: 50%
      content: ''
      transition: transform .3s
    &:before
      transform: translate(0, -50%)
    &:after
      transform: translate(0, -50%) rotateZ(90deg)
    &.unfolded
      &:before
        transform: translate(0, -50%) rotateZ(45deg)
      &:after
        transform: translate(0, -50%) rotateZ(135deg)