@import "../settings"

.jobvibe-future
  .navbar.navbar-fixed-top.navbar-default
    position: relative
    height: 1px
    background-color: #efefef
    min-height: 1px
    border: none
  .vibe-index
    margin: 0 -17px
    .filtering-info-outer-wrap
      padding-right: 240px
      position: relative
      text-align: center
      padding-bottom: 15px
      #jf-back-btn
        position: absolute
        display: block
        left: 2px
        top: -1px
        z-index: 2
      .filtering-info-inner-wrap
        display: flex
        justify-content: center
        position: relative
        top: -7px
        left: 1px
        span
          font-size: 20px
          position: relative
          left: 23px
        svg
          align-self: center
    @media (max-width: 768px)
      margin: 0
    .vibe-explore-wrap
      margin: 0 -6px 24px
      .sticky
        z-index: 9999
        background-color: #efefef
    .page-title
      display: flex
      align-items: center
      font-size: 24px
      line-height: 24px
      font-weight: 900
      color: #323232
      padding-bottom: 21px
      .space-name
        padding-left: 15px
        position: relative
      img
        height: 28px
        width: 28px
      .space-img
        height: 24px
        width: 24px
        border-radius: 100%
      .space-logo
        width: 24px
        height: 24px
        border-radius: 100%
        font-weight: bold
        display: inline-flex
        justify-content: center
        align-items: center
        font-size: 12px
    .heelix-feed-wrap
      display: flex
      .create-and-masonry-wrap
        width: 100%
        .no-fixes-page
          h1
            margin-top: 39px
            margin-bottom: 0
            font-size: 20px
            line-height: 0px
            color: #323232
            font-weight: normal
          h2
            margin-top: 10px
            font-size: 15px
            color: #979797
            font-weight: normal
            margin-bottom: 36px
          button
            background: #F3F3F3
            border: 1px solid #D6D6D6
            box-sizing: border-box
            border-radius: 4px
            font-family: Roboto, sans-serif
            font-style: normal
            font-weight: 500
            line-height: normal
            font-size: 15px
            color: #575563
            padding: 11px 45px
        h1.vibe-index-title
          margin-top: 8px
          text-align: center
          font-family: Roboto, sans-serif
          font-size: 24px
          color: #323232
          font-weight: normal
          margin-bottom: 19px
        h3.vibe-index-subtitle
          text-align: center
          margin-top: -22px
          font-size: 12px
          color: #979797
        .post-feed-wrap
          margin-left: -10px
          margin-right: -10px
          .post-tile-wrap
            padding-left: 10px
            padding-right: 10px
            .text__suggestions
              border-color: #323232
              z-index: 3 !important
              .text__suggestions__item--focused
                background: #fff
                font-weight: bold
                color: #323232 !important
                .hashtag-suggestion
                  color: #323232
                  .tag-count
                    color: #757575
            //------If you need to overwrite some styles for specific type of post, add additional class to component with ".post-tile" class
            //------to make your styles more important
            .post-tile
              position: relative
              border: 1px solid #F3F3F3
              background-color: #fff
              padding: 25px
              width: 100%
              margin-bottom: 20px
              border-radius: 5px
              .__react_component_tooltip.place-top:after
                margin-left: -12px
              .post-tile-space-info
                width: 70%
                text-align: right
                margin-top: 3px
                font-size: 13px
                color: #B7B7B7
                padding: 0 1px
                a
                  color: #58575F
                  text-decoration: none
                  padding-left: 12px
                .space-logo, .circle-image
                  display: inline-block
                  font-weight: bold
                  width: 18px
                  height: 18px
                  border: 1px solid white
                  border-radius: 100%
                  margin: 0
                  line-height: 180%
                  font-size: 9px
                  font-family: "Roboto", sans-serif
                  text-align: center
                  text-shadow: none
                  position: relative
                  bottom: 2px
                .circle-image
                  border-color: #F3F3F3
              .post-tile-header
                display: flex
                margin-bottom: 18px
                position: relative
                align-items: center
                .author-avatar
                  padding-right: 13px
                  img
                    width: 36px
                    height: 36px
                    border-radius: 100%
                .author-info
                  white-space: pre-wrap
                  font-size: 14px
                  line-height: 16px
                  font-weight: bold
                  align-self: center
                  max-width: 65%
                  a
                    color: #323232
                    text-decoration: none
                  .author-role-position
                    font-size: 11px
                    color: #BBBBBB
                    font-weight: normal
                    display: none
                    margin-top: 4px
                    @media (min-width: 991px)
                      display: block
                  & + div, + img.heart
                    margin-left: auto
                .heart
                  width: 20px
                  height: 20px
                  margin-left: 20px
                  stroke: #D5D5D5
                  fill: #ffffff
                  overflow: visible
                  transition: stroke .3s, fill .3s
                  cursor: pointer
                  &.marked
                    fill: #F5351B
                    stroke: #F5351B
                .pinned-post
                  height: 22px
                  width: 22px
                  overflow: visible
                  cursor: pointer
                .app-alerts-dropdown
                  display: initial
                  right: 0
                  top: 0
                  margin-left: 22px
                  position: absolute
                  user-select: none
                  cursor: pointer
                  z-index: 2
                  .options-wrap
                    position: absolute
                    display: none
                    width: 211px
                    border: 1px solid #E7E7E7
                    box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35)
                    border-radius: 8px
                    top: 31px
                    left: -99px
                    background: white
                    padding: 6px 0
                    text-align: left
                    & > div
                      padding: 9px 18px 8px
                      font-size: 14px
                      color: #11A9FF
                      transition: color .3s
                      &:hover
                        color: #0065e2
                      &:not(:last-child)
                        border-bottom: 1px solid #F3F3F3
                    &:before
                      width: 16px
                      height: 16px
                      position: absolute
                      content: ''
                      left: 50%
                      top: 0
                      transform: translate(-3px, -50%) rotate(-45deg)
                      border-top: 1px solid #E7E7E7
                      border-right: 1px solid #E7E7E7
                      background: white
                    @media(max-width: $screen-md-max)
                      left: -170px
                      &:before
                        left: 81%
                  &[custom-open="true"] .options-wrap
                    display: block
                .post-dropdown
                  margin-left: 22px
                  position: relative
                  user-select: none
                  cursor: pointer
                  z-index: 2
                  .options-wrap
                    position: absolute
                    display: none
                    width: 182px
                    border: 1px solid #E7E7E7
                    box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35)
                    border-radius: 8px
                    top: 31px
                    left: -84px
                    background: white
                    padding: 6px 0
                    & > div
                      padding: 9px 28px 8px
                      font-size: 14px
                      color: #11A9FF
                      transition: color .3s
                      &:hover
                        color: #0065e2
                      &:not(:last-child)
                        border-bottom: 1px solid #F3F3F3
                    &:before
                      width: 16px
                      height: 16px
                      position: absolute
                      content: ''
                      left: 50%
                      top: 0
                      transform: translate(-3px, -50%) rotate(-45deg)
                      border-top: 1px solid #E7E7E7
                      border-right: 1px solid #E7E7E7
                      background: white
                    @media(max-width: $screen-md-max)
                      left: -140px
                      &:before
                        left: 81%
                  &[custom-open="true"] .options-wrap
                    display: block
              .poll-alert-body
                .poll-alert-icon
                  display: block
                  margin: 0 auto 30px
                .vibe-anon
                  span
                    font-size: 11px
                    line-height: 13px
                    padding-left: 8px
              .app-alert-body
                text-align: center
                .app-alert-content
                  font-size: 18px
                  font-weight: bold
                  line-height: 25px
                  padding: 20px 0 30px
                .app-alert-btn
                  display: inline-block
                  background: #11A9FF
                  padding: 9px 18px
                  border-radius: 4px
                  font-size: 14px
                  line-height: 16px
                  font-weight: bold
                  color: #ffffff
                .app-alert-feedback
                  display: inline-flex
                  width: 100%
                  font-size: 14px
                  line-height: 21px
                  text-align: left
                  padding-bottom: 20px
                  .feedback-paragraph
                    display: flex
                  .feedback-counter
                    display: inline-block
                    background: #11A9FF
                    color: #ffffff
                    border-radius: 50%
                    min-width: 24px
                    width: 24px
                    height: 24px
                    text-align: center
                    font-weight: bold
                    padding-top: 2px
                    margin-right: 16px
                .app-alert-header
                  font-size: 18px
                  line-height: 25px
                  font-weight: bold
                  padding-right: 30px
                  margin-bottom: 24px
                .show-feedback-btn
                  display: inline-block
                  background: #ffffff
                  border: 1px solid #11A9FF
                  padding: 9px 18px
                  border-radius: 4px
                  font-size: 14px
                  line-height: 16px
                  font-weight: bold
                  color: #11A9FF
                  cursor: pointer
                  &.show-full-feedback
                    display: none
                .feedback-wrapper
                  .feedback-info
                    font-size: 14px
                    line-height: 21px
                    font-weight: bold
                  &.hide-full-feedback
                    overflow: hidden
                    max-height: 130px
                svg.hide-full-feedback
                  position: absolute
                  bottom: 72px
                  left: 0
                svg.show-full-feedback
                  display: none
              .post-tile-body
                padding: 0 1px
                a:hover
                  text-decoration: none
                a
                  cursor: pointer
                .post-details-wrap
                  display: flex
                  .post-details
                    width: 30%
                .post-tile-media
                  margin: 21px -26px 22px -26px
                  position: relative
                  height: 342px
                  &.no-height
                    height: auto !important
                  .slide
                    height: 342px
                    bottom: 0
                  .img-responsive
                    margin: 0 auto
                  .border
                    border-radius: 0 !important
                    border: 1px solid #fff !important
                    .cover
                      border-radius: 0 !important
                  div.img-div
                    height: 342px
                    background-size: cover
                    background-position: center 10%
                    background-repeat: no-repeat
                  .carousel-inner
                    .item
                      height: 342px
                      .img-responsive
                        margin: auto
                        max-width: none
                        width: auto
                        height: 100%
                  .carousel-indicators
                    position: absolute
                    z-index: 1
                    bottom: -8px !important //important required to overwrite webview settings
                    li
                      position: relative
                      width: 5px
                      height: 5px
                      background-color: rgba(255, 255, 255, 0.6)
                      margin: 0 2px
                      top: -2px
                      transition: .1s height, .1s width, .1s top, .1s margin
                      &.active
                        background-color: #FFFFFF
                        width: 9px
                        height: 9px
                        top: 0
                        margin: 0
                  .carousel-control
                    color: #DADADA
                    line-height: 30px
                    opacity: 1
                    width: 30px
                    &.right,
                    &.left
                      background: none
                    div
                      position: absolute
                      width: 13px
                      height: 13px
                      top: 50%
                      transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg)
                      border-bottom: 2px solid white
                      border-right: 2px solid white
                      &.control-right
                        right: 11px
                      &.control-left
                        transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg)
                        left: 11px
                  .video-react
                    padding: 0 !important
                    min-height: 50px
                    height: 100%
                .shoutout-receiver
                  text-align: center
                  .buzz-shoutout-title
                    font-weight: 500
                    line-height: normal
                    font-size: 18px
                    color: #323232
                    margin: 5px 0 20px
                    a
                      color: #323232
                .post-tile-title
                  margin-bottom: 20px
                  color: #323232
                  font-size: 18px
                  font-weight: bold
                  word-wrap: break-word
                  .type
                    color: #B7B7B7
                    line-height: 21px
                    &.black
                      color: #323232
                  h4.poll-content
                    line-height: 21px
                .post-tile-content
                  color: #323232
                  font-size: 14px
                  line-height: 21px
                  margin-bottom: 14px
                  word-wrap: break-word
                  &.shoutout-buzz-post
                    text-align: center
                .post-tile-possible-solution
                  font-size: 14px
                  font-weight: bold
                  margin-bottom: 14px
                  color: #323232
                  p
                    line-height: 20px
                    margin-top: 11px
                    font-weight: normal
                .categories-wrapper
                  overflow-x: hidden
                  text-overflow: ellipsis
                  margin-top: 22px
                  margin-bottom: 27px
                  font-size: 14px
                  line-height: 22px
                  span
                    color: #323232
                    font-weight: bold
                .tags-wrapper
                  margin-bottom: 24px
                  margin-top: -5px
                  a, span
                    max-width: 100%
                    overflow-x: hidden
                    text-overflow: ellipsis
                    text-decoration: none
                    font-size: 14px
                    line-height: 21px
                    background: #11A9FF
                    color: white
                    border-radius: 20px
                    padding: 6px 13px
                    font-weight: bold
                    margin-right: 7px
                    white-space: nowrap
                    display: inline-block
                    margin-top: 5px
                .file-link
                  text-decoration: none
                  .file-tile
                    background: #F8F8F8
                    //background: linear-gradient(to left, #8BDBFF 0%, rgba(139, 219, 255, 0.5)  100%)
                    display: flex
                    height: 40px
                    padding: 0 13px
                    border-radius: 6px
                    margin-bottom: 10px
                    position: relative
                    cursor: pointer
                    text-decoration: none
                    svg
                      align-self: center
                      margin-right: 12px
                    .file-name
                      align-self: center
                      color: #11A9FF
                .time-ago, .views-counter
                  font-style: normal !important
                  font-weight: normal !important
                  line-height: normal !important
                  font-size: 12px !important
                  color: #D5D5D5 !important
                  margin-bottom: 20px
                  display: inline-block
                .time-ago
                  padding-right: 20px
                .vote-btn
                  width: 100%
                  @media(max-width: 330px)
                    width: 90%
                  text-align: center
                  margin-top: 5px
                  margin-bottom: 20px
                  a
                    font-weight: 500
                    cursor: pointer
                    position: relative
                    font-size: 13px
                    line-height: 15px
                div.poll-info
                  position: relative
                  height: 45px
                  .time-ago
                    position: absolute
                .post-owners
                  height: 30px
                  cursor: pointer
                  margin-bottom: 25px
                  display: flex
                  justify-content: flex-start
                  a
                    text-decoration: none
                    &:hover
                      color: #323232
                  .media-left
                    padding-right: 45px
                  a.post-owner-link
                    font-family: Roboto, sans-serif
                    font-style: normal
                    font-weight: normal
                    line-height: 13px
                    font-size: 13px
                    color: #323232
                    text-decoration: none
                  .media-left.media-middle
                    position: relative
                    z-index: 0
                    img
                      width: 34px
                      height: 34px
                      border: 2px solid #fff
                      position: absolute
                      top: 0
                      left: 0
                      &.post-owner-multiple-icon
                        &:nth-child(2)
                          left: 18px
                          opacity: 0.95
                          z-index: -1
                        &:nth-child(3)
                          left: 36px
                          opacity: 0.9
                          z-index: -2
                        &:nth-child(4)
                          left: 54px
                          opacity: 0.85
                          z-index: -3
                        &:nth-child(5)
                          left: 72px
                          opacity: 0.8
                          z-index: -4
                        &:nth-child(6)
                          left: 90px
                          opacity: 0.75
                          z-index: -5
                        &:nth-child(7)
                          left: 108px
                          opacity: 0.7
                          z-index: -6
                  p
                    font-family: Roboto, sans-serif
                    font-weight: normal
                    line-height: 34px
                    font-size: 13px
                    color: #323232
                    text-decoration: none
              .comment-error
                font-size: 12px
                margin-top: 7px
                color: #FE483C
                margin-left: 61px
              .post-tile-comment-input
                position: relative
                display: flex
                .media-control-panel
                  margin-left: 5px
                  img
                    cursor: pointer
                .post-tile-icon-wrapper
                  font-family: Roboto, sans-serif
                  font-size: 13px
                  display: inline-flex
                  color: #323232
                  cursor: pointer
                  line-height: 40px
                  font-weight: bold
                  margin-left: -2px
                  margin-right: 13px
                  .post-tile-icon
                    width: 30px
                    height: 30px
                    background-color: #E6E6E6
                    -webkit-border-radius: 50%
                    -moz-border-radius: 50%
                    border-radius: 50%
                    color: #fff
                    display: inline-flex
                    margin-top: 5px
                    margin-right: 10px
                    font-size: 15px
                    justify-content: center
                    img
                      align-self: center
                      padding-bottom: 0
                  &.post-tile-liked
                    font-weight: bold
                    color: #11A9FF
                    .post-tile-icon
                      background-color: #11A9FF
                .mentions-input-wrap
                  border-radius: 4px
                  border: 1px solid #E7E7E7
                  flex-grow: 1
                  width: 80%
                  min-height: 40px
                  padding: 10px 34px 10px 14px
                  font-size: 14px
                  line-height: 16px
                  background: #FFFFFF
                  textarea
                    word-wrap: break-word
                    border: none
                    min-height: unset
                    &::placeholder
                      color: #B2B1C0
                      letter-spacing: .6px
                      font-size: 12px
                      line-height: 21px
                  &.error
                    border: 1px solid #F5351B
                    background: #FFF7F5
              .comment-dropzone-wrap
                position: absolute
                top: 7px
                right: 16px
                cursor: pointer
                >div
                  height: auto !important
                  border: none !important
                  border-radius: 0 !important
                h3
                  position: absolute
                  top: 50%
                  left: 50%
                  -ms-transform: translate(-50%, -50%)
                  transform: translate(-50%, -50%)
                  text-align: center
                  font-size: 14px
                  font-family: Roboto, sans-serif
                  white-space: initial
                  color: #69BADE
                  z-index: 1
                  margin-bottom: 4px
                  line-height: 20px
              .comment-images-wrap
                text-align: left
                line-height: 0
                margin: 20px 0 20px 60px
                .preview
                  border-radius: 3px !important
                  display: inline-block
                  width: 80px
                  background-size: cover
                  background-position: center
                  margin: 5px 15px 5px 5px
                  position: relative
                  &:before
                    content: 'x'
                    position: absolute
                    top: -10px
                    right: -10px
                    width: 20px
                    height: 20px
                    background: #11A9FF
                    border-radius: 50%
                    cursor: pointer
                    color: #fff
                    line-height: 20px
                    text-align: center
                    font-weight: bold
                    font-size: 10px
                    z-index: 1
                  .video-react-big-play-button
                    font-size: 1.5em
                  .preview-img
                    max-width: 100%
                    height: auto
                    vertical-align: top
                > div
                  height: 100%
                  .video-react-control-bar
                    display: none
                  .video-react-big-play-button
                    width: 2em
              .post-tile-comments-section
                margin: 25px -28px -28px
                border-top: 1px solid #F3F3F3
                border-bottom-left-radius: 5px
                border-bottom-right-radius: 5px
                ul
                  margin: 0
                  padding: 0
                  .comment
                    display: table
                    &.user-comment
                      font-size: 13px
                      padding: 0 26px 16px
                      .comment-content
                        display: table-row
                        vertical-align: top
                        padding-left: 0
                        @media (min-width: 1300px)
                          display: table-cell
                        a
                          color: #323232
                          font-weight: 500
                        span
                          width: 100%
                          text-align: justify
                          line-height: 18px
                          word-wrap: break-word
                          word-break: break-word
                          a
                            color: #1297E3
                            text-decoration: none
                        .comment-show-media
                          margin: 5px 0 0 0
                          width: 160px
                          @media (min-width: 460px)
                            width: 200px
                          &.single-image
                            height: auto
                            width: auto
                            max-width: 200px
                            margin-right: 0
                          .img-responsive
                            margin: auto
                          .slide
                            height: auto
                            bottom: 0
                          .border
                            border-radius: 0 !important
                            border: 1px solid #fff !important
                            .cover
                              border-radius: 0 !important
                          div.img-div
                            height: 160px
                            width: 160px
                            background-size: contain
                            background-position: left top
                            background-repeat: no-repeat
                            @media (min-width: 460px)
                              width: 200px
                          .carousel-indicators
                            position: absolute
                            bottom: 10px
                            li
                              background-color: rgb(255,255,255)
                              background-color: rgba(255,255,255, 0.6)
                              &.active
                                background-color: rgb(255,255,255)
                                background-color: rgba(255,255,255, 1)
                          .carousel-indicators
                            bottom: -25px
                          .carousel-inner
                            .item
                              height: 160px
                              width: 160px
                              div.img-div
                                background-position: center
                          .carousel-control
                            color: #DADADA
                            line-height: 30px
                            opacity: 1
                            width: 30px
                            div
                              position: absolute
                              width: 13px
                              height: 13px
                              top: 50%
                              transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg)
                              border-bottom: 2px solid white
                              border-right: 2px solid white
                              &.control-right
                                right: 11px
                              &.control-left
                                transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg)
                                left: 11px
                          .video-react
                            padding: 0 !important
                            min-height: 50px
                            height: 100%
                    &:first-of-type
                      padding-top: 25px
                    &:last-of-type
                      padding-bottom: 30px
                .react-tooltip.limit-reached
                  text-align: center
                  pointer-events: auto !important
                  background-color: #8B8A96
                  box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
                  z-index: 10
                  &::after
                    border-top-color: transparent !important
                    border-left-color: #8B8A96 !important
                    border-right-color: #8B8A96 !important
                  span
                    a
                      color: white
                      text-decoration: underline

                .post-tile-comments-unfold
                  font-size: 12px
                  line-height: 16px
                  color: #11A9FF
                  cursor: pointer
                  padding: 0 26px 30px
                .post-tile-comments
                  padding: 0 2px
                  .post-tile-comment
                    margin-bottom: 16px
                    font-size: 14px
                    line-height: 18px
                    color: #323232
                    a
                      color: #323232
                      text-decoration: none
                      word-wrap: break-word
                      cursor: pointer
                    span
                      margin-left: 7px
                      word-wrap: break-word
                      a
                        color: #11A9FF
                        white-space: nowrap
                    .inline-block
                      display: inline-block
                      vertical-align: text-top
              .react-tooltip
                background-color: #8B8A96
                box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15)
                border-radius: 0
              &.resolved
                @media (min-width: 991px)
                  background: #ffffff
                  filter: none
                .post-tile-title
                  color: #4DA60B
                  img
                    margin-top: 3px
                  .type
                    color: #4DA60B
                .post-tile-marked-done
                  color: #4DA60B
                  font-weight: bold
                  font-size: 14px
                  font-family: Roboto, sans-serif
                  margin: -3px 0 24px 1px
                  display: flex
                  img
                    width: 30px
                    height: 30px
                    margin-right: 13px
                    border-radius: 100%
                  span
                    padding-top: 6px
                    a
                      color: #4DA60B
                .post-tile-icon-done
                  vertical-align: top
                  margin-right: 12px
              .fix-tile-error
                margin-top: 10px
              &.poll-tile
                .poll-options
                  font-size: 14px
                  line-height: 16px
                  .pool-option-box
                    display: flex
                    margin-bottom: 12px
                    .option-content
                      position: relative
                      min-width: 50%
                      max-width: 90%
                      color: #323232
                      font-size: 14px
                      line-height: 19px
                      .vote-value
                        color: #11A9FF
                        position: absolute
                        right: -58px
                        top: 10px
                        font-weight: normal
                      &.option-chosen
                        font-weight: bold
                    .round
                      margin-right: 12px
                    .round label
                      border: 2px solid #D5D5D5
                      border-radius: 50%
                      cursor: pointer
                      height: 18px
                      width: 18px
                    .round label:after
                      border: 2px solid #fff
                      border-top: none
                      border-right: none
                      content: ""
                      height: 5px
                      left: 2px
                      opacity: 0
                      position: absolute
                      top: 4px
                      transform: rotate(-45deg)
                      width: 10px
                    .round input[type="checkbox"]
                      visibility: hidden
                      width: 0
                    .round input[type="checkbox"]:checked + label
                      cursor: default
                      background-color: $new-blue
                      border-color: $new-blue

                    .round input[type="checkbox"]:checked + label:after
                      opacity: 1
                  .pool-option-box.selected
                    &:nth-of-type(1)
                      .option-content
                        background-color: #BDEBFF
                    &:nth-of-type(2)
                      .option-content
                        background-color: #DAF4FF
                    &:nth-of-type(3)
                      .option-content
                        background-color: #EFFAFF
                    &:nth-of-type(4)
                      .option-content
                        background-color: #EFFAFF
                    &.empty
                      .option-content
                        background-color: white
                    .round
                      padding-bottom: 15px
                .poll-options-expired
                  .pool-option-box
                    .option-content
                      color: #323232
                      font-size: 14px
                      line-height: 16px
                      .vote-value
                        color: #11A9FF
                      .vote-progress-bar
                        background-color: #F8F8F8
                        height: 10px
                        margin-bottom: 13px
                        margin-top: 5px
                        .vote-color-bar
                          border-radius: 3px
                          height: 10px
                          background-color: #BDEBFF
                  .pool-option-box
                    &:nth-of-type(1)
                      .option-content
                        .vote-progress-bar
                          .vote-color-bar
                            background-color: #11A9FF
              &.vibe-tile
                .fullscreen-close
                  display: none !important
                .vibe-user-avatar
                  display: none !important
                .vibe-visible-only-info
                  font-size: 11px
                  color: #323232
                .vibe_new
                  .app-alerts-dropdown
                    display: initial
                    right: 0
                    top: 0
                    margin-left: 22px
                    position: absolute
                    user-select: none
                    cursor: pointer
                    z-index: 2
                    .options-wrap
                      position: absolute
                      display: none
                      width: 211px
                      border: 1px solid #E7E7E7
                      box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35)
                      border-radius: 8px
                      top: 31px
                      left: -99px
                      background: white
                      padding: 6px 0
                      text-align: left
                      & > div
                        padding: 9px 18px 8px
                        font-size: 14px
                        color: #11A9FF
                        transition: color .3s
                        &:hover
                          color: #0065e2
                        &:not(:last-child)
                          border-bottom: 1px solid #F3F3F3
                      &:before
                        width: 16px
                        height: 16px
                        position: absolute
                        content: ''
                        left: 50%
                        top: 0
                        transform: translate(-3px, -50%) rotate(-45deg)
                        border-top: 1px solid #E7E7E7
                        border-right: 1px solid #E7E7E7
                        background: white
                      @media(max-width: $screen-md-max)
                        left: -170px
                        &:before
                          left: 81%
                    &[custom-open="true"] .options-wrap
                      display: block
                  .shadowfilter
                    margin: 50px auto
                    animation: heelixGlow 2s infinite
                    @keyframes heelixGlow
                      0%
                        -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.0))
                      50%
                        -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.5))
                      100%
                        -webkit-filter: drop-shadow(0px 0px 15px rgba(255, 163, 19, 0.0))
                  .panel
                    position: relative
                    margin-top: 0
                    margin-bottom: 0
                    box-shadow: none
                    +mobile-only
                      margin-top: 0
                    .panel-header
                      line-height: inherit
                      font-size: 18px
                      height: 100%
                      padding: 0 15px 15px
                      span
                        color: #323232
                        height: 100%
                        display: inline-block
                        vertical-align: middle
                        padding-top: 3px
                      .btn-previous
                        position: absolute
                        left: 0
                        top: -4px
                    .select_good_factors
                      .panel-footer
                        background-color: #11A9FF
                        .btn-next
                          color: #11A9FF
                          border-color: #11A9FF
                    .default-factors-screen, .other-factors-screen, .why-factors-screen
                      .panel-body
                        padding: 0
                        +mobile-only
                          height: calc(100% - 130px)
                          overflow: auto
                          width: 100%

                        &.message-body-wrap
                          margin: 21px 0 0
                      .panel-footer
                        margin-bottom: 25px
                      .vibe-anon
                        bottom: -45px
                        span.anonymous-vibe
                          text-align: right
                          min-width: 60%
                    .why-factors-screen
                      .panel-header span
                        margin-left: 26px
                    .other-factors-screen
                      .panel-body
                        +mobile-only
                          height: calc(100% - 50px)
                      .panel-footer
                        &.secondary
                          background-color: $sidebar-background-color
                    .panel-body
                      padding-top: 0
                      padding-bottom: 0
                      min-height: unset
                      #additional-message.optional
                        border-radius: 0
                        border: none
                        background-color: #F8F8F8
                        box-shadow: none
                        padding: 13px 19px
                        font-size: 14px
                        line-height: 16px
                        margin-top: 18px
                      .vibe-question
                        font-size: 18px
                        padding-bottom: 0
                        margin-bottom: 0
                      +mobile-only
                        .vibe-question
                          line-height: rhythm(1)
                          font-size: 18px
                        .vibe-user-avatar
                          width: 112px
                          height: 112px
                      .vibe-sentiments-list
                        margin: 35px auto
                        max-width: 280px
                        +mobile-only
                          margin-top: 50px
                          margin-bottom: 90px
                        .vibe-sentiment
                          font-size: 16px
                          width: 20%
                          float: left
                          padding-bottom: 30px
                          .vibe-sentiment-images
                            width: 34px
                            height: 34px
                            &:hover
                              transform: scale(1.52)
                            cursor: pointer
                          +mobile-only
                            font-size: 13px
                          .vibe-sentiment-round
                            min-height: 25px
                            .round
                              background-color: #DADADA
                              width: 13px
                              height: 13px
                              border-radius: 100%
                              border: 1px solid white
                              margin-left: auto
                              margin-right: auto
                              will-change: transform
                              transform: scale(1)
                              transition-duration: 0.03s
                          .vibe-sentiment-name
                            padding-top: 10px
                            text-transform: uppercase
                            color: #8A8A8A
                            font-weight: bold
                            min-height: 45px
                            transition-duration: 0.03s
                            margin-bottom: 5px
                          &.selected, &.selected:hover
                            .vibe-sentiment-round .round
                              background-color: #11A9FF
                              transform: scale(1.7)
                              +desktop-only
                                animation-duration: 0.5s
                                animation-name: pulse
                            .vibe-sentiment-name
                              color: #11A9FF
                              transition-duration: 0.03s
                          &:hover
                            .vibe-sentiment-round .round
                              background-color: $mid-grey
                              transform: scale(1.7)
                              transition-duration: 0.03s
                            .vibe-sentiment-name
                              color: $mid-grey
                              transition-duration: 0.03s
                        .vibe-sentiments-line
                          background-color: #E8E8E8
                          position: relative
                          width: 81%
                          height: 2px
                          margin-left: 10%
                          top: 58px
                      .vibe-factors
                        padding: 30px 0 0 0
                        .col-sm-6
                          padding-left: 12px !important
                          padding-right: 12px !important
                        .vibe-factor
                          padding: 15px
                          font-size: 13px
                          text-align: center
                          line-height: 17px
                          height: 63px
                          cursor: pointer
                          display: flex
                          align-items: center
                          justify-content: center
                          font-weight: normal
                          border: 1px solid #EDEDED
                          border-radius: 6px
                          color: #323232
                          transition: background-color linear .2s
                          margin: 5px 0
                          @media(min-width: $screen-sm) and (max-width:  852px)
                            height: 88px
                          &.selected
                            color: white
                            line-height: 16px
                            font-weight: 500
                          &.disabled
                            font-style: initial
                            border-color: $default-border-color
                            color: $pale-grey
                            cursor: not-allowed
                        .control-label
                          padding: 0 0 18px
                      .accordion-factors
                        .accordion-factor
                          margin-bottom: 5px
                          .accordion-factor-title
                            background-color: $blue
                            clear: both
                            cursor: pointer
                            color: white
                            +mobile-only
                              font-size: 20px
                            padding: 14px
                            font-weight: bold
                          .vibe-factors
                            margin-top: 20px
                            margin-bottom: 20px
                            display: none
                            &.open
                              animation-name: fadeInDown
                              animation-duration: 0.5s
                              display: inline-block
                              +mobile-only
                                display: block
                    .vibe-submit
                      padding-top: 30px
                      padding-bottom: 23px
                      #next-step-btn
                        background: #11A9FF
                        border: none
                        border-radius: 4px
                        font-weight: bold
                        font-size: 14px
                        line-height: 16px
                        padding: 9px 28px
                      .large-button
                        padding-left: 60px
                        padding-right: 60px
                    .vibe-anon
                      span.seen-by
                        padding-top: 10px
                        padding-right: 5px
                        min-width: 30%
                        text-align: left
                        margin-top: 5px
                        font-size: 12px
                        line-height: 14px
                        color: #ADADAD
                      span.anonymous-vibe
                        margin-right: 0
                        font-size: 11px
                        line-height: 13px
                        color: #6A6A6A
                        cursor: pointer
                        padding-top: 10px
                      display: flex
                      position: initial
                      cursor: pointer
                      width: 100%
                      font-weight: normal
                      font-size: 11px
                      line-height: 13px
                      color: #6A6A6A
                      +mobile-only
                        display: none
                      .icon
                        margin-right: 8px
                        font-size: 150%
                        vertical-align: middle
                  +mobile-only
                    margin-top: 0
                    .react-vibe-flow > div, .vibe-step, .panel, .vibe_answer, .frame-wrapper, .page-wrapper
                      height: 100%
                    .page-wrapper
                      padding: 0
                    .fullscreen-close
                      font-size: 16px
                    .nav-page-title-xs
                      margin-bottom: 0
                    .select_sentiment .panel-body, .good_final_screen .panel-body, .better_final_screen .panel-body
                      width: 100%
                      top: 60%
              &.blank-post
                .blank-post-avatar
                  width: 36px
                  height: 36px
                  background: #EAEAEA
                  border-radius: 100%
                .author-info
                  user-select: none
                  .author-name
                    line-height: 14px
                    margin-top: -2px
                    background: #EAEAEA
                    color: rgba(0, 0, 0, 0)
                  .author-role-position
                    background: #F8F8F8
                    line-height: 8px
                    font-size: 12px
                    margin-top: 9px
                    color: rgba(0, 0, 0, 0)
                .post-tile-body
                  margin-top: 30px
                  user-select: none
                  margin-bottom: 30px
                  word-wrap: break-word
                  word-break: break-all
                  span
                    background: #EAEAEA
                    color: rgba(0, 0, 0, 0)
                    line-height: 32px
                    font-size: 14px
                .post-tile-comments
                  padding: 0
                  .post-tile-comment-input
                    align-items: center
                  .blank-post-likes-placeholder
                    width: 30px
                    height: 30px
                    border-radius: 100%
                    background: #EAEAEA
                  .blank-post-comment-placeholder
                    height: 40px
                    background: white
                    flex-grow: 1
                    margin-left: 22px
                    border-radius: 4px
                &.animated
                  @keyframes loadingAnimation
                    0%
                      background-position-x: 200%
                    100%
                      background-position-x: 0%
                  .author-name
                    background: linear-gradient(to right, #EAEAEA 50%, #C4C4C4 75%, #EAEAEA 100%)
                    background-size: 200% 100%
                    animation: loadingAnimation infinite 1s
                  .author-role-position
                    background: linear-gradient(to right, #F8F8F8 50%, #C4C4C4 75%, #F8F8F8 100%)
                    background-size: 200% 100%
                    animation: loadingAnimation infinite 1s
                  .post-tile-body span
                    background: linear-gradient(to right, #EAEAEA 50%, #C4C4C4 75%, #EAEAEA 100%)
                    background-size: 200% 100%
                    animation: loadingAnimation infinite 1s

              &.app-alert-tile
                padding: 30px 28px 36px
                .post-tile-header
                  margin: 0
      .mobile-panel-overlay
        display: inline
        position: static
        background: rgba(32,32,32,0)
        transition: background .2s .2s, height 0s .4s, width 0s .4s
        float: right
        min-width: 240px
        @media(max-width: $screen-sm)
          z-index: 1031
          width: 100vw
          position: fixed
          top: 0
          left: 0
          height: 0
          &.open
            height: 100vh
            transition: background .2s
            background: rgba(32,32,32,0.7)
            .feed-right-panel-wrap
              overflow: scroll
              transform: translateX(0)
              transition: transform .2s .2s

        .feed-right-panel-wrap
          top: 10px
          padding-left: 25px
          transition: transform .2s
          width: 240px
          overflow-x: hidden
          @media(max-width: $screen-sm)
            transform: translateX(100%)
            padding-top: 20px
            padding-bottom: 20px
            padding-right: 20px
            background: #F3F3F3
            height: 100vh
            position: absolute !important
            right: 0
            top: 0
          .feed-right-panel-inner-wrap
            max-height: calc(100vh - 112px)
            overflow-y: scroll
            padding-right: 20px
            margin-right: -20px
            .search-input-wrap
              position: relative
              margin-bottom: 24px
              margin-right: -10px
              input
                width: 100%
                border-radius: 8px
                border: 1px solid #E6E6E6
                box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
                background: white
                height: 38px
                outline: none
                padding: 0 40px 0 10px
                font-size: 13px
              svg
                position: absolute
                top: 20px
                right: 18px
                transform: translateY(-50%)
              a
                font-size: 10px
                margin-top: 0
                text-decoration: none
                line-height: 14px
                display: block
                height: 0
                opacity: 0
                transition: .3s .3s height, .3s opacity, .3s .3s margin-top
                overflow: hidden
                &.visible
                  transition: .3s height, .3s .3s opacity, .3s margin-top
                  height: 28px
                  opacity: 1
                  margin-top: 8px

            h3
              font-weight: bold
              font-size: 14px
              color: #6A6A6A
              margin-left: 6px
              margin-bottom: 9px
              span
                display: inline-block
                background: white
                border-radius: 14px
                font-size: 11px
                color: #C4C4C4
                height: 18px
                margin-left: 9px
                padding: 0 5px
                min-width: 28px
                line-height: 20px
                text-align: center
                position: relative
                top: -1px
            img.space-settings
              margin-top: 4px
            .filters-group
              margin-bottom: 30px
              overflow-y: auto
              p
                font-size: 14px
                color: #6A6A6A
                transition: color .3s, background .3s
                margin-left: 6px
                margin-bottom: 2px
                cursor: pointer
                &:hover
                  color: #909090
                &[custom-checked='true']
                  background: white
                  border-radius: 1px
                  color: #11A9FF
                  padding-left: 6px
                  margin-left: 0
                  position: relative
                  &:before, &:after
                    width: 10px
                    height: 2px
                    background: #D5D5D5
                    border-radius: 1px
                    position: absolute
                    right: 10px
                    top: 50%
                    content: ''
                    transition: transform .3s
                  &:before
                    transform: translate(0, -50%) rotateZ(45deg)
                  &:after
                    transform: translate(0, -50%) rotateZ(135deg)
                &.space-members
                  display: inline-block
                  overflow: hidden
                  text-overflow: ellipsis
                  vertical-align: middle
                  max-width: 150px
              a:not(:last-child)
                .space-members
                  max-width: 114px
              div.lead-label
                font-size: 8px
                background-color: #FFFFFF
                padding: 2px 5px
                display: inline
                vertical-align: middle
                margin-left: 7px
                text-transform: uppercase
                color: #11A9FF
                font-weight: 500
                border-radius: 6px
                cursor: default
              a
                text-decoration: none
              .unfold-button
                cursor: pointer
                margin-top: 22px
                position: relative
                margin-left: 8px
                background: white
                border-radius: 22px
                font-size: 12px
                color: #6A6A6A
                width: 86px
                height: 26px
                line-height: 26px
                padding-left: 29px
                padding-right: 11px
                &:before, &:after
                  width: 10px
                  height: 2px
                  background: #D5D5D5
                  border-radius: 1px
                  position: absolute
                  left: 10px
                  top: 50%
                  content: ''
                  transition: transform .3s
                &:before
                  transform: translate(0, -50%)
                &:after
                  transform: translate(0, -50%) rotateZ(90deg)
                &.unfolded
                  &:before
                    transform: translate(0, -50%) rotateZ(45deg)
                  &:after
                    transform: translate(0, -50%) rotateZ(135deg)
              &.space-members
                overflow-y: unset
                &> div
                  padding: 5px 6px
                  img:first-of-type
                    border-radius: 50%
                    width: 26px
                    height: 26px
                    margin-right: 5px
            .leave-space
              cursor: pointer
              padding-left: 7px
              font-size: 14px
              line-height: 16px
              color: #6A6A6A
              display: flex
              align-items: center
              span
                margin-left: 10px
            .edit-space, .edit-space-leads
              cursor: pointer
              padding-left: 7px
              font-size: 14px
              line-height: 28px
              color: #11A9FF
              display: flex
              align-items: center
              margin-bottom: 10px

              span
                margin-left: 11px

            .edit-space-leads
              margin-bottom: 15px
    .guest-view-feed
      padding-bottom: 24px
      padding-left: 10px
      padding-right: 10px
      .guest-view-wrap
        display: flex
        font-size: 15px
        background: $white
        padding: 20px 23px
        text-decoration: none
        height: auto
        background: white
        border: 1px solid #E6E6E6
        border-radius: 8px
        box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px, rgba(0, 0, 0, 0.05) -1px -1px 10px
        div.circle-team-logo
          display: block
          position: initial
          border-radius: 50%
          span, img
            align-self: center
            width: 110px
            height: 110px
            border-radius: 100%
            font-weight: bold
            display: inline-flex
            justify-content: center
            align-items: center
            margin-top: 10px
            margin-right: 10px
            font-size: 55px
            +mobile-only
              width: 90px
              height: 90px
              font-size: 40px
              margin-top: 0px
        div.team-info
          padding-left: 15px
          h1
            font-weight: bold
            font-size: 22px
            img
              width: 24px
              opacity: 0.5
          p
            font-size: 14px
            line-height: 20px
            font-weight: normal
            margin-bottom: 22px
            min-height: 40px
            padding-right: 2px
          input#join-btn
            padding: 0 40px
            font-size: 15px
            line-height: 30px