$darkgray: #323232
$outer-width: 460px
$inner-width: 380px
$primary: #11A9FF
$mobile-breakpoint: 460px

@mixin vertical-center($min-top-margin: 0px, $min-bot-margin: 0px, $min-width: 0px)
  @media (min-width: $min-width)
    margin-bottom: 0
    margin-top: 0
    display: -webkit-box
    display: -moz-box
    display: -ms-flexbox
    display: -webkit-flex
    display: flex
    -webkit-flex-direction: column
    flex-direction: column

    &:before
      min-height: $min-top-margin
      -webkit-box-flex: 1
      box-flex: 1
      -webkit-flex-grow: 1
      flex-grow: 1
      content: ''
      display: block
      height: 24px

    &:after
      -webkit-box-flex: 1
      box-flex: 1
      -webkit-flex-grow: 1
      flex-grow: 1
      content: ''
      display: block
      height: 22px
      min-height: $min-bot-margin

.welcome
  background-color: white

  .error-color
    color: #F5351B


  .mx-auto
    margin-left: auto !important
    margin-right: auto !important

  .vertical-center-container
    margin-bottom: 30px
    @media (min-width: $mobile-breakpoint)
      @include vertical-center(130px, 64px)
      min-height: 100vh
      position: relative

    .overlay-wrapper
      position: relative

    .welcome-body
      width: 90.4%
      @media (min-width: $mobile-breakpoint)
        width: $outer-width

      .welcome-title
        color: $darkgray
        font-weight: bold
        font-size: 24px
        text-align: center

      .google-button
        margin-left: auto !important
        margin-right: auto !important
        max-width: $inner-width
        margin-top: 30px

        .btn
          background-color: white
          border-radius: 4px
          border: 1px solid #DEDEDE
          width: 100%
          height: 50px
          font-weight: bold
          font-size: 16px
          color: #323232

          &:focus
            border-color: #1297E3

          &:hover
            background: darken(white, 7%)

          img
            margin-right: 12px

      .horizontal-line
        margin-left: auto !important
        margin-right: auto !important
        max-width: $inner-width
        text-align: center
        border-bottom: 1px solid #EAEAEA
        line-height: 0.1em
        margin: 38px 0 38px

        span
          background: #fff
          padding: 0 34px
          font-weight: bold
          font-size: 14px

          color: #6A6A6A

      form
        max-width: $inner-width
        margin-left: auto !important
        margin-right: auto !important

        .form-control
          background: rgb(246, 246, 246)
          color: $darkgray
          font-size: 15px
          font-weight: bold
          height: 50px
          border-radius: 4px

          &::placeholder
            color: #6A6A6A
            opacity: 1
            font-weight: normal
            font-size: 15px

        .email-disabled
          margin-top: 30px
          margin-bottom: 24px
          background: url($AWS_S3_URL + 'public/images/icons/Vector.svg') no-repeat scroll right 15px top 55%, rgba(246, 246, 246, 0.3)

        .demo-checkbox
          input
            margin-right: 10px

        .email-confirmation
          margin-top: 37px

        .error
          background: #FFECEB
          border: 1px solid #F5351B

          &::placeholder
            color: #F5351B


      .password-reset-link
        text-align: center
        color: #11A9FF
        font-size: 15px
        margin-top: 24px

      .btn-primary
        max-width: $inner-width
        margin-top: 24px
        background: $primary
        border-color: $primary
        border-radius: 4px
        height: 50px

        &:hover:enabled
          background: darken($primary, 7%)

        &:disabled
          background: #E7E7E7
          border-color: #E7E7E7

  .verify-email
    margin-top: 30px
    font-weight: normal
    font-size: 16px
    line-height: 24px
    text-align: center
    color: #323232

    div
      margin-bottom: 26px

    .verify-didnt-receive
      margin-top: 70px
      font-size: 14px

  .password-set
    .password-form
      margin-top: 40px

    .password-description
      text-align: left
      color: #9A9A9A
      font-size: 13px
      margin-bottom: 14px

    .description-error
      color: #F5351B
    .name-error
      color: #F5351B
      font-size: 13px
      text-align: left

  .form-inline
    @media (min-width: 768px)
      .form-group
        margin-left: 0
        margin-right: 0
        margin-bottom: 30px

        &:first-child
          margin-right: 20px

        input
          max-width: 180px

  .privacy-terms-checkbox
    margin-top: 25px !important

    input
      &:hover
        cursor: pointer

      &:checked ~ label:after
        display: block
        margin-top: 10px
        margin-left: -17px

    label
      padding-left: 12px
      padding-top: 3px
      text-align: left
      font-size: 13px
      line-height: 20px
      color: #6A6A6A

      &::before
        width: 20px
        height: 20px

      &::after
        content: " " !important
        display: none
        background-image: url($AWS_S3_URL + 'public/images/icons/Vector.svg') !important
        background-repeat: no-repeat

      span
        margin-left: 7px

      a
        color: #A8A8A8

  .business-description
    color: #6A6A6A
    font-size: 13px
    line-height: 21px
    margin-top: 50px

    a
      color: #A8A8A8

  .description-gray
    margin-top: 29px
    margin-bottom: 46px
    max-width: 340px
    font-size: 15px
    line-height: 21px
    color: #6A6A6A

    div
      margin-bottom: 20px

  .description-invite
    font-size: 15px
    line-height: 21px
    color: #6A6A6A
    max-width: 430px
    margin-top: 20px

  .invite-sent
    margin-top: 70px

  .invite-submit
    margin-top: 7px !important

  .invite-terms
    margin-top: 30px

    .submit-button
      margin-top: 30px !important

  .heelix-logo
    text-align: center
    padding-top: 50px
    margin-bottom: 50px
    @media (min-width: $mobile-breakpoint)
      padding-top: 0
      margin-bottom: 0
      position: absolute
      top: 46px
      left: 52px

  .loading-overlay
    z-index: 10000
    position: absolute
    top: 0
    width: 100%
    height: 70%
    background: white
    opacity: 0.9

    @include vertical-center()

    div
      display: inline-block

    @keyframes rotate
      from
        transform: rotate(360deg)
      to
        transform: rotate(0deg)

    .spinner
      animation: rotate 1s linear infinite
      background: url($AWS_S3_URL + 'public/images/circular-spinner.png') no-repeat
      background-size: cover
      height: 46px
      width: 46px

    .overlay-description
      font-size: 18px
      color: #6A6A6A
      position: relative
      bottom: 18px
      margin-left: 19px

