@import "../settings"

.jobvibe-future.grow
  &.users.show
    background-color: #F3F3F3
    color: #323232
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 20px
    .nav-page-title-xs
      h1
        display: none
    .page-wrapper
      padding: 15px
      margin: 0
      @media (min-width: 991px)
        padding: 40px
        padding-top: 15px
      #back-btn-wrapper
        a
          color: #6A6A6A
    #user-profile-wrap
      display: flex
      @media(max-width: $screen-sm-max)
        display: initial
    #user-header-wrapper
      float: none
      margin-right: 0
      @media (min-width: 992px)
        float: left
        margin-right: -2px
      @media (min-width: 1000px)
        margin-right: 1px
      @media (min-width: 1070px)
        margin-right: 15px
      @media (min-width: 1120px)
        margin-right: 20px
      #user-header
        #shoutout-wrapper
          padding-right: 0
          text-align: center
        #contact-wrapper
          padding-left: 0
          text-align: center
        .user-profile-btn
          background-color: transparent
          border: 1px solid #8BDBFF
          border-radius: 8px
          font-weight: normal
          font-size: 12px
          line-height: 14px
          text-align: center
          color: #fff
          padding: 6px 8px
          img
            display: block
            margin: auto
        #img-wrapper
          img
            width: 100px
            height: 100px
            display: block
            margin: 0 auto 18px
    #user-details-wrapper
      flex-grow: 1
      @media(min-width: $screen-md)
        float: left
        padding: 0 10px
    #name-role-wrapper
      margin-bottom: 26px
      position: relative
      @media (max-width: 991px)
        text-align: center
      .user-description-with-time-info
        margin-top: 8px
        font-size: 13px
        line-height: 18px
        color: #6A6A6A
        vertical-align: middle
        a
          color: #6A6A6A
          text-decoration: none
        i
          font-size: 17px
          color: #C4C4C4
          margin-left: 20px
          margin-right: 10px
      div#edit-profile-link
        display: none
        @media (min-width: 991px)
          display: block
          position: absolute
          top: 0
          right: 0
        @media (min-width: 1480px)
          right: -100px
        @media (min-width: 1680px)
          right: -200px
        @media (min-width: 1760px)
          right: -300px
        a
          align-items: center
          color: #11A9FF
          font-weight: normal
          display: inline-flex
          svg
            margin-right: 13px
      h2
        font-weight: bold
        font-size: 24px
        line-height: unset
        margin-bottom: 5px
      div
        color: #bdeaff
        a
          color: #323232
          text-decoration: none
    #team-time-wrapper
      color: #fff
      @media (max-width: 991px)
        text-align: center
      p
        color: #323232
        margin-bottom: 10px
        line-height: 20px
        font-size: 14px
        a
          color: #323232
          text-decoration: none
        img
          vertical-align: text-top
          margin-right: 10px
      #user-profile-timezone-wrapper
        color: #323232
        margin-top: 37px
        border-bottom: 1px solid #8BDBFF
        margin-bottom: 30px
        padding-bottom: 30px
        @media (min-width: 991px)
          border-bottom: none
          margin-bottom: 0
          padding-bottom: 0
        #user-profile-timezone
          margin-right: 10px
        #user-profile-local-time
          @media (min-width: 991px) and (max-width: 1180px)
            display: block
            margin-left: 22px

    #desc-tags-wrapper
      font-size: 14px
      line-height: 20px
      @media (max-width: 991px)
        text-align: center
      p
        line-height: 20px
        .user-profile-tag
          color: #11A9FF
          margin-right: 10px

    #action-buttons-wrapper
      margin-top: 0
      @media (min-width: 991px)
        margin-top: 86px
      #action-buttons
        border-top: none
        padding-top: 15px
        text-align: center
        @media (min-width: 991px)
          text-align: left
          padding-top: 35px
          border-top: 1px solid #E1E1E1
        a, button
          text-decoration: none
          color: #323232
          &:hover, &:active, &:focus
            border-color: #75C6EA
        .user-profile-btn
          margin-right: 30px
          text-align: center
          background-color: #11A9FF
          color: #FFFFFF
          border: 1px solid #11A9FF
          padding: 9px 17px 8px 17px
          border-radius: 3px
          font-size: 14px
          font-weight: bold
          line-height: 16px
          @media (min-width: 991px) and (max-width: 1180px)
            margin-right: 4px
            font-size: 14px
            width: 160px
          @media(max-width: $screen-sm-max)
            margin: 0
          img
            margin-right: 10px
        #give-shoutout-btn, #email-user-btn
          display: none
          @media (min-width: 991px)
            display: inline-block

    .shoutout-modal
      .modal-content
        .modal-inner-wrap
          select
            color: #323232
        .doughnut.chart p
          color: #464646

.jobvibe-future.users.tags
  #users-by-tags-container
    margin-top: 50px
    .users-tags-tile
      background: #FFFFFF
      padding: 25px
      margin-bottom: 20px
      border: 1px solid #E6E6E6
      cursor: pointer
      a
        text-decoration: none
        h4
          font-family: Roboto, sans-serif
          font-weight: bold
          font-size: 18px
          margin-bottom: 10px
          color: #323232
          text-decoration: none
        p
          line-height: 20px
          margin-bottom: 0
          color: #323232
        img
          width: 70px
          height: 70px
          border: 5px solid #fff
          display: block
          margin: 0 auto 18px
          border-radius: 50%
        .user-profile-tag
          margin-right: 7px
          &:first-of-type
            margin-left: 7px
    span#view-all-interests-btn
      color: #1297E3
      cursor: pointer
    #other-interests-wrapper
      .user-profile-tag
        display: inline-block
        margin-right: 10px
    .pagination
      > li
        display: inline-block
        margin-right: 6px
        &:first-child,&:last-child
          display: none
        &:nth-child(2), &:nth-last-child(2)
          a
            &:hover
              &:after
                background: #0c689c
              &:before
                border-color: #0c689c
            &:after
              content: ''
              width: 16px
              height: 2px
              background: #1297E3
              position: absolute
              top: 50%
              left: 50%
              transform: translate(-50%, -50%)
            &:before
              content: ''
              width: 10px
              height: 10px
              position: absolute
              transform: translate(0, -50%) rotate(-45deg)
              top: 50%
            span
              color: rgba(0,0,0,0)
        &:nth-last-child(2)
          a:before
            border-bottom: 2px solid #1297E3
            border-right: 2px solid #1297E3
            right: 8px
        &:nth-child(2)
          a:before
            border-top: 2px solid #1297E3
            border-left: 2px solid #1297E3
            left: 8px

      &.prev-hidden
        li:nth-child(2)
          display: none
      &.next-hidden
        li:nth-last-child(2)
          display: none

.jobvibe-future.users.edit
  .simple_form.edit_user
    color: #323232

    #edit-profile-header
      h1
        font-weight: 900
        font-size: 24px
        line-height: 28px
        margin-bottom: 30px
    #edit-form-wrapper
      #edit-form-left
        padding-left: 15px
        padding-right: 15px
        @media (min-width: 992px)
          padding-right: 20px
        @media (min-width: 1200px)
          padding-right: 40px

        #croppie-form
          margin-bottom: 35px

          .dropzone-wrap
            position: relative

            .dropzone-placeholder
              position: absolute
              top: 0
              left: 0
              right: 0
              bottom: 0
              display: flex
              flex-direction: row
              justify-content: space-around
              align-items: center
              height: 121px

              .dropzone-placeholder-info
                display: flex
                justify-content: space-between
                align-items: center
                flex-direction: row
                margin-right: 10px

                svg
                  margin-right: 17px
                h3
                  font-size: 14px
                  color: #11A9FF
                  line-height: 16px
                  margin: 0

                  span
                    font-weight: bold
                    text-decoration: underline

                    &.dropzone-placeholer-img-info
                      font-weight: normal
                      text-decoration: none
                      font-size: 12px
                      line-height: 14px
                      color: #C4C4C4
                      margin-top: 6px
                      display: block

            .square-box
              border: none
              padding: 0
              margin: 10px

          a#remove-image-btn
            font-size: 14px
            line-height: 21px
            color: #F5351B
            text-decoration: none
            font-weight: normal

      #edit-form-right
        padding-left: 15px
        padding-right: 15px
        @media (min-width: 992px)
          padding-left: 20px
        @media (min-width: 1200px)
          padding-left: 40px

        #password-change-switch
          display: flex
          font-size: 14px
          line-height: 21px

        .select-component
          display: block

        .user_lead_teams
          .Select-placeholder
            padding-top: 2px

          .Select-input
            margin-top: -1px
            height: 38px

    .account-deletion
      display: flex

      button
        border-radius: 4px
        padding: 10px 60px
        font-size: 15px
        line-height: 18px
      .info-icon
        margin: auto 10px auto 10px

      .info-tooltip
        text-align: center
        line-height: 18px
        margin: 0

    .form-footer
      margin-top: 25px
      padding-top: 50px
      border-top: 1px solid #E1E1E1

      button
        background-color: #11A9FF
        border-radius: 4px
        padding: 10px 60px
        font-size: 15px
        line-height: 18px

    .form-group
      margin-bottom: 25px

      .control-label
        font-size: 15px
        line-height: 18px
        margin-bottom: 13px
        font-weight: 500

        .required-icon
          color: #F5351B
          vertical-align: top
          font-size: 7px
          margin-left: 5px

        span
          font-weight: normal

      .form-control
        border-radius: 5px
        border: 1px solid #E7E7E7

        &.select
          height: 42px

      &.user_description
        p
          margin-top: 12px
          text-align: right
          font-size: 13px
          line-height: 15px
          color: #979797

      &.password-section
        .password-info
          font-size: 14px
          line-height: 21px
          margin-bottom: 20px

      &.checkbox-form-group
        margin-bottom: 19px

      &.notification-section
        margin-bottom: 46px

        p
          font-size: 14px
          line-height: 21px
    .form-group.user_email
      &.has-warning
        label
          color: #FE873C
        .form-control
          border-color: #FE873C
        span.text-warning
          color: #FE873C
