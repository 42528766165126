body
  margin-top: $header-height
  @media (max-width: $screen-phone)
    margin-top: $header-height - 14

#navbar-mobile-overlay
  display: inline
  background: rgba(32,32,32,0)
  transition: background .2s .2s, height 0s .4s, width 0s .4s
  position: fixed
  z-index: 1
  top: 0
  left: 0
  @media(max-width: $screen-xs-max)
    z-index: 1031
    width: 100vw
    height: 0
    #side-navbar
      transform: translateX(-100%)
    &.open
      height: 100vh
      transition: background .2s
      background: rgba(32,32,32,0.7)
      #side-navbar
        transform: translateX(0)
        transition: transform .2s .2s
  #side-navbar
    background: #575563
    width: $sidebar-width
    overflow: hidden
    transition: transform .2s
    position: relative
    .custom-scrollbar
      opacity: 0
      transition: .3s opacity
    &:hover .custom-scrollbar
      opacity: 0.7
    #sidebar-outer-wrap
      height: 100vh
      width: 240px
      overflow-y: auto
      #navigation-static-elements-wrap
        li
          display: flex
          align-items: center
          width: 100%
          padding: 12px 18px
          float: none
          max-width: $sidebar-width
          text-align: left
          color: white
          font-weight: normal
          font-size: 15px
          cursor: pointer
          transition: background .3s, transform .3s
          &[custom-checked='true']
            background: #84838B
            text-shadow: white 1px 0
          .circle-image
            margin: 0
            .space-img
              display: inline-block
              font-weight: 900
              width: 30px
              height: 30px
              border: 1px solid white
              border-radius: 100%
              margin: 0
          span
            font-size: 16px
            margin-left: 12px
            letter-spacing: 1px
            &.team-name
              font-size: 13px
              transition: text-shadow .3s
            &.space-logo
              display: inline-block
              font-weight: 900
              width: 30px
              height: 30px
              border: 1px solid white
              border-radius: 100%
              margin: 0
              line-height: 30px
              font-size: 15px
              text-align: center
              text-shadow: none
            &.notification-count
              font-size: 11px
              line-height: 16px
              background-color: #E43F3F
              width: auto
              padding: 0 4px
              top: 0
              right: 0
              position: relative
              text-shadow: none

          .grow-icon
            display: inline-block
            font-weight: 900
            line-height: 30px
            font-size: 15px
            text-align: center
            width: 30px
            height: 30px
            border: 2px solid white
            border-radius: 100%
          &#users-tab i
            width: 42px
            padding-left: 9px

          &#teams-tab i
            width: 42px
            padding-left: 6px
          a
            margin: 0 auto
            line-height: 20px
            color: $white
            font-size: 17px
            &:hover
              background-color: $mid-purple !important
              color: $white !important
          &:not(:first-of-type)
            border-bottom: 1px solid #65646B
          &.mini-logo
            padding: 36px 25px
            height: auto
            max-width: $sidebar-width
            cursor: default !important
            p
              margin-bottom: 0
            img
              min-height: 33px
              max-height: 46px
            a:hover
              background-color: $dark-purple !important
        .navbar-round-button
          cursor: pointer
          margin-top: 24px
          position: relative
          margin-left: 20px
          background: #65646B
          border-radius: 22px
          font-size: 12px
          color: #D5D5D5
          width: 86px
          height: 26px
          line-height: 26px
          padding-left: 29px
          padding-right: 11px
          &:before, &:after
            width: 10px
            height: 2px
            background: #D5D5D5
            border-radius: 1px
            position: absolute
            left: 10px
            top: 50%
            content: ''
            transition: transform .3s
          &:before
            transform: translate(0, -50%)
          &:after
            transform: translate(0, -50%) rotateZ(90deg)
        .informal-spaces-wrap
          margin-top: 36px
          h1
            padding-left: 18px
            color: white
            font-size: 15px
            font-weight: bold
            margin-bottom: 7px
            display: flex
            align-items: center
            span
              display: inline-block
              background: #65646B
              border-radius: 14px
              font-size: 11px
              height: 17px
              line-height: 17px
              margin-left: 10px
              padding: 0 9px
              color: #BEBEBE
          li
            padding: 7px 0 7px 18px
            color: white
            letter-spacing: .6px
            font-size: 14px
            display: flex
            align-items: center
            transition: text-shadow .2s
            cursor: pointer
            position: relative
            border-bottom: none
            &[custom-checked='true']
              text-shadow: 1px 0 0 white
            .notification-count
              position: relative
              top: 0
              left: 10px
              padding: 0 5px
              font-size: 11px
              line-height: 16px
              background-color: #E43F3F
              width: auto
              text-shadow: none
      #navigation-dynamic-elements-wrap
        bottom: 0
        padding-top: 20px
        min-height: 300px
        .button-wrap
          display: block
          padding: 0 0 19px 17px
          position: relative
          font-size: 14px
          color: #AFAFAF
          cursor: pointer
          line-height: 16px
          text-decoration: none
          span
            position: relative
            top: 0
            left: 9px
            font-size: 14px
          &.vibe-now-btn
            color: #11A9FF
            span
              top: 2px
              left: 6px
          span.notification-count
            right: unset
            left: 40px
            top: -5px
            font-size: 11px
            position: absolute