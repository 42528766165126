.horizontal-list
  padding: 0
  margin: 0
  list-style: none
  li
    display: inline-block
    margin-right: 3px

.plain-list
  padding: 0
  margin: 0
  list-style: none
  li
    text-overflow: ellipsis
    overflow: hidden

.list-no-bullet
  list-style: none

.list-spaced-sm
  li
    margin-bottom: rhythm(0.5)


.list-group
  // No need to set list-style: none; since .list-group-item is block level
  padding-left: 0 // reset padding because ul and ol
  margin-bottom: 0

.list-group-item
  padding: $card-spacer-x $card-spacer-x

.list-group-flush
  .list-group-item
    border-right: 0
    border-left: 0
    border-radius: 0
    &:first-child
      border-top: 0
    &:last-child
      border-bottom: 0

.list-group-item.no-border
  border: 0
