// datepicker.js overrides

.datepicker-dropdown
  margin-top: 70px !important

.datepicker-dropdown .table-condensed
  width: 100% !important

.form-control.datepicker
  max-width: 100% !important
