.create-vibe-form
  .fullscreen-close
    display: none !important
  .vibe-user-avatar
    display: none !important
  .vibe_new
    .vibe-step
      .panel
        box-shadow: none
        margin-bottom: 40px
        .panel-body
          padding: 15px 30px 40px 30px
          .vibe-options-wrap
            .vibe-question
              font-size: 20px
              line-height: 23px
              margin-bottom: 44px

            .vibe-sentiments-list
              max-width: 380px
              margin: auto

              .vibe-sentiment
                padding-bottom: 53px

                .vibe-sentiment-images
                  height: 42px
                  width: 42px

          .default-factors-screen, .why-factors-screen
            padding: 0 30px 30px 30px

            .vibe-factors
              padding: 0

          .final-screen-logo
            padding-bottom: 30px

          &.message-body-wrap
            margin: 6px 0 30px
  .vibe-anon
    margin-bottom: 0 !important
    font-style: normal
    font-weight: normal
    cursor: default !important
    max-width: 420px
    margin: 0 auto
    span.seen-by
      font-size: 12px
      line-height: 14px
      color: #ADADAD
    span.anonymous-vibe
      font-size: 13px
      line-height: 13px
      color: #6A6A6A
      cursor: pointer
  .default-factors-screen, .why-factors-screen
    span.anonymous-vibe
      text-align: right
      min-width: 60%