ul.textcomplete-dropdown
  li
    font-size: 14px
    a
      padding: 10px 5px
      span.slug
        font-size: 13px
        font-style: italic
        color: $mid-grey

    &.active a span.slug
      color: $white
