// these rules override rules in the sass of select2's included vendor styles. That's why they're so hideous.

// text-field border color, focused
.select2-container--default.select2-container--open .select2-selection--single,
.select2-container--default.select2-container--open .select2-selection--multiple
  border-color: $deep-blue

.select2-container--focus,
.select2-container--focus .select2-selection
  outline: 0 !important

.select2-container--default input:focus
  border-color: $light-grey !important
  outline: 0

// as above, on button-down
.select2-container--default.select2-container--focus .select2-selection
  border-color: $deep-blue

// text-field border color, unfocused
.select2-container--default .select2-selection
  border-color: #ecf1f4

// input field background color
.select2-container--default .select2-selection
  background-color: $super-light-grey
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important

.select2-container--default .select2-results__option[aria-selected=true]
  background-color: lighten($deep-blue, 50)

.select2-container--default .select2-results__option--highlighted[aria-selected]
  background-color: $deep-blue

.select2-selection--single
  height: 45px !important

.select2-selection--multiple
  padding: 10px 5px 0

.select2-selection__choice
  line-height: 30px
  padding: 0 10px !important
  background-color: white !important
  border-color: $light-grey !important

.select2-dropdown
  border-color: $deep-blue

.select2-selection__choice__remove
  padding-right: 2px

.select2-search input
  height: 45px !important
  margin-top: 0 !important

.select2-selection__rendered
  height: inherit !important
  line-height: 43px !important

.select2-search
  background: none !important
  border: 0 !important
  line-height: 0 !important

.select2-selection__arrow
  height: 45px !important
  margin-right: 10px

// fix for bootstrap and select2 collision
.form-control.select2-hidden-accessible
  width: auto !important
