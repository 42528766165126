@import "../pages/welcome"

.fade.modal-backdrop.show
  opacity: .5

.modal.fade.take-action-modal
  .modal-dialog
    min-height: 100vh
    // transform: translate(0) !important

    .css-76jqe6-Button
      margin: auto 22px auto auto !important
      line-height: 34px !important
      font-size: 14px

.fade.modal-backdrop.show
  opacity: .5

.modal-dialog
  // &::before
  //   height: 100px !important
.take-action-modal
  &::after
    opacity: .5
  &.fade
    opacity: 1
    &.meeting
      .modal-dialog
        max-width: 980px !important

        .modal-content
          @media(max-width: 500px)
            max-width: 100% !important
            overflow-x: auto !important

          .create-mobile-view-wrapper
            @media(max-width: 500px)
              width: 143%

    &.quick-meeting
      .modal-dialog
        max-width: 600px !important

  .modal-dialog
    width: auto
    // padding-top: 300px
    max-width: 680px
    // min-height: 100vh

    @include vertical-center()
    pointer-events: none

    &:before, &:after
      pointer-events: none

  .modal-content
    border-radius: 8px
    pointer-events: all
    overflow-y: visible !important
    height: auto !important
    background: #FFF9E0 !important

    .create-posts-feed
      padding: 0

      .info-panel
        margin-top: 29px
        margin-left: auto
        margin-right: 24px

        .team-dropdown-toggle
          order: 1

        input[type='button']
          order: 2

      .create-post-tab
        padding: 24px 24px 0px 24px
        background: #F3F3F3
        border-radius: 0 0 8px 8px

  &.with-right-panel
    .modal-dialog
      max-width: 980px

      .modal-content
        display: flex
        flex-direction: row
        background: white !important

        .create-mobile-view-wrapper
          position: relative
          width: 100%

          .create-forms-wrap
            border: none

.share-menu
  .team-logo
    width: 42px
    height: 42px
    border-radius: 100%
    font-weight: bold
    display: inline-flex
    justify-content: center
    align-items: center
    font-size: 21px

  .select-component
    display: block
    width: 100%
    @media(min-width: 600px)
      width: 50%

    .Select-control
      height: 42px
      background-color: #ffffff
      border-radius: 5px
      border: 1px solid #EDEDED
      box-sizing: border-box
      font-size: 14px
      line-height: 16px
      table-layout: fixed

      .Select-multi-value-wrapper
        height: 42px

      .Select-placeholder
        color: #C4C4C4
        padding: 4px 16px

      .Select-value
        color: #979797
        padding: 4px 16px

      .Select-input
        color: #979797
        height: 100%
        padding: 0 16px
        overflow: hidden
        max-width: calc(100% - 20px)

        #type-input
          height: 100%
          padding: 0

          &:focus
            outline: none

    &.assign
      .Select-placeholder
        color: #323232

.select-component
  .css-13cymwt-control
    border: 1px solid #ededed
    &:hover
      box-shadow: 0 1px 0 rgba(0,0,0,.06)

    .css-1fdsijx-ValueContainer
      padding: 0
      margin: 0

    .css-qbdosj-Input
      padding: 0
      margin: 0

    .css-1u9des2-indicatorSeparator
      display: none
