@import "../settings"
.team-vibe-dashboard
  position: relative

  .top-controls-wrap
    display: flex
    align-items: center
    .date-range
      margin-right: 36px
    @media(max-width: $screen-sm-max)
      flex-direction: column
      .date-range-wrap
        margin-left: 0
        margin-top: 31px
        width: 100%
        .date-range
          margin-right: 0
          flex-grow: 1
          &:after
            right: 12px
    .period-switch-wrap
      display: flex
      flex-shrink: 0
      span
        font-size: 14px
      @media(max-width: $screen-sm-max)
        justify-content: space-between
        width: 100%
        margin-top: 36px
        font-weight: bold
        span
          order: 1
        .switch
          order: 2
          margin-right: 0

  .speedometers-section
    padding: 72px 22px 0
    z-index: 1
    position: relative
    overflow: hidden
    display: flex
    justify-content: space-around
    > div
      position: relative
    @media(max-width: 1080px)
      flex-wrap: wrap
      padding: 28px 8px
      .vibe-score-wrap
        width: 100%
        order: 1
      .engagement-wrap
        width: 50%
        order: 2
        margin-top: -64px
        z-index: 2
      .active-users-wrap
        width: 50%
        order: 3
        margin-top: -64px
        z-index: 2
  .info-section
    border-top: 1px solid #F3F3F3
    margin-top: -41px
    display: flex
    align-items: center
    justify-content: space-between
    padding: 24px 1px 0
    position: relative
    z-index: 2
    flex-wrap: wrap
    @media(max-width: 1080px)
      margin-top: -24px
      padding: 23px 18px 17px
    & > div
      display: flex
      align-items: center
      @media(max-width: 1080px)
        margin: 12px 0
      &.vibe-info-wrap
        margin-right: 40px
        @media(max-width: 500px)
          margin-right: 0
      &.recommendations-info-wrap
        margin-right: 40px
        @media(max-width: 1080px)
          margin-right: 0
        .scroll-to-link
          cursor: pointer
      &.start-discussion-button
        background: #11A9FF
        border-radius: 4px
        font-weight: bold
        font-size: 15px
        color: #FFFFFF
        padding: 11px 26px
        cursor: pointer
        @media(max-width: 1180px)
          margin: 18px auto 12px
      &.download-reports-dropdown
        #download-report-btn
          background: #FFFFFF
          border: 1px solid #E6E6E6
          border-radius: 4px
          font-family: Roboto, sans-serif
          font-style: normal
          font-weight: normal
          font-size: 14px
          line-height: 16px
          color: #323232
          padding: 12px 26px
          i
            margin-left: 20px
      img, svg
        width: 29px
        margin-right: 13px
      span
        position: relative
        top: 1px
  .factors-section-vertical
    padding-top: 10px
    @media(max-width: 1080px)
      border-top: 1px solid #F3F3F3
    h2
      font-size: 18px
    .factors-chart
      height: 200px
      display: flex
      flex-direction: row
      align-items: flex-end
      position: relative
      margin-top: 21px
      margin-right: 34px
      &:after
        content: 'Number of people'
        position: absolute
        top: 50%
        transform: translateY(-50%) rotateZ(-90deg)
        color: #C4C4C4
        font-size: 12px
        right: -71px
      .chart-step
        background: #E5E5E5
        height: 1px
        position: absolute
        width: 100%
        &.start
          background: #BEBEBE
        .y-axis-legend
          position: absolute
          left: calc(100% + 19px)
          top: 50%
          transform: translateY(-50%)
          color: #706E84
          font-size: 12px
      .chart-bar-group
        position: relative
        flex: 1 1 auto
        margin: 0 18px
        display: flex
        align-items: flex-end
        .bar
          position: relative
          flex: 1 1 auto
          text-decoration: none
          .subbar
            transition: 1.5s height
            &.awesome-subbar
              background: #52BA05
            &.good-subbar
              background: #8ED04C
            &.okay-subbar
              background: #ECD69F
            &.bad-subbar
              background: #FF7F63
            &.terrible-subbar
              background: #FE483C
          &.previous
            margin-right: 2px
            &:before
              position: absolute
              content: ''
              background: rgba(255, 255, 255, .7)
              width: 100%
              height: 100%
              top: 0
              left: 0
        .factors-tooltip
          position: absolute
          top: -42px
          background: #FFFFFF
          border: 1px solid #F3F3F3
          box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15)
          border-radius: 3px
          left: 50%
          transform: translateX(-50%)
          font-weight: bold
          font-size: 16px
          padding: 0 5px
          white-space: nowrap
          opacity: 0
          transition: opacity .3s
          z-index: 21
          &.mounted
            opacity: 1
          &:after
            position: absolute
            bottom: 0
            left: 50%
            transform: translate(-50%, 50%) rotateZ(45deg)
            content: ''
            background: white
            border-bottom: 1px solid #E7E7E7
            border-right: 1px solid #E7E7E7
            width: 14px
            height: 14px
          span
            margin: 5px
            display: inline-block
            text-align: center
            &.terrible
              color: #FE483C
            &.bad
              color: #FF7F63
            &.okay
              color: #ECD69F
            &.good
              color: #8ED04C
            &.awesome
              color: #52BA05
    &.dashboard
      .factors-legend-wrap
        margin-right: 0
        margin-top: 45px
        div
          transform: rotate(-90deg)
          text-align: right
          font-size: 10px
          font-weight: normal
          line-height: 140.62%
          padding: 5px 0
          margin: auto
      .factors-chart
        margin-right: 0
        .chart-step
          background: #F3F3F3
        .chart-bar-group
          margin: 0 5px
        &:after
          content: ''
    .factors-legend-wrap
      display: flex
      margin-right: 34px
      margin-top: 32px
      > div
        flex: 1 1 0
        padding: 0 2px
        text-align: center
        font-size: 12px
        line-height: 16px
        text-transform: uppercase
        font-weight: bold
        position: relative
        transition: color .3s
        cursor: pointer
        min-width: 0
        &:after
          width: 0
          height: 0
          transition: width .3s, height .3s
          position: absolute
          bottom: -33px
          left: 50%
          transform: translate(-50%, 50%) rotateZ(45deg)
          content: ''
          background: white
          border-top: 1px solid #cdeaf7
          border-left: 1px solid #cdeaf7
        &.active
          color: #11A9FF
          &:after
            width: 16px
            height: 16px
    .detailed-feedback-wrap
      margin: 33px 3px 0
      border: 1px solid #cdeaf7
      border-radius: 4px
      padding: 29px 28px
      h2
        font-weight: bold
        font-size: 16px
        text-transform: uppercase
        margin-bottom: 11px
        span
          text-transform: none
          display: inline-block
          font-weight: normal
          color: #979797
          font-size: 14px
          margin-left: 4px
          margin-right: 20px
        span:nth-child(2)
          color: #11A9FF
      .factors-columns-wrap
        display: flex
        .factors-column
          flex: 1 1 0
          .single-factor
            font-size: 13px
            line-height: 23px
      .view-more
        border: 1px solid #CDEAF7
        border-radius: 4px
        font-weight: bold
        font-size: 14px
        line-height: normal
        color: #11A9FF
        padding: 12px 35px
        display: inline-block
        margin-top: 22px
        text-decoration: none
  .factors-section-horizontal
    border-top: 1px solid #F3F3F3
    padding: 32px 28px 32px 16px
    h2
      font-size: 18px
      margin-bottom: 10px
    .horizontal-wrap-helper
      display: flex
      .factors-legend-wrap
        display: flex
        flex: 0 1 0
        flex-direction: column
        padding: 4px 21px 4px 0
        > div
          flex: 1 1 0
          text-align: left
          font-size: 11px
          line-height: 16px
          text-transform: uppercase
          font-weight: bold
          position: relative
          transition: color .3s
          cursor: pointer
          display: flex
          align-items: center
          &.active
            color: #11A9FF
      .factors-chart
        height: 630px
        display: flex
        flex-direction: column
        position: relative
        flex: 1 0 0
        padding: 4px 0
        &:after
          content: 'Number of people'
          position: absolute
          color: #C4C4C4
          font-size: 12px
          top: -30px
          left: 50%
          transform: translateX(-50%)
        .chart-step
          background: #E5E5E5
          width: 1px
          position: absolute
          height: 100%
          margin: -4px 0
          &.start
            background: #BEBEBE
          .y-axis-legend
            position: absolute
            left: 0
            top: -30px
            transform: translateX(-50%)
            color: #706E84
            font-size: 12px
        .chart-bar-group
          position: relative
          padding: 6px 0
          display: flex
          flex-direction: column
          flex: 1 0 auto
          &.with-previous .bar
            height: calc(50% - 1px)
          .bar
            position: relative
            display: inline-flex
            flex-grow: 1
            text-decoration: none
            .subbar
              transition: 1.5s width
              &.awesome-subbar
                background: #52BA05
              &.good-subbar
                background: #8ED04C
              &.okay-subbar
                background: #ECD69F
              &.bad-subbar
                background: #FF7F63
              &.terrible-subbar
                background: #FE483C
            &.previous
              margin-bottom: 2px
              &:before
                position: absolute
                content: ''
                background: rgba(255, 255, 255, .7)
                height: 100%
                width: 100%
                top: 0
                left: 0
          .factors-tooltip
            position: absolute
            left: calc(100% + 5px)
            top: 50%
            background: #FFFFFF
            border: 1px solid #F3F3F3
            box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15)
            border-radius: 3px
            transform: translateY(-50%)
            font-weight: bold
            font-size: 16px
            padding: 0 5px
            white-space: nowrap
            opacity: 0
            transition: opacity .3s
            z-index: 2
            &.mounted
              opacity: 1
            &:after
              position: absolute
              bottom: 0
              top: 50%
              left: 0
              transform: translate(-50%, -50%) rotateZ(135deg)
              content: ''
              background: white
              border-bottom: 1px solid #E7E7E7
              border-right: 1px solid #E7E7E7
              width: 14px
              height: 14px
            span
              margin: 5px
              display: inline-block
              text-align: center
              &.terrible
                color: #FE483C
              &.bad
                color: #FF7F63
              &.okay
                color: #ECD69F
              &.good
                color: #8ED04C
              &.awesome
                color: #52BA05
    .detailed-feedback-wrap
      margin-top: 40px
      h3
        color: #11A9FF
        font-size: 11px
        text-transform: uppercase
        font-weight: bold
        margin-bottom: 3px
        line-height: normal
      h2
        font-weight: bold
        font-size: 16px
        text-transform: uppercase
        margin-bottom: 11px
      .view-more
        border: 1px solid #CDEAF7
        border-radius: 4px
        font-weight: bold
        font-size: 14px
        line-height: normal
        color: #11A9FF
        padding: 12px 35px
        display: inline-block
        margin-top: 22px
        text-decoration: none
      .single-factor
        font-size: 13px
        line-height: 23px
  .anonymous-feedback-section
    margin: 18px 3px 0
    border: 1px solid #cdeaf7
    border-radius: 4px
    padding: 29px 28px
    @media(max-width: $screen-sm-max)
      margin: 0
      margin-top: 2px
      border: none
      border-top: 1px solid #cdeaf7
      padding: 28px 17px
    h2
      font-weight: bold
      font-size: 16px
      text-transform: uppercase
      margin-bottom: 24px
      svg
        position: relative
        top: -2px
        left: 13px
    .single-anonymous-comment
      margin-bottom: 10px
      @media(max-width: $screen-sm-max)
        display: flex
        flex-wrap: wrap
        margin-bottom: 19px
      img
        width: 18px
        height: 18px
        margin-right: 13px
      span
        position: relative
        display: inline-block
        top: 1px
        &.anonymous-comment-content
          font-size: 14px
          margin-right: 13px
          @media(max-width: $screen-sm-max)
            width: calc(100% - 31px)
            margin-right: 0
        &.anonymous-comment-date
          font-size: 13px
          line-height: 18px
          color: #979797
          @media(max-width: $screen-sm-max)
            margin-left: 31px
            margin-top: 10px
    .view-more
      border: 1px solid #CDEAF7
      border-radius: 4px
      font-weight: bold
      font-size: 14px
      line-height: normal
      color: #11A9FF
      padding: 12px 35px
      display: inline-block
      margin-top: 16px
      text-decoration: none
.recommendations-section
  margin: 18px 3px 26px
  border: 1px solid #cdeaf7
  border-radius: 4px
  padding: 29px 28px
  h2
    font-weight: bold
    font-size: 16px
    text-transform: uppercase
    margin-bottom: 22px
    height: 28px
    span.request_disabled_section
      img
        margin-top: -2px
        margin-left: 35px
        cursor: not-allowed
        &.active
          cursor: pointer
        @media(max-width: 888px)
          margin-left: 0
      span.request-string
        padding-left: 12px
        text-transform: none
        color: #C4C4C4
        font-size: 14px
        line-height: 16px
        font-weight: normal
        cursor: not-allowed
        &.active
          color: #11A9FF
          cursor: pointer
      @media(max-width: 888px)
        display: block
        margin-top: 5px
    span.request-categories
      text-transform: none
      margin-left: 35px
      display: inline-flex
      @media(max-width: 888px)
        margin-left: 0
        margin-top: 5px
        display: block
      .Select
        width: 210px
        margin-left: 18px
        margin-top: -6px
        .Select-control
          height: 40px
          border-color: #E6E6E6
          .Select-arrow
            margin-right: 10px
            border-color: #6A6A6A transparent transparent
        .Select-menu
          overflow-y: hidden
          max-height: 400px
        .Select-menu-outer
          border-color: #E6E6E6
          font-weight: normal
          font-size: 13px
          line-height: 18px
          max-height: 140px
          overflow-y: scroll
        .Select-placeholder
          padding: 2px 0 0 16px
          font-weight: normal
          font-size: 13px
        .Select-clear-zone
          display: none
        .Select-value
          padding: 2px 0 0 16px
        .Select-value-label
          font-weight: normal
          font-size: 13px
          line-height: 18px
        @media(max-width: 888px)
          display: inline-flex
        @media(max-width: 452px)
          display: inline-block
          margin-left: 0
          margin-top: 5px
          width: 90%
      .Select.is-open
        .Select-arrow
          margin-right: 10px
          border-color: transparent transparent #6A6A6A
      .Select.is-focused
        .Select-control
          box-shadow:  none
      .close-outer
        position: relative
        cursor: pointer
        .close-btn
          position: absolute
          left: 18px
          top: 8px
          width: 14px
          height: 14px
          opacity: 0.5
          @media(max-width: 452px)
            display: inline-block
            left: 13px
            top: -11px
        .close-btn:hover
          opacity: 1
        .close-btn:before, .close-btn:after
          position: absolute
          left: 7px
          content: ''
          height: 14px
          width: 2px
          background-color: #C4C4C4
        .close-btn:before
          transform: rotate(45deg)
        .close-btn:after
          transform: rotate(-45deg)
    .react-tooltip.request-tooltip
      text-align: center
      text-transform: none
      font-weight: normal
      pointer-events: auto !important
      background-color: #8B8A96
      margin-top: -14px !important
      margin-left: 20px !important
      box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
      z-index: 10
      &::after
        border-top: 15px solid #8B8A96 !important
        border-left: 13px solid transparent !important
        border-right: 13px solid transparent !important
        bottom: -10px
        width: 22px
    &.can_request
      @media(max-width: 888px)
        height: 60px
      @media(max-width: 452px)
        height: 90px
  .recommendations-info
    text-transform: uppercase
    color: #706E84
    font-size: 12px
  table.recommendations-table
    width: 100%
    td.recommendation-label
      &:not(:first-of-type)
        padding-left: 5px
      text-transform: uppercase
      font-size: 12px
      line-height: 17px
      text-align: left
    tr.single-recommendation
      font-size: 14px
      line-height: normal
      &:not(:last-of-type)
        border-bottom: 1px solid #F1F1F1
      td:not(.recommendation-label)
        padding-top: 22px
        padding-bottom: 22px
      td
        width: 3%
        div.round
          float: left
          position: relative
          padding: 0 20px 0 0
          label
            border: 2px solid $new-blue
            border-radius: 50%
            cursor: pointer
            height: 22px
            left: 0
            position: absolute
            top: -2px
            width: 22px
          label:after
            border: 2px solid #fff
            border-top: none
            border-right: none
            content: ""
            height: 6px
            left: 3px
            opacity: 0
            position: absolute
            top: 4px
            transform: rotate(-45deg)
            width: 12px
          input[type="checkbox"]
            visibility: hidden
          input[type="checkbox"]:checked + label
            background-color: #52BA05
            border-color: #52BA05
          input[type="checkbox"]:checked + label:after
            opacity: 1
      td.recommendation-content
        width: 69%
        line-height: 20px
        padding-right: 5px
        &.completed
          color: #52BA05
        &.mobile
          padding: 0
        .mobile-category
          color: #6A6A6A
          font-size: 14px
          line-height: 16px
          padding-top: 12px
        .mobile-actions
          float: right
          font-size: 12px
          line-height: 17px
          color: #575563
          padding-top: 16px
          span
            padding-right: 21px
          img:first-of-type
            padding-right: 16px
      td.recommendation-category
        padding-left: 5px
        vertical-align: text-top
        width: 20%
      td.recommendation-actions
        padding-left: 5px
        vertical-align: text-top
        width: 8%
        img:first-of-type
          padding-right: 16px
          @media (max-width: 1258px)
            padding-right: 2px
          @media (max-width: 1079px)
            padding-bottom: 4px
        img
          cursor: pointer
@import "timeline_vibe_show"
@import "mood_over_time_vibe_show"
@import "mood_vs_factor_vibe_show"
@import "performance_over_time"