#performance-over-time-tab
  .top-controls-wrap
    display: flex
    align-items: center
    @media (min-width: 991px)
      padding: 0 0 35px
    .date-range-wrap
      .date-range
        margin-right: 0
    @media(max-width: $screen-sm-max)
      flex-direction: column
      align-items: center
      .date-range-wrap
        margin-left: 0
        margin-top: 31px
        width: 100%
        .date-range
          margin-right: 0
          flex-grow: 1
          &:after
            right: 12px
  .mobile-chart-categories
    padding-top: 35px
    .category-name
      display: inline-flex
      font-size: 15px
      color: #323232
      &.active
        font-weight: bold
    .switch-wrap
      float: right
    .category-wrap
      padding: 12px 0
      &#mobile-category-1
        .turned-on
          background: #ADA7EE
        .active
          color: #ADA7EE
      &#mobile-category-2
        .turned-on
          background: #86C854
        .active
          color: #86C854
      &#mobile-category-3
        .turned-on
          background: #F58A3C
        .active
          color: #F58A3C
      &#mobile-category-4
        .turned-on
          background: #3c76f5
        .active
          color: #3c76f5
      &#mobile-category-5
        .turned-on
          background: #e399f5
        .active
          color: #e399f5
      &#mobile-category-6
        .turned-on
          background: #F5CF3A
        .active
          color: #F5CF3A
      &#mobile-category-7
        .turned-on
          background: #f56f52
        .active
          color: #f57d63
      &#mobile-category-8
        .turned-on
          background: #32d6aa
        .active
          color: #32d6aa
      &#mobile-category-9
        .turned-on
          background: #11A9FF
        .active
          color: #11A9FF
      &#mobile-category-10
        .turned-on
          background: #9bac61
        .active
          color: #9bac61
  #chart-categories
    @media (max-width: 1005px)
      width: 100% !important
    #categories-btns
      width: 100%
      display: flex
      flex-direction: row
      flex-wrap: wrap
      justify-content: space-around
      padding: 0
      .category-btn
        display: flex
        justify-content: center
        flex-direction: column
        text-align: center
        font-family: Roboto, sans-serif
        font-style: normal
        font-weight: normal
        line-height: 15px
        font-size: 10px
        text-transform: uppercase
        color: #323232
        background: #FFFFFF
        border: 1px solid #E6E6E6
        border-radius: 4px
        margin-bottom: 12px
        padding: 5px 6px
        vertical-align: middle
        cursor: pointer
        &:not(:first-of-type)
          margin-left: 8px
        &.active, &:active, &:focus, &:hover
          opacity: 1
          color: #ffffff
          font-weight: bold
          &#category-1
            background-color: #ADA7EE
            border-color: #ADA7EE
          &#category-2
            background-color: #86C854
            border-color: #86C854
          &#category-3
            background-color: #F58A3C
            border-color: #F58A3C
          &#category-4
            background-color: #3c76f5
            border-color: #3c76f5
          &#category-5
            background-color: #e399f5
            border-color: #e399f5
          &#category-6
            background-color: #F5CF3A
            border-color: #F5CF3A
          &#category-7
            background-color: #f56f52
            border-color: #f57d63
          &#category-8
            background-color: #32d6aa
            border-color: #32d6aa
          &#category-9
            background-color: #11A9FF
            border-color: #11A9FF
          &#category-10
            background-color: #9bac61
            border-color: #9bac61
        &:hover
          opacity: 0.7
        @media (min-width: 1200px)
          padding: 11px 11px
          font-size: 11px
        @media (min-width: 1350px)
          margin-bottom: 0
          margin-left: 0
          padding: 11px 16px
  #stats-wrapper
    position: relative
    height: 367px
    @media (max-width: 768px)
      width: 100%
      overflow-x: scroll
      position: relative
  #timeline-chart
    margin-top: 28px
    height: 100%
    @media (max-width: 480px)
      width: 300%
    @media (max-width: 768px)
      width: 200%
      overflow-x: scroll
      white-space: nowrap
    canvas
      margin-top: -20px
  .legend-wrap
    margin-top: 34px
    #legend-text
      font-size: 14px
      position: relative
  .custom-date
    font-size: 14px
    line-height: 16px
    color: #575563
    text-align: right
    div.datepicker-wrapper
      display: inline-block
    input
      width: 90px
      height: 36px
      border-radius: 4px
      border: 1px solid #E6E6E6
      padding-left: 5px
      font-size: 14px
    #start_date
      margin-left: 15px
    .date-picker
      position: absolute
      right: 50px
      margin-top: -310px
      border: 1px solid #E6E6E6
    .react-datepicker
      font-family: Roboto, sans-serif
      .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected
        background-color: #575563
      .react-datepicker__day--in-selecting-range, .react-datepicker__day--today
        background-color: #706E84
        color: white
    .end-date-calendar
      left: -150px
      .react-datepicker__triangle
        left: 200px
  .date-range-wrapper
    .custom-date
      text-align: left
      @media(min-width: 991px)
        text-align: right
        padding-top: 50px
      span
        display: block
        margin-left: 15px
        margin-top: 10px
        margin-bottom: 10px
        @media (min-width: 991px)
          display: inline-block
          margin-left: 0
          margin-top: 0
          margin-bottom: 0
