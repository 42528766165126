@import "../settings"

.win-page
  .win-post
    background: #FFF
    border: 1px solid #E6E6E6
    padding: 23px
    h2
      font-size: 18px
      font-weight: 500
      line-height: 24px
      color: $new-black
      margin-bottom: 17px
    h4
      font-size: 13px
      line-height: 13px
      font-weight: normal
      color: #8b8a96
      margin-bottom: 20px
      span
        color: $new-black
      &:last-of-type
        margin-bottom: 23px
    h3
      font-size: 14px
      line-height: 18px
      font-weight: bold
      margin-bottom: 11px
    p
      font-size: 14px
      line-height: 20px
      margin-bottom: 30px
      &:last-of-type
        margin-bottom: 7px
    .box
      &.goal
        background: #6264ff
      &.value
        background: #FF0000
      padding: 10px 30px 10px 18px
      color: white
      position: relative
      border-radius: 3px
      font-size: 13px
      font-weight: bold
      display: inline-block
      margin: 5px 10px 5px 0px
      .cross
        cursor: pointer
        position: absolute
        display: inline-block
        height: 10px
        width: 10px
        right: 8px
        top: 50%
        transform: translateY(-50%)
        &:before, &:after
          border-radius: 2px
          width: 10px
          height: 2px
          background: white
          content: ''
          position: absolute
          top: 50%
          left: 50%
        &:before
          transform: translate(-50%, -50%) rotate(-45deg)
        &:after
          transform: translate(-50%, -50%) rotate(45deg)
    textarea
      height: 81px
      margin-bottom: 4px
    input, textarea
      width: 100%
      margin-bottom: 10px
      padding: 9px 13px
      font-size: 13px
      line-height: 16px
      border-radius: 3px
      border: 1px solid #D9D9D9
      &::placeholder
        color: #8B8A96
    span#save-edit-btn
      display: block
      margin-top: 20px
    span.error-message
      font-family: Roboto
      opacity: 0
      transition: opacity .3s
      color: #cd3c40
      font-weight: bold
      padding-left: 20px
      &.shown
        opacity: 1
    .help-block.text-danger
      color: #CD3C40 !important
      font-size: 16px !important
      font-weight: bold !important
      animation: pulse 1s ease infinite
    &.editing
      h2
        font-size: 16px
        font-family: Roboto
        font-weight: 500
        color: #323232
        margin: 10px 0
        span
          color: #E43F3F
          margin-left: 5px
  .win-menu
    padding-left: 31px
    width: 297px
    margin-top: -3px
    h3
      font-size: 14px
      color: #11A9FF
      margin-bottom: 8px
      cursor: pointer
      transition: color .5s
      &:hover
        color: #00D5E2
  .wins-list-title
    font-size: 24px
    padding-left: 12px
  .wins-shoutouts-wrap
    padding: 0 12px 20px
  .wins-shoutouts-div
    border: 1px solid #E6E6E6
    padding: 0
  .side-win-box
    padding: 0 12px
    hr
      background-color: #E9E9E9
      border-color: #E9E9E9
      margin: 24px 0
    #btn-record-win
      width: 100%
      background-color: $new-blue
      color: #fff
      font-size: 16px
      line-height: 24px
      font-weight: bold
      text-align: center
      border-radius: 3px
      border: none
      padding: 10px 0
    #export-wins
      width: 100%
      background-color: #fff
      color: $new-blue
      font-size: 15px
      line-height: 18px
      text-align: center
      border-radius: 3px
      border: 1px solid $new-blue
      padding: 12px 0
      img
        padding-right: 13px
  .top-block
    display: block
    position: inherit
    height: 100px
    background: #FFF
    border: 1px solid #E6E6E6
    padding: 24px 20px
    font-size: 15px
    line-height: 18px
    cursor: pointer
    text-decoration: none
    margin-bottom: 18px
    div.info-text
      position: absolute
      top: 50%
      transform: translateY(-50%)
      -webkit-transform: translateY(-50%)
      left: 86px
      right: 25px
      line-height: 18px
      color: #000
      .blue
        color: $new-blue
    div.circle-wrap
      display: block
      position: initial
      background: $new-blue
      height: 52px
      width: 52px
      border-radius: 50%
      span
        color: $white
        font-weight: bold
        font-size: 24px
        line-height: 28px
        display: block
        text-align: center
        padding-top: 12px
  .view-all-list
    padding: 0 20px
    margin: 0
    li
      list-style-type: none
      cursor: pointer
      display: inline-block
      text-transform: uppercase
      text-align: center
      font-weight: 500
      font-size: 16px
      line-height: 24px
      padding: 19px 20px 10px
      &.active
        color: $new-blue
        border-bottom: 3px solid $new-blue
      @media(max-width: 430px)
        display: block
  .view-all-div
    min-height: 100px
    background-color: #fff
    border-top: 1px solid #E6E6E6
    h3
      font-size: 24px
      font-weight: 500
      line-height: 28px
      color: $new-blue
      padding: 0 24px
      margin-bottom: 2px
      &:first-of-type
        padding-top: 29px
    ul
      padding: 0 24px
      margin: 0
      li
        list-style: none
        padding: 14px 0 15px
        border-bottom: 1px solid #E6E6E6
        .list-item-title
          color: #323232
          padding: 0 9px
          font-size: 16px
          line-height: 24px
          font-weight: 500
          &.trophy
            padding-left: 15px
        .blue-tag
          font-size: 13px
          line-height: 13px
          color: $new-blue
        a:nth-of-type(2)
          padding-left: 37px
        .date
          font-size: 13px
          line-height: 13px
        &.win
          padding: 17px 0
        &:first-of-type
          padding-top: 20px
        &:last-of-type
          border-bottom: none
          padding-bottom: 38px
          img
            padding: 5px 0
        span.user-link
          color: $new-black
          font-size: 13px
          line-height: 13px
          padding-left: 37px
          a
            text-decoration: underline
        a
          color: $new-black
          text-decoration: none
    .loader-wrap
      padding: 70px 10px 40px
  .filter-dropdown
    list-style: none
    float: right
    margin: 30px 24px 0 15px
    cursor: pointer
    a
      color: #575563
      font-size: 14px
      line-height: 16px
      text-decoration: none
    span
      margin-left: 11px
      color: $new-blue
      font-weight: 500
    .blue-circle
      display: inline-block
      height: 15px
      width: 15px
      background-color: #8BDBFF
      border-radius: 50%
      color: white
      i
        padding-left: 3px
    .dropdown-menu
      border-color: #ecf1f4
      padding: 10px
      li.filter-list
        padding: 0
        .filter-list-item
          padding: 10px
    .nav-arrow-indicator
      bottom: -1px
  .export-mode
    .export-data
      padding: 0 0 0 50px
    .round
      float: left
      position: relative
      padding-left: 18px
      label
        border: 2px solid $new-blue
        border-radius: 50%
        cursor: pointer
        height: 22px
        width: 22px
        left: 0
        position: absolute
        top: 0
      &.export-by-header
        float: none
        width: 80%
        padding-left: 28px
        label
          top: 2px
    .round label:after
      border: 2px solid #fff
      border-top: none
      border-right: none
      content: ""
      height: 6px
      left: 4px
      opacity: 0
      position: absolute
      top: 5px
      transform: rotate(-45deg)
      width: 10px
    .round input[type="checkbox"]
      visibility: hidden
      margin-left: -9px
      &.export-shoutout
        margin-left: -5px
    .round input[type="checkbox"]:checked + label
      background-color: $new-blue
      border-color: $new-blue
    .round input[type="checkbox"]:checked + label:after
      opacity: 1
  .export-button-box
    padding-top: 0
    .export-selected-button
      margin: 0 0 38px 24px
    #cancel-export
      margin: 0 0 38px 24px
  .export-modal
    .modal-content
      max-width: 470px
      .export-modal-wrap
        padding: 85px 40px
        text-align: center
        h1
          font-size: 24px
          line-height: 28px
          font-weight: 500
          margin: 12px 0 34px
        h2
          max-width: 250px
          font-size: 16px
          line-height: 24px
          font-weight: 500
          margin: 0 auto
        p
          max-width: 220px
          margin: 0 auto
          padding-top: 39px
          font-size: 13px
          line-height: 18px
          color: #979797
        .blue-circle
          display: block
          width: 46px
          height: 46px
          background-color: $new-blue
          border-radius: 50%
          padding: 8px
          margin: 0 auto
          i
            font-size: 28px
            color: #fff

