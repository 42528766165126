
$AWS_S3_URL: 'https://assets.heelix.com/'
// DEPRECATED: use bootstraps .text-center
.centered
  text-align: center

// Put on container to vertically center
// Note if put on a row, this will prevent items stacking
.vertical-centered-md
  +desktop-only
    display: flex
    align-items: center

// DEPRECATED: user bootstraps .clearfix
.clear
  overflow: hidden

.space-to-left
  margin-left: 5px

.space-to-left-sm
  margin-left: 10px

.space-to-left-md
  margin-left: 25px

.space-to-right
  margin-left: 5px

.half-width
  width: 50%

.stretch
  width: 100%

.constrain
  max-width: 960px

.centered-page
  max-width: 960px
  margin-left: auto
  margin-right: auto

.centered-page-md
  max-width: 615px
  margin-left: auto
  margin-right: auto

.giant-padding
  padding: 30px

.vertical-divider
  border-right: 1px solid #EEE

.o
  outline: 1px solid red

.disabled_group
  opacity: .5
  pointer-events: none

// pillows

.pillow
  background-color: white
  padding: 10px

.ellipsis
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.horizontal-divider
  display: inline-block
  margin: 0 10px

.callout-padding
  padding-top: 100px
  padding-bottom: 100px
  max-width: 640px
  margin-left: auto
  margin-right: auto

// remove space

.gutterless
  margin-left: -10px
  margin-right: -10px

.no-wrap
  white-space: nowrap

.shadow
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15)

.rounded-corners
  border-radius: 2px

.tab-border
  border: 1px solid $light-grey
  border-width: 0 1px 1px

// heights

.height-1x
  height: $baseline * 1
  line-height: $baseline * 1

.height-2x
  height: $baseline * 2
  line-height: $baseline * 2

.height-3x
  height: $baseline * 3
  line-height: $baseline * 3

.height-4x
  height: $baseline * 4
  line-height: $baseline * 4

.height-5x
  height: $baseline * 5
  line-height: $baseline * 5

.visually-hide
  visibility: hidden

.u-side-by-side
  > *
    display: inline-block
    margin-right: rhythm(0.25)
    float: none
    vertical-align: middle
    &:last-child
      margin-right: 0

@mixin vertical-align($position: relative, $adjust: -50%)
  position: $position
  top: 50%
  left: 0
  transform: translateY($adjust)
  width: 100%

.u-vertical-align
  +vertical-align($position: absolute)
