.team-select
  font-size: 20px
  font-weight: bold
  color: #323232
  background: #F4F4F4
  display: flex
  align-items: center
  position: relative
  padding: 7px 13px
  margin: -11px 50px -11px 0
  border-radius: 4px
  cursor: pointer
  z-index: 20
  max-width: 500px
  min-width: 256px
  top: -1px
  justify-content: space-between
  > span
    padding-right: 15px
  @media(max-width: $screen-xs-max)
    position: static
    margin: 0
    width: 100%

  .autotrim .ending
    color: #979797
  .dropdown-arrow
    transition: .3s
    margin-right: 8px
    min-width: 14px
    float: right
  .teams-dropdown
    width: 388px
    position: absolute
    top: calc(100% + 3px)
    padding: 19px 17px 5px
    border: 1px solid #E6E6E6
    box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
    border-radius: 3px
    left: 0
    z-index: 10
    background: white
    cursor: initial
    font-weight: initial
    transition: .3s
    opacity: 0
    @media(max-width: $screen-xs-max)
      width: 100%
      height: 100vh
      top: 0
      position: fixed
    @media(min-width: $screen-sm) and (max-width: $screen-sm-max)
      left: 50%
      transform: translateX(-50%)
    &.mounted
      opacity: 1
    .teams-list.search
      @media(min-width: $screen-xs-max)
        overflow-y: auto
        overflow-x: hidden
        max-height: 310px
    h1
      font-size: 18px
      line-height: normal
      color: #323232
      margin-top: -2px
      margin-bottom: 19px
      display: none
      position: relative
      @media(max-width: $screen-xs-max)
        display: block
      span
        position: absolute
        display: block
        width: 18px
        height: 18px
        right: -3px
        top: 50%
        transform: translateY(-50%)
        cursor: pointer
        &:before, &:after
          background: #D5D5D5
          position: absolute
          top: 50%
          left: 50%
          content: ''
          width: 100%
          height: 4px
          border-radius: 1px
        &:before
          transform: translate(-50%, -50%) rotate(45deg)
        &:after
          transform: translate(-50%, -50%) rotate(-45deg)
    input
      background: #FFFFFF
      border: 1px solid #11A9FF
      box-sizing: border-box
      border-radius: 4px
      font-size: 14px
      width: 100%
      padding: 10px 13px
      height: 42px
      outline: none
      &::placeholder
        color: #C4C4C4
        font-style: italic
    h2
      font-weight: bold
      font-size: 15px
      margin-top: 15px
      margin-left: 3px
      margin-bottom: 11px
    a
      text-decoration: none
      color: inherit
      margin-bottom: 16px
      display: flex
      align-items: center
      .space-logo, img
        display: inline-block
        font-weight: 900
        width: 38px
        height: 38px
        border: 1px solid white
        border-radius: 100%
        margin: 0
        line-height: 38px
        font-size: 15px
        text-align: center
      .team-name
        font-size: 16px
        display: inline-block
        position: relative
        left: 11px
        top: 1px
        flex-grow: 1
  @media(min-width: $screen-sm-min)
    &.unfolded
      .dropdown-arrow
        transform: rotateZ(-180deg)