.heelix
  &.settings-page
    .btn-primary
      background: #11A9FF
      border-color: #11A9FF
      border-radius: 6px
    .no-gutter
      padding-left: 0
      padding-right: 0
    .no-gutter-left
      padding-left: 0
    .no-gutter-right
      padding-right: 0
    .settings-wrapper
      background: #FFFFFF
      border: 1px solid #E6E6E6
      box-sizing: border-box
      box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
      border-radius: 8px
      padding: 15px
      @media (min-width: 768px)
        padding: 30px
      h1#settings-title
        font-style: normal
        font-weight: bold
        line-height: normal
        font-size: 30px
        text-align: center
        margin-top: 6px
        margin-bottom: 24px
      .settings-divider
        height: 1px
        background-color: #E6E6E6
        margin: 36px 0
      #subscription-settings-wrapper, #company-name-form-wrapper,
      #notifications-settings-wrapper, #invoices-settings-wrapper, #account-settings-wrapper
        padding: 0
        @media (min-width: 768px)
          padding: 0 20px
      h3.settings-page-title
        font-weight: bold
        line-height: normal
        font-size: 18px
        margin-bottom: 18px
        &.small-font
          font-size: 16px
      #invoices-settings-wrapper
        margin-top: 39px
        table
          th
            color: #6A6A6A
            font-size: 14px
            line-height: 23px
            font-weight: bold
            padding-left: 0 !important
          tr
            border-bottom: 1px solid #E6E6E6
            &:last-of-type, &:first-of-type
              border-bottom: none
            td
              padding: 12px 0
              font-size: 14px
              line-height: 23px
              color: #6A6A6A
              a
                color: #11A9FF !important
      #subscription-settings-wrapper
        p#plan-info
          margin-bottom: 40px
        div#monthly-payment-info
          max-width: 500px
          margin-top: 40px
          @media (min-width: 768px)
            max-width: 800px
          @media (min-width: 890px)
            margin-top: 0
        .crown-img
          margin-right: 10px
        .card-summary
          border: 1px solid #E6E6E6
          border-radius: 8px
          padding: 23px
          float: none
          @media (min-width: 890px)
            float: left
            margin-right: 36px
          a.card-details
            color: #11A9FF
          div.card-number
            font-size: 13px
            margin-top: 26px
            margin-bottom: 10px
            .dot
              width: 6px
              height: 6px
              margin-right: 5px
              background-color: #D5D5D5
              display: inline-block
              border-radius: 50%
              &:nth-of-type(4n)
                margin-right: 16px
      .js-unsubscribe-modal
        font-size: 14px
        color: #11A9FF
      #notifications-settings-wrapper
        a
          font-size: 15px
          color: #11A9FF
      #company-name-form-wrapper
        .form-group
          margin: 0
          &.account-name-label
            label
              font-weight: bold
              line-height: normal
              font-size: 18px
              margin-bottom: 18px
            p.company-name-desc
              font-size: 15px
              color: #323232
              margin: 0 0 12px
              line-height: normal
          &.account_name
            input#account_name
              background: #F6F6F6
              border: 1px solid #D5D5D5
              box-sizing: border-box
              border-radius: 6px
            input#update-company-name-btn
              font-style: normal
              font-weight: bold
              line-height: normal
              font-size: 16px
              text-align: center
              color: #FFFFFF
              background: #11A9FF
              border-color: #11A9FF !important
              border-radius: 6px
              padding: 11px 15px
        .help-block
          margin-bottom: 0

@import "payments_page"