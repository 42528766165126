.small-speedometer
  width: 121px
  height: 121px
  border-radius: 100%
  transform: translateX(0)
  display: inline-block
  margin: 25px 50px 0
  @media(max-width: 1392px)
    margin: 25px 40px 0
  vertical-align: top
  text-align: left
  &.engagement
    background: radial-gradient(174.75px at -2.32% 100%, #FFCB7D 0%, #FB9600 100%)
    @media(max-width: 1392px)
      margin: 25px 40px 0 20px
    @media(max-width: 415px)
      margin: 10px 40px

    .speedometer-bottom-caption-wrap
      width: 180px

    &.disabled
      h2
        color: #9D9D9D !important
        font-style: normal !important

      span
        font-style: normal !important
  &.active-users
    background: radial-gradient(181.00px at 0% 100%, #CDEAF7 0%, #11A9FF 100%)
    @media(max-width: 415px)
      margin: 10px 40px
    .speedometer-bottom-caption-wrap
      width: 216px
  &:before
    bottom: 0
    height: 50%
    width: 100%
    background: white
    content: ''
    position: absolute
    z-index: 2
  &:after
    position: absolute
    top: 50%
    left: 50%
    background: white
    width: 76px
    height: 76px
    content: ''
    transform: translate(-50%, -50%)
    border-radius: 100%
    z-index: 3
  .speedometer-center-caption
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1)
    display: flex
    justify-content: center
    align-items: center
    font-weight: bold
    width: 57px
    font-size: 18px
    height: 57px
    border-radius: 100%
    background: linear-gradient(to bottom, #e9e9e9 0%, #ffffff 50%)
    z-index: 5
    span
      position: relative
      top: -1px
      left: 2px
      &.decline:after
        content: ''
        background: linear-gradient(to bottom right, rgba(254, 72, 60, 0) 0%, rgba(254, 72, 60, 0) 50%, rgb(254, 72, 60) 50%)
        height: 7px
        width: 7px
        display: inline-block
        transform: rotateZ(45deg) skew(10deg, 10deg)
        position: relative
        top: -6px
        margin-left: 3px
      &.equal:after
        content: ''
        height: 2px
        width: 7px
        display: inline-block
        position: relative
        top: -6px
        margin-left: 3px
        background: black
      &.incline:after
        content: ''
        background: linear-gradient(to bottom right, rgba(82, 186, 5, 0) 0%, rgba(82, 186, 5, 0) 50%, rgb(82,186,5) 50%)
        height: 7px
        width: 7px
        display: inline-block
        transform: rotateZ(-135deg) skew(10deg, 10deg)
        position: relative
        top: -1px
        margin-left: 3px
  .empty-graph
    width: 100%
    position: absolute
    background: linear-gradient(to bottom, #E8E8E8 0%, #E8E8E8 49.5%, rgba(232,232,232, 0) 50.5%, rgba(232,232,232, 0) 100%)
    top: 0
    left: 0
    height: 100%
    border-radius: 100%
    z-index: 1
    transform: rotateZ(0)
    transition: transform cubic-bezier(.44,.91,.63,.99) 1.5s
  .needle
    position: absolute
    height: 11px
    top: 50%
    left: 0
    transform: translate(0, -50%) rotateZ(0)
    width: 50%
    transform-origin: right
    z-index: 4
    transition: transform cubic-bezier(.44,.91,.63,.99) 1.5s
  .previous-value
    position: absolute
    height: 1px
    top: 50%
    left: 0
    transform: translate(0, -50%) rotateZ(0)
    width: 50%
    transform-origin: right
    z-index: 3
    .position-helper
      position: absolute
      left: 0
      div
        transform: translate(0px, -50%) rotateZ(0deg)
        display: inline-block
        position: absolute
        right: 6px
        font-size: 11px
        background: #EEEEEE
        border-radius: 3px
        padding: 1px 5px
  .speedometer-bottom-caption-wrap
    position: absolute
    z-index: 5
    top: 80px
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    left: 50%
    transform: translateX(-50%)
    h2
      font-weight: normal
      font-size: 15px
      color: #323232
      margin-bottom: -1px
    span
      font-size: 12px
      line-height: 17px
      text-align: center
  @media(max-width: 1080px)
    transform: translateX(0%) scale(.8)
    margin-top: 10px
    &:after
      width: 84px
      height: 84px
    .speedometer-bottom-caption-wrap
      top: 95px
      h2
        font-size: 18px
        font-weight: normal
        width: 148px
        line-height: 23px
      span
        display: none


.large-speedometer
  width: 270px
  height: 270px
  border-radius: 100%
  position: relative
  background: radial-gradient(270px at 0% 50%, #F5351B , #ECD69F 60%, rgba(236,214,159, 0) 75%), radial-gradient(270px at 100% 50%, #52BA05 , #ECD69F 60%, rgba(236,214,159, 0) 75%)
  @media(max-width: 1199.98px)
    left: 50%
    transform: translateX(-50%)
  &:before
    bottom: 0
    height: 50%
    width: 100%
    background: white
    content: ''
    position: absolute
    z-index: 2
  &:after
    position: absolute
    top: 50%
    left: 50%
    background: white
    width: 134px
    height: 134px
    content: ''
    transform: translate(-50%, -50%)
    border-radius: 100%
    z-index: 3
  .speedometer-center-caption
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.1)
    display: flex
    justify-content: center
    align-items: center
    font-weight: bold
    width: 106px
    font-size: 32px
    height: 106px
    border-radius: 100%
    background: linear-gradient(to bottom, #e9e9e9 0%, #ffffff 50%)
    z-index: 5
    span
      position: relative
      top: -12px
      width: 100%
      text-align: center
      &.decline:after
        content: ''
        background: linear-gradient(to bottom right, rgba(254, 72, 60, 0) 0%, rgba(254, 72, 60, 0) 50%, rgb(254, 72, 60) 50%)
        height: 9px
        width: 9px
        display: inline-block
        transform: rotateZ(45deg) skew(10deg, 10deg)
        position: relative
        top: -6px
        margin-left: 3px
      &.equal:after
        content: ''
        height: 2px
        width: 9px
        display: inline-block
        position: relative
        top: -6px
        margin-left: 3px
        background: black
      &.incline:after
        content: ''
        background: linear-gradient(to bottom right, rgba(82, 186, 5, 0) 0%, rgba(82, 186, 5, 0) 50%, rgb(82,186,5) 50%)
        height: 9px
        width: 9px
        display: inline-block
        transform: rotateZ(-135deg) skew(10deg, 10deg)
        position: relative
        top: -3px
        left: 15px
        margin-left: -10px
  .needle
    position: absolute
    height: 19px
    top: 50%
    left: 0
    transform: translate(0, -50%) rotateZ(90deg)
    width: 50%
    transform-origin: right
    z-index: 4
    transition: transform cubic-bezier(.44,.91,.63,.99) 1.5s
  .previous-value
    position: absolute
    height: 2px
    top: 50%
    left: 0
    transform: translate(0, -50%) rotateZ(0)
    width: 50%
    transform-origin: right
    z-index: 3
    background: white
    .position-helper
      position: absolute
      left: 0
      div
        transform: translate(0px, -50%) rotateZ(0deg)
        display: inline-block
        position: absolute
        right: 9px
        font-size: 14px
        background: #EEEEEE
        border-radius: 3px
        padding: 2px 6px
        line-height: initial
  .speedometer-bottom-caption-wrap
    position: absolute
    z-index: 5
    top: 152px
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    transform: translateX(-50%)
    left: 50%
    width: 100%
    h2
      font-weight: bold
      font-size: 14px
      text-transform: uppercase
    svg
      position: absolute
      &.bad-vibe
        left: 20px
        top: -4px
      &.good-vibe
        right: 20px
        top: -4px
  #full-negative-caption
    position: absolute
    left: -7px
    width: 7px
    top: calc(50% - 1px)
    height: 1px
    background: #C4C4C4
    &:before
      content: '-100'
      left: -31px
      transform: translateY(-50%)
      top: 50%
      position: absolute
      color: #706E84
      font-size: 12px
  #full-positive-caption
    position: absolute
    right: -7px
    top: calc(50% - 1px)
    width: 7px
    height: 1px
    background: #C4C4C4
    &:before
      content: '100'
      right: -31px
      transform: translateY(-50%)
      top: 50%
      position: absolute
      color: #706E84
      font-size: 12px
  #neutral-caption
    position: absolute
    left: 50%
    top: -7px
    height: 7px
    width: 1px
    background: #C4C4C4
    &:before
      content: '0'
      top: -20px
      position: absolute
      color: #706E84
      font-size: 12px
      transform: translateX(-50%)
  @media(max-width: 1080px)
    transform: translateX(-50%) scale(.8666)
    .speedometer-bottom-caption-wrap h2
      font-size: 16px
      font-weight: normal
