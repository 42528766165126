// Extend Bootstrap utilities
// https://github.com/twbs/bootstrap/blob/master/less/utilities.less

.text-left-not-xs,
.text-left-not-sm,
.text-left-not-md,
.text-left-not-lg {
  text-align: left;
}
.text-center-not-xs,
.text-center-not-sm,
.text-center-not-md,
.text-center-not-lg {
  text-align: center;
}
.text-right-not-xs,
.text-right-not-sm,
.text-right-not-md,
.text-right-not-lg {
  text-align: right;
}
.text-justify-not-xs,
.text-justify-not-sm,
.text-justify-not-md,
.text-justify-not-lg {
  text-align: justify;
}

.full-screen {
  height: 100%;
}

@media (max-width: $screen-xs-max) {
  .pull-xs-left {
    float: left;
  }
  .pull-xs-right {
    float: right;
  }
  .pull-xs-none {
    float: none !important;
  }

  .text-left-not-xs,
  .text-center-not-xs,
  .text-right-not-xs,
  .text-justify-not-xs {
    text-align: inherit;
  }
  .text-left-xs {
    text-align: left;
  }
  .text-center-xs {
    text-align: center;
  }
  .text-right-xs {
    text-align: right;
  }
  .text-justify-xs {
    text-align: justify;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .pull-sm-left {
    float: left;
  }
  .pull-sm-right {
    float: right;
  }
  .pull-sm-none {
    float: none !important;
  }

  .text-left-not-sm,
  .text-center-not-sm,
  .text-right-not-sm,
  .text-justify-not-sm {
    text-align: inherit;
  }
  .text-left-sm {
    text-align: left;
  }
  .text-center-sm {
    text-align: center;
  }
  .text-right-sm {
    text-align: right;
  }
  .text-justify-sm {
    text-align: justify;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .pull-md-left {
    float: left;
  }
  .pull-md-right {
    float: right;
  }
  .pull-md-none {
    float: none !important;
  }

  .text-left-not-md,
  .text-center-not-md,
  .text-right-not-md,
  .text-justify-not-md {
    text-align: inherit;
  }
  .text-left-md {
    text-align: left;
  }
  .text-center-md {
    text-align: center;
  }
  .text-right-md {
    text-align: right;
  }
  .text-justify-md {
    text-align: justify;
  }
}

@media (min-width: $screen-lg-min) {
  .pull-lg-left {
    float: left;
  }
  .pull-lg-right {
    float: right;
  }
  .pull-lg-none {
    float: none !important;
  }

  .text-left-not-lg,
  .text-center-not-lg,
  .text-right-not-lg,
  .text-justify-not-lg {
    text-align: inherit;
  }
  .text-left-lg {
    text-align: left;
  }
  .text-center-lg {
    text-align: center;
  }
  .text-right-lg {
    text-align: right;
  }
  .text-justify-lg {
    text-align: justify;
  }
}
