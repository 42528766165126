#mood-over-time-vibe
  .top-controls-wrap
    display: flex
    align-items: center
    @media (min-width: 991px)
      padding: 0 0 35px
    .period-switch-wrap
      display: flex
      flex-shrink: 0
      span
        font-size: 14px
    @media(max-width: $screen-sm-max)
      flex-direction: column
      align-items: center
      .team-select
        margin-right: -11px
      .date-range-wrap
        margin-left: 0
        margin-top: 31px
        width: 100%
        .date-range
          margin-right: 0
          flex-grow: 1
          &:after
            right: 12px
  #chart-categories
    width: 100% !important
    #categories-btns
      width: 100%
      display: flex
      flex-direction: row
      flex-wrap: wrap
      padding: 0
      .category-btn
        display: flex
        justify-content: center
        flex-direction: column
        text-align: center
        font-family: Roboto, sans-serif
        font-style: normal
        font-weight: normal
        line-height: 17px
        font-size: 10px
        text-transform: uppercase
        color: #323232
        background: #FFFFFF
        border: 1px solid #E6E6E6
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15)
        border-radius: 4px
        vertical-align: middle
        cursor: pointer
        margin-top: 10px
        &:not(:last-of-type)
          margin-right: 12px
        &.active, &:active, &:focus
          &.terrible
            border: 2px solid #FE483C
          &.bad
            border: 2px solid #FF7F63
          &.okay
            border: 2px solid #BEB49E
          &.good
            border: 2px solid #8ED04C
          &.awesome
            border: 2px solid #52BA05
        &:not(.active):hover
          &.terrible
            border: 1px solid #FE483C
          &.bad
            border: 1px solid #FF7F63
          &.okay
            border: 1px solid #BEB49E
          &.good
            border: 1px solid #8ED04C
          &.awesome
            border: 1px solid #52BA05
        margin-bottom: 0
        margin-left: 0
        padding: 12px 12px
        @media (min-width: 1044px)
          padding: 12px 27px
        .mood-icon
          width: 29px
          height: 29px
  #stats-wrapper
    height: 367px
    @media (max-width: 768px)
      width: 100%
      overflow-x: scroll
      position: relative
  #mood-over-time-chart
    margin-top: 40px
    height: 100%
    @media (max-width: 480px)
      width: 300%
    @media (max-width: 768px)
      width: 200%
      white-space: nowrap
    canvas
      margin-top: -40px