@import "../settings"

.notification_settings
  .settings-container
    background-color: white
    border: 1px solid #e6e6e6
    border-radius: 8px
    box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
    .select-component
      margin: 0 0 14px 14px
      max-width: 400px
      .Select-control
        background-color: #F6F6F6
        border: 1px solid #D5D5D5
    h4
      font-size: 15px
      font-weight: 500
      margin-left: 14px
    h3
      font-size: 24px
      font-weight: bold
      margin-left: 14px
    h2
      text-align: center
      font-size: 30px
      font-weight: bold
      color: #323232
      margin-top: 36px
      margin-bottom: 51px
    .notifications-group-wrap
      margin:  0 34px 51px  43px
      @media (max-width: 480px)
        margin:  0 15px 51px
      &.spaces-list
        overflow-y: hidden
        overflow-x: auto
      .column-headers-wrap
        display: flex
        padding-bottom: 13px
        min-width: 700px
        .main-header
          display: flex
          min-width: 35%
          font-size: 24px
          font-weight: bold
          margin-left: 14px
        .sub-header
          text-align: center
          font-weight: 500
          font-size: 15px
          margin: 0
          padding: 8px 5px 0
          min-width: 15%
          .info-icon
            cursor: pointer
            font-size: 12px
            line-height: 18px
            color: #979797
            width: 18px
            height: 18px
            background-color: #F3F3F3
            border-radius: 50%
            display: inline-block
            position: relative
            bottom: 2px
      .space-row
        display: flex
        padding: 21px 0
        border-bottom: 1px solid #E6E6E6
        min-width: 700px
        &:last-of-type
          border: none
        .space-details
          width: 35%
          min-width: 35%
          display: inline-flex
          margin-left: 14px
          .space-name
            display: flex
            font-size: 16px
            padding-left: 12px
            align-items: center
          .circle-image
            margin: 0
            display: inline-flex
            .space-img
              display: inline-flex
              font-weight: 900
              width: 36px
              height: 36px
              border: 1px solid white
              border-radius: 100%
              margin: 0
          .space-logo
            display: inline-block
            font-weight: 900
            min-width: 36px
            height: 36px
            border: 1px solid white
            border-radius: 100%
            margin: 0
            line-height: 36px
            font-size: 15px
            text-align: center
        .switch-wrap
          display: inline-flex
          margin: 0
          align-items: center
          min-width: 15%
          padding: 0 5px
          .switch
            margin: 0 auto
      .unfollowed-users-list
        .user-row
          position: relative
          padding: 24px 0
          border-bottom: 1px solid #E6E6E6
          margin-left: 14px
          max-width: 600px
          &:last-of-type
            border: none
            margin-bottom: 51px
          .user-details
            padding: 0 48px
            display: inline-flex
            width: 100%
            .user-name
              font-weight: bold
              font-size: 14px
              position: absolute
              padding-top: 2px
              left: 96px
            .user-team
              font-size: 11px
              color: #BBBBBB
              position: relative
              top: 20px
          .user-img
            display: inline-block
            font-weight: 900
            line-height: 30px
            font-size: 15px
            text-align: center
            width: 36px
            min-width: 36px
            height: 36px
            border: 1px solid #E6E6E6
            border-radius: 100%
            margin-right: 12px
          .cross
            cursor: pointer
            display: none
            transform: translateY(-50%)
            &:before, &:after
              border-radius: 2px
              width: 13px
              height: 2px
              background: #C4C4C4
              content: ''
              position: absolute
              top: 18px
              left: 15px
            &:before
              transform: translate(-50%, -50%) rotate(-45deg)
            &:after
              transform: translate(-50%, -50%) rotate(45deg)
          .switch-wrap
            float: right
            .switch
              bottom: 32px
        &:hover
          .show
            display: inline-block
      .digest-notification
        margin-left: 14px
        max-width: 600px
        .digest-details
          display: inline-flex
          font-size: 15px
          width: 80%
        .digest-switch
          float: right
  .react-tooltip
    margin-left: 2px
    padding: 15px 25px 15px 31px
    background-color: #8B8A96
    color: #FFFFFF
    font-size: 14px
    line-height: 18px
    text-align: center
    opacity: 1
  .__react_component_tooltip.place-top
    margin-top: -24px !important
    box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
    &::after
      border-top: 20px solid #8B8A96 !important
      border-left: 13px solid transparent !important
      border-right: 13px solid transparent !important
      bottom: -20px
      width: 22px
      margin-left: -15px
