$background-color: #FFFFFF
$primary: #2A50FC
$secondary: #FF5C3E
$gray: #5B686E
$darkgray: #58575F
$verydarkgray: #36363B
$lightbrain: #F6F9FB
$darkbrain: #EAF2F6
$lightblue: #11A9FF
$tooltip: #D5E0E4
$tooltipbg: $tooltip
.heelix
  &.choose-plan

    @import "../components/modals"
    .form-group
      margin-bottom: 0
    .pricing
      padding-top: 42px
      @media (min-width: 768px)
        padding-top: 28px


      .title
        font-weight: bold
        line-height: normal
        font-size: 26px
        text-align: center
        color: $primary
        @media (min-width: 768px)
          font-size: 30px


      .description
        line-height: 21px
        font-size: 14px
        text-align: center
        color: $verydarkgray
        margin-top: 22px
        letter-spacing: -0.02em
        @media (min-width: 768px)
          line-height: 24px
          font-size: 16px
          margin-top: 25px

        @media (min-width: 1200px)
          margin-left: 20px
          margin-right: 20px


      .picker
        margin-top: 16px

        .accordion
          .horizontal-line
            width: 100%
            height: 2px
            background: #DFEBF0


          .enterprise-text
            font-style: normal
            font-weight: normal
            line-height: normal
            font-size: 12px
            text-align: center
            color: #7F7F8A
            margin-left: 6px


          .collapsed
            .vertical
              transform: rotate(90deg)
          //@keyframes rotate
          //  from transform: rotate(90deg)
          //  to transform: rotate(0deg)
          //
          //animation: rotate 2s


          .text-btn
            margin-top: 18px
            margin-bottom: 18px
            font-weight: bold
            line-height: normal
            font-size: 20px
            width: 100%
            text-align: left
            padding-right: 9px

            &:hover
              cursor: pointer


            .text
              float: left
              text-transform: none


            .toggle-icon
              vertical-align: middle
              float: right
              width: 16px
              height: 16px
              margin-top: 9px

              .line
                background: #C4C4C4
                position: absolute


              .horizontal
                width: 16px
                height: 2px


              .vertical
                transition: transform .3s
                width: 16px
                height: 2px


          .content
            margin-top: -7px
            padding: 0 !important

            .price-tag
              margin-bottom: 10px

              .free
                font-size: 24px
                text-align: center
                color: $verydarkgray
                margin-bottom: 20px


              .enterprise
                font-size: 18px
                text-align: center
                color: $verydarkgray
                margin-bottom: 20px


              .dollar
                font-size: 18px
                vertical-align: 44%
                color: $verydarkgray


              .tag
                font-size: 36px
                color: $verydarkgray


              .caption
                font-size: 12px
                vertical-align: 50%
                color: #7F7F8A
                font-weight: normal
                display: block

            .custom-button
              font-size: 14px
              width: 265px


            .list-title
              font-weight: bold
              line-height: 22px
              font-size: 14px
              color: $verydarkgray
              margin-top: 20px
              margin-left: 20px


            ul
              margin-top: 19px
              margin-bottom: 33px
              list-style-image: url($AWS_S3_URL + 'public/images/icons/Vector.svg')

              li
                color: $verydarkgray
                font-style: normal
                font-weight: normal
                line-height: normal
                font-size: 13px
                margin-bottom: 25px
                padding-left: 8px


          .starter
            color: $primary


          .pro
            color: #FFA63E


          .plus
            color: $secondary


          .enterprise
            color: #0F821A


        .desktop
          $bordercolor: #DFEBF0
          //margin-top: 61px
          .content
            padding: 0 !important

          .text
            font-weight: bold
            line-height: normal
            font-size: 24px

            &:after
              margin-left: auto
              margin-right: auto
              margin-top: 23px
              background: $bordercolor
              content: " "
              width: calc(100% - 10px)
              height: 4px


          .enterprise-text
            font-style: normal
            font-weight: normal
            line-height: normal
            font-size: 12px
            text-align: center
            color: #7F7F8A
            margin-top: 4px


          .prices
            margin-top: 18px

            .custom-button
              min-width: 147px
              max-width: 100%
              line-height: 20px
              margin-top: 8px


            .multiline
              height: 62px
              line-height: normal
              font-size: 14px
              padding-top: 14px


            .price
              color: $verydarkgray
              margin-bottom: 9px


            .free
              font-size: 24px
              margin-bottom: 40px


            .enterprise
              font-size: 18px
              margin-bottom: 48px
              font-weight: bold
              margin-top: 20px


            .dollar
              font-size: 18px
              vertical-align: 44%
              color: $verydarkgray


            .tag
              font-size: 36px
              color: $verydarkgray


            .caption
              font-size: 12px
              vertical-align: 50%
              color: #7F7F8A
              display: block
              font-weight: normal


            &:after
              margin-left: auto
              margin-right: auto
              margin-top: 51px
              background: $bordercolor
              content: " "
              width: calc(100% - 10px)
              height: 1px


          .lists
            display: flex
            flex-wrap: wrap

            .content
              padding: 0 !important
              margin-top: 33px
              margin-left: 6px
              @media (min-width: 1200px)
                margin-right: 31px


            .list-title
              font-weight: bold
              line-height: 22px
              font-size: 14px
              color: $verydarkgray
              margin-top: 20px
              margin-left: 20px

            .first-list
              margin-top: 39px

            ul
              margin-top: 23px
              margin-bottom: 33px
              //list-style-image: url('../Vector.svg')
              list-style-image: url($AWS_S3_URL + 'public/images/icons/Vector.svg')

              li
                color: $verydarkgray
                font-style: normal
                font-weight: normal
                font-size: 13px
                line-height: 20px
                margin-bottom: 20px
                padding-left: 8px


            &:after
              margin-left: auto
              margin-right: auto
              margin-top: 0px
              background: $bordercolor
              content: " "
              width: calc(100% - 10px)
              height: 1px


          .starter
            color: $primary


          .pro
            color: #FFA63E


          .plus
            color: $secondary


          .enterprise
            color: #0F821A

          .border-between > [class*='col-']:before
            background: $bordercolor
            bottom: 0
            content: " "
            left: 0
            position: absolute
            width: 1px
            top: -18px
            min-height: 250px


          .border-between > [class*='col-']:first-child:before
            display: none


          .border-between > [class*='col-']:last-child:before
            top: -9px


      .custom-button
        font-style: normal
        font-weight: bold
        line-height: normal
        font-size: 14px
        text-align: center
        color: #FFFFFF
        background: #11A9FF
        border-color: #11A9FF !important
        border-radius: 4px
        padding: 11px 15px


    .react-tooltip
      max-width: 300px
      margin-left: -4px
      padding: 15px 25px 15px 31px
      background-color: #8B8A96
      color: #FFFFFF
      font-size: 14px
      line-height: 18px
      text-align: center
      opacity: 1

    .__react_component_tooltip.place-top
      margin-top: -24px !important
      box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px

      &::after
        border-top: 20px solid #8B8A96 !important
        border-left: 13px solid transparent !important
        border-right: 13px solid transparent !important
        bottom: -20px
        width: 22px

    .faq
      margin-top: 63px
      color: #323232

      .faq-header
        margin-bottom: 16px

      .faq-title
        font-weight: bold
        font-size: 18px

      .question
        margin-bottom: 32px

        .question-title
          font-weight: bold
          font-size: 15px

        .question-body
          font-weight: normal
          line-height: 21px
          font-size: 14px
          margin-top: 13px


    .my-auto
      display: inline-block
      vertical-align: middle
      float: none

    .tooltip-icon
      margin-left: 7px
      height: 14px
      width: 14px
      background-color: $tooltip
      border-radius: 50%
      display: inline-block
      text-align: center
      color: $white
      font-size: 12px
      line-height: normal
      cursor: pointer


    .tooltip-inner
      padding-left: 20px
      padding-right: 20px
      padding-top: 20px
      padding-bottom: 20px
      text-align: left
      background-color: $tooltipbg
      color: $verydarkgray
      line-height: 20px
      font-size: 13px
      opacity: 1
      max-width: 223px


    .tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before, .tooltip.bs-tooltip-top .arrow::before
      border-top-color: $tooltipbg
      opacity: 1


    .tooltip.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .tooltip.bs-tooltip-bottom .arrow::before
      border-bottom-color: $tooltipbg
      opacity: 1


    .tooltip.bs-tooltip-auto[x-placement^=left] .arrow::before, .tooltip.bs-tooltip-left .arrow::before
      border-left-color: $tooltipbg
      opacity: 1


    .tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before, .tooltip.bs-tooltip-right .arrow::before
      border-right-color: $tooltipbg
      opacity: 1


    .tooltip.show
      opacity: 1
      width: 223px

//margin-left: 2px
    
