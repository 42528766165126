@import "../settings"

.jobvibe-future
  h1.member-notes-header
    font-size: 24px
    font-weight: normal
    font-family: Roboto
    margin-bottom: 18px
  .member-notes-tile
    background: #FFFFFF
    padding: 25px 30px 25px 29px
    margin-bottom: 20px
    border: 1px solid #E6E6E6
    cursor: pointer
    .empty-notes-info
      font-size: 15px
      text-align: center
    div:first-child h1
      margin-top: 0
    div
      h1
        font-family: Roboto
        font-weight: bold
        font-size: 16px
        margin-top: 14px
        margin-bottom: 0
        a
          text-decoration: none
          color: #464646
          cursor: pointer
      h2
        display: inline-block
        color: #8b8a96
        font-size: 13px
        font-family: Roboto
        font-weight: normal
        span
          color: #323232
          font-family: Roboto
          padding: 0 5px
          &:first-child
            padding-left: 0
      hr
        cursor: default
        display: block
        height: 1px
        border-top: 1px solid #E9E9E9
        margin-top: -2px
        margin-bottom: 0
      &:last-child hr
        display: none
  .member-notes-periods
    border: 1px solid #E6E6E6
    color: #323232
    padding: 19px 0 12px 0
    cursor: pointer
    span
      margin: 20px 3px 20px 21px
      padding-bottom: 12px
      padding-right: 17px
      padding-left: 23px
    span.selected
      color: #11A9FF
      border-bottom: 2px solid #11A9FF
    +phones-and-tablets
      span
        margin: 20px 3px 20px 21px
        padding-bottom: 12px
        padding-right: 7px
        padding-left: 13px

  .member-note-details
    background: #FFFFFF
    padding: 22px 25px 25px 23px
    margin-bottom: 20px
    border: 1px solid #E6E6E6
    .menu-handler
      position: initial
      padding-right: 5px
    div:nth-last-child(2) hr
      display: none
    .export-mode
      .export-data
        padding: 0 0 0 45px
      .round
        float: left
        position: relative
        padding: 0 20px 0 13px
      .round label
        border: 2px solid $new-blue
        border-radius: 50%
        cursor: pointer
        height: 22px
        left: 0
        position: absolute
        top: 0
        margin-top: 3px
        width: 22px
      .round label:after
        border: 2px solid #fff
        border-top: none
        border-right: none
        content: ""
        height: 6px
        left: 3px
        opacity: 0
        position: absolute
        top: 4px
        transform: rotate(-45deg)
        width: 12px
      .round input[type="checkbox"]
        visibility: hidden

      .round input[type="checkbox"]:checked + label
        background-color: $new-blue
        border-color: $new-blue

      .round input[type="checkbox"]:checked + label:after
        opacity: 1
    h2
      display: block
      color: #8b8a96
      font-size: 13px
      font-family: Roboto
      font-weight: normal
      margin-bottom: 2px
      span
        color: #323232
        font-family: Roboto
        padding: 0 5px
        &:first-child
          padding-left: 0
    h2.observation-content
      line-height: 20px
      display: block
    h2.bold-title
      margin-top: 17px
      margin-bottom: 6px
      font-weight: bold
      color: #323232
    hr
      display: block
      height: 1px
      border-top: 1px solid #D5D5D5
      margin-bottom: 23px
  .member-notes-info-box
    font-size: 15px
    font-weight: normal
    line-height: 21px
    text-align: left
    font-family: Roboto
    float: right
    padding-left: 7px
    img.eye-icon
      padding: 4px 24px 20px 6px
      float: left
      vertical-align: sub
    button.create-new-note-button
      width: 95%
      margin-top: 25px
      padding: 0 15px 0 15px
      background-color: $new-blue
    hr
      background-color: #E9E9E9
      border-color: #E9E9E9
      margin: 24px 0
    #export-notes
      width: 95%
      background-color: #fff
      color: $new-blue
      font-size: 15px
      line-height: 18px
      text-align: center
      border-radius: 3px
      border: 1px solid $new-blue
      padding: 11px 14px 11px 0
      img
        padding-right: 12px
  .note-dropdown
    top: 115%
    padding: 25px
    li
      margin-bottom: 15px
      hr.dropdown-menu-divider
        margin-top: 23px
        margin-bottom: 19px
      a
        padding: 9px 79px
        font-weight: bold
        font-size: 15px
        line-height: 18px
        font-family: Roboto, sans-serif
        cursor: pointer
        border-radius: 4px
        &.js-inline-edit-trigger
          background-color: #11A9FF
          color: #fff
          text-align: center
        &.js-inline-delete-trigger
          background-color: #fff
          color: #E43F3F
          border: 1px solid #E43F3F
      label
        color: #323232
        font-size: 16px
        line-height: 19px
        margin-bottom: 10px
      .assign-fix
        font-size: 13px
        line-height: 16px
        color: #8B8A96
    &.dropdown-menu-right
      right: -20px
    &:after
      content: ""
      position: absolute
      width: 15px
      height: 15px
      background: #ffffff
      transform: rotate(45deg)
      top: -8px
      right: 18px
      border-top: 1px solid #dedede
      border-left: 1px solid #dedede
.filter-dropdown
  list-style: none
  float: right
  margin-top: 5px
  cursor: pointer
  a
    color: #575563
    font-size: 14px
    line-height: 16px
    text-decoration: none
  span
    margin-left: 11px
    color: $new-blue
    font-weight: 500
  .blue-circle
    display: inline-block
    height: 15px
    width: 15px
    background-color: #8BDBFF
    border-radius: 50%
    color: white
    i
      padding-left: 3px
  .dropdown-menu
    border: none
    i.checked-filter
      color: $new-blue
      float: right
  .nav-arrow-indicator
    bottom: -1px
.export-button-box
  padding-top: 40px
  button.export-selected-button
    font-size: 16px
    font-weight: bold
    font-family: Roboto
    padding: 0 40px 0 40px
    background-color: $new-blue
    color: #ffffff
    margin-right: 23px
  button#cancel-export
    background-color: #fff
    color: $new-blue
    font-size: 16px
    line-height: 18px
    text-align: center
    border-radius: 3px
    border: 1px solid $new-blue
    padding: 11px 35px 11px 35px
    margin-top: 20px

.note-modal
  width: auto
  @media (max-width: 479px)
    width: 100%
  @media (min-width: 768px)
    width: 692px
  .modal-content
    margin-top: 50px
    max-width: 692px
    transform: translateX(-50%)
    position: relative
    left: 50%
    .modal-inner-wrap
      width: 100%
      padding: 45px 55px
      .close-cross
        position: absolute
        width: 30px
        height: 30px
        top: 15px
        right: 15px
        background: #11A9FF
        border-radius: 100%
        cursor: pointer
        &:before, &:after
          display: block
          content: ''
          height: 2px
          width: 16px
          position: absolute
          left: 50%
          top: 50%
          background: white
        &:before
          transform: translate(-50%, -50%) rotate(-45deg)
        &:after
          transform: translate(-50%, -50%) rotate(45deg)
        @media (min-width: 768px)
          top: -15px
          right: -15px
      h1
        font-family: Roboto
        font-size: 20px
        font-weight: 500
        color: #11A9FF
        margin-bottom: 17px
      h2
        font-size: 16px
        font-family: Roboto
        font-weight: 500
        color: #323232
        margin: 9px 0 8px 0
        span
          color: #E43F3F
          margin-left: 5px
      .text__control
        max-height: 112px
        font-size: 13px
        .text__highlighter
          max-height: 112px
      .goal-box
        padding: 10px 30px 10px 18px
        background: #FE873C
        color: white
        position: relative
        border-radius: 3px
        font-size: 13px
        font-weight: bold
        display: inline-block
        margin: 5px 10px 5px 0px
        .goal-cross
          cursor: pointer
          position: absolute
          display: inline-block
          height: 10px
          width: 10px
          right: 8px
          top: 50%
          transform: translateY(-50%)
          &:before, &:after
            border-radius: 2px
            width: 10px
            height: 2px
            background: white
            content: ''
            position: absolute
            top: 50%
            left: 50%
          &:before
            transform: translate(-50%, -50%) rotate(-45deg)
          &:after
            transform: translate(-50%, -50%) rotate(45deg)
      .new-note-input
        width: 100%
        margin-bottom: 8px
        padding: 10px 14px
        font-size: 13px
        line-height: 16px
        border-radius: 3px
        border: 1px solid #D9D9D9
        &[type='button']
          margin-top: 23px
          background: #11A9FF
          border-radius: 3px
          width: 173px
          text-align: center
          color: white
          font-size: 16px
          font-weight: bold
          padding: 12px 30px 12px 30px
          outline: none
          border: none
      textarea
        height: 78px
      .select-component
        font-size: 13px
        line-height: 16px
        margin-bottom: 21px
        margin-top: 10px
        &.is-focused .Select-value
          border: 1px solid #FE873C
          border-radius: 3px
        &.is-pseudo-focused
          .Select-control
            border: 1px solid #b3b3b3
            box-shadow: none
            border-radius: 3px
        .Select-placeholder
          margin-left: 4px
        input
          padding-left: 4px
          width: 100%
          border-radius: 3px
        .Select-option.is-selected, .Select-option.is-focused
            background-color: #FE873C
            color: white
      .select-component.users .Select-arrow-zone
          display: none
      span.error-message
        font-family: Roboto
        opacity: 0
        transition: opacity .3s
        color: #cd3c40
        font-weight: bold
        padding-left: 20px
        &.shown
          opacity: 1

.edit-member-note-form
  width: 100%
  padding: 23px 23px 53px 23px
  .close-cross
    position: absolute
    width: 30px
    height: 30px
    top: 15px
    right: 15px
    background: #11A9FF
    border-radius: 100%
    cursor: pointer
    &:before, &:after
      display: block
      content: ''
      height: 2px
      width: 16px
      position: absolute
      left: 50%
      top: 50%
      background: white
    &:before
      transform: translate(-50%, -50%) rotate(-45deg)
    &:after
      transform: translate(-50%, -50%) rotate(45deg)
    @media (min-width: 768px)
      top: -15px
      right: -15px
  h1
    font-family: Roboto
    font-size: 20px
    font-weight: 500
    color: #11A9FF
    margin-bottom: 17px
  h2
    font-size: 16px
    font-family: Roboto
    font-weight: 500
    color: #323232
    margin: 8px 0
    span
      color: #E43F3F
      margin-left: 5px
  .text__control
    max-height: 112px
    font-size: 13px
    .text__highlighter
      max-height: 112px
  .goals-box
    margin: 5px 10px 5px 0px
    position: relative
    display: block
  .goal-box
    padding: 10px 30px 10px 18px
    background: #FE873C
    color: white
    position: relative
    border-radius: 3px
    font-size: 13px
    font-weight: bold
    display: inline-block
    margin: 5px 10px 5px 0px
    .goal-cross
      cursor: pointer
      position: absolute
      display: inline-block
      height: 10px
      width: 10px
      right: 8px
      top: 50%
      transform: translateY(-50%)
      &:before, &:after
        border-radius: 2px
        width: 10px
        height: 2px
        background: white
        content: ''
        position: absolute
        top: 50%
        left: 50%
      &:before
        transform: translate(-50%, -50%) rotate(-45deg)
      &:after
        transform: translate(-50%, -50%) rotate(45deg)
  input.edit-note-input, textarea
    width: 100%
    margin-bottom: 7px
    padding: 10px 14px
    font-size: 13px
    line-height: 16px
    border-radius: 3px
    border: 1px solid #D9D9D9
    &[type='button']
      margin-top: 20px
      background: #11A9FF
      border-radius: 3px
      width: 173px
      text-align: center
      color: white
      font-size: 16px
      font-weight: bold
      padding: 12px 30px 12px 30px
      outline: none
      border: none
      display: block
      float: right
  textarea
    height: 78px
  .select-component
    font-size: 13px
    line-height: 16px
    margin-bottom: 21px
    margin-top: 10px
    &.is-focused .Select-value
      border: 1px solid #FE873C
      border-radius: 3px
    &.is-pseudo-focused
      .Select-control
        border: 1px solid #b3b3b3
        box-shadow: none
        border-radius: 3px
    .Select-placeholder
      margin-left: 4px
    input
      padding-left: 4px
      width: 100%
      border-radius: 3px
    .Select-option.is-selected, .Select-option.is-focused
      background-color: #FE873C
      color: white
  span.error-message
    font-family: Roboto
    opacity: 0
    transition: opacity .3s
    color: #cd3c40
    font-weight: bold
    padding-right: 20px
    display: block
    float: right
    margin-top: 40px
    &.shown
      opacity: 1