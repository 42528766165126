
.insights
  font-size: 18px

#chartjs-line-tooltip
  background-color: white
  opacity: 1
  position: absolute
  transition: all .1s ease
  pointer-events: none
  transform: translate(-50%, 0)
  z-index: 100
  overflow: visible
  .chart-tooltip
    background-color: white
    text-align: center
    margin-top: -45px
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.15)
    border-radius: 4px
    padding: 3px 10px
    white-space: nowrap
    .date
      font-color: #333
      font-size: 10px
    .value
      font-weight: bold
      font-size: 18px
      &.estimate
        color: rgba(38, 195, 129, 0.3)
      &.old
        color: #fdc029

    .notch
      position: absolute
      bottom: -5px
      border-width: 5px 5px 0
      border-style: solid
      border-color: white transparent
      display: block
      width: 0
      left: 37px

.vibe-score-hover
  overflow: visible

.media.comment
  img
    width: 50px
    height: 50px
