.panel-footer
  background-color: white
  border-color: $light-grey
  line-height: rhythm(2) - 1px

  &-no-border
    border-color: transparent

.panel
  border: none
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05)

.panel-fixed-md
  max-width: 615px
  margin: 0 auto

.panel-header
  line-height: rhythm(3)
  color: white

.panel-body
  +mobile-only
    padding: rhythm(0.25)
    .tl-dash &
      padding: rhythm(1)

.panel-body.panel-body-xs
  padding: rhythm(0.5)
  min-height: 112px

.panel-heading-light
  min-height: rhythm(2) - 1px
  padding: 0 rhythm(0.5)
  border-bottom: 1px solid $light-grey
  // TODO: Abstract this heading style
  h5
    text-transform: uppercase
    font-weight: normal
    letter-spacing: 0.05em
    line-height: rhythm(2) - 1

.panel-heading-no-border
  border-bottom: 0

// Equalise panel heights across a row
.equal-panels-md .panel-body
  @media (min-width: $screen-md-min)
    min-height: rhythm(8.25)
    &.no-footer
      min-height: 289px

+desktop-only
  .floating-panel
    overflow: hidden
    margin: 0 auto
    background-color: white
    border: none
    border-radius: 5px
    max-width: 45rem
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05)

// +mobile-only
//   margin: 0
