@import "../settings"

#spaces-index-loader.la-line-scale-pulse-out
  display: flex
  height: 100vh
  width: 100%
  align-items: center
  justify-content: center
  margin: -28px 0
  > div
    width: 12px
    height: 60px
.spaces-index
  margin: 14px -16px 0
  @media(max-width: $screen-xs-max)
    margin: 14px 0 0
  .spaces-header
    &.informal-spaces-header
      margin-top: 42px
    h1
      font-weight: bold
      font-size: 24px
      line-height: normal
      color: #323232
      margin-top: 20px
      margin-bottom: 16px
      .spaces-count
        display: inline-block
        height: 21px
        min-width: 21px
        border-radius: 14px
        background-color: #ffffff
        color: #c4c4c4
        font-size: 13px
        text-align: center
        position: relative
        margin-left: 12px
        padding: 3px 2px 0
        top: -2px
        margin-right: 30px
      .view-switch-wrap
        font-size: 14px
        font-weight: normal
        display: inline-flex
        position: relative
        top: 2px
        line-height: 18px
        transition: color .3s
        &.active
          color: #11A9FF
  .spaces-list-view
    .switch
      margin-right: 0
    .column-headers-wrap
      font-size: 14px
      border-bottom: 1px solid #E1E1E1
      padding-bottom: 17px
    .column-headers-wrap, .single-space
      display: flex
      margin-bottom: 25px
      .space-name-wrap
        width: 31.1%
        display: flex
        align-items: center
        .notification-count
          position: relative
          top: -2px
          right: initial
          font-size: 11px
          background-color: #E43F3F
          padding: 1px 7px
          left: 6px
          margin-right: 17px
        a
          display: flex
          align-items: center
          color: #323232
          text-decoration: none
          img
            width: 35px
            height: 35px
            border-radius: 100%
          .space-logo
            width: 35px
            height: 35px
            border-radius: 100%
            font-weight: bold
            display: inline-flex
            justify-content: center
            align-items: center
            font-size: 17px
          .space-name
            position: relative
            left: 13px
      .space-members-count
        width: 80px
        display: flex
        align-items: center
        justify-content: center
        a
          color: #323232
          text-decoration: none
      .space-lead
        margin-left: 8%
        width: 20.6%
        a
          color: #323232
          text-decoration: none
          .more-leads
            color: #8a8a8a
            font-size: 10px
            position: relative
            margin-left: 12px
            top: -1px
          &:not(:last-child)
            margin-bottom: 5px
        img
          width: 32px
          height: 32px
          border-radius: 100%
          margin-right: 7px
        .lead-caption
          font-size: 14px
      .space-score
        width: 80px
        display: flex
        justify-content: center
        align-items: center
        a, span
          color: #323232
          text-decoration: none
          position: relative
          cursor: pointer
          .custom-tooltip
            top: -60px
          .doughnut .number
            font-size: 13px
            top: 9px
            .trend-icon
              padding: 0
              bottom: initial
              font-size: 12px
              font-weight: normal
              position: absolute
              right: -14px
              left: initial
              .fa-caret-up
                color: #11A9FF
              .fa-caret-down
                color: #F5351B
      .space-actions
        margin-left: 5.6%
        width: 16%
      .space-fav
        width: 60px
        display: flex
        align-self: center
        align-items: center
        justify-content: center
    .single-space
      .space-members-count
        position: relative
        left: -10px
        font-size: 14px
      .space-lead
        display: flex
        flex-direction: column
        justify-content: center

  .spaces-hierarchical-view
    overflow-x: hidden
    .column-headers-wrap, .single-space-hierarchical
      display: flex
      .space-name-wrap
        width: 67%
        display: flex
        align-items: center
        .notification-count
          position: relative
          top: -2px
          right: initial
          font-size: 11px
          background-color: #E43F3F
          padding: 1px 7px
          left: 6px
        a
          display: flex
          align-items: center
          color: #323232
          text-decoration: none
          img
            width: 35px
            height: 35px
            border-radius: 100%
          .space-logo
            width: 35px
            height: 35px
            border-radius: 100%
            font-weight: bold
            display: inline-flex
            justify-content: center
            align-items: center
            font-size: 17px
          .space-name
            position: relative
            left: 13px
      .space-members-count
        width: 80px
        display: flex
        align-items: center
        justify-content: center
        a
          color: #323232
          text-decoration: none
      .space-score
        width: 21%
        display: flex
        justify-content: center
        align-items: center
        a, span
          color: #323232
          text-decoration: none
          position: relative
          cursor: pointer
          .custom-tooltip
            top: -60px
          .doughnut .number
            font-size: 13px
            top: 9px
            .trend-icon
              padding: 0
              bottom: initial
              font-size: 12px
              font-weight: normal
              position: absolute
              right: -14px
              left: initial
              .fa-caret-up
                color: #11A9FF
              .fa-caret-down
                color: #F5351B
      .space-fav
        width: 60px
        display: flex
        align-self: center
        align-items: center
        justify-content: center
    .column-headers-wrap
      margin: 0 0 12px
      font-size: 14px
      border-bottom: 1px solid #E1E1E1
      padding-bottom: 17px
    .space-tree-wrap
      position: relative
      &.has-children
        border-left: 1px solid #E1E1E1
      .single-space-hierarchical
        position: relative
        background: #F3F3F3
        padding: 12px 0
        margin-left: -1px
        .space-name-wrap
          position: relative
          left: -18px
        .space-members-count
          position: relative
          left: -10px
          font-size: 14px
        // .space-score

  .sorting-arrows
    display: inline-block
    position: relative
    height: 10px
    width: 10px
    margin-left: 6px
    &:before
      width: 6px
      height: 6px
      transform: rotateZ(-45deg)
      background: linear-gradient(to right top, rgba(214, 214, 214, 0) 50%, #D6D6D6 50%)
      content: ''
      top: 0
      left: 0
      position: absolute
    &:after
      width: 6px
      height: 6px
      transform: rotateZ(135deg)
      background: linear-gradient(to right top, rgba(214, 214, 214, 0) 50%, #D6D6D6 50%)
      content: ''
      top: 3px
      left: 0
      position: absolute

  .spaces-list-view, .spaces-hierarchical-view-wrap
    overflow-x: auto
    .spaces-group-wrap, .spaces-hierarchical-view
      min-width: 1100px
      overflow: hidden
      &.sort-name.asc .space-name-wrap .sorting-arrows:before, &.sort-name.desc .space-name-wrap .sorting-arrows:after,
      &.sort-members.asc .space-members-count .sorting-arrows:before, &.sort-members.desc .space-members-count .sorting-arrows:after,
      &.sort-lead.asc .space-lead .sorting-arrows:before, &.sort-lead.desc .space-lead .sorting-arrows:after,
      &.sort-score.asc .space-score .sorting-arrows:before, &.sort-score.desc .space-score .sorting-arrows:after,
      &.sort-fav.asc .space-fav .sorting-arrows:before, &.sort-fav.desc .space-fav .sorting-arrows:after
        background: linear-gradient(to right top, rgba(214, 214, 214, 0) 50%, #323232 50%)

  .loader-wrap
    margin-left: -80px
  .back-btn
    width: 15%
    min-width: 100px
  .search-input-wrap
    display: inline-flex
    width: 60%
    svg
      position: relative
      top: 20px
      right: 25px
      -ms-transform: translateY(-50%)
      transform: translateY(-50%)
    .search-input
      border-radius: 8px
      box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
      border: none
      height: 38px
      padding: 0 18px
      width: 100%

.space-logo-teams-new
  margin-left: 5px
  width: 30px
  height: 30px
  border-radius: 100%
  font-weight: bold
  display: inline-flex
  justify-content: center
  align-items: center
  font-size: 17px
.space-score-teams-new
  display: flex
  justify-content: center
  align-items: center
  a, span
    color: #323232
    text-decoration: none
    position: relative
    cursor: pointer
    .custom-tooltip
      top: -65px
      white-space: normal
      right: -15px
      left: auto
      transform: none

      &:after
        left: auto
        right: 5%
    .doughnut .number
      font-size: 13px
      top: 9px
      .trend-icon
        padding: 0
        bottom: initial
        font-size: 12px
        font-weight: normal
        position: absolute
        right: -14px
        left: initial
        .fa-caret-up
          color: #11A9FF
        .fa-caret-down
          color: #F5351B
