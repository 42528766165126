.heelix
  &.settings-page
    .btn-primary
      background: #11A9FF
      border-color: #11A9FF
      border-radius: 6px

    .no-gutter
      padding-left: 0
      padding-right: 0

    .no-gutter-left
      padding-left: 0

    .no-gutter-right
      padding-right: 0

    #company-settings-header
      #company-settings-title
        display: flex
        justify-content: flex-start
        align-content: center
        align-items: center
        flex-direction: row
        margin-bottom: 19px

        h1#settings-title
          font-style: normal
          font-weight: 900
          font-size: 24px
          line-height: 28px
          margin: 0 0 0 12px

    #company-settings-wrapper
      background: #FFFFFF
      border: 1px solid #E6E6E6
      box-sizing: border-box
      box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
      border-radius: 8px
      padding: 15px
      @media (min-width: 768px)
        padding: 30px

      hr.settings-divider
        border: 1px solid #E7E7E7
        margin: 36px 0

      h2
        margin-bottom: 18px
        font-style: normal
        font-weight: bold
        font-size: 18px
        line-height: 21px
        color: #323232

        &.header-blue
          color: #11A9FF

      a#export-shoutouts-link
        font-style: normal
        font-weight: normal
        font-size: 15px
        line-height: 18px

      p.section-description
        font-style: normal
        font-weight: normal
        font-size: 14px
        line-height: 150%
        margin-bottom: 24px

      h3.settings-header
        font-style: normal
        font-weight: bold
        font-size: 15px
        line-height: 150%
        margin: 0 0 12px 0
        color: #323232

      input.input-value-form, input.edit-value-form
        border: 1px solid #EDEDED
        box-sizing: border-box
        border-radius: 5px
        width: 100%
        max-width: 200px
        margin-right: 24px
        display: inline-block
        @media (min-width: 890px)
          max-width: 400px

      p.update-value-save
        padding: 11px 13px
        font-style: normal
        font-weight: normal
        font-size: 13px
        line-height: 15px
        margin: 0 0 0 34px
        cursor: pointer
        color: #11A9FF

      p.text-danger
        font-size: 13px
        line-height: 15px
        margin: 0

      p.update-value-cancel
        padding: 11px 13px
        font-style: normal
        font-weight: normal
        font-size: 13px
        line-height: 15px
        margin: 0
        cursor: pointer
        color: #FE483C

      button.add-value-form
        background: #11A9FF
        border-radius: 3px
        font-style: normal
        font-weight: bold
        font-size: 15px
        line-height: 18px
        padding: 11px 24px

      button.reset-value-form
        margin-left: 15px

      div.current-values-list
        margin-top: 30px

        div.current-value
          max-width: 575px
          display: flex
          flex-direction: row
          align-content: flex-start
          align-items: center
          justify-content: flex-start
          justify-items: flex-start

          &:not(:last-of-type)
            border-bottom: 1px solid #EDEDED

          div.current-value-name-wrapper
            width: 425px
            display: flex
            flex-direction: column
            align-content: flex-start
            align-items: flex-start
            justify-content: flex-start
            justify-items: flex-start

            p.current-value-name
              font-weight: normal
              font-size: 14px
              line-height: 16px
              color: #323232
              margin: 0
              white-space: nowrap
              overflow: hidden
              text-overflow: ellipsis
              max-width: 125px
              @media (min-width: 580px)
                max-width: 325px

            p.current-value-team-count
              margin: 0 0 0 10px
              color: #A6A6A6
              font-weight: normal
              font-size: 14px
              line-height: 16px
              white-space: nowrap

          div.current-value-actions
            display: flex
            flex-direction: row
            justify-items: center
            justify-content: center
            align-items: center
            align-content: space-around
            margin-left: auto

            p
              padding: 11px 13px
              font-style: normal
              font-weight: normal
              font-size: 13px
              line-height: 15px
              margin: 0
              cursor: pointer
              &.current-value-edit
                color: #11A9FF

              &.current-value-delete
                color: #FE483C