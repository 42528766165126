.switch
  display: inline-block
  width: 34px
  height: 18px
  position: relative
  background: rgba(196,196,196,1)
  border-radius: 10px
  margin-right: 12px
  transition: backkground .5s, color .5s
  cursor: pointer
  &:after
    border-radius: 100%
    background: white
    content: ''
    top: 2px
    left: 100%
    width: 14px
    height: 14px
    transition: transform .4s, left .4s
    transform: translateX(-16px)
    position: absolute
  &.turned-on
    background: #11A9FF
  &.turned-off
    &:after
      left: 0
      transform: translateX(2px)

  &.show-label
    width: 44px
    height: 20px
    text-align: right

    &:after
      right: 1px

    span
      font-size: 11px
      font-weight: bold
      line-height: 16px
      text-align: left
      color: #ffffff
      padding: 0 6px 2px
      display: inline-block
      vertical-align: middle

    &.turned-on
      text-align: left

    &:after
      top: 3px
      right: calc(100% - 1px)

  &.temp-disabled
    background: rgba(196,196,196,.6)
  &.full-disabled
    background: rgba(205,60,64,.4)
    cursor: initial
  .custom-tooltip
    right: -8px
    left: unset
    transform: none
    &:after
      right: 10px
      left: unset

