// DEPRECATED: See _vertical_rhythm.sass instead
.no-margin
  margin: 0

.no-margin-below
  margin-bottom: 0

.no-margin-above
  margin-top: 0

.vertical-margin
  margin-top: 20px
  margin-bottom: 20px

.margin-below
  margin-bottom: 20px

.vertical-padding
  padding-top: 20px
  padding-bottom: 20px

.double-padding
  padding: 20px
