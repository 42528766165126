// yes, this uses flex box but it's only used in the admin at the moment.

.stats-bar
  border: 1px solid $light-grey
  border-radius: 3px
  padding: rhythm(0.5) 10px
  margin: rhythm(1) 0
  background-color: $super-light-grey
  display: -webkit-flex
  display: flex
  -webkit-flex-flow: row wrap
  flex-direction: row
  justify-content: space-between
  align-content: flex-start

.stats-content
  border-right: 1px solid $light-grey
  padding: 0 10px
  text-align: left
  flex-grow: 1
  &:last-of-type
    border: none

.stats-heading
  font-size: 13px
  text-transform: uppercase
  line-height: rhythm(1)

.stats-figure
  font-size: 30px
  font-weight: bold
  line-height: rhythm(1)
