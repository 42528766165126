// Radios and checkboxes have 10px margins in bootstrap
.radio,
.checkbox
  margin: 0
  max-height: rhythm(1)
  position: static

.form-container
  max-width: 530px

.control-group
  margin: rhythm(1) 0

  .error
    input
      background-color: lighten($red, 34)
      border-color: $red

.help-inline
  color: $red

::-webkit-input-placeholder
  color: #adbcc6
  font-weight: 400

::-moz-placeholder
  color: #adbcc6
  font-weight: 400

// https://github.com/CSSLint/csslint/wiki/Disallow-unqualified-attribute-selectors
input[type='text'],
input[type='password'],
input[type='email'],
input[type='search'],
input[type='number']
  height: $input-height
  color: $input-color
  font-size: 15px
  border-radius: 2px
  border: none
  box-shadow: none
  border: 1px solid $light-grey

  &:focus,
  &.focus
    border: 1px solid $deep-blue
    box-shadow: none

.select2 input:focus
  border: none !important

.select2
  width: 100% !important

.input-group-btn button
  height: $input-height
  border: none

input.stretch
  max-width: 100%

.form-compact .form-group
  margin-bottom: rhythm(0.5)

input[type="number"]
  width: initial

textarea
  // @extend .u-size0
  min-height: rhythm(2)
  height: rhythm(4)
  padding: rhythm(0.25) - 1px rhythm(0.25)
  resize: none
  border-color: $pale-grey
  box-shadow: none
  &:focus,
  &:active
    border-color: $pale-grey
    box-shadow: none
    outline: none
  &.large
    @extend .u-size1

textarea.textarea-expanding
  margin: 0
  padding: rhythm(0.25) - 1px rhythm(0.25)
  border: 1px solid $light-grey
  width: 100%
  height: auto
  min-height: 0
  border-radius: 3px
  resize: none
  overflow-x: hidden
  box-shadow: none
  &:focus,
  &:active
    outline: 0
    box-shadow: none

textarea.borderless
  width: 100%
  border: none
  box-shadow: none
  transition: border-color linear .12s, height linear .12s
  border: 1px solid transparent
  height: 50px
  padding: 10px 0
  resize: none
  min-height: 130px

  &:focus
    box-shadow: none
    border: 1px solid transparent
    outline: none

  &.focused
    height: 130px

  & + p.subdued
    opacity: 0

  &:focus + p.subdued
    opacity: 1
    transition: opacity 0.4s ease

.js-users-list .form-group
  margin: 0
  + .help-block
    margin-top: 0

.input-group-addon
  border: none

.form-control:focus
  border-color: $field-border-color

// temp hack for bug in simple_form
.form-control.valid
  border-color: #ecf1f4
.valid.form-control:focus
  box-shadow: rgba(0, 0, 0, 0.0745098) 0px 1px 1px 0px inset, rgba(102, 175, 233, 0.6) 0px 0px 8px 0px
  border-color: $field-border-color
// temp hack for bug in simple_form END

.select2-container--default .select2-selection
  border-radius: $field-border-radius
  background-color: $field-background-color !important
  border: 1px solid $light-grey !important

.select2
  text-align: left

.btn-padded
  padding-left: 25px
  padding-right: 25px

.has-error span.help-block
  @extend .u-sizevr-2

.has-error + .help-block,
.payment-errors.errors
  color: $state-danger-text
  font-size: 14px
  line-height: rhythm(0.5)
  margin-top: - rhythm(0.5)
  margin-bottom: rhythm(0.5)

input.search
  padding-left: 30px

.image-input
  .square-box
    cursor: pointer
    position: relative
    padding: 10px
    border: 1px solid $light-grey

  .icon-edit
    position: absolute
    right: 0
    bottom: 0
    padding: 6px 8px
    color: rgba(0,0,0,0.4)
    font-size: 17px
    background-color: rgba(0,0,0,0.1)
    border-radius: 3px 0 0 0
    line-height: normal

.upload-label
  padding-bottom: 5px
