body.no-navigation
  margin-top: 70px
  background-color: #EFEFEF
  .frame-wrapper
    padding-left: 0

    .navbar
      display: none
    .page-wrapper
      padding-top: 0

.navbar-default
  min-height: 0
  border: none
  @media (max-width: $screen-xs-max)
    height: rhythm(2)
    &, .navbar-collapse
      border: 0
      background: white
    .navbar-collapse
      height: calc(100vh - 56px)!important
      max-height: none
      border-top: 1px solid $default-border-color
      margin-top: 2px
      &.collapsing
        transition: none
  .media-body
    width: auto
  .navbar-header
    .vibe-now-top
      margin-right: 40px

.navbar-ex1-collapse
  @media (max-width: $screen-xs-max)
    box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.08)

.navbar-nav > li > a
  padding-top: 10px
  padding-bottom: 10px

// subtle navbar background opacity to prevent apple products
// looking strange when scrolling.
// .navbar-default
//   background-color: rgba(255,255,255,0.97) !important

#primary-navbar
  // Add a transparent fade to the navbar profile section
  // and prevent wrapping to 2 lines
  @media (min-width: $screen-xs-max)
    &.navbar-collapse
      overflow: hidden
      white-space: nowrap
      padding-left: 215px
    .navbar-nav
      position: absolute
      right: rhythm(0.5)
      top: 0
      background-color: white
      &:before
        position: absolute
        left: -30px
        width: 30px
        height: $header-height
        background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.6) 40%, rgba(255,255,255,1))
        top: 0
        content: ""

#primary-navbar .navbar-nav > li
  &> a
    height: $header-height
    line-height: $header-height - 16
    font-size: 16px
    padding: 8px rhythm(1)
    color: $text-color
    cursor: pointer
    &.vibe-now-top
      font-size: 15px
      color: $new-blue
      background-color: $white
      img
        margin: 0 10px 5px 0
      &:focus
        background-color: $white !important
        color: $new-blue !important
  &.right-icon-separator
    a
      padding-right: 0px
      i
        padding-left: 28px
        font-size: 6px
        position: relative
        top: -3px

@media (max-width: $screen-xs-max)
  .frame-wrapper
    padding-left: 0 !important

.frame-wrapper
  padding-left: $sidebar-width
  transition: padding .4s
  padding-top: 60px
  &--navigation-collapsed
    padding-left: 63px

.page-wrapper
  padding: 10px
  @media (max-width: $screen-xs-max)
    padding: 0

.navbar-toggle
  margin-top: 14px
  margin-right: 0
  +phones-and-tablets
    & .icon-bar + .icon-bar
      margin-top: 3px

.navbar-brand
  height: $header-height
  padding: 8px 15px

.navbar-text
  +phones-and-tablets
    margin-top: rhythm(0.25)
    margin-bottom: 0

.navbar-text.pull-right
  +phones-and-tablets
    position: relative

.navbar
  background-color: white
  margin-bottom: 0

.top-nav > li
  line-height: $header-height - 20px
  padding-top: 0 !important
  padding-bottom: 0 !important
  .dropdown-menu
    padding: 14px
    max-width: none
    min-width: 180px
    margin-right: 14px
    border: $default-border-color 1px solid
    box-shadow: 0px 3px 2px 0px rgba(0,0,0,0.08)
    &.profile-nav > li > a
      padding: 0 14px
      line-height: 28px
      border-radius: 3px

.top-nav .open.dropdown > a
  background: transparent !important
  color: $blue !important

li.mini-logo
  padding: 24px 30px
  height: $new-header-height
  background-color: $dark-purple
  width: $sidebar-width
  line-height: 53px
  margin-left: 0
  &:hover
    background-color: $dark-purple
  img
    margin: 0 auto
    display: block
    max-height: rhythm(1.5)
    padding: 0
    &:hover
      background-color: $dark-purple
.navbar-default > .navbar-nav > li > a
  color: $white
.navbar-nav > li > a:focus
  color: $sidebar-default-link-hover-color !important
  background-color: $sidebar-default-link-hover-bg !important

.navbar-brand.pull-left.invite
  position: absolute
  left: $sidebar-width + 20

.nav-tabs
  font-size: 110%
  li.active
    font-weight: bold
  li a
    padding: 10px 20px

.dropdown-menu
  min-width: 150px
  padding: 0
  > li > a
    padding: 20px 20px

  .selected a
    color: $deep-blue
    &::before
      content: "\f00c"
      padding: 0 5px 5px 0
      font: normal normal normal 14px/1 FontAwesome
    &:hover
      color: $deep-blue

.dropdown-menu-sm
  > li > a
    padding: 5px 20px

// Navbar title
.nav-page-title
  padding: 0 rhythm(1) 0 8px
  line-height: $header-height
  float: left
  h1.nav-title
    font-size: 18px
    color: $dark-purple
    margin: 0
    padding: 0
    line-height: $header-height
    .navigation-link
      color: $new-blue
      font-size: 16px
      text-decoration: none
      font-weight: normal

  small, .help-popover
    padding-left: 0
    margin-left: rhythm(0.5)
    a
      position: relative
      top: -1px

  small
    display: block
    margin-left: 0
    @media (min-width: $screen-xs-max)
      margin-top: - 16px

  &-xs
    padding: 0
    margin: 0 0 rhythm(0.5) 0
    line-height: inherit
    h1
      line-height: rhythm(1)
    small
      display: block
      margin-left: 0

.nav-arrow-indicator,
.nav-arrow-indicator:after
  display: none
  border-width: 0 8px 8px
  content: ""
  margin-top: 2px
  bottom: 0
  margin-left: -9px
  position: absolute
  height: 0
  width: 0
  border-color: transparent
  border-bottom-color: $default-border-color
  left: 50%
  border-style: solid
  z-index: 9999

.nav-arrow-indicator:after
  border-width: 0 7px 7px
  bottom: -9px
  margin-left: -7px
  border-bottom-color: white
  content: ""

.open
  .nav-arrow-indicator,
  .nav-arrow-indicator:after
    display: block

.page-wrapper
  .nav-page-title-xs
    padding: 0
    margin: 0

body
  margin-top: 0 !important
  &.buzz
    .frame-wrapper
      border: none !important
  .ReactModal__Overlay--after-open
    z-index: 9999
    .slide-pane__content
      padding: 24px 0
      display: flex
      flex-direction: column
      .profile-nav
        flex-grow: 1
      .slack-button
        align-self: center
    .slide-pane__header
      background: #FFF
      .slide-pane__close
        display: none
      .slide-pane__title-wrapper
        .slide-pane__title
          position: relative
          color: #323232
          font-weight: bold
          font-size: 14px
          line-height: 16px
          padding: 10px 30px
          text-align: center
          &:before
            content: ''
            width: 18px
            height: 20px
            background: none
            position: absolute
            top: 8px
            left: 0
        .slide-pane__subtitle
          .close-notification
            position: absolute
            top: 18px
            right: 10px
            width: 26px
            height: 26px
            background: url('https://s3-ap-southeast-2.amazonaws.com/jv-assets/public/images/icons/notification_close.svg')
            cursor: pointer
            border: none
    .notification-list
      display: flex
      max-height: none
      min-width: 215px !important
      border-radius: 0
      ul
        height: auto
        margin-top: 40px
        overflow-y: hidden
        li.notification
          background: #FFF
          a
            border-bottom: 1px solid #F1F1F1
            display: table-cell
            height: 90px
            vertical-align: middle
            font-size: 13px !important
            line-height: 18px !important
            .media-body
              max-width: 171px
              span.greyed
                display: block
                margin-top: 8px
      .slide-pane__title-wrapper
        .slide-pane__title
          &:before
            background: url('https://s3-ap-southeast-2.amazonaws.com/jv-assets/public/images/icons/new_bell_2.svg')
    .notification-footer
      bottom: auto
      top: 0
      border-top: none
      border-bottom: 1px solid #ecf1f4
    .settings-list
      ul
        list-style: none
        padding: 0
        li
          text-align: center
          a, button
            color: #777
            text-decoration: none
            padding-top: 10px
            padding-bottom: 10px
            line-height: 22px
            font-size: 16px
            display: block
            cursor: pointer
            border: none
            background: none
            width: 100%
            outline: none
