@import ./settings

//new styles 

.modal.fade .modal-dialog
  transition: transform .3s ease-out !important

.fade.modal-backdrop.show
  opacity: .5

.modal-dialog
  transform: translate(0) !important

  &.win-modal
    min-width: 700px
    @media(max-width: 768px)
      min-width: 692px

.team-modal.modal-dialog
  top: 0 !important
  transform: translate(0, 0) !important

.intro-modal
  padding-left: 0px !important
  margin-left: -7px !important

  .modal-dialog-centered
    display: block !important
    min-height: 0 !important
  // margin-top: -230px
  @media(min-width: 768px)
    min-width: 715px

.fade.modal.show
  &::after
    opacity: .5

  &.fade
    opacity: 1

.create-discussion-tab
  .css-1ferdf4
    .css-1yeyut1
      position: relative

      .css-hceb3s
        position: absolute
        right: 0
        top: 0

.css-k7hjjo
  .modal-content
    &:first-child
      margin-top: 0

.heelix-modal.top
  .modal-dialog
    min-width: 600px
    @media(max-width: 768px)
      min-width: auto

//input

.r-select__control, .css-t3ipsp-control
  border: 1px solid #ededed !important
  padding: 0 5px !important
  height: auto !important
  min-height: 38px !important

  &:hover
    padding: 0 5px !important
    box-shadow: 0 1px 0 rgba(0,0,0,.06)

  .r-select__value-container
    padding: 0
    margin: 0
    height: auto !important

  .r-select__input-container
    padding: 0
    margin: 2px
    height: auto !important

    input
      height: auto !important
      &:focus
        height: auto !important
      &:active
        height: auto !important

  .r-select-menu
    background-color: #fff9e0!important

  .css-1u9des2-indicatorSeparator
    display: none

  .css-1xc3v61-indicatorContainer
    padding: 5px

  .css-15lsz6c-indicatorContainer
    padding: 5px

  .css-166bipr-Input
    padding: 0
    margin: 0 5px
    height: 38px
    input[type=text], input[type=password], input[type=email], input[type=search], input[type=number]
      height: 38px

.checkbox
  .select-all, input
    position: relative !important
    margin-left: 0 !important
    display: inline-flex
    vertical-align: text-top

    input
      margin-right: 5px

.css-1xygukg input[type='checkbox']
  left: 0 !important
  border-color: #11a9ff !important

.css-p8zdl9
  .btn
    border: none
    color: #ffffff
    margin-bottom: 12px
  .btn.css-11zv0m0
    background: rgb(17, 169, 255)
  .btn.css-ywcjic
    background: rgb(254, 72, 60)

//select

.react-datepicker__navigation-icon
  display: none

.css-1du0cai-OrganisationInsights
  .css-mvuoj4
    margin-left: 8px

.css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer
  svg
    display: none
  &::after
    content: ""
    position: absolute
    top: 50%
    right: 18px
    width: 8px
    height: 8px
    transition: 0.3s
    -webkit-transform: translateY(-6px) rotateZ(45deg) skew(10deg, 10deg)
    transform: translateY(-6px) rotateZ(45deg) skew(10deg, 10deg)
    background: linear-gradient(to bottom right, #FFFFFF, #FFFFFF 49.5%, #6A6A6A 50.5%)

// PostMedia.module.scss

.css-1p2yklx
  .space-score-teams-new
    display: flex
    justify-content: center
    align-items: center

  a, span
    color: #323232
    text-decoration: none
    position: relative
    cursor: pointer

    .custom-tooltip
      top: -65px
      white-space: normal
      right: -15px
      left: auto
      transform: none

      &:after
        left: auto
        right: 5%

    .doughnut .number
      font-size: 13px
      top: 9px

      .trend-icon
        padding: 0
        bottom: initial
        font-size: 12px
        font-weight: normal
        position: absolute
        right: -14px
        left: initial

        .fa-caret-up
          color: #11A9FF

        .fa-caret-down
          color: #F5351B

//carousel 
.post-tile-media
  .carousel
    position: relative !important

  // .carousel-item
  .carousel-item.active
    display: block !important

  .carousel-indicators
    left: 50% !important
    z-index: 15 !important
    width: 60% !important
    padding-left: 0 !important
    margin-left: -30% !important
    text-align: center !important
    list-style: none !important
    margin-top: 0 !important
    margin-bottom: 11px !important

  .carousel-indicators button
    padding: 0 !important
    position: relative !important
    width: 5px !important
    height: 5px !important
    background-color: hsla(0,0%,100%,.6) !important
    margin: 0 2px !important
    top: -2px !important
    -webkit-transition: height .1s,width .1s,top .1s,margin .1s !important
    transition: height .1s,width .1s,top .1s,margin .1s !important
    border: 1px solid transparent !important
    border-radius: 10px !important
    text-indent: -999px !important
    cursor: pointer !important

  .carousel-indicators button.active
    background-color: #fff !important
    width: 9px !important
    height: 9px !important
    top: -4px !important

  .carousel-control-prev,
  .carousel-control-next
    position: absolute !important
    top: 50% !important
    z-index: 1 !important
    display: flex !important
    align-items: center !important
    justify-content: center !important
    width: 40px !important
    height: 40px !important
    color: #fff !important
    background-color: transparent !important
    border-radius: 50% !important
    cursor: pointer !important
    transition: background-color 0.3s ease !important

  .carousel-control-prev:hover,
  .carousel-control-next:hover
    background-color: rgba(0, 0, 0, 0.8) !important

  .carousel-control-prev
    left: 10px !important

  .carousel-control-next
    right: 10px !important

  .control-right
    position: absolute
    width: 13px
    height: 13px
    top: 50%
    transform: translateY(-50%) rotate(-45deg) skew(-5deg,-5deg)
    border-bottom: 2px solid #fff
    border-right: 2px solid #fff

  .control-left
    transform: translateY(-50%) rotate(135deg) skew(-5deg,-5deg)
    left: 11px
    position: absolute
    width: 13px
    height: 13px
    top: 50%
    border-bottom: 2px solid #fff
    border-right: 2px solid #fff

  .carousel-inner>.item.active
    -webkit-transform: translateZ(0)
    transform: translateZ(0)
    left: 0

  .carousel-inner>.item
    -webkit-transition: -webkit-transform .6s ease-in-out
    transition: -webkit-transform .6s ease-in-out
    transition: transform .6s ease-in-out
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out
    -webkit-backface-visibility: hidden
    backface-visibility: hidden
    -webkit-perspective: 1000px
    perspective: 1000px

  .carousel-control-prev:hover, .carousel-control-next:hover
    background-color: transparent !important

.post-tile .styles-module_tooltip__mnnfp .styles-module_arrow__K0L3T
  // left: 65px !important

.post-tile-wrap .post-tile .react-tooltip
  z-index: 99

#css-1kak4ds-PerformanceMetric
  color: #000000 !important

.post-tile
  .styles-module_tooltip__mnnfp
    left: 318.062px
    top: -27px
    .styles-module_arrow__K0L3T
      height: 28px
      width: 26px
      z-index: -1
      // left: 65px !important
      bottom: -10px !important

#no-vibes-performance-tooltip
  top: 680.675px
  .styles-module_arrow__K0L3T
    bottom: -9px !important
    height: 16px
    width: 16px

#performance-icon-tooltip
  top: 680.675px
  .styles-module_arrow__K0L3T
    bottom: -9px !important
    height: 16px
    width: 16px

.member-element
  .lead-label
    background-color: #ffffff !important

.table>:not(caption)>*>*
  padding: 0
  background-color: transparent !important
  box-shadow: none

//check box
.post-tile, .css-7mnzga-GrowPost
  .checkbox
    position: relative
    height: 20px
    width: 20px
    // background-color: red
    border-radius: 2px
    margin-left: 10px

  input[type="checkbox"]
    visibility: hidden

    &:checked + label::after
      content: ""
      display: block
      position: absolute
      top: 4px
      left: 8px
      width: 6px
      height: 12px
      border: solid white
      border-width: 0 2px 2px 0
      transform: rotate(45deg)

  .boolean
    position: absolute
    top: 0
    left: 15px
    height: 20px
    width: 20px
    cursor: pointer

    &::before
      content: ""
      position: absolute
      top: 0
      left: 0
      height: 20px
      width: 20px
      border-radius: 3px

  .css-185c155 label:after
    width: 8px!important
    height: 13px!important
    margin-top: -2px!important
    margin-left: -17px!important
    padding-top: 3px!important
    font-size: 15px!important
    line-height: 25px

  .css-1tep4nv label:after
    width: 8px!important
    height: 13px!important
    margin-left: -2px!important
    padding-top: 3px!important
    font-size: 15px!important
    line-height: 25px
    top: 2px!important

// .post-tile-wrap .post-tile .post-tile-header .author-info + img.heart
//   margin-right: 10px

  // .checkbox.checkbox-primary
    margin-left: 5px
    max-height: 28px
    position: static
    outline: none !important
    border: none !important

  label
    display: block
    width: 20px !important
    height: 20px !important
    border: 2px solid rgb(17, 169, 255) !important
    border-radius: 3px !important
    top: -33px !important
    left: 5px

    input[type="checkbox"i]
      position: relative
      width: 20px
      height: 20px
      margin-right: 10px
      display: inline-flex
      vertical-align: super
      margin-left: 5px !important
      background-color: #8b1616

      input[type="checkbox"]
        position: absolute
        opacity: 0
        cursor: pointer

    &.checked
      background-color: transparent
      border: 2px solid $white

      &:after
        display: block

// .select-390532:checked + label::before
//   background-color: red !important
//   border-color: red !important

// .select-390532:checked + label::after
//   color: white !important

.chart-categories
  .css-13cymwt-control, .css-t3ipsp-control
    padding: 10px 0px !important
    max-height: initial !important
    outline: none
    &:hover
      border-color: #ededed !important

    .css-1dimb5e-singleValue
      text-transform: uppercase
      font-weight: 700
      font-size: 13px
      line-height: 18px
      height: 36px
      display: flex
      align-items: center
      padding-left: 20px

.css-nz1r55, .css-1uevvr8
  svg
    top: 0 !important

.css-urs9mm
  vertical-align: bottom !important

.css-1j1chy2
  background: linear-gradient(rgb(156 152 152) 0%, rgba(232, 232, 232, 0) 100%) !important



#profileDropdown
  li
    a
      padding: 0 14px
      line-height: 28px
      border-radius: 3px
  .btn-link
    text-decoration : none !important
    padding: 8px 28px !important
    outline: none

.dropdown-menu-admin
  padding: 14px
  max-width: none
  min-width: 180px
  margin-right: 14px
  border: 1px solid #ecf1f4
  box-shadow: 0 3px 2px 0 rgba(0,0,0,.08)

.primary-sidebar-bottom-label
  font-weight: 700
  height: 54px
  line-height: 38px
  font-size: 16px
  color: #464646
  cursor: pointer
  text-decoration: none
  
.primary-sidebar-bottom-label:hover
  color: #1297e3
  background: transparent
  
.dropdown-toggle-new
  padding: 8px 28px
  background: transparent
  border:0 !important

.wrapper-admin-nav
  display: flex
  justify-content: space-between
  align-items: center

.wrapper-admin-navbar-header
  width: 100%
  text-align: left !important
  .mini-logo
    margin:10px 14px

    
.que-flex
  display: inline-flex
  #post-questionnaire-btn
    margin-left: 10px

.setup-graph 
  .team-tile 
    .team-leads-list 
      .no-users-placeholder-wrap 
        .assign-lead 
          font-size: 14px !important

.setup-graph
  .r-select__menu-list
    height: 145px !important
    max-height: 145px !important




