@import "../settings"

.create-space-btn
  display: inline-flex
  max-height: 30px
  cursor: pointer
  text-align: center
  width: 25%
  min-width: 160px
  span
    display: inline-block
    background: $new-blue
    border-radius: 50%
    color: #F3F3F3
    font-weight: bold
    font-size: 25px
    line-height: 30px
    height: 30px
    width: 30px
    margin-right: 10px
    text-align: center
  h4
    text-decoration: none
    font-weight: bold
    color: $new-blue
    font-size: 14px
    line-height: 13px
    width: 100%
    text-align: right
    margin: 0

.informal-space-modal, .assign-lead-modal
  width: auto
  @media (max-width: 479px)
    width: 100%
  @media (min-width: 768px)
    width: 692px
  #space-modal-loader
    display: flex
    justify-content: center
    align-items: center
    height: 0
    min-height: unset
    padding: 380px 0
    width: 60px
    & > div
      height: 50px
      width: 16px
  .modal-content
    margin-top: 54px
    max-width: 800px
    width: 692px
    transform: translateX(-50%)
    position: relative
    left: 50%
    .close-cross
      position: absolute
      width: 30px
      height: 30px
      top: 15px
      right: 15px
      background: #11A9FF
      border-radius: 100%
      cursor: pointer
      z-index: 1
      &:before, &:after
        display: block
        content: ''
        height: 2px
        width: 16px
        position: absolute
        left: 50%
        top: 50%
        background: white
      &:before
        transform: translate(-50%, -50%) rotate(-45deg)
      &:after
        transform: translate(-50%, -50%) rotate(45deg)
      @media (min-width: 768px)
        top: -15px
        right: -15px
    .modal-inner-wrap
      width: 100%
      padding: 30px 30px 60px
      color: #323232
      h1
        font-family: Roboto
        font-size: 24px
        font-weight: bold
        margin-bottom: 16px
      h2
        font-size: 16px
        font-family: Roboto
        font-weight: 500
        color: #323232
        margin: 10px 0
        span
          color: #E43F3F
          margin-left: 5px
      input, textarea
        width: 100%
        margin-bottom: 10px
        padding: 9px 13px
        font-size: 13px
        line-height: 16px
        border-radius: 3px
        border: 1px solid #D9D9D9
        &::placeholder
          color: #8B8A96
        &[type='button']
          background: #11A9FF
          border-radius: 3px
          width: 171px
          text-align: center
          color: white
          font-size: 16px
          font-weight: bold
          padding: 13px
          outline: none
          border: none
          margin-top: 22px
        &[type='file']
          display: none
      span.error-message
        font-family: Roboto
        opacity: 0
        transition: opacity .3s
        color: #cd3c40
        font-weight: bold
        display: block
        &.shown
          opacity: 1
      .invite-users
        margin-bottom: 5px
        .Select-arrow-zone
          display: none
        .Select-input
          input
            border: none
            padding-left: 0
      .assign-lead
        .Select-input
          input
            border: none
            padding-left: 0
      .switch-private
        font-size: 14px
        font-weight: normal
        display: inline-flex
        position: relative
        top: 2px
        line-height: 18px
        transition: color .3s
        margin-top: 10px
        margin-bottom: 10px
        &.active
          span.switch
            color: #11A9FF
      span.info-box
        font-size: 12px
        color: #8B8A96
      span.profile-picture
        .space-logo, img
          width: 50px
          height: 50px
          border-radius: 100%
          font-weight: bold
          display: inline-flex
          justify-content: center
          align-items: center
          font-size: 25px
          margin-bottom: 15px
          margin-right: 10px
        i
          margin-right: 8px
          color: #11A9FF
        label
          color: #11A9FF
          font-weight: normal
          cursor: pointer
    div.nopadding
      padding: 0
      margin: 0
    .create-space
      width: 100%
    .row.table
      display: table
      width: 100%
    .space-members
      padding: 20px 23px 0 23px
      display: table-cell
      background-color: #F3F3F3
      float: none
      vertical-align: top
      h2
        font-size: 16px
        font-family: Roboto
        font-weight: bold
        color: #6A6A6A
        margin: 10px 0
        padding-bottom: 13px
      .member-element
        margin-bottom: 10px
        color: #6A6A6A
        font-size: 14px
        .lead-label
          font-size: 8px
          background-color: #FFFFFF
          padding: 2px 5px
          display: inline
          vertical-align: middle
          margin-left: 7px
          text-transform: uppercase
          color: #11A9FF
          font-weight: 500
          border-radius: 6px
        img
          width: 30px
          height: 30px
          margin-right: 10px
          border-radius: 50%
        .cross
          cursor: pointer
          display: inline-block
          height: 27px
          width: 27px
          transform: translateY(-50%)
          &:before, &:after
            border-radius: 2px
            width: 15px
            height: 2px
            background: #C4C4C4
            content: ''
            position: absolute
            top: 100%
            left: 100%
          &:before
            transform: translate(-50%, -50%) rotate(-45deg)
          &:after
            transform: translate(-50%, -50%) rotate(45deg)