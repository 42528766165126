.fade-in
  animation: fade-in 0.7s

@keyframes fade-in
  0%
    opacity: 0
  100%
    opacity: 1

.highlight-fade-out
  animation: fade-out 3s

@keyframes fade-out
  0%
    opacity: 0.75
  75%
    opacity: 0.75
  100%
    opacity: 0

.loader-inner div
  background: $blue
