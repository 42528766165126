.tag
  color: white
  border-radius: 3px
  margin-right: 10px
  padding: 5px 15px
  font-size: 14px
  font-weight: 600
  white-space: nowrap
  display: inline-block
  @extend .ellipsis

  a
    &:hover
      color: #000

  &.inline
    margin-top: -1px
    font-size: 10px
    padding-left: 7px
    padding-right: 7px
    text-transform: uppercase
    letter-spacing: 0.1em
    display: inline-block
    font-weight: bold
    border-radius: 3px
    max-width: 100%

  &.buzz
    background: $deep-blue
    padding: 3px
    font-size: 8px
    line-height: 8px
    margin-right: 0
  &.fix
    background: $amber

.tag-group
  padding: 10px
  background-color: $light-grey
  border-radius: 5px
  padding-bottom: 5px
  margin-bottom: 20px
  overflow: hidden
  white-space: nowrap
