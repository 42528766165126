// DO NOT USE .circle-image
// note: deprecated in favor of bootstraps .img-rounded

.circle-image
  img
    border-radius: 100%
    width: 100%
    height: 100%

  &.pull-left
    margin-right: 10px

.img-circle.size4
  height: rhythm(4)
  width: rhythm(4)

.circle-image.xs
  width: 20px
  height: 20px

.circle-image.sm
  width: 50px
  height: 50px

.circle-image.md
  width: 100px
  height: 100px

.circle-image.lg
  width: 140px
  height: 140px

.circle-image.centered
  margin-left: auto
  margin-right: auto

.settings-mobile-icon
  padding-top: 8px

// image sizes
.img-mobile-profile
  width: 42px
  height: 42px

.img-xs
  width: 35px
  height: 35px

.img-sm
  width: 50px
  height: 50px

.img-md
  width: 90px
  height: 90px

.img-vr-md
  width: rhythm(1.5)
  height: rhythm(1.5)

.img-lg
  width: 130px
  height: 130px

.img-border
  border: 3px solid $yellow

.img-arrow
  width: 22px
  height: 23px
  margin: 0 11px 5px 0

.img-header
  width: 56px
  height: 56px

.img-icon-nav
  width: 28px
  height: 28px

.user-icon-nav
  width: 32px
  height: 32px

#img-wrapper
  img
    max-width: 200px