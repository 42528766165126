.date-range-wrap
  flex-shrink: 0
  margin-left: auto
  display: flex
  align-items: center
  .date-range
    height: 40px
    width: 190px
    background: #FFFFFF
    border: 1px solid #E6E6E6
    border-radius: 4px
    position: relative
    display: inline-block
    line-height: 40px
    font-size: 14px
    padding-left: 15px
    user-select: none
    &:after
      content: ''
      position: absolute
      top: 50%
      right: 18px
      width: 8px
      height: 8px
      transition: .3s
      transform: translateY(-6px) rotateZ(45deg) skew(10deg, 10deg)
      background: linear-gradient(to bottom right, #FFFFFF, #FFFFFF 49.5%, #6A6A6A 50.5%)
  .range-caption
    font-size: 14px
    font-weight: bold
    margin-right: 19px
  .date-dropdown
    z-index: 10
    padding-left: 15px
    padding-right: 15px
    position: absolute
    top: calc(100% + 3px)
    background: #FFFFFF
    border: 1px solid #E6E6E6
    box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
    width: 100%
    border-radius: 3px
    left: 0
    transition: .3s
    opacity: 0
    &.mounted
      opacity: 1
    a
      display: block
      color: inherit
      text-decoration: none
      &:not(:last-child)
        border-bottom: 1px solid #E6E6E6
    div
      cursor: pointer

  .custom-range
    position: absolute
    top: calc(100% + 3px)
    z-index: 10
    right: 0
    line-height: initial
    padding: 0 10px 10px
    border: 1px solid #E6E6E6
    box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
    border-radius: 3px
    opacity: 0
    transition: .3s opacity
    &.mounted
      opacity: 1
    .rdrDateDisplay
      margin: 0 -10px



