@import "../settings"

.page-wrapper.company-structure-alter-wrapper
  max-width: unset
  padding: 28px 24px
.dragging *, .dragging
  cursor: grabbing !important
  user-select: none
#company-structure
  .la-line-scale-pulse-out
    display: flex
    height: 100vh
    width: 100%
    align-items: center
    justify-content: center
    margin: -28px 0
    > div
      width: 12px
      height: 60px
  #company-view
    &.search-applied .view-graph
      .team-tile
        &:before, &:after
          background: #46B700
      .team-tree-wrap .team-subtree-wrap .vertical-branch
        background: #46B700
    padding-top: 14px
    .problems-flash
      padding: 12px 23px 6px
      position: relative
      top: -42px
      margin: 0 -24px -12px
      overflow: hidden
      background: #FFF3C7
      border: 1px solid #FFE68C
      border-radius: 3px
      font-size: 14px
      svg
        margin-right: 14px
      .text-wrap
        position: relative
        top: -4px
        color: #575563
        .fix-wrap
          color: #11A9FF
          user-select: none
          cursor: pointer
      .switch-wrap
        float: right
        user-select: none
        .switch-text-wrap
          display: inline-block
          position: relative
          top: -4px
          color: #6A6A6A
          width: 100px
    .company-view-header-wrap
      display: flex
      align-items: center
      width: 100%
      h1
        font-size: 30px
        margin-bottom: 4px
      .search-wrap
        flex-grow: 1
        position: relative
        margin-left: 49px
        margin-top: -6px
        input
          width: 100%
          box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
          border-radius: 8px
          border: none
          outline: none
          padding: 10px 16px 9px
          font-size: 14px
          &::placeholder
            color: #6A6A6A
            opacity: .4
        svg
          position: absolute
          right: 15px
          top: 50%
          transform: translateY(-50%)
      //.switch-wrap
      //  display: flex
      //  align-items: center
      //  font-size: 14px
      //  color: #6A6A6A
      //  margin-top: -2px
      //  margin-right: 41px
      //  margin-left: 48px
      //  transition: .3s color
      //  cursor: pointer
      //  &:hover
      //    color: #909090
      //  .switch
      //    margin-top: -2px
      .mode-change-wrap
        display: flex
        color: #6A6A6A
        font-size: 14px
        margin-right: 15px
        align-items: center
        margin-top: -2px
        transition: .3s color
        cursor: pointer
        margin-left: 48px
        &:hover
          color: #909090
        svg
          margin-right: 12px

    .view-graph
      padding-top: 36px
      white-space: nowrap
      overflow: auto
      overflow-y: hidden
      .team-tree-wrap
        margin-left: 48px
        display: inline-block
        vertical-align: top
        .team-subtree-wrap
          position: relative
          .vertical-branch
            content: ''
            position: absolute
            left: -26px
            top: 34px
            width: 2px
            height: 100%
            background: #EAEAEA
            border-radius: 1px
            transition: background .2s
          &.hide-vertical-branch > .vertical-branch
            display: none
      .team-tile
        cursor: pointer
        white-space: initial
        border-radius: 8px
        padding: 18px 18px 20px
        background: #ffffff
        box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
        display: inline-block
        margin-bottom: 16px
        width: 300px
        position: relative
        border: 2px solid rgba(255, 188, 87, 0)
        transition: border .2s, width .2s

        &.hide-horizontal-branch:after
          display: none
        &:before
          position: absolute
          content: ''
          top: 32px
          left: -27px
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
          transition: background .2s
        &:after
          position: absolute
          content: ''
          top: 32px
          right: -27px
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
          transition: background .2s
        .unfold-button
          position: absolute
          right: -2px
          transform: translateX(50%)
          background: #11A9FF
          color: white
          font-size: 14px
          font-weight: bold
          z-index: 2
          top: 21px
          height: 24px
          border-radius: 20px
          transition: width .3s, opacity .3s
          cursor: pointer
          opacity: 1
          &:before, &:after
            position: absolute
            content: ''
            top: 50%
            height: 2px
            width: 7px
            border-radius: 1px
            background: white
            left: 7px
            transition: left .3s, transform .3s
          &:before
            transform-origin: right bottom
            transform: translateY(-50%) rotateZ(40deg)
          &:after
            transform-origin: right top
            transform: translateY(-50%) rotateZ(-40deg)
          span
            display: block
            padding: 0 10px 0 20px
            line-height: 24px
            transition: padding .3s, opacity .3s .3s
          &.unfolded
            transition: width .3s .3s, opacity .3s
            width: 24px
            span
              opacity: 0
              padding: 0
              transition: opacity .3s, padding .3s .3s
            &:before, &:after
              left: 50%
              transition: left .3s .3s, transform .3s .3s
              transform: translate(-50%, -50%) rotateZ(0)
          &.nonfunctional
            transition: opacity .3s
            opacity: 0
        .team-tile-header
          display: flex
          align-items: center
          padding-right: 10px
          .invite-checkbox
            margin-right: 14px
          .team-letters
            font-size: 15px
            border-radius: 100%
            border: 1px solid
            font-weight: bold
            height: 36px
            width: 36px
            text-align: center
            line-height: 34px
            margin-right: 12px
            flex-shrink: 0
          img
            margin-right: 12px
            height: 36px
            width: 36px
            border-radius: 100%
          span
            font-weight: bold
            font-size: 18px
        .team-info-wrap
          display: flex
          align-items: center
          margin-left: 48px
          font-size: 14px
          color: #6A6A6A
          span
            height: 4px
            width: 4px
            background: #C4C4C4
            border-radius: 100%
            display: inline-block
            margin: 0 12px
        .leads-wrap
          background: #F8F8F8
          border-bottom-right-radius: 8px
          border-bottom-left-radius: 8px
          margin: 18px -20px -22px
          padding: 13px 20px 15px
          position: relative
          transition: margin .0s .2s, padding .0s .2s
          h3
            color: #11A9FF
            font-size: 12px
            font-weight: bold
            user-select: none
            opacity: 0
            margin: 0
            transition: .2s .2s height, .2s opacity, .2s .2s margin
            &:not(:first-child)
              margin-top: -8px
            &.mounted
              margin-bottom: 8px
              transition: .2s height, .2s .2s opacity, .2s margin
              opacity: 1
              margin-top: 0
          &.empty
            padding: 0
          .lead-tile
            display: block
            text-decoration: none
            &:not(:last-child)
              margin-bottom: 8px
            img
              width: 26px
              height: 26px
              margin-right: 8px
              border-radius: 100%
            span
              color: #6A6A6A
              font-size: 14px
              position: relative
              top: 2px
          .searched-user-tile
            display: block
            text-decoration: none
            margin-bottom: 8px
            img
              width: 26px
              height: 26px
              margin-right: 8px
              border-radius: 100%
            span
              color: #6A6A6A
              font-size: 14px
              position: relative
              top: 2px
              overflow: hidden
              text-overflow: ellipsis
              max-width: 220px
              display: inline-block
              vertical-align: middle
        .issues-wrap
          .issue
            opacity: 0
            margin: 0
            transition: .2s .2s height, .2s opacity, .2s .2s margin
            display: flex
            align-items: center
            color: #F5B049
            font-size: 14px
            &.mounted
              margin-left: 23px
              transition: .2s height, .2s .2s opacity, .2s margin
              opacity: 1
              &:first-child
                margin-top: 12px
              &:not(:last-child)
                margin-bottom: 4px
            svg
              margin-right: 7px
        &.show-issues
          border: 2px solid #FFBC57
          .leads-wrap
            transition: none
            margin: 18px -18px -20px
            padding: 13px 18px 13px
            &.empty
              padding: 0
        &.search-match
          border: 2px solid #46B700
          .leads-wrap
            transition: none
            margin: 18px -18px -20px
            padding: 13px 18px 13px
            &.empty
              padding: 0





  #company-setup
    padding-top: 13px
    .company-setup-header
      display: flex
      h1
        font-size: 30px
        margin-bottom: 10px
      h2
        font-size: 15px
        color: #8B8A96
        font-weight: normal
        display: flex
        align-items: center
        cursor: pointer
        &:before
          content: '?'
          border-radius: 100%
          display: inline-block
          height: 16px
          width: 16px
          background: #8B8A96
          color: #F3F3F3
          line-height: 16px
          text-align: center
          font-size: 13px
          font-weight: bold
          margin-right: 9px
      & > div:last-child
        flex-grow: 1
        display: flex
        padding: 27px 0 27px 120px
        max-width: 766px
        align-items: center
        .step-circle
          position: relative
          width: 28px
          height: 28px
          background: #FFFFFF
          color: #C4C4C4
          font-weight: bold
          border-radius: 100%
          line-height: 28px
          text-align: center
          font-size: 15px
          transition: color .3s, background .3s
          position: relative
          &:before
            color: #8B8A96
            position: absolute
            top: -27px
            transform: translateX(-50%)
            white-space: nowrap
            line-height: initial
            font-size: 13px
            left: 14px
            transition: color .3s
          &.first-step:before
            content: 'Setup users & structure'
          &.second-step:before
            content: 'Send invites'
          &.active
            transition: color .3s 1.7s, background .3s 1.7s
            background: #11A9FF
            color: #FFFFFF
            &:before
              color: #11A9FF
              transition: color .3s 1.7s, background .3s 1.7s
          .done-overlay
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            border-radius: 100%
            opacity: 0
            background: #11A9FF
            transition: opacity .3s 1.7s
            &:before, &:after
              background: white
              content: ''
              position: absolute
              border-radius: 1px
              width: 3px
            &:before
              height: 0
              left: 7px
              transform: rotateZ(-45deg)
              top: 14px
              transform-origin: top left
              transition: height .3s 1.4s
            &:after
              height: 0
              left: 13px
              top: 19px
              transform: rotateZ(225deg)
              transform-origin: top left
              transition: height .3s 1.1s
          &.done .done-overlay
            opacity: 1
            transition: opacity .3s
            &:before
              transition: height .3s .3s
              height: 8px
            &:after
              transition: height .3s .6s
              height: 12px
        .step-line
          flex-grow: 1
          height: 3px
          margin: 0 10px
          background: #E4E4E4
          border-radius: 2px
          &:before
            content: ''
            display: block
            width: 0
            background: #11A9FF
            height: 100%
            transition: width .8s .3s
            border-radius: 2px
          &.filled:before
            transition: width .8s .9s
            width: 100%


    .setup-graph
      padding-top: 8px
      white-space: nowrap
      overflow: auto
      margin-right: -24px
      font-size: 0
      height: calc(100vh - 350px)
      &.setup-graph-onboarding
        overflow: hidden
      &.first-subteam
        .team-tile#main-team:after
          top: 24px

      .guides-wrap
        width: 100%
        position: relative
        color: #8B8A96
        white-space: initial
        .first-guide
          top: 29px
          left: 23px
          width: 100%
          text-align: right
          position: absolute
          opacity: 0
          transition: .3s opacity
          svg
            position: absolute
            top: -50px
            right: -48px
          &.mounted
            opacity: 1
            transition: .3s .6s opacity
        .second-guide
          left: -17px
          top: 36px
          width: 320px
          position: absolute
          opacity: 0
          transition: .3s opacity
          line-height: 20px
          svg
            position: absolute
            right: -40px
            top: -79px
          &.mounted
            opacity: 1
            transition: .3s .6s opacity
        .third-guide
          top: 29px
          left: 23px
          width: 100%
          text-align: right
          position: absolute
          opacity: 0
          transition: .3s opacity
          svg
            position: absolute
            top: -50px
            right: -48px
          &.mounted
            opacity: 1
            transition: .3s .6s opacity
      .dnd-guide
        color: #8B8A96
        font-size: 14px
        line-height: 20px
        white-space: initial
        position: relative
        width: 300px
        left: 1px
        margin-top: 5px
        height: 104px
        transition: height .3s .3s
        .vertical-branch
          content: ''
          position: absolute
          left: -27px
          top: 27px
          width: 2px
          height: calc(100% - 1px)
          background: #EAEAEA
          border-radius: 1px
        svg
          position: absolute
          top: -75px
          right: -54px
        span, svg
          opacity: 0
          transition: .3s opacity
        &.mounted
          .vertical-branch
            transition: .3s height
          span, svg
            opacity: 1
            transition: .3s .3s opacity

      .team-tree-wrap
        margin-left: 48px
        display: inline-block
        vertical-align: top
        .team-subtree-wrap
          position: relative
          .vertical-branch
            content: ''
            position: absolute
            left: -26px
            top: 32px
            width: 2px
            height: 100%
            background: #EAEAEA
            border-radius: 1px
          &:nth-last-child(2) > .vertical-branch
            height: calc(100% - 6px)
          &.hide-vertical-branch > .vertical-branch
            display: none
          &.set-equal-branch-heights > .vertical-branch
            height: 100%

      #main-team-wrap
        width: 330px
        display: inline-block
      .team-tile-background
        display: inline-block
        position: relative
        background: #EEEEEE
        border-radius: 8px
        margin-bottom: 20px
        &:before
          position: absolute
          content: ''
          top: 32px
          left: -25px
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
        &:after
          position: absolute
          content: ''
          top: 32px
          right: -25px
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
        &.hide-horizontal-branch:after
          display: none
      .drag-capture-area
        height: 100%
        width: 100%
        position: absolute
        top: 0
        left: 0
        z-index: 2
        &.active
          z-index: 6
      .team-tile
        white-space: initial
        border-radius: 8px
        padding: 18px 18px 20px
        background: #ffffff
        box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
        width: 300px
        position: relative
        transition: opacity .2s cubic-bezier(.64,.3,.85,.72), top .1s, left .1s, transform .3s
        cursor: grab
        &.remove-grab-cursor
          cursor: initial
        &.stop-position-transition
          transition: opacity .2s cubic-bezier(.64,.3,.85,.72)
        &.dragged
          position: fixed
          z-index: 5
        &#main-team
          cursor: initial
          padding: 22px 20px 20px
          width: 330px
          background: #E3E3E3
          box-shadow: none
          z-index: 5
          position: fixed
          p
            white-space: normal
          .team-tile-header
            margin-bottom: 11px
            .custom-checkbox
              opacity: 0
              display: inline-block
              border: 2px solid #C4C4C4
              border-radius: 3px
              margin-left: auto
              width: 18px
              height: 18px
              cursor: initial
              transition: .2s opacity, .2s background
              position: relative
              &.visible
                opacity: 1
              &.selected
                background: #C4C4C4
              &:before, &:after
                background: #E3E3E3
                content: ''
                position: absolute
                border-radius: 2px
                width: 2px
              &:before
                height: 7px
                left: 3px
                transform: rotateZ(-50deg)
                top: 5px
              &:after
                height: 9px
                left: 8px
                top: 2px
                transform: rotateZ(44deg)
            span
              font-weight: bold
              font-size: 20px

          p
            font-size: 13px
            line-height: 18px
            margin-bottom: 0
          .main-team-members-list-wrap
            width: 100%
            overflow: hidden
            position: relative
            .custom-scrollbar
              opacity: 0
              transition: .3s opacity
              z-index: 5
            &:hover .custom-scrollbar
              opacity: .7
            .main-team-members-list
              max-height: calc(100vh - 600px)
              margin-right: -20px
              padding-right: 7px
              overflow-y: scroll
              padding-bottom: 2px
          .remove-button
            background: white
            border: 1px solid #E6E6E6
            border-radius: 22px
            font-size: 13px
            cursor: default
            color: #F5351B
            width: 80px
            margin-bottom: 18px
            height: 0
            margin-top: 0
            opacity: 0
            transition: height .2s .2s, opacity .2s, margin-top .2s .2s
            padding: 4px 16px
            &.visible
              height: 28px
              margin-top: 8px
              opacity: 1
              cursor: pointer
              transition: height .2s, opacity .2s .2s, margin-top .2s


          #add-users-buttons-wrapper
            display: flex
            justify-content: space-between
            #add-users-button, #add-csv-users-button
              padding: 11px 15px
              width: 135px
              background: #ffffff
              border-radius: 8px
              color: #11A9FF
              display: flex
              align-items: center
              font-size: 14px
              cursor: pointer
              margin-top: 17px
              img
                margin-right: 10px
                width: 20px
                height: 20px
            &:before
              content: unset

          &:after
            position: absolute
            content: ''
            top: 32px
            right: -25px
            background: #EAEAEA
            border-radius: 1px
            height: 2px
            width: 25px
          &.hide-horizontal-branch:after
            display: none
        .invite-checkbox
          display: inline-block
          border: 2px solid #C4C4C4
          border-radius: 3px
          margin-left: auto
          width: 18px
          height: 18px
          cursor: initial
          transition: .2s opacity, .2s background, .2s border
          position: relative
          &.selected
            background: #C4C4C4
          &.disabled
            opacity: .8
            cursor: initial
          &.already-invited
            background: rgba(0,0,0,0)
            border: 2px solid rgba(0,0,0,0)
            &:before, &:after
              background: #46B700
          &:before, &:after
            background: #ffffff
            content: ''
            position: absolute
            border-radius: 2px
            width: 2px
          &:before
            height: 7px
            left: 3px
            transform: rotateZ(-50deg)
            top: 5px
          &:after
            height: 9px
            left: 8px
            top: 2px
            transform: rotateZ(44deg)
        .team-tile-dropdown-button
          width: 14px
          height: 14px
          position: absolute
          right: 12px
          top: 7px
          display: flex
          justify-content: space-between
          align-items: center
          cursor: pointer
          user-select: none
          &:before, &:after
            width: 5px
            height: 5px
            background: #DADADA
            border-radius: 100%
            content: ''
          .team-tile-dropdown
            position: absolute
            width: 182px
            border: 1px solid #E7E7E7
            box-shadow: 1px 4px 8px rgba(120, 120, 120, 0.35)
            border-radius: 8px
            top: 31px
            left: 17%
            transform: translateX(-50%)
            background: white
            padding: 6px 0
            z-index: 5
            & > div
              padding: 9px 28px 8px
              font-size: 14px
              color: #11A9FF
              transition: color .3s
              &:hover
                color: #0065e2
              &:not(:last-child)
                border-bottom: 1px solid #F3F3F3
            &:before
              width: 16px
              height: 16px
              position: absolute
              content: ''
              left: 50%
              top: 0
              transform: translate(-3px, -50%) rotate(-45deg)
              border-top: 1px solid #E7E7E7
              border-right: 1px solid #E7E7E7
              background: white


        .team-tile-header
          display: flex
          align-items: center
          .invite-checkbox
            margin-right: 14px
          .team-letters
            font-size: 15px
            border-radius: 100%
            border: 1px solid
            font-weight: bold
            height: 36px
            width: 36px
            text-align: center
            line-height: 34px
            margin-right: 12px
            flex-shrink: 0
          span
            font-weight: bold
            font-size: 18px
            margin-right: 15px

        .team-info-wrap
          display: flex
          align-items: center
          margin-left: 48px
          font-size: 14px
          span
            height: 4px
            width: 4px
            background: #C4C4C4
            border-radius: 100%
            display: inline-block
            margin: 0 12px

        .team-leads-list
          .no-users-placeholder-wrap
            .assign-lead
              margin: 18px 0
              z-index: 4
              display: block

              .Select-menu
                overflow-y: auto
                max-height: 190px
              .Select-menu-outer
                border-color: #E6E6E6
                font-weight: normal
                font-size: 13px
                line-height: 18px
                max-height: 190px
              .Select-option
                background-color: #F3F3F3
                color: #323232
                display: block
                padding: 10px 16px
                font-size: 14px
                word-wrap: break-word
              .Select-control
                height: 36px
                background-color: #F3F3F3
                border-radius: 5px
                border: none
                box-sizing: border-box
                font-size: 14px
                line-height: 16px
                table-layout: fixed

                .Select-multi-value-wrapper
                  height: 42px

                .Select-placeholder
                  color: #7B7B7B
                  padding: 4px 16px

                .Select-value
                  color: #979797
                  padding: 4px 16px

                .Select-input
                  color: #979797
                  height: 100%
                  padding: 4px 16px
                  overflow: hidden
                  max-width: calc(100% - 20px)

                  #type-input
                    height: 100%
                    padding: 0

                    &:focus
                      outline: none
        .team-members-list
          background: #F8F8F8
          border-bottom-right-radius: 8px
          border-bottom-left-radius: 8px
          margin: 10px -18px -20px
          padding: 11px
          position: relative
          .user-list-buttons-wrap
            display: flex
            opacity: 0
            height: 0
            padding: 0
            transition: height .2s .2s, padding .2s .2s, opacity .2s
            z-index: 3
            position: relative
            overflow: hidden
            &.visible
              opacity: 1
              height: 45px
              padding: 3px 0 14px
              transition: height .2s, padding .2s, opacity .2s .2s
            & > div
              background: white
              border: 1px solid #E6E6E6
              border-radius: 22px
              font-size: 13px
              padding: 4px 16px
              cursor: pointer
              &.make-lead-button
                color: #11A9FF
                margin-right: 10px
              &.remove-button
                color: #F5351B
          .no-users-placeholder-wrap
            .no-users-placeholder
              opacity: 1
              display: flex
              align-items: center
              min-height: 43px
              span
                width: 160px
                font-size: 14px
                margin-left: 17px
                color: #11A9FF
                line-height: 17px
            .user-placeholder
              height: 43px !important
              margin-top: 0
              transition: opacity .2s !important
              &:not(:first-child)
                margin-top: 9px
              &.mounted
                opacity: 1

          .user-placeholder
            width: 100%
            background: #EEEEEE
            opacity: 0
            height: 0
            border-radius: 6px
            transition: height .2s .2s, opacity .2s, margin .2s .2s
            margin-top: 0
            display: block
            &.mounted
              transition: height .2s, opacity .2s .2s, margin .2s
              opacity: 1
              margin-top: 9px

        .user-state
          color: #11A9FF
          font-size: 12px
          margin-top: 17px
          font-weight: bold
          padding-left: 18px
          margin-bottom: 9px
          cursor: pointer
          position: relative
          z-index: 3
          user-select: none
          &:nth-child(3)
            margin-top: 4px
          &:before, &:after
            content: ''
            width: 8px
            height: 2px
            border-radius: 1px
            position: absolute
            top: 40%
            background: #11A9FF
            transition: transform .3s
          &:before
            right: 27px
            transform-origin: left
            transform: rotateZ(0deg)
          &:after
            right: 25px
            transform-origin: right
            transform: rotateZ(0deg)
          &.unfolded
            &:before
              transform: rotateZ(45deg)
            &:after
              transform: rotateZ(-45deg)

        .lead-tile-background
          width: 100%
          background: #EEEEEE
          border-radius: 6px

          &:first-of-type
            margin-top: 9px

          .lead-tile
            opacity: 0
            transition: opacity .2s cubic-bezier(.64, .3, .85, .72), top .1s, left .1s, transform .3s
            background: #ffffff
            width: 100%
            padding: 9px 2px 9px 2px
            line-height: 25px
            font-size: 15px
            position: relative
            top: 0
            left: 0
            z-index: 3
            cursor: initial
            display: flex
            align-items: center

            &.stop-position-transition
              transition: opacity .2s cubic-bezier(.64, .3, .85, .72)

            .lead-tooltip
              display: inline-block
              color: #4AB3E2
              font-size: 8px
              background: #F3F3F3
              border-radius: 3px
              font-weight: normal
              height: 16px
              line-height: 16px
              padding: 0 5px

            .user-name
              max-width: 180px
              overflow: hidden
              text-overflow: ellipsis
              padding-left: 12px

            .cross
              position: absolute
              top: 20px
              right: -7px
              cursor: pointer
              display: inline-block
              height: 27px
              width: 27px
              transform: translateY(-50%)

              &:before, &:after
                border-radius: 2px
                width: 10px
                height: 2px
                background: #C4C4C4
                content: ''
                position: absolute
                top: 50%
                left: 50%

              &:before
                transform: translate(-50%, -50%) rotate(-45deg)

              &:after
                transform: translate(-50%, -50%) rotate(45deg)

          &.mounted .lead-tile
            opacity: 1
        .user-tile-background
          width: 100%
          background: #EEEEEE
          border-radius: 6px
          &:not(:last-of-type)
            margin-bottom: 9px
          .user-tile
            opacity: 0
            transition: opacity .2s cubic-bezier(.64,.3,.85,.72), top .1s, left .1s, transform .3s
            border-radius: 6px
            box-shadow: 1px 2px 3px rgba(166, 166, 166, 0.35)
            background: #ffffff
            width: 100%
            padding: 9px 22px 9px 19px
            line-height: 25px
            font-size: 15px
            position: relative
            top: 0
            left: 0
            z-index: 3
            cursor: grab
            display: flex
            align-items: center
            &.remove-grab-cursor
              cursor: initial
            &.stop-position-transition
              transition: opacity .2s cubic-bezier(.64,.3,.85,.72)
            &.dragged
              position: fixed
              z-index: 5
              .custom-checkbox
                opacity: 0 !important
            .custom-checkbox
              opacity: 0
              display: inline-block
              float: right
              border: 2px solid #11A9FF
              border-radius: 3px
              margin-left: auto
              width: 18px
              height: 18px
              cursor: initial
              transition: .2s opacity, .2s background
              position: relative
              &.selected
                opacity: 1
                background: #11A9FF
              &:before, &:after
                background: white
                content: ''
                position: absolute
                border-radius: 2px
                width: 2px
              &:before
                height: 7px
                left: 3px
                transform: rotateZ(-50deg)
                top: 5px
              &:after
                height: 9px
                left: 8px
                top: 2px
                transform: rotateZ(44deg)
            &:hover .custom-checkbox
              opacity: 1
            .user-name
              max-width: 180px
              overflow: hidden
              text-overflow: ellipsis
              padding-right: 8px
          &.mounted .user-tile
            opacity: 1
        .unfold-button
          position: absolute
          right: -2px
          transform: translateX(50%)
          background: #11A9FF
          color: white
          font-size: 14px
          font-weight: bold
          z-index: 2
          top: 21px
          height: 24px
          border-radius: 20px
          transition: width .3s, opacity .3s
          cursor: pointer
          opacity: 1
          &:before, &:after
            position: absolute
            content: ''
            top: 50%
            height: 2px
            width: 7px
            border-radius: 1px
            background: white
            left: 7px
            transition: left .3s, transform .3s
          &:before
            transform-origin: right bottom
            transform: translateY(-50%) rotateZ(40deg)
          &:after
            transform-origin: right top
            transform: translateY(-50%) rotateZ(-40deg)
          span
            display: block
            padding: 0 10px 0 20px
            line-height: 24px
            transition: padding .3s, opacity .3s .3s
          &.unfolded
            transition: width .3s .3s, opacity .3s
            width: 24px
            span
              opacity: 0
              padding: 0
              transition: opacity .3s, padding .3s .3s
            &:before, &:after
              left: 50%
              transition: left .3s .3s, transform .3s .3s
              transform: translate(-50%, -50%) rotateZ(0)
      .add-team-tile
        background: #ffffff
        border-radius: 8px
        display: inline-block
        width: 300px
        margin-bottom: 20px
        padding: 12px 18px
        font-size: 14px
        position: relative
        min-height: 48px
        box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
        transition: padding .0s .2s
        &:before
          position: absolute
          content: ''
          top: 24px
          left: -25px
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
        .untriggered-content
          z-index: 3
          position: relative
          color: #11A9FF
          display: flex
          align-items: center
          height: 24px
          cursor: pointer
          overflow: hidden
          transition: opacity .2s .4s, height .0s .4s
        .triggered-content
          z-index: 3
          position: relative
          transition: opacity .2s, height .2s .2s
          height: 0
          opacity: 0
          overflow: hidden
          h1
            font-weight: bold
            font-size: 15px
            margin-bottom: 8px
          input
            background: #F6F6F6
            border: 1px solid #D5D5D5
            border-radius: 6px
            width: 100%
            padding: 10px
            outline: none
          .buttons-wrap
            display: flex
            margin-top: 18px
            & > div
              cursor: pointer
              border-radius: 4px
              font-size: 15px
              &.add-button
                padding: 7px 25px
                background: #11A9FF
                color: white
                font-weight: bold
              &.cancel-button
                padding: 7px 18px
                color: #11A9FF
        .drag-capture-area
          min-height: 48px
        .team-placeholder
          background: #EEEEEE
          border-radius: 8px
          width: 100%
          height: 0
          opacity: 0
          transition: height .2s .2s, opacity .2s
          &.invalid
            background: #ffd1d1
        &.triggered
          .untriggered-content
            opacity: 0
            height: 0
            transition: opacity .2s, height 0s .2s
          .triggered-content
            transition: opacity .2s .4s, height .2s .2s
            opacity: 1
            height: 129px
        &.drag-over
          padding: 0
          .untriggered-content
            opacity: 0
            height: 0
            transition: opacity .2s, height 0s .2s, margin 0s .2s
            margin: 0
          .triggered-content
            opacity: 0
            height: 0
            transition: opacity .2s, height 0s .2s
          .team-placeholder
            transition: height .2s, opacity .2s .2s
            opacity: 1
      .add-subteam-tile
        display: inline-block
        width: 300px
        align-items: center
        font-size: 14px
        position: relative
        padding: 12px 18px
        border-radius: 8px
        transition: box-shadow .2s, padding .0s .2s
        margin-bottom: 20px
        &:before
          position: absolute
          content: ''
          top: 33px
          left: -25px
          transform: translateY(-50%)
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
        .untriggered-content
          color: #11A9FF
          display: flex
          align-items: center
          height: 24px
          cursor: pointer
          transition: opacity .2s .2s, height .0s .2s, margin .0s .2s
          margin: 8px -18px
          overflow: hidden
          z-index: 3
          position: relative
        .triggered-content
          z-index: 3
          transition: opacity .2s, height .0s .2s
          height: 0
          opacity: 0
          overflow: hidden
          position: relative
          h1
            font-weight: bold
            font-size: 15px
            margin-bottom: 8px
          input
            background: #F6F6F6
            border: 1px solid #D5D5D5
            border-radius: 6px
            width: 100%
            padding: 10px
            outline: none
          .buttons-wrap
            display: flex
            margin-top: 18px
            & > div
              cursor: pointer
              border-radius: 4px
              font-size: 15px
              &.add-button
                padding: 7px 25px
                background: #11A9FF
                color: white
                font-weight: bold
              &.cancel-button
                padding: 7px 18px
                color: #11A9FF
        .drag-capture-area
          min-height: 48px
        .team-placeholder
          background: #EEEEEE
          border-radius: 8px
          width: 100%
          height: 0
          opacity: 0
          transition: height .0s .2s, opacity .2s
          &.invalid
            background: #ffd1d1
        &.triggered
          background: white
          box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
          transition: background .2s .2s, box-shadow .2s .2s
          .untriggered-content
            opacity: 0
            height: 0
            transition: opacity .2s, height 0s .2s, margin 0s .2s
            margin: 0
          .triggered-content
            transition: opacity .2s .2s, height 0s .2s
            opacity: 1
            height: 129px
        &.drag-over
          padding: 0
          .untriggered-content
            opacity: 0
            height: 0
            transition: opacity .2s, height 0s .2s, margin 0s .2s
            margin: 0
          .triggered-content
            opacity: 0
            height: 0
            transition: opacity .2s, height 0s .2s
          .team-placeholder
            transition: height .0s .2s, opacity .2s .2s
            opacity: 1
      .plus-button
        background: #11A9FF
        border-radius: 100%
        width: 24px
        height: 24px
        position: relative
        display: inline-block
        margin-right: 10px
        &:before, &:after
          content: ''
          display: block
          position: absolute
          background: white
          border-radius: 1px
          top: 50%
          left: 50%
          width: 10px
          height: 2px
          transform: translate(-50%, -50%)
        &:after
          transform: translate(-50%, -50%) rotateZ(90deg)

    .bottom-buttons-wrap
      width: 100%
      margin-top: 19px
      padding-right: 15px
      text-align: right
      &:before
        display: block
        content: ''
        width: 100%
        background: #E3E3E3
        height: 2px
        border-radius: 5px
        margin-bottom: 30px
      & > div
        display: inline-block
        width: 200px
        cursor: pointer
        border-radius: 4px
        transition: opacity .2s
        user-select: none
        &.disabled
          opacity: .8
          font-style: normal
          cursor: default
        &.next-step-button
          width: unset
          padding: 10px 50px
          text-align: center
          font-size: 16px
          background: #11A9FF
          color: white
          font-weight: bold
        &.cancel-button
          font-size: 15px
          padding: 10px 27px
          color: #11A9FF

  #company-issues
    padding-top: 13px
    .company-issues-header
      display: flex
      h1
        font-size: 30px
        margin-bottom: 10px
      h2
        font-size: 15px
        color: #8B8A96
        font-weight: normal
        display: flex
        align-items: center
        cursor: pointer
        &:before
          content: '?'
          border-radius: 100%
          display: inline-block
          height: 16px
          width: 16px
          background: #8B8A96
          color: #F3F3F3
          line-height: 16px
          text-align: center
          font-size: 13px
          font-weight: bold
          margin-right: 9px
      .header-checkboxes-wrap
        > div
          display: inline-flex
          font-size: 15px
          color: #6A6A6A
          &:first-child
            margin-left: 37px
            margin-top: 6px
          &:last-child
            margin-left: 30px
          .invite-checkbox
            margin-right: 12px
            display: inline-block
            border: 2px solid #C4C4C4
            border-radius: 3px
            margin-left: auto
            width: 18px
            height: 18px
            cursor: initial
            transition: .2s background, .2s border
            background: white
            position: relative
            &.selected
              opacity: 1
              background: #11A9FF
              border: 2px solid #11A9FF
            &:before, &:after
              background: white
              content: ''
              position: absolute
              border-radius: 2px
              width: 2px
            &:before
              height: 7px
              left: 3px
              transform: rotateZ(-50deg)
              top: 5px
            &:after
              height: 9px
              left: 8px
              top: 2px
              transform: rotateZ(44deg)
    .issues-graph
      padding-top: 8px
      white-space: nowrap
      overflow: auto
      margin-right: -24px
      font-size: 0
      max-height: calc(100vh - 350px)
      .team-tree-wrap
        margin-left: 48px
        display: inline-block
        vertical-align: top
        .team-subtree-wrap
          position: relative
          .vertical-branch
            content: ''
            position: absolute
            left: -26px
            top: 32px
            width: 2px
            height: 100%
            background: #EAEAEA
            border-radius: 1px
          &:nth-last-child(2) > .vertical-branch
            height: calc(100% - 6px)
          &.hide-vertical-branch > .vertical-branch
            display: none
          &.set-equal-branch-heights > .vertical-branch
            height: 100%

      #main-team-wrap
        width: 330px
        display: inline-block
      .team-tile-background
        display: inline-block
        position: relative
        background: #EEEEEE
        border-radius: 8px
        margin-bottom: 20px
        &:before
          position: absolute
          content: ''
          top: 32px
          left: -25px
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
        &:after
          position: absolute
          content: ''
          top: 32px
          right: -25px
          background: #EAEAEA
          border-radius: 1px
          height: 2px
          width: 25px
        &.hide-horizontal-branch:after
          display: none
      .drag-capture-area
        height: 100%
        width: 100%
        position: absolute
        top: 0
        left: 0
        z-index: 2
        &.active
          z-index: 6
      .team-tile
        white-space: initial
        border-radius: 8px
        padding: 18px 18px 20px
        background: #ffffff
        box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
        width: 300px
        position: relative
        transition: opacity .2s cubic-bezier(.64,.3,.85,.72), top .1s, left .1s, transform .3s
        &.stop-position-transition
          transition: opacity .2s cubic-bezier(.64,.3,.85,.72)
        &.dragged
          position: fixed
          z-index: 5
        &#main-team
          cursor: initial
          padding: 22px 20px 20px
          width: 330px
          background: #E3E3E3
          box-shadow: none
          z-index: 5
          position: fixed
          .team-tile-header
            margin-bottom: 11px

          p
            white-space: normal
            font-size: 13px
            line-height: 18px
            margin-bottom: 28px
          .main-team-members-list-wrap
            width: 100%
            overflow: hidden
            position: relative
            .custom-scrollbar
              opacity: 0
              transition: .3s opacity
              z-index: 5
            &:hover .custom-scrollbar
              opacity: .7
            .main-team-members-list
              max-height: calc(100vh - 600px)
              margin-right: -20px
              padding-right: 7px
              overflow-y: scroll
              padding-bottom: 2px
          &:before
            content: unset

          &:after
            position: absolute
            content: ''
            top: 32px
            right: -25px
            background: #EAEAEA
            border-radius: 1px
            height: 2px
            width: 25px
          &.hide-horizontal-branch:after
            display: none
          .user-tile
            cursor: grab
        .invite-checkbox
          display: inline-block
          border: 2px solid #C4C4C4
          border-radius: 3px
          margin-left: auto
          width: 18px
          height: 18px
          cursor: initial
          transition: .2s opacity, .2s background, .2s border
          position: relative
          &.selected
            background: #C4C4C4
          &.disabled
            opacity: .8
            cursor: initial
          &.already-invited
            background: rgba(0,0,0,0)
            border: 2px solid rgba(0,0,0,0)
            &:before, &:after
              background: #46B700
          &:before, &:after
            background: #ffffff
            content: ''
            position: absolute
            border-radius: 2px
            width: 2px
          &:before
            height: 7px
            left: 3px
            transform: rotateZ(-50deg)
            top: 5px
          &:after
            height: 9px
            left: 8px
            top: 2px
            transform: rotateZ(44deg)
        .team-tile-header
          display: flex
          align-items: center
          .invite-checkbox
            margin-right: 14px
          .team-letters
            font-size: 15px
            border-radius: 100%
            border: 1px solid
            font-weight: bold
            height: 36px
            width: 36px
            text-align: center
            line-height: 34px
            margin-right: 12px
            flex-shrink: 0
          span
            font-weight: bold
            font-size: 18px
            margin-right: 15px

        .team-info-wrap
          display: flex
          align-items: center
          margin-left: 48px
          font-size: 14px
          span
            height: 4px
            width: 4px
            background: #C4C4C4
            border-radius: 100%
            display: inline-block
            margin: 0 12px
        .team-members-list
          background: #F8F8F8
          border-bottom-right-radius: 8px
          border-bottom-left-radius: 8px
          margin: 20px -18px -20px
          padding: 11px
          position: relative
          .no-users-placeholder-wrap
            .no-users-placeholder
              opacity: 1
              display: flex
              align-items: center
              min-height: 43px
              span
                width: 160px
                font-size: 14px
                margin-left: 17px
                color: #11A9FF
                line-height: 17px
            .user-placeholder
              height: 43px !important
              margin-top: 0 !important
              transition: opacity .2s !important
          .user-placeholder
            width: 100%
            background: #EEEEEE
            opacity: 0
            height: 0
            border-radius: 6px
            transition: height .2s .2s, opacity .2s, margin .2s .2s
            margin-top: 0
            display: block
            &.mounted
              transition: height .2s, opacity .2s .2s, margin .2s
              opacity: 1
              margin-top: 9px

        .user-state
          color: #11A9FF
          font-size: 12px
          margin-top: 17px
          font-weight: bold
          padding-left: 18px
          margin-bottom: 9px
          cursor: pointer
          position: relative
          z-index: 3
          user-select: none
          &:nth-child(2)
            margin-top: 4px
          &:before, &:after
            content: ''
            width: 8px
            height: 2px
            border-radius: 1px
            position: absolute
            top: 40%
            background: #11A9FF
            transition: transform .3s
          &:before
            right: 27px
            transform-origin: left
            transform: rotateZ(0deg)
          &:after
            right: 25px
            transform-origin: right
            transform: rotateZ(0deg)
          &.unfolded
            &:before
              transform: rotateZ(45deg)
            &:after
              transform: rotateZ(-45deg)
        .user-tile-background
          width: 100%
          background: #EEEEEE
          border-radius: 6px
          &:not(:last-of-type)
            margin-bottom: 9px
          .user-tile
            opacity: 0
            transition: opacity .2s cubic-bezier(.64,.3,.85,.72), top .1s, left .1s, transform .3s
            border-radius: 6px
            box-shadow: 1px 2px 3px rgba(166, 166, 166, 0.35)
            background: #ffffff
            width: 100%
            padding: 9px 22px 9px 19px
            line-height: 25px
            font-size: 15px
            position: relative
            top: 0
            left: 0
            z-index: 3
            cursor: grab
            display: flex
            align-items: center
            &.stop-position-transition
              transition: opacity .2s cubic-bezier(.64,.3,.85,.72)
            &.dragged
              position: fixed
              z-index: 5
              .custom-checkbox
                opacity: 0 !important
            .custom-checkbox
              opacity: 0
              display: inline-block
              float: right
              border: 2px solid #11A9FF
              border-radius: 3px
              margin-left: auto
              width: 18px
              height: 18px
              cursor: initial
              transition: .2s opacity, .2s background
              position: relative
              &.selected
                opacity: 1
                background: #11A9FF
              &:before, &:after
                background: white
                content: ''
                position: absolute
                border-radius: 2px
                width: 2px
              &:before
                height: 7px
                left: 3px
                transform: rotateZ(-50deg)
                top: 5px
              &:after
                height: 9px
                left: 8px
                top: 2px
                transform: rotateZ(44deg)
            &:hover .custom-checkbox
              opacity: 1
            .lead-tooltip
              display: inline-block
              color: #11A9FF
              font-size: 8px
              background: #F3F3F3
              border-radius: 14px
              font-weight: bold
              margin-left: 10px
              height: 17px
              line-height: 17px
              padding: 0 5px
          &.mounted .user-tile
            opacity: 1
            .user-name
              overflow: hidden
              text-overflow: ellipsis
              max-width: 240px
              padding-right: 8px
        .unfold-button
          position: absolute
          right: -2px
          transform: translateX(50%)
          background: #11A9FF
          color: white
          font-size: 14px
          font-weight: bold
          z-index: 2
          top: 21px
          height: 24px
          border-radius: 20px
          transition: width .3s, opacity .3s
          cursor: pointer
          opacity: 1
          &:before, &:after
            position: absolute
            content: ''
            top: 50%
            height: 2px
            width: 7px
            border-radius: 1px
            background: white
            left: 7px
            transition: left .3s, transform .3s
          &:before
            transform-origin: right bottom
            transform: translateY(-50%) rotateZ(40deg)
          &:after
            transform-origin: right top
            transform: translateY(-50%) rotateZ(-40deg)
          span
            display: block
            padding: 0 10px 0 20px
            line-height: 24px
            transition: padding .3s, opacity .3s .3s
          &.unfolded
            transition: width .3s .3s, opacity .3s
            width: 24px
            span
              opacity: 0
              padding: 0
              transition: opacity .3s, padding .3s .3s
            &:before, &:after
              left: 50%
              transition: left .3s .3s, transform .3s .3s
              transform: translate(-50%, -50%) rotateZ(0)
    .bottom-buttons-wrap
      width: 100%
      margin-top: 19px
      padding-right: 15px
      text-align: right
      &:before
        display: block
        content: ''
        width: 100%
        background: #E3E3E3
        height: 2px
        border-radius: 5px
        margin-bottom: 30px
      & > div
        display: inline-block
        width: 200px
        cursor: pointer
        border-radius: 4px
        transition: opacity .2s
        user-select: none
        &.disabled
          opacity: .8
          font-style: normal
          cursor: default
        &.next-step-button
          width: unset
          padding: 10px 50px
          text-align: center
          font-size: 16px
          background: #11A9FF
          color: white
          font-weight: bold
        &.cancel-button
          font-size: 15px
          padding: 10px 27px
          color: #11A9FF

.add-users-modal
  max-width: 600px
  width: 100%
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3)
  position: relative
  border-radius: 8px
  @media(max-width: 480px)
    height: 800px
  &.one-by-one
    max-width: 1046px
  .close-cross
    top: 0
    right: 0
    position: absolute
    width: 30px
    height: 30px
    background: #11A9FF
    border-radius: 100%
    cursor: pointer
    transform: translate(50%, -50%)
    &:before, &:after
      display: block
      content: ''
      height: 2px
      width: 16px
      position: absolute
      left: 50%
      top: 50%
      background: white
    &:before
      transform: translate(-50%, -50%) rotate(-45deg)
    &:after
      transform: translate(-50%, -50%) rotate(45deg)

    @media(max-width: 480px)
      display: none
  .modal-content
    border-radius: 8px
    padding: 30px 36px
    position: static
    h1
      letter-spacing: -0.5px
      margin-bottom: 11px
    p
      line-height: 23px
      font-size: 15px
      padding-right: 10px
      margin-bottom: 23px
      &.one-by-one-subtitle
        margin-bottom: 0
    .adding-info
      color: #ff0000
      font-weight: bold
      font-size: 12px
      margin-bottom: 19px
      min-height: 17px
    textarea
      background: #F6F6F6
      border: 1px solid #D5D5D5
      padding: 10px
      border-radius: 6px
      width: 100%
      resize: none
      font-size: 14px
    .switch-method-wrap
      display: flex
      align-items: center
      font-size: 14px
      margin-top: 12px
      img
        height: 20px
        width: 20px
        margin-right: 12px
      span
        color: #11A9FF
        cursor: pointer
        &:first-of-type
          margin-right: 5px
        &:last-of-type
          margin-left: 5px
    .buttons-wrap
      display: flex
      margin-top: 31px
      padding-bottom: 5px
      & > div
        cursor: pointer
        border-radius: 4px

        &.disabled
          opacity: .8
          font-style: normal
          cursor: default
        &.next-step-button
          font-size: 16px
          background: #11A9FF
          color: white
          font-weight: bold
          padding: 10px 70px
        &.cancel-button
          font-size: 15px
          padding: 10px 27px
          color: #11A9FF
    .form-wrap
      margin-bottom: 8px
      .form-row-wrap
        display: flex
        @media(max-width: 480px)
          display: block
        &:not(:last-child)
          margin-bottom: 15px
          @media(max-width: 480px)
            padding-bottom: 30px
            margin-bottom: 25px
            border-bottom: 1px solid #F6F6F6
        .column-wrap
          &.demo-account
            @media(min-width: 480px)
              width: 300px
          &:not(:last-child)
            margin-right: 24px

          @media(min-width: 480px)
            &:first-child
              width: 300px
            width: 201px
          width: 100%
          p
            @media(max-width: 701px)
              height: 40px
            @media(max-width: 480px)
              margin-top: 10px
            margin-bottom: 10px
            color: #323232
            font-size: 15px
            font-weight: bold
            span
              color: #c5c5c4
          input
            background: #F6F6F6
            border: 1px solid #D5D5D5
            border-radius: 6px
            width: 100%
            padding: 10px
            outline: none
            font-size: 15px
            transition: .2s background, border .2s
          .value-error
            color: #ff0000
            font-size: 11px
            height: 0
            opacity: 0
            margin-top: 0
            transition: opacity .2s, height .2s .2s, margin-top .2s .2s
            padding: 0 5px
            &.mounted
              margin-top: 2px
              opacity: 1
              transition: opacity .2s .2s, height .2s, margin-top .2s
              height: 15px
          &.error
            input, div.Select-control
              background: #ffd1d1
              border: 1px solid #ff0000
            div.Select-placeholder, div.Select-value, div.Select-input
              background: #ffd1d1 !important
          div.Select-input
            background: #F6F6F6
            padding: 5px 0 0 0
            input
              border: none
              width: 100%
              font-size: 15px
          div.Select-control, div.Select-input, div.Select-placeholder
            background: #F6F6F6
            height: 41px
            border-radius: 6px
          div.Select-menu-outer
            background: #F6F6F6
          div.Select-placeholder
            padding-top: 5px
            background: #F6F6F6
          div.Select-value
            background: #F6F6F6
            width: 100%
            padding: 3px 15px 3px 10px
            outline: none
            font-size: 15px
            transition: .2s background, border .2s

        .cross
          cursor: pointer
          display: inline-block
          margin-top: 53px
          transform: translateY(-50%)

          &:before, &:after
            border-radius: 2px
            width: 15px
            height: 2px
            background: #C4C4C4
            content: ''
            position: absolute
            top: 100%
            left: 100%

          &:before
            transform: translate(-50%, -50%) rotate(-45deg)

          &:after
            transform: translate(-50%, -50%) rotate(45deg)
      & + .switch-method-wrap
        margin-top: 0
    .general-error
      color: #ff0000
      font-weight: bold
      height: 0
      opacity: 0
      margin-bottom: 20px
      transition: .2s .2s height, .2s opacity
      &.visible
        height: auto
        transition: .2s height, .2s .2s opacity
        opacity: 1
.team-modal
  margin-top: 102px
  max-width: 679px
  width: calc(100% - 20px)
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3)
  position: relative
  border-radius: 8px
  @media(max-width: 480px)
    width: 100%
  @media(min-width: 700px)
    width: 679px
  .close-cross
    top: 0
    right: 0
    position: absolute
    width: 30px
    height: 30px
    background: #11A9FF
    border-radius: 100%
    cursor: pointer
    transform: translate(50%, -50%)
    &:before, &:after
      display: block
      content: ''
      height: 2px
      width: 16px
      position: absolute
      left: 50%
      top: 50%
      background: white
    &:before
      transform: translate(-50%, -50%) rotate(-45deg)
    &:after
      transform: translate(-50%, -50%) rotate(45deg)
  .modal-content
    border-radius: 8px
    padding: 30px 36px
    position: static
    .team-modal-header-wrap
      display: flex
      align-items: center
      .team-letters
        font-size: 23px
        border-radius: 100%
        border: 1px solid
        font-weight: bold
        height: 48px
        width: 48px
        text-align: center
        line-height: 48px
        margin-right: 12px
        flex-shrink: 0
      img
        margin-right: 12px
        height: 48px
        width: 48px
        border-radius: 100%
      h1
        margin-bottom: 0
        margin-left: 6px
        font-size: 24px
      .request-access
        color: #11A9FF
        font-size: 14px
        margin-left: 18px
        position: relative
        top: 3px
        cursor: pointer
      .team-score
        margin-left: auto
        margin-right: 17px
        a
          text-decoration: none
        &.positive-trend .trend-icon
          color: #11A9FF
        &.negative-trend .trend-icon
          color: #F5351B
        .number
          color: initial
          font-size: 16px
          top: 12px
        .trend-icon
          position: absolute
          right: -21px
          top: -2px
          font-size: 15px
    .members-wrap
      margin-top: 21px
      margin-right: -22px
      h2
        font-size: 18px
        font-weight: normal
        margin-bottom: 20px
        span
          color: #11A9FF
          font-size: 14px
          margin-left: 8px
          position: relative
          cursor: pointer
      a
        display: inline-flex
        align-items: center
        width: 50%
        @media(max-width: 500px)
          width: 100%
        text-decoration: none
        margin-bottom: 23px
        img
          height: 37px
          width: 37px
          border-radius: 100%
        .user-info-wrap
          display: flex
          flex-direction: column
          margin-left: 11px
          .name
            color: initial
            font-size: 14px
            font-weight: bold
            overflow: hidden
            text-overflow: ellipsis
            max-width: 240px
          .role
            font-size: 11px
            color: #bbbbbb
            position: relative
            top: 2px
      .unfold-users
        font-size: 15px
        background: #11A9FF
        border-radius: 22px
        width: 108px
        margin: 12px auto 36px
        position: relative
        left: -10px
        height: 32px
        color: white
        display: flex
        align-items: center
        justify-content: center
    .subs-wrap
      margin: 13px -36px -30px
      padding: 27px 26px 20px
      background: #F8F8F8
      border-bottom-right-radius: 8px
      border-bottom-left-radius: 8px
      h2
        margin-left: 10px
        font-size: 18px
        font-weight: normal
        margin-bottom: 15px
      .sub-tile
        display: inline-flex
        width: calc(50% - 20px)
        @media(max-width: 500px)
          width: calc(100% - 20px)
        margin: 0 10px 20px
        background: #FFFFFF
        box-shadow: 1px 4px 8px rgba(193, 193, 193, 0.35)
        border-radius: 8px
        padding: 11px 18px 8px
        .team-letters
          font-size: 15px
          border-radius: 100%
          border: 1px solid
          font-weight: bold
          height: 30px
          width: 30px
          text-align: center
          line-height: 30px
          margin-right: 12px
          flex-shrink: 0
        .team-info-wrap
          flex: 1
          h1
            font-size: 18px
            position: relative
            top: -2px
            left: 1px
            margin-bottom: 0
          .sub-members-count
            font-size: 14px
            position: relative
            top: -2px
            left: 1px
.final-setup-modal
  width: 470px
  margin-top: 102px
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3)
  position: relative
  border-radius: 4px
  .close-cross
    top: 0
    right: 0
    position: absolute
    width: 30px
    height: 30px
    background: #11A9FF
    border-radius: 100%
    cursor: pointer
    transform: translate(50%, -50%)
    &:before, &:after
      display: block
      content: ''
      height: 2px
      width: 16px
      position: absolute
      left: 50%
      top: 50%
      background: white
    &:before
      transform: translate(-50%, -50%) rotate(-45deg)
    &:after
      transform: translate(-50%, -50%) rotate(45deg)
  .modal-content
    border-radius: 4px
    padding: 30px 36px 23px
    position: static
    .circle-tick
      width: 46px
      height: 46px
      background: #11A9FF
      position: relative
      border-radius: 100%
      margin: 55px auto 0
      &:before, &:after
        background: #ffffff
        content: ''
        position: absolute
        width: 4px
      &:before
        height: 13px
        left: 15px
        transform: rotateZ(-50deg)
        top: 20px
      &:after
        height: 21px
        left: 25px
        top: 12px
        transform: rotateZ(40deg)
    h1
      color: #11A9FF
      text-align: center
      font-size: 24px
      margin-top: 12px
    h2
      font-size: 16px
      text-align: center
      margin-top: 33px
      line-height: 24px
      padding: 0 20px
    p
      line-height: 18px
      font-size: 13px
      text-align: center
      color: #979797
      padding: 0 85px
      margin-top: 31px
.instructions-modal
  width: 581px
  margin-top: 102px
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3)
  position: relative
  border-radius: 8px
  .close-cross
    top: 0
    right: 0
    position: absolute
    width: 30px
    height: 30px
    background: #11A9FF
    border-radius: 100%
    cursor: pointer
    transform: translate(50%, -50%)
    &:before, &:after
      display: block
      content: ''
      height: 2px
      width: 16px
      position: absolute
      left: 50%
      top: 50%
      background: white
    &:before
      transform: translate(-50%, -50%) rotate(-45deg)
    &:after
      transform: translate(-50%, -50%) rotate(45deg)
  .modal-content
    border-radius: 8px
    padding: 35px 42px 9px
    position: static
    h1
      font-size: 24px
    p
      font-size: 15px
      line-height: 23px
      margin-top: 17px
      color: #6A6A6A
      
.account-deletion-modal
  max-width: 550px
  margin-top: 102px
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3)
  position: relative
  border-radius: 4px
  @media(max-width: 480px)
    width: 100%
    height: auto
  .close-cross
    top: 0
    right: 0
    position: absolute
    width: 30px
    height: 30px
    background: #11A9FF
    border-radius: 100%
    cursor: pointer
    transform: translate(50%, -50%)
    &:before, &:after
      display: block
      content: ''
      height: 2px
      width: 16px
      position: absolute
      left: 50%
      top: 50%
      background: white
    &:before
      transform: translate(-50%, -50%) rotate(-45deg)
    &:after
      transform: translate(-50%, -50%) rotate(45deg)
  .modal-content
    border-radius: 4px
    padding: 30px 36px 23px
    position: static
    h2
      font-size: 16px
      text-align: center
      margin-top: 33px
      line-height: 24px
      padding: 0

