.post-tile-comments-section
  border-top: 1px solid #F3F3F3
  border-bottom-left-radius: 5px
  border-bottom-right-radius: 5px
  ul
    margin: 0
    padding: 0
    .comment
      display: table
      &.user-comment
        font-size: 13px
        padding: 0 26px 16px
        width: 100%
        position: relative

        .comment-content
          display: table-row
          vertical-align: top
          padding-left: 0
          margin-right: 20px
          @media (min-width: 1300px)
            display: table-cell

          .error
            border: 1px solid #F5351B
            background: #FFF7F5

          .comment-error
            font-size: 12px
            margin-top: 7px
            color: #FE483C
          a
            color: #323232
            font-weight: 500
          span
            width: 100%
            text-align: justify
            line-height: 18px
            word-wrap: break-word
            word-break: break-word
            a
              color: #1297E3
              text-decoration: none
          .comment-show-media
            margin: 5px 0 0 0
            width: 160px
            @media (min-width: 460px)
              width: 200px
            &.single-image
              height: auto
              width: auto
              max-width: 200px
              margin-right: 0
            .img-responsive
              margin: auto
            .slide
              height: auto
              bottom: 0
            .border
              border-radius: 0 !important
              border: 1px solid #fff !important
              .cover
                border-radius: 0 !important
            div.img-div
              height: 160px
              width: 160px
              background-size: contain
              background-position: left top
              background-repeat: no-repeat
              @media (min-width: 460px)
                width: 200px
            .carousel-indicators
              position: absolute
              bottom: 10px
              li
                background-color: rgb(255,255,255)
                background-color: rgba(255,255,255, 0.6)
                &.active
                  background-color: rgb(255,255,255)
                  background-color: rgba(255,255,255, 1)
            .carousel-indicators
              bottom: -25px
            .carousel-inner
              .item
                height: 160px
                width: 160px
                div.img-div
                  background-position: center
            .carousel-control
              color: #DADADA
              line-height: 30px
              opacity: 1
              width: 30px
              div
                position: absolute
                width: 13px
                height: 13px
                top: 50%
                transform: translateY(-50%) rotateZ(-45deg) skew(-5deg, -5deg)
                border-bottom: 2px solid white
                border-right: 2px solid white

                &.control-right
                  right: 11px

                &.control-left
                  transform: translateY(-50%) rotateZ(135deg) skew(-5deg, -5deg)
                  left: 11px

            .video-react
              padding: 0 !important
              min-height: 50px
              height: 100%

        .comment-dropdown
          cursor: pointer
          position: absolute
          right: 20px
          display: none
          user-select: none

          .options-wrap
            position: absolute
            display: none
            z-index: 2
            background-color: #fff
            border: 1px solid rgba(0, 0, 0, .15)
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
            min-width: 80px
            font-weight: 700
            line-height: 18px
            font-size: 14px
            cursor: pointer
            border-radius: 4px
            padding: 10px
            right: -26px
            top: 30px

            &:after
              content: ""
              position: absolute
              width: 15px
              height: 15px
              background: linear-gradient(to left top, rgba(255, 255, 255, 0) 49.5%, rgba(255, 255, 255, 1) 50.5%)
              transform: rotate(45deg) skew(15deg, 15deg)
              top: -8px
              right: 29px
              border-top: 1px solid #dedede
              border-left: 1px solid #dedede

            .edit
              background-color: #11A9FF
              color: #fff
              text-align: center
              padding: 7px 30px
              margin-bottom: 5px
              border-radius: 2px
              z-index: 2
              position: relative

            .delete
              background-color: #fff
              color: #e43f3f
              border: 1px solid #e43f3f
              padding: 6px 30px
              border-radius: 2px
              margin-bottom: 10px

          &[custom-open='true']
            display: block

            .options-wrap
              display: block
              z-index: 3

        &:hover .comment-dropdown
          display: block

        &.editing
          a
            margin-top: 7px

          .mentions-input-wrap
            width: 85%
            padding: 6px
            background: white
            border-radius: 4px
            border: 1px solid #E7E7E7

            textarea
              min-height: unset

          i
            padding-left: 3px
            margin-top: 7px
            color: #939da3

          div.comment-error
            margin-bottom: 15px
            padding-left: 75px
            padding-right: 50px
      &:first-of-type
        padding-top: 25px
      &:last-of-type
        padding-bottom: 30px
  .react-tooltip.limit-reached
    text-align: center
    pointer-events: auto !important
    background-color: #8B8A96
    box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px
    z-index: 10
    &::after
      border-top-color: transparent !important
      border-left-color: #8B8A96 !important
      border-right-color: #8B8A96 !important
    span
      a
        color: white
        text-decoration: underline

  .post-tile-comments-unfold
    font-size: 12px
    line-height: 16px
    color: #11A9FF
    cursor: pointer
    padding: 0 26px 30px
  .post-tile-comments
    padding: 0 2px
    .post-tile-comment
      margin-bottom: 16px
      font-size: 14px
      line-height: 18px
      color: #323232
      a
        color: #323232
        text-decoration: none
        word-wrap: break-word
        cursor: pointer
      span
        margin-left: 7px
        word-wrap: break-word
        a
          color: #11A9FF
          white-space: nowrap
      .inline-block
        display: inline-block
        vertical-align: text-top
