.flash
  padding: 0 13px
  height: 44px
  display: flex
  align-items: center
  position: relative
  overflow: hidden
  border-radius: 3px
  font-size: 14px
  color: #575563
  &.warning
    background: #FFF3C7
    border: 1px solid #FFE68C
    .dismiss-cross
      &:after, &:before
        background: #f5dd84
  &.success
    background: #CEEFBF
    border: 1px solid #B6E79F
    .dismiss-cross
      &:after, &:before
        background: #6bc046
  &.error
    background: #FFE0DE
    border: 1px solid #FFCDC6
    color: #F5351B
    .dismiss-cross
      &:after, &:before
        background: #feab9f
  &.notice
    background: #CFF0FF
    border: 1px solid #B0E7FF
    .dismiss-cross
      &:after, &:before
        background: #7fc8e9

  svg
    margin-right: 14px
  .dismiss-cross
    margin-left: auto
    position: relative
    width: 16px
    height: 16px
    cursor: pointer
    &:before, &:after
      width: 100%
      height: 2px
      top: 50%
      content: ''
      position: absolute
    &:before
      transform: translateY(-50%) rotateZ(-45deg)
    &:after
      transform: translateY(-50%) rotateZ(45deg)


  //---Add styling for additional components in more complex flashes below
  .company-view-fix-wrap
    color: #11A9FF
    user-select: none
    cursor: pointer
    margin-left: 3px
  .company-view-switch-wrap
    user-select: none
    margin-left: auto
    display: flex
    .switch-text-wrap
      display: inline-block
      color: #6A6A6A
      width: 113px