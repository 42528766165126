// For a speed improvement when developing,
// we should consider the use of `require`:
// http://silvenon.com/speedy-sass-with-import-and-sprockets/
// http://inside.unbounce.com/product-dev/handling-code-duplication/
// http://blog.teamtreehouse.com/tale-front-end-sanity-beware-sass-import

@import "settings"

// Vendor imports

@import "../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap"
@import "../../node_modules/bootstrap-datetimepicker-sass/src/sass/bootstrap-datetimepicker"
@import "../../node_modules/font-awesome/css/font-awesome.css"
@import "../../node_modules/select2/dist/css/select2.css"
@import "../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css"
//@import "loaders.css/loaders"
@import "../../node_modules/nprogress/nprogress.css"
//@import "nprogress-bootstrap"
@import "../../node_modules/croppie/croppie.css"
@import "../../node_modules/video-react/dist/video-react.css"

// Custom styles
@import "base/all"
@import "components/all"
@import "mobile_app"
@import "pages/all"

// Not sure what this is for, was in 'vendor'
.checkbox label::before,
.radio label::before
  top: 5px
.radio label::after
  top: 8px

.content
  padding: 40px

.top-bar.actions
  margin: 30px 0

.body-content
  width: calc(100% - 210px)
  height: auto
  padding: 0 170px
  display: inline-block
  position: relative
  float: left
  overflow-y: scroll

.caddy
  width: auto
  height: auto
  display: inline-block
  vertical-align: top

.logo
  width: auto
  height: 100%
  margin-left: 40px
  display: inline-block
  vertical-align: top

.page-wrapper
  padding: rhythm(1)
  max-width: 1200px
  margin: 0 auto
  @media (max-width: $screen-tablet)
    padding: rhythm(0.5)

.page-inner
  margin: 0 auto
  max-width: 960px

.trend-icon
  font-size: 15px
  font-weight: normal
  padding: 3px
  position: relative
  bottom: 5px

.carousel-indicators
  bottom: 0 !important
  position: inherit
  li
    background-color: $light-grey
  li, li.active
    margin-left: 5px
    margin-right: 5px

.actions.disabled
  opacity: 0.5
  pointer-events: none

// short term fix to remove nprogress spinner (it doesn't fit with the existing designs)
#nprogress .spinner
  display: none !important

.\_pendo-badge\_
  z-index: 1 !important


.picker-dialog-bg
  z-index: 20000 !important

.picker-dialog
  z-index: 20001 !important

