/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
.jobvibe-blue
  color: #0095E6
.la-line-scale-pulse-out
  position: relative
  margin: auto
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box
  > div
    position: relative
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
  display: block
  font-size: 0
  color: #0095E6
  &.la-dark
    color: #333
  > div
    display: inline-block
    float: none
    background-color: currentColor
    border: 0 solid currentColor
    width: 4px
    height: 32px
    margin: 2px
    margin-top: 0
    margin-bottom: 0
    border-radius: 0
    -webkit-animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85)
    -moz-animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85)
    -o-animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85)
    animation: line-scale-pulse-out 0.9s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85)
    &:nth-child(3)
      -webkit-animation-delay: -.9s
      -moz-animation-delay: -.9s
      -o-animation-delay: -.9s
      animation-delay: -.9s
    &:nth-child(2), &:nth-child(4)
      -webkit-animation-delay: -.7s
      -moz-animation-delay: -.7s
      -o-animation-delay: -.7s
      animation-delay: -.7s
    &:nth-child(1), &:nth-child(5)
      -webkit-animation-delay: -.5s
      -moz-animation-delay: -.5s
      -o-animation-delay: -.5s
      animation-delay: -.5s
  width: 40px
  height: 32px
  &.la-sm
    width: 20px
    height: 16px
    > div
      width: 2px
      height: 16px
      margin: 1px
      margin-top: 0
      margin-bottom: 0
  &.la-2x
    width: 80px
    height: 64px
    > div
      width: 8px
      height: 64px
      margin: 4px
      margin-top: 0
      margin-bottom: 0
  &.la-3x
    width: 120px
    height: 96px
    > div
      width: 12px
      height: 96px
      margin: 6px
      margin-top: 0
      margin-bottom: 0
  &.centered-la-2x
    position: absolute
    top: 50%
    left: 50%
    margin-top: -32px
    margin-left: -40px


/*
 * Animation
@-webkit-keyframes line-scale-pulse-out
  0%
    -webkit-transform: scaley(1)
    transform: scaley(1)

  50%
    -webkit-transform: scaley(0.3)
    transform: scaley(0.3)

  100%
    -webkit-transform: scaley(1)
    transform: scaley(1)


@-moz-keyframes line-scale-pulse-out
  0%
    -moz-transform: scaley(1)
    transform: scaley(1)

  50%
    -moz-transform: scaley(0.3)
    transform: scaley(0.3)

  100%
    -moz-transform: scaley(1)
    transform: scaley(1)


@-o-keyframes line-scale-pulse-out
  0%
    -o-transform: scaley(1)
    transform: scaley(1)

  50%
    -o-transform: scaley(0.3)
    transform: scaley(0.3)

  100%
    -o-transform: scaley(1)
    transform: scaley(1)


@keyframes line-scale-pulse-out
  0%
    -webkit-transform: scaley(1)
    -moz-transform: scaley(1)
    -o-transform: scaley(1)
    transform: scaley(1)

  50%
    -webkit-transform: scaley(0.3)
    -moz-transform: scaley(0.3)
    -o-transform: scaley(0.3)
    transform: scaley(0.3)

  100%
    -webkit-transform: scaley(1)
    -moz-transform: scaley(1)
    -o-transform: scaley(1)
    transform: scaley(1)