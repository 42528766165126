@import ./settings

.text__input
  border: none

.react-datepicker
  font-size: 1em

.react-datepicker__header
  padding-top: 0.8em

.react-datepicker__month
  margin: 0.4em 1em

.react-datepicker__day-name, .react-datepicker__day
  width: 1.9em
  line-height: 1.9em
  margin: 0.166em

.react-datepicker__current-month
  font-size: 1em

.react-datepicker__navigation
  top: 1em
  line-height: 1.7em
  border: 0.45em solid transparent

.react-datepicker__navigation--previous
  border-right-color: #ccc
  left: 1em

.react-datepicker__navigation--next
  border-left-color: #ccc
  right: 1em

.form-group label + div
  display: inline

.impersonate
  margin-top: 10px
  margin-left: 15px
  margin-bottom: 25px

.card-types img
  opacity: 0.3

  &.active
    opacity: 1

.new_payment
  //.navbar
    display: none!important
  //.frame-wrapper
    padding-left: 0!important
  p
    margin: 0

  body
    margin-top: 28px

  .flash
    display: none

  @media(max-width: 480px)
    body
      margin-top: 0
    .page-wrapper
      padding: 0

  #address-wrapper
    .form-control
      margin-bottom: 10px
      height: 42px
      color: #3f3f3f
      font-size: 15px
      border-radius: 2px
      box-shadow: none
      border: 1px solid #D9D9D9

.settings.edit
  #address-wrapper
    .form-control
      margin-bottom: 10px
      height: 42px
      color: #3f3f3f
      font-size: 15px
      border-radius: 2px
      box-shadow: none
      border: 1px solid #D9D9D9

.payment_success
  .navbar
    display: none !important

  .frame-wrapper
    padding-left: 0 !important

  p
    margin: 0

  body
    margin-top: 28px

  @media(max-width: 480px)
    body
      margin-top: 0
    .page-wrapper
      padding: 0

.new_offline_invoice
  .react-datepicker-wrapper
    width: 100%

  .react-datepicker__input-container
    width: 100%

  .react-datepicker-popper
    z-index: 10
    transform: translate3d(0, 0, 0) !important
    top: 40px !important

.label.label-success
  background-color: #5cb85c

.account-show
  .actions
    float: right

  .actions
    a
      margin-left: 10px

  .actions
    a
      i
        padding-right: 10px

  dd
    dl
      margin-left: 20px
      margin-bottom: 0

.post-comment-form .controls .upload input.file
  z-index: 10
  cursor: pointer
  top: 0
  left: 0
  opacity: 0
  filter: alpha(opacity=0)

.post-comment-form #upload_preview
  padding: 10px
  max-width: 100%
  max-height: 100%

.mentions-input-wrap
  width: 100%
  transition: .3s
  textarea
    padding: 0
    color: rgba(0, 0, 0, 0)
    border: none
    caret-color: black
    line-height: 1.5

    &::placeholder, &:-ms-input-placeholder
      color: #979797

  .mentions__highlighter
    color: black !important
    overflow: visible !important
    line-height: 1.5

    .mentions__highlighter__substring
      visibility: visible !important

    .hashtag
      color: #1297E3

    .mention
      background-color: #11A9FF
      color: white
      padding: 1px 0
      border-radius: 4px
      text-shadow: 0px 1px white
      position: relative

      &[hovered='false'] .mention-tooltip
        display: none

      .mention-tooltip
        background: white
        position: absolute
        left: 50%
        transform: translateX(-50%)
        box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px, rgba(0, 0, 0, 0.05) -1px -1px 10px
        top: -62px
        display: flex
        align-items: center
        color: black
        font-weight: normal
        white-space: nowrap
        border-radius: 8px
        padding: 10px
        z-index: 1040

        img
          width: 26px
          height: 26px
          border-radius: 100%
          margin-right: 10px

        &:after
          position: absolute
          bottom: 0
          left: 50%
          transform: translate(-50%, 50%) rotateZ(45deg)
          content: ''
          background: white
          border-bottom: 1px solid #E7E7E7
          border-right: 1px solid #E7E7E7
          width: 16px
          height: 16px

        .user-info-wrap
          display: flex
          flex-direction: column

          span:first-child
            font-weight: bold

          span:last-child
            color: #BBBBBB
            font-size: 12px

  div.mentions__suggestions
    top: 10px !important
    z-index: 5 !important
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px, rgba(0, 0, 0, 0.05) -1px -1px 10px
    border-radius: 8px

    .mentions__suggestions__item
      padding: 10px
      transition: background .3s, color .3s

      &:first-child
        border-top-left-radius: 8px
        border-top-right-radius: 8px

      &:last-child
        border-bottom-left-radius: 8px
        border-bottom-right-radius: 8px

      img
        width: 26px
        height: 26px
        border-radius: 100%
        margin-right: 10px

    .mentions__suggestions__item--focused
      background-color: #11A9FF
      color: white

.inline-mention
  display: inline-block
  position: relative
  text-decoration: none !important
  line-height: initial

  &[hovered='false'] .mention-tooltip
    display: none

  .mention-tooltip
    background: white
    position: absolute
    left: 50%
    transform: translateX(-50%)
    box-shadow: rgba(0, 0, 0, 0.05) 1px 1px 10px, rgba(0, 0, 0, 0.05) -1px -1px 10px
    top: -62px
    display: flex
    align-items: center
    color: black
    font-weight: normal
    white-space: nowrap
    border-radius: 8px
    padding: 10px
    text-align: left
    z-index: 1040

    img
      width: 26px
      height: 26px
      border-radius: 100%
      margin-right: 10px

    &:after
      position: absolute
      bottom: 0
      left: 50%
      transform: translate(-50%, 50%) rotateZ(45deg)
      content: ''
      background: white
      border-bottom: 1px solid #E7E7E7
      border-right: 1px solid #E7E7E7
      width: 16px
      height: 16px

    .user-info-wrap
      display: flex
      flex-direction: column

      span:first-child
        font-weight: bold

      span:last-child
        color: #BBBBBB
        font-size: 12px

div.update-form__suggestions
  border: solid thin
  z-index: 3 !important
  top: 10px !important

  .update-form__suggestions__item
    padding: 15px 10px !important

  .update-form__suggestions__item--focused
    padding: 15px 10px !important
    background-color: #337ab7
    color: white

.fix-actions
  .m-action
    margin-top: 3px

.fix-filter-form
  margin-bottom: 5px

.inline-form-label
  margin-right: 5px

body.session
  margin-top: 0

body.password
  margin-top: 0

.image-input
  .fa-pencil
    position: absolute
    right: 0
    bottom: 0
    padding: 6px 8px
    color: rgba(0, 0, 0, 0.4)
    font-size: 17px
    background-color: rgba(0, 0, 0, 0.1)
    border-radius: 3px 0 0 0
    line-height: normal

.cancel-modal
  margin-right: 20px
  margin-top: 10px

.buzz_content
  .form-control
    padding: 0

.navbar-header
  text-align: center

.navbar-toggle
  margin-left: 20px

body.platform_overview.index
  margin-top: 0
  border-top: 0

.comment_content .text--multiLine
  padding: 0

.panel-body.u-padding-vertical-none.message-input
  min-height: 0

.checkbox label
  display: inline-block
  vertical-align: middle
  position: relative
  padding-left: 5px

.help-block.small
  font-size: 75%

.centered.u-margin-vertical-sm
  font-size: 1.6rem
  line-height: 28px

.pace-wrap
  position: fixed
  top: 0

  div
    position: fixed

body.sessions.new
  margin: 0 !important
  +phones-and-tablets
    background: white !important

body.registrations.new
  margin: 0 !important
  +phones-and-tablets
    background: white !important

  #error-message-name, #error-message-email, #error-message-terms
    color: #CD3C40 !important

  #g-recaptcha
    display: inline-block

.in-line-wrap
  display: inline-block

  &.media-left
    padding-left: 10px

body.full-screen div#root
  +phones-and-tablets
    height: 100%

body.full-screen .route-wrapper
  +phones-and-tablets
    height: 100%
    .select_sentiment .panel-body
      position: absolute
      transform: translateY(-60%)

.other-factors-screen
  .panel-body
    +mobile-only
      position: absolute

.other-factors-screen .panel-body.u-padding-vertical-none
  padding-left: 15px !important
  padding-right: 15px !important

.vibe-factors .col-sm-6
  padding-left: 8px !important
  padding-right: 8px !important

body
  +phones-and-tablets
  min-height: 100%

  div#root
    +phones-and-tablets
    height: 100%

.audience.pull-right.pull-xs-none.u-margin-right-xs
  float: right !important

html
  +phones-and-tablets
  height: 100%

.select.optional.user_lead_teams
  margin-bottom: 28px

.btn-group.visible-xs-block.visible-sm-block.clearfix.u-margin-vertical-xs.btn-group-justified
  display: none !important

.default-factors-screen .panel-footer
  +phones-and-tablets
  position: inherit !important

.why-factors-screen .panel-footer
  +phones-and-tablets
  position: inherit !important

body.webview
  margin-top: 0

.panel-body.panel-body-xs.panel-messages
  min-height: 0px
  padding-bottom: 0px
  padding-top: 5px

.checkbox.privacy-terms-checkbox
  position: relative
  max-height: none
  margin-top: 10px
  margin-bottom: 10px

.checkbox.child-teams
  padding: 10px 20px 25px

.toggle-all-factors.u-margin-bottom-sm a
  margin-bottom: 24px

.panel-footer.clear
  margin-top: 28px

.panel-footer.clear.secondary
  margin-top: 0

.add-another-users-box
  display: inline-block

  a
    cursor: pointer

  +desktop-only
    margin-top: 14px

.new-invites-container
  .row.first-row
    +desktop-only
      .col-sm-2
        margin-bottom: -14px
      .col-sm-4
        margin-bottom: -14px

.new-invites-container
  .row.first-row
    div.form-group.string.optional
      +desktop-only
        margin-bottom: 14px

.new-invites-container
  .row.first-row
    label.hidden-for-mobile
      +mobile-only
        display: none

      .col-sm-2
        margin-bottom: -14px

      .col-sm-4
        margin-bottom: -14px

    div.col-sm-2:last-child
      +mobile-only
        margin-bottom: 28px

  .row
    div.form-group.string.optional
      +mobile-only
        margin-bottom: 5px

.notification-list ul.no-notifications
  height: calc(15vh - 56px - 4px)

.teams
  ul.react-autocomplete-input
    left: 0 !important

.loading-scale
  left: 0
  right: 0
  top: 0
  bottom: 0
  position: fixed
  z-index: 2000
  background: rgba(0, 0, 0, 0.75)

.loading-scale-box
  position: relative
  z-index: 2000
  height: 154px

.loader-box
  position: absolute
  top: 50%
  transform: translateY(-50%)
  width: 100%

h5.horizontal-line
  width: 100%
  text-align: center
  border-bottom: 1px solid #C7C7C7
  line-height: 0.1em
  margin: 30px 0 20px

  span
    color: #000
    background: #fff
    padding: 0 20px

#sign-up-tooltip, #sign-up-tooltip-google
  margin-left: 2px
  padding: 15px 25px 15px 31px
  background-color: #8B8A96
  color: #FFFFFF
  font-size: 14px
  line-height: 18px
  text-align: center
  opacity: 1

.__react_component_tooltip.place-top
  margin-top: -24px !important
  box-shadow: rgba(0, 0, 0, 0.15) 0 4px 10px

  &::after
    border-top: 20px solid #8B8A96 !important
    border-left: 13px solid transparent !important
    border-right: 13px solid transparent !important
    bottom: -20px
    width: 22px

.user-link
  color: $new-black

  &:hover
    text-decoration: none

body.jobvibe-future
  margin-top: 0

.cursor-not-allowed
  cursor: not-allowed !important

.input-required
  color: #F5351B

.menu-icon
  display: inline-block

.menu-icon > .line
  width: 12px
  background-color: #D5D5D5
  height: 2px
  display: block

.menu-icon > .line:last-child
  width: 6px

.menu-icon > .line + .line
  margin-top: 2px


//new styles 

