// These need to be imported here to be
// accessible by the rest of the project.
// Not too sure why...

// fonts
// Use system Proxima Nova first, then Typekit
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700')
@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap')

$roboto-font: 'Roboto', sans-serif
$default-font-family: 'Proxima Nova', 'proxima-nova', sans-serif
$icon-font-path: '../assets/'
$font-size-base: 16px

// colors
$yellow: #FEC334
$red: #CD3C40
$light-blue: #ecf7fd
$blue: #0095E6
$deep-blue: #1297E3
$dark-blue: #063C5B
$green: #26C381
$bright-green: #09D286
$pale-green: #f1fcf8
$yellow: #fdc029
$orange: #FDAB00
$amber: #FBAA00
$pale-amber: lighten($amber, 45)

$charcoal: #3f3f3f
$dark-grey: #464646
$blue-grey: #496575
$mid-grey: #9D9D9D
$pale-grey: #C7C7C7
$light-grey: #D9D9D9
$super-light-grey: #fafafa
$white: #FFF

$dark-purple: #575563
$mid-purple: #706E84
$background-beige: #F3F3F3
$new-blue: #11A9FF
$new-red: #FE483C
$new-orange: #FE873C
$new-light-orange: #FFB932
$new-yellow: #FFD326
$new-beige: #EDECEF
$new-black: #323232

// global
$background-light: #fafafa
$body-bg: #EFEFEF
$brand-primary: $deep-blue
$brand-success: $pale-green
$link-color: $deep-blue
$text-color: $dark-grey
$hr-border: $super-light-grey
$hr-border-darker: $light-grey

$base-font-size: 16
$base-line-height: 1.75 * $base-font-size

@function rhythm($lines, $vertical-line-height: $base-line-height)
  @return $lines * $vertical-line-height * 1px
@function r($lines, $vertical-line-height: $base-line-height)
  @return rhythm($lines, $vertical-line-height: $base-line-height)

$baseline: 14px

// sidebar
$sidebar-width: 230px
$sidebar-background-color: #575563
$sidebar-text-color: $white
$sidebar-hover-background-color: lighten($sidebar-background-color, 2%)
$sidebar-hover-text-color: white
$sidebar-active-background-color: lighten($sidebar-background-color, 5%)
$sidebar-active-text-color: white
$default-border-color: #ecf1f4
$sidebar-default-link-hover-color: $sidebar-active-text-color
$sidebar-default-link-hover-bg: lighten($sidebar-active-background-color, 5)

// navbar
$navbar-default-brand-hover-bg: $sidebar-background-color
// $navbar-default-bg: red # navbar bottom line
$nav-tabs-active-link-hover-bg: white
// header height is 2.5 * vertical rhythm
$header-height: 54px

$new-header-height: 124px

// tables and lists
$table-bg-hover: lighten($yellow, 35)
$table-bg-hover-text-color: darken($yellow, 40)
$table-link-color: $dark-grey
$table-cell-padding: rhythm(0.5)
$table-border-color: $super-light-grey

// forms
$input-height: rhythm(1.5)
$field-border-color: $deep-blue
$field-background-color: white
$field-border-radius: 2px
$callout-button-color: $deep-blue
$button-border-radius: 4px
$form-group-margin-bottom: rhythm(1)
$panel-footer-padding: 0 rhythm(0.5)

$state-danger-text: $red

$btn-primary-color: white
$btn-primary-bg: $blue
$btn-primary-border: $blue

$input-color: $charcoal

// modals
$modal-lg: 900px !default
$modal-md: 600px !default
$modal-sm: 430px !default

// Tooltips
$tooltip-bg: $sidebar-background-color
$tooltip-opacity: 0.85

// dropdowns
$dropdown-link-hover-bg: $table-bg-hover
$dropdown-link-hover-color: $table-bg-hover-text-color

// labels
$large-label-background-color: #DEDEDE

// cards
$card-border-radius: 2px
$card-border-radius-inner: 2px
$card-spacer-x: rhythm(0.75)
$card-spacer-y: rhythm(1)
$card-bg: $white
$card-cap-bg: $white
$card-border-width: 1px
$card-border-color: $light-grey

// Panels
$panel-body-padding: rhythm(1)

// other
$carousel-indicator-border-color: transparent
$carousel-indicator-active-bg: $mid-grey

// Sizing

// 25px
$font-size-h1: floor(($font-size-base * 1.6))
// 22px
$font-size-h2: floor(($font-size-base * 1.4))
// 20px
$font-size-h3: $font-size-base * 1.25
// 18px
$font-size-h4: floor(($font-size-base * 1.15))
// 14px
$font-size-h5: floor(($font-size-base * 0.9))
// 12px
$font-size-h6: $font-size-base * 0.75

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default
$screen-sm-min: $screen-sm !default
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default
$screen-md-min: $screen-md !default
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default
$screen-lg-min: $screen-lg !default
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default
$screen-sm-max: ($screen-md-min - 1) !default
$screen-md-max: ($screen-lg-min - 1) !default

// Shorthand (deprecated)
=mobile-only
  @media (max-width: $screen-xs-min - 1)
    @content

=desktop-only
  @media (min-width: $screen-sm-min)
    @content

=phones-and-tablets
  @media (max-width: $screen-xs-max)
    @content
