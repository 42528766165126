.tl-dash
  a:focus,
  a:active
    outline: none

  .btn-own
    top: -1px
    position: relative

.dash-charts
  .panel-body
    height: rhythm(9.5)

.top-factors
  .panel-body
    min-height: rhythm(7)
    @media (max-width: $screen-md-min)
      min-height: auto

.member-rows .avatar-number
  display: none

.member-row:last-of-type .avatar-number
  text-align: center
  display: inline-block
  height: rhythm(1)
  padding: rhythm(0.5) 0
  box-sizing: content-box
  width: rhythm(2)
  border-radius: 200px
  background: $sidebar-background-color
  color: white
  font-size: 14px
  line-height: rhythm(0.5)
  vertical-align: middle

// Fix issue with comment field being clipped
// in dashboard modal

#full-action-items .comment-list
  padding-bottom: rhythm(0.25)
