body.Error
  background: #EFEFEF
  padding: 30px
  font-family: 'Proxima Nova', 'proxima-nova', sans-serif
  font-size: 18px
  line-height: 28px
  color: #404749
  img
    max-width: 100%
  .ErrorPage
    background: white
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.12), 0px 2px 2px 0px rgba(0,0,0,0.24)
    border-radius: 3px
    padding: 30px 30px 50px 30px
    margin: 0 auto
    max-width: 460px
    text-align: center
  p
    margin-top: 10px
    margin-bottom: 0
  p + p
    margin-top: 0

  .ErrorPage-back
    display: inline-block
    margin-top: 20px
    color: white
    text-decoration: none
    padding: 5px 20px
    border-radius: 3px
    background: #1297E3
    font-size: 16px
    letter-spacing: 0.05em

  body.Error .ErrorPage-back:hover
    background: #1297C8
  body.Error .ErrorPage-type img
    max-width: 100%
.u-marginTl
  margin-top: 20px
.u-marginTm
  margin-top: 10px