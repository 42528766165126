#mood-vs-factor-vibe
  .top-controls-wrap
    display: flex
    align-items: center
    @media (min-width: 991px)
      padding: 0 0 35px
    .period-switch-wrap
      display: flex
      flex-shrink: 0
      span
        font-size: 14px
    @media(max-width: $screen-sm-max)
      flex-direction: column
      align-items: center
      .team-select
        margin-right: -11px
      .date-range-wrap
        margin-left: 0
        margin-top: 31px
        width: 100%
        .date-range
          margin-right: 0
          flex-grow: 1
          &:after
            right: 12px
  #chart-categories
    width: 100% !important
    #categories-btns
      width: 100%
      display: flex
      flex-direction: row
      flex-wrap: wrap
      padding: 0
      .category-btn
        display: flex
        justify-content: center
        flex-direction: column
        font-family: Roboto, sans-serif
        font-style: normal
        font-weight: normal
        line-height: 17px
        font-size: 10px
        text-transform: uppercase
        color: #323232
        background: #FFFFFF
        cursor: pointer
        vertical-align: middle
        margin-top: 10px
        &.factor-select
          margin-right: 12px
          width: 240px
          @media (min-width: 1200px)
            width: 270px
          div.Select.is-focused
            .Select-control
              border-color: #E6E6E6
              box-shadow:  0 1px 3px rgba(0, 0, 0, 0.15)
          .Select-placeholder
            padding: 11px 0 0 23px
          .Select-menu
            overflow-y: hidden
            max-height: 400px
          .Select-menu-outer
            max-height: 400px
          .Select-control
            padding: 10px 0 10px 0
          .Select-value
            padding: 11px 0 0 23px
          .Select-value-label
            text-transform: uppercase
            font-weight: bold
            font-size: 13px
            line-height: 18px
          .Select-clear-zone
            display: none
      .category-btn:not(.factor-select)
        text-align: center
        border: 1px solid #E6E6E6
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15)
        border-radius: 4px
        &:not(:last-of-type)
          margin-right: 12px
        &.active, &:active, &:focus
          &.terrible
            border: 2px solid #FE483C
          &.bad
            border: 2px solid #FF7F63
          &.okay
            border: 2px solid #BEB49E
          &.good
            border: 2px solid #8ED04C
          &.awesome
            border: 2px solid #52BA05
        &:not(.active):hover
          &.terrible
            border: 1px solid #FE483C
          &.bad
            border: 1px solid #FF7F63
          &.okay
            border: 1px solid #BEB49E
          &.good
            border: 1px solid #8ED04C
          &.awesome
            border: 1px solid #52BA05
        margin-bottom: 0
        margin-left: 0
        padding: 12px 12px
        @media (min-width: 1044px)
          padding: 12px 27px
        .mood-icon
          width: 29px
          height: 29px
  #stats-wrapper
    height: 367px
  #mood-over-time-chart
    margin-top: 40px
    height: 100%
    canvas
      margin-top: -40px
  .detailed-feedback-section
    margin-top: 38px
    padding: 0 15px
    h1
      font-weight: bold
      line-height: initial
      font-size: 22px
      color: #323232
      margin-bottom: 21px
      @media(max-width: 1080px)
        font-size: 16px
      svg
        position: relative
        left: 18px
        top: -2px
        @media(max-width: 1080px)
          left: 14px
          top: 0
          width: 24px
    h2
      font-weight: bold
      font-size: 14px
      text-transform: uppercase
      margin-bottom: 8px
      &.positive
        color: #52BA05
      &.neutral
        color: #BEB49E
      &.negative
        color: #F5351B
    .single-factor
      font-size: 13px
      line-height: 27px
      color: #323232
  .anonymous-comments-section
    margin-top: 37px
    padding: 0 15px
    h1
      font-weight: bold
      line-height: initial
      font-size: 22px
      color: #323232
      margin-bottom: 24px
      @media(max-width: 1080px)
        font-size: 16px
      svg
        position: relative
        top: -2px
        left: 18px
        @media(max-width: 1080px)
          left: 14px
          top: 0
          width: 24px
    .anonymous-comments-section
      margin-top: 0
      padding: 0
    .anonymous-comment
      line-height: 18px
      align-items: center
      margin-bottom: 25px
      img
        width: 24px
        height: 24px
        margin-right: 14px
        align-self: flex-start
      .comment-content
        font-size: 14px
        margin-right: 13px
        @media(max-width: 1080px)
          width: calc(100% - 28px)
          margin-right: 0
      .date
        font-size: 13px
        color: #979797

.mood-vs-factor-wrapper
  @media (max-width: 895px)
    width: 100%
    overflow-x: scroll
    position: relative
    .mood-vs-factor-chart
      @media (max-width: 480px)
        width: 300%
      @media (max-width: 895px)
        width: 200%
        white-space: nowrap


